import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NetworkService } from '../../../core/net-utils/network.service';
import {
  CreateRegionMapper,
  ModifyRegionMapper,
  RegionDetailsMapper,
  RegionsCountriesListsMapper,
  RegionsListsMapper,
} from '../../update-country-table/mappers/regions-lists-mapper';
import {
  RegionsCountriesListModel,
  RegionsListsModel,
} from '../models/region-filters-model';
import { RegionDetailsModel } from '../models/region-details-model';
import { ClassModel } from '../models/class-model';
import {
  ClassesListsMapper,
  CreateClassification,
} from '../mappers/classes-mapper';

@Injectable({
  providedIn: 'root',
})
export class RegionsManagementService {
  networkService?: NetworkService;
  baseUrl = `${environment.backend.endpoint}/data-management-module`;
  private emptyMapper = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fillFromJson(_json: any): void {},
    fillToJson(): string {
      return '{}';
    },
    toString(): string {
      return '';
    },
  };

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getRegionsList(classID?: string): Promise<RegionsListsModel[]> {
    const regionsListMapper = new RegionsListsMapper();
    await this.networkService?.get(
      `${this.baseUrl}/region`,
      regionsListMapper,
      classID ? { classID } : undefined
    );
    return regionsListMapper.regionsList;
  }

  async getClassList(): Promise<ClassModel[]> {
    const classesListMapper = new ClassesListsMapper();
    await this.networkService?.get(
      `${this.baseUrl}/classification`,
      classesListMapper
    );
    return classesListMapper.classesList;
  }

  async getCountriesByRegions(
    regions?: string[]
  ): Promise<RegionsCountriesListModel[]> {
    try {
      const regionscountriesListMapper = new RegionsCountriesListsMapper();
      await this.networkService?.get(
        `${this.baseUrl}/country`,
        regionscountriesListMapper,
        { regionIDs: JSON.stringify(regions) }
      );
      return regionscountriesListMapper.regionsCountriesList;
    } catch (e) {
      return [];
    }
  }

  async getRegionDetails(regionId: string): Promise<RegionDetailsModel> {
    const mapper = new RegionDetailsMapper();
    await this.networkService?.get(
      `${this.baseUrl}/region/${regionId}`,
      mapper
    );
    return mapper.regionDetails[0];
  }

  async createRegion(
    regionName: string | null,
    regionCode: string | null,
    regionParent?: string | null,
    regionClass?: string | null,
    years?: any[]
  ): Promise<any> {
    const mapper = new CreateRegionMapper();
    mapper.regionName = regionName || '';
    mapper.regionCode = regionCode || '';
    mapper.parentRegionID = regionParent || '';
    mapper.classID = regionClass || '';
    mapper.years = years || undefined;
    await this.networkService?.post(`${this.baseUrl}/region`, mapper, mapper);
    return mapper.value;
  }

  async modifyRegion(region: RegionDetailsModel): Promise<any> {
    const mapper = new ModifyRegionMapper();
    mapper.regionCode = region.regionCode;
    mapper.regionName = region.regionName;
    if (region.classID) {
      mapper.classID = region?.classID;
    }
    if (region.parentRegionID) {
      mapper.parentRegionID = region?.parentRegionID;
    }
    mapper.years = region.years;
    await this.networkService?.put(
      `${this.baseUrl}/region/${region.regionID}`,
      mapper,
      mapper
    );
    return mapper.value;
  }

  async deleteRegion(regionId: string): Promise<void> {
    await this.networkService?.delete(
      `${this.baseUrl}/region/${regionId}`,
      this.emptyMapper
    );
  }

  async createClassification(
    className: string,
    classCode: string,
    classDescription: string
  ): Promise<any> {
    const mapper = new CreateClassification();
    mapper.className = className;
    mapper.classCode = classCode;
    mapper.classDescription = classDescription;
    await this.networkService?.post(
      `${this.baseUrl}/classification`,
      mapper,
      mapper
    );
    return mapper.classId;
  }
}
