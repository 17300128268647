import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/net-utils/network.service';
import { environment } from 'src/environments/environment';
import { CollectionModel } from '../models/collection-model';
import { ListCollectionsMapper } from '../mappers/list-collections-mapper';

@Injectable({
  providedIn: 'root',
})
export class CollectionFilterService {
  networkService?: NetworkService;
  baseUrl = `${environment.backend.endpoint}/data-management-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getCollectionsList(): Promise<CollectionModel[]> {
    const collectionsListMapper = new ListCollectionsMapper();
    await this.networkService?.get(
      `${this.baseUrl}/collection`,
      collectionsListMapper
    );
    return collectionsListMapper.collectionsList;
  }
}
