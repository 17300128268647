import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { environment } from '../../../../environments/environment';
import {
  ContactDetailsMapper,
  ContactListMapper,
  CreateUpdateContactMapper,
  DeleteContactMapper,
} from '../mappers/contact-mapper';
import { ContactModel, ContactsListModel } from '../models/contact-model';
import { SurveySeriesModel } from '../models/survey-series-model';
import { SurveySeriesListMapper } from '../mappers/survey-series-mapper';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private networkService!: NetworkService;
  baseUrl = `${environment.backend.endpoint}/contact-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getContactAndCount(
    limit?: number,
    offset?: number,
    orderBy?: string,
    order?: 'asc' | 'desc',
    countryId?: string,
    surveySeriesIds?: string,
    linkedToAccount?: string | null
  ): Promise<ContactsListModel> {
    const contactListMapper = new ContactListMapper();
    await this.networkService?.get(
      `${this.baseUrl}/contact`,
      contactListMapper,
      {
        limit: limit?.toString(),
        offset: offset?.toString(),
        orderBy: orderBy ?? 'first_name',
        order: order ?? 'asc',
        countryId: countryId,
        surveySeriesIds:
          surveySeriesIds?.length === 0 ? undefined : surveySeriesIds,
        linkedToAccount: linkedToAccount,
      }
    );
    return {
      contacts: contactListMapper.contacts,
      count: contactListMapper.count,
    };
  }

  async getContactDetails(contactId: string): Promise<ContactModel> {
    const contactDetailsMapper = new ContactDetailsMapper();
    await this.networkService?.get(
      `${this.baseUrl}/contact/${contactId}`,
      contactDetailsMapper
    );
    return contactDetailsMapper.contact;
  }

  async getSurverySeries(): Promise<SurveySeriesModel[]> {
    const surveySeriesListMapper = new SurveySeriesListMapper();
    await this.networkService?.get(
      `${this.baseUrl}/survey_series`,
      surveySeriesListMapper
    );
    return surveySeriesListMapper.surveySeries;
  }

  async createContact(
    countryId: string,
    surveySeriesIds: string,
    prefix: string,
    firstName: string,
    lastName: string,
    title: string,
    organisationName: string,
    phoneNumber: string,
    email: string,
    website: string,
    internalNote?: string | undefined
  ): Promise<void> {
    const createContactMapper = new CreateUpdateContactMapper();
    createContactMapper.countryId = countryId;
    createContactMapper.surveySeriesIds = surveySeriesIds;
    createContactMapper.prefix = prefix;
    createContactMapper.firstName = firstName;
    createContactMapper.lastName = lastName;
    createContactMapper.title = title;
    createContactMapper.organisationName = organisationName;
    createContactMapper.phoneNumber = phoneNumber;
    createContactMapper.email = email;
    createContactMapper.website = website;
    createContactMapper.internalNote = internalNote ?? null;
    await this.networkService?.post(
      `${this.baseUrl}/contact`,
      createContactMapper,
      createContactMapper
    );
  }

  async updateContact(
    contactId: string,
    countryId: string,
    surveySeriesIds: string,
    prefix: string,
    firstName: string,
    lastName: string,
    title: string,
    organisationName: string,
    phoneNumber: string,
    email: string,
    website: string,
    internalNote?: string | undefined
  ): Promise<void> {
    const updateContactMapper = new CreateUpdateContactMapper();
    updateContactMapper.countryId = countryId;
    updateContactMapper.surveySeriesIds = surveySeriesIds;
    updateContactMapper.prefix = prefix;
    updateContactMapper.firstName = firstName;
    updateContactMapper.lastName = lastName;
    updateContactMapper.title = title;
    updateContactMapper.organisationName = organisationName;
    updateContactMapper.phoneNumber = phoneNumber;
    updateContactMapper.email = email;
    updateContactMapper.website = website;
    updateContactMapper.internalNote = internalNote ?? null;
    await this.networkService?.put(
      `${this.baseUrl}/contact/${contactId}`,
      updateContactMapper,
      updateContactMapper
    );
  }

  async deleteContact(id: string): Promise<void> {
    const deleteContactMapper = new DeleteContactMapper();
    await this.networkService?.delete(
      `${this.baseUrl}/contact/${id}`,
      deleteContactMapper
    );
  }
}
