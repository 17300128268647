import { Component, OnInit } from '@angular/core';
import { SurveyModel } from '../survey-table/models/survey-model';
import { CountrySurveyModel } from '../survey-country-table/models/country-survey-model';
import { MatRadioChange } from '@angular/material/radio';
import { ValidationModuleListCodesModel } from '../validation-module-list-codes/models/validation-module-list-codes-models';
import { FormControl, FormGroup } from '@angular/forms';
import { ProviderService } from 'src/app/core/provider.service';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

export enum ValidationModuleSteps {
  selectSurvey,
  selectCountry,
  selectCode,
  validateContact,
  showCountryValidationTable,
  showCodeValidationTable,
}

@Component({
  selector: 'app-validation-module',
  templateUrl: './validation-module.component.html',
  styleUrls: ['./validation-module.component.scss'],
})
export class ValidationModuleComponent extends AntiMemLeak implements OnInit {
  protected readonly ValidationModuleSteps = ValidationModuleSteps;
  currentStep: ValidationModuleSteps = ValidationModuleSteps.selectSurvey;
  selectedSurvey?: SurveyModel;
  selectedCountry?: CountrySurveyModel;
  selectedCode?: ValidationModuleListCodesModel;
  radioButtonSelectedOption: 'country' | 'code' = 'country';
  filteredCodes: ValidationModuleListCodesModel[] = [];
  codesPageNumber = 0;
  codesNumber = 0;
  totalCodes = 0;
  codeSelectLoading = false;
  codeFormGroup = new FormGroup({
    code: new FormControl({} as ValidationModuleListCodesModel),
    autocompleteCode: new FormControl(''),
  });
  selectedCodeChangedSubject = new Subject<ValidationModuleListCodesModel>();
  loading = false;

  constructor(
    private providerService: ProviderService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.codeFormGroup.controls.autocompleteCode.valueChanges
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe((value) => {
          this.providerService.validationModuleListCodesService
            .listCodes(
              this.selectedSurvey!.surveyId,
              0,
              50,
              value ? [value] : undefined
            )
            .then((value) => {
              this.codesPageNumber = 0;
              this.filteredCodes = value.data;
              this.codesNumber = 50;
              this.totalCodes = value.total;
              if (
                this.filteredCodes.find(
                  (code: ValidationModuleListCodesModel) =>
                    code.codeId === this.selectedCode!.codeId
                ) === undefined
              ) {
                this.filteredCodes.push(this.selectedCode!);
              }
            });
        })
    );
    this.subscriptions.add(
      this.codeFormGroup.controls.code.valueChanges.subscribe((value) => {
        if (value && value !== this.selectedCode) {
          this.selectedCodeChangedSubject.next(value);
        }
      })
    );
    this.subscriptions.add(
      this.router.events.subscribe(async (event) => {
        if (event instanceof NavigationStart) {
          if (event.navigationTrigger === 'popstate') {
            if (event.url.includes('?')) {
              const urlSearchParams = new URLSearchParams(
                event.url.split('?')[1]
              );
              const params = {
                surveyID: urlSearchParams.get('surveyID'),
                selectionType: urlSearchParams.get('selectionType'),
                codeID: urlSearchParams.get('codeID'),
                countryID: urlSearchParams.get('countryID'),
                validatingContact: urlSearchParams.get('validatingContact'),
              };
              this.checkParams(params);
            } else {
              this.checkParams({});
            }
          }
        }
      })
    );
    this.checkParams();
  }

  async checkParams(params = this.route.snapshot.queryParams): Promise<void> {
    this.loading = true;
    try {
      const surveyId = params['surveyID'];
      let selectedStep = ValidationModuleSteps.selectSurvey;
      if (surveyId) {
        this.selectedSurvey =
          await this.providerService.validationModuleService.getSurvey(
            surveyId
          );
        selectedStep = ValidationModuleSteps.selectCountry;
        const selectionType = params['selectionType'];
        if (selectionType) {
          if (selectionType === 'code') {
            this.radioButtonSelectedOption = 'code';
            selectedStep = ValidationModuleSteps.selectCode;
          } else {
            this.radioButtonSelectedOption = 'country';
            selectedStep = ValidationModuleSteps.selectCountry;
          }
          const codeId = params['codeID'];
          const countryId = params['countryID'];
          const validatingContact = params['validatingContact'];
          if (codeId) {
            this.selectedCode =
              await this.providerService.validationModuleService.getCode(
                codeId
              );
            const codes =
              await this.providerService.validationModuleListCodesService.listCodes(
                this.selectedSurvey.surveyId,
                this.codesPageNumber,
                50
              );
            this.codesPageNumber = 0;
            this.filteredCodes = codes.data;
            this.codesNumber = 50;
            this.totalCodes = codes.total;
            if (
              this.filteredCodes.find(
                (code: ValidationModuleListCodesModel) =>
                  code.codeId === this.selectedCode?.codeId
              ) === undefined
            ) {
              this.filteredCodes.push(this.selectedCode);
            }
            this.codeFormGroup.controls.code.setValue(this.selectedCode, {
              emitEvent: false,
            });
            selectedStep = ValidationModuleSteps.showCodeValidationTable;
          } else if (!!countryId && validatingContact === 'true') {
            this.selectedCountry =
              await this.providerService.validationModuleService.getCountry(
                countryId
              );
            selectedStep = ValidationModuleSteps.validateContact;
          } else if (countryId) {
            this.selectedCountry =
              await this.providerService.validationModuleService.getCountry(
                countryId
              );
            selectedStep = ValidationModuleSteps.showCountryValidationTable;
          }
        }
      } else {
        this.removeAllQueryParams();
      }
      this.currentStep = selectedStep;
    } catch (e) {
      this.removeAllQueryParams();
      this.currentStep = ValidationModuleSteps.selectSurvey;
    } finally {
      this.loading = false;
    }
  }

  compareCodes(o1: any, o2: any): boolean {
    return o1.codeId === o2.codeId;
  }

  async nearEndCodeScroll(): Promise<void> {
    this.codeSelectLoading = true;
    try {
      if (this.totalCodes > this.codesNumber) {
        const backendResponse =
          await this.providerService.validationModuleListCodesService.listCodes(
            this.selectedSurvey!.surveyId,
            this.codesPageNumber,
            50
          );
        this.filteredCodes.push(...backendResponse.data);
        this.codesPageNumber += 1;
        this.codesNumber += 50;
      }
    } finally {
      this.codeSelectLoading = false;
    }
  }

  selectSurvey(survey: SurveyModel): void {
    this.selectedSurvey = survey;
    this.providerService.validationModuleListCodesService
      .listCodes(this.selectedSurvey.surveyId, this.codesPageNumber, 50)
      .then((value) => {
        this.codesPageNumber = 0;
        this.filteredCodes = value.data;
        this.codesNumber = 50;
        this.totalCodes = value.total;
      });
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { surveyID: survey.surveyId },
      queryParamsHandling: 'merge',
    });
    this.radioButtonSelectedOption = 'country';
    this.currentStep = ValidationModuleSteps.selectCountry;
  }

  selectCountry(country: CountrySurveyModel): void {
    this.selectedCountry = country;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { countryID: country.countryID, selectionType: 'country' },
      queryParamsHandling: 'merge',
    });
    this.currentStep = ValidationModuleSteps.showCountryValidationTable;
  }

  validateContact(country: CountrySurveyModel): void {
    this.selectedCountry = country;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        countryID: country.countryID,
        selectionType: 'country',
        validatingContact: true,
      },
      queryParamsHandling: 'merge',
    });
    this.currentStep = ValidationModuleSteps.validateContact;
  }

  selectByChanged($event: MatRadioChange): void {
    if ($event.value === 'country') {
      this.currentStep = ValidationModuleSteps.selectCountry;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { selectionType: 'country' },
        queryParamsHandling: 'merge',
      });
    } else if ($event.value === 'code') {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { selectionType: 'code' },
        queryParamsHandling: 'merge',
      });
      this.currentStep = ValidationModuleSteps.selectCode;
    }
  }

  selectCode($event: ValidationModuleListCodesModel): void {
    this.selectedCode = $event;
    if (
      this.filteredCodes.find(
        (code: ValidationModuleListCodesModel) => code.codeId === $event.codeId
      ) === undefined
    ) {
      this.filteredCodes.push($event);
    }
    this.codeFormGroup.controls.code.setValue($event, {
      emitEvent: false,
    });
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { codeID: $event.codeId },
      queryParamsHandling: 'merge',
    });
    this.currentStep = ValidationModuleSteps.showCodeValidationTable;
  }

  goBackTo(step: ValidationModuleSteps): void {
    switch (step) {
      case ValidationModuleSteps.selectSurvey:
        this.removeQueryParam(['selectionType', 'surveyID']);
        break;
      case ValidationModuleSteps.selectCountry:
        this.removeQueryParam(['countryID', 'validatingContact']);
        this.radioButtonSelectedOption = 'country';
        break;
      case ValidationModuleSteps.selectCode:
        this.removeQueryParam(['codeID']);
        this.radioButtonSelectedOption = 'code';
        break;
    }
    this.currentStep = step;
  }

  goBackToHome(): void {
    this.providerService.utilService.navigateTo('/app/home');
  }

  async removeQueryParam(params: string[]): Promise<void> {
    const queryParams = { ...this.route.snapshot.queryParams };
    params.forEach((param) => {
      delete queryParams[param];
    });
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'replace',
    });
  }

  removeAllQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      queryParamsHandling: 'replace',
    });
  }

  async validationCompleted(): Promise<void> {
    await this.removeQueryParam(['countryID', 'validatingContact']);
    this.checkParams();
  }
}
