<div class="sign-in-container">
  <div class="sign-in-logo-container">
    <img class="logo" src="assets/images/logo.svg" title="logo" alt="logo" />
  </div>
  @if (!showChangePasswordForm) {
    <div class="sign-in-form-container">

      <h1>{{ 'SIGN_IN.SIGN_IN' | translate }}</h1>
      <h3>{{ 'SIGN_IN.INSERT_CREDENTIALS' | translate }}</h3>

      <div class="auth-form">
        <form spellcheck="false" [formGroup]="form">

          <mat-form-field appearance="fill">
            <mat-label class="label-sign-in">{{ 'SIGN_IN.EMAIL_LABEL' | translate }}</mat-label>
            <input matInput type="email" id="email" name="email" formControlName="email"
                   placeholder="{{'SIGN_IN.EMAIL_PLACEHOLDER' | translate}}">
            <i class="fa fa-regular fa-envelope" matSuffix></i>
            <mat-hint>{{ 'SIGN_IN.EMAIL_HINT' | translate }}</mat-hint>
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'email'" [icon]="'fa-regular fa-circle-exclamation'"
                            message="{{'SIGN_IN.EMAIL_ERROR' | translate}}"></app-validator-ui>

          <mat-form-field appearance="fill">
            <mat-label class="label-sign-in">{{ 'SIGN_IN.PASSWORD_LABEL' | translate }}</mat-label>
            <input matInput [type]="showPassword ? 'text' : 'password'" id="password" name="password"
                   formControlName="password" (keydown.enter)="signIn()">
            <button class="transparent" matSuffix type="button" (click)="showPassword = !showPassword">
              <i class="fa-regular {{ showPassword ? 'fa-eye-slash' : 'fa-eye' }}"></i>
            </button>
            <mat-hint>{{ 'SIGN_IN.PASSWORD_HINT' | translate }}</mat-hint>
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'password'" [icon]="'fa-regular fa-circle-exclamation'"
                            message="{{'SIGN_IN.PASSWORD_ERROR' | translate}}"></app-validator-ui>

          <button color="primary" mat-raised-button type="button" class="confirm-button" (click)="signIn()"
                  [disabled]="loading">
            @if (loading) {
              <span class="fa fa-spin fa-spinner" role="status" aria-hidden="true"></span>
            }
            <span>{{ 'SIGN_IN.SIGN_IN' | translate }}</span>
          </button>
          @if (errorMessageVisible) {
            <span class="error-login">{{ 'SIGN_IN.ERROR_MSG' | translate }}</span>
          }
          @if (errorUserNotFoundVisibile) {
            <span class="error-login">User not found</span>
          }
          <span class="forgot-password" (click)="startForgotPasswordSteps()">Forgot password?</span>
        </form>
        <!--      <span class="sign-up-message">{{'SIGN_IN.NOT_REGISTERED_MESSAGE' | translate}} <a (click)="navigateToSignUp()">{{'SIGN_IN.HERE' | translate}}</a></span>-->
      </div>
    </div>
  } @else {
    @if(showForgotPasswordEnterEmail) {
      <div class="sign-in-form-container">
        <h1>Reset password</h1>
        <h3 style="margin-bottom: 0">Insert your email, then insert the code</h3>
        <h3>you will receive in your email inbox</h3>
        <div class="auth-form">
          <form spellcheck="false" [formGroup]="resetPasswordForm">
            <mat-form-field appearance="fill">
              <mat-label class="label-sign-in">{{ 'SIGN_IN.EMAIL_LABEL' | translate }}</mat-label>
              <input matInput type="email" name="email" formControlName="email"
                     placeholder="{{'SIGN_IN.EMAIL_PLACEHOLDER' | translate}}">
              <i class="fa fa-regular fa-envelope" matSuffix></i>
            </mat-form-field>
            <app-validator-ui [form]="resetPasswordForm" [name]="'email'" [icon]="'fa-regular fa-circle-exclamation'"
                              message="{{'SIGN_IN.EMAIL_ERROR' | translate}}"></app-validator-ui>
            <button color="primary" mat-raised-button type="button" class="confirm-button" (click)="sendResetPasswordCode()"
              [disabled]="loading">
              @if (loading) {
                <span class="fa fa-spin fa-spinner" role="status" aria-hidden="true"></span>
              }
              <span>Send Code</span>
            </button>
            @if (errorMessageVisible) {
              <span class="error-login">An error occurred</span>
            }
            <span class="back-from-forgot-password" (click)="goBack()">Back</span>
          </form>
        </div>
      </div>
    } @else {
      <div class="sign-in-form-container">
        @if (isResettingPassword) {
          <h1>Reset your password</h1>
          <h3 style="margin-bottom: 0">Insert the verification code you recevied</h3>
          <h3>and choose a new password</h3>
        } @else {
          <h1>Insert new password</h1>
          <h3>Complete the registration</h3>
        }
        <div class="auth-form">
          @if (isResettingPassword) {
            <form spellcheck="false" [formGroup]="resetPasswordForm">
              <mat-form-field appearance="fill">
                <mat-label class="label-sign-in">Enter Verification Code</mat-label>
                <input matInput type="text" name="code"
                       formControlName="code">
              </mat-form-field>
              <app-validator-ui [form]="resetPasswordForm" [name]="'code'" [icon]="'fa-regular fa-circle-exclamation'"
                                message="{{'SIGN_IN.PASSWORD_ERROR' | translate}}"></app-validator-ui>
            </form>
          }
          <form spellcheck="false" [formGroup]="newPasswordForm">
            <mat-form-field appearance="fill">
              <mat-label class="label-sign-in">Enter new Password</mat-label>
              <input matInput [type]="showNewPassword ? 'text' : 'password'" name="password"
                     formControlName="password" (keydown.enter)="submitNewPassword()">
              <button class="transparent" matSuffix type="button" (click)="showNewPassword = !showNewPassword">
                <i class="fa-regular {{ showNewPassword ? 'fa-eye-slash' : 'fa-eye' }}"></i>
              </button>
            </mat-form-field>
            @if (newPasswordForm.controls.password.errors?.['minlength']) {
              <app-validator-ui [form]="newPasswordForm" [name]="'password'" [icon]="'fa-regular fa-circle-exclamation'"
                                message="Min 8 characters"></app-validator-ui>
            }
            <mat-form-field appearance="fill">
              <mat-label class="label-sign-in">Confirm new Password</mat-label>
              <input matInput [type]="showNewPassword ? 'text' : 'password'" name="confirmPassword"
                     formControlName="confirmPassword" (keydown.enter)="submitNewPassword()">
              <button class="transparent" matSuffix type="button" (click)="showNewPassword = !showNewPassword">
                <i class="fa-regular {{ showNewPassword ? 'fa-eye-slash' : 'fa-eye' }}"></i>
              </button>
            </mat-form-field>
            @if (newPasswordForm.controls.confirmPassword.errors?.['minlength']) {
              <app-validator-ui [form]="newPasswordForm" [name]="'confirmPassword'" [icon]="'fa-regular fa-circle-exclamation'"
                                message="Min 8 characters"></app-validator-ui>
            }
            <button color="primary" mat-raised-button type="button" class="confirm-button" (click)="submitNewPassword()"
                    [disabled]="loading">
              @if (loading) {
                <span class="fa fa-spin fa-spinner" role="status" aria-hidden="true"></span>
              }
              <span>Submit</span>
            </button>
            @if (errorMessageVisible) {
              <span class="error-login">An error occurred</span>
            }
            @if (errorNewPasswordMatch) {
              <span class="error-login">Passwords do not match</span>
            }
          </form>
        </div>
      </div>
    }
  }
</div>
