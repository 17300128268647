import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { environment } from '../../../../environments/environment';
import {
  CreateUpdateUserMapper,
  DeleteUserMapper,
  UserInfoMapper,
  UsersListMapper,
} from '../mappers/user-mapper';
import { UsersListModel, UsersRoles } from '../models/users-models';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private networkService!: NetworkService;
  baseUrl = `${environment.backend.endpoint}/user-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getUserInfo(): Promise<any> {
    const mapper = new UserInfoMapper();
    await this.networkService?.get(`${this.baseUrl}/user/info`, mapper);
    return mapper.userInfo;
  }

  async getUsersAndCount(
    limit?: number | undefined,
    offset?: number | undefined,
    sortCol?: string | undefined,
    orderBy?: string | undefined,
    order?: 'asc' | 'desc',
    filter?: string
  ): Promise<UsersListModel> {
    const usersListMapper = new UsersListMapper();
    await this.networkService?.get(`${this.baseUrl}/user`, usersListMapper, {
      limit: limit?.toString(),
      offset: offset?.toString(),
      sortCol: sortCol,
      orderBy: orderBy,
      order: order ?? 'asc',
      filter,
    });
    return { users: usersListMapper.users, count: usersListMapper.count };
  }

  async createUser(
    name: string,
    surname: string,
    email: string,
    role: UsersRoles,
    countryId?: string
  ): Promise<void> {
    const createUserMapper = new CreateUpdateUserMapper();
    createUserMapper.name = name;
    createUserMapper.surname = surname;
    createUserMapper.email = email;
    createUserMapper.role = role;
    createUserMapper.countryId = countryId ?? null;
    await this.networkService?.post(
      `${this.baseUrl}/user`,
      createUserMapper,
      createUserMapper
    );
  }

  async updateUser(
    id: string,
    name: string,
    surname: string,
    role: UsersRoles,
    countryId?: string
  ): Promise<void> {
    const updateUserMapper = new CreateUpdateUserMapper();
    updateUserMapper.name = name;
    updateUserMapper.surname = surname;
    updateUserMapper.role = role;
    updateUserMapper.countryId = countryId ?? null;
    await this.networkService?.put(
      `${this.baseUrl}/user/${id}`,
      updateUserMapper,
      updateUserMapper
    );
  }

  async deleteUser(id: string): Promise<void> {
    const deleteUserMapper = new DeleteUserMapper();
    await this.networkService?.delete(
      `${this.baseUrl}/user/${id}`,
      deleteUserMapper
    );
  }
}
