@if (loading) {
<app-loader></app-loader>
} 
<div class="filters-class">
  <div class="filters-bar" [formGroup]="filterCountryFormGroup">
    <div class="first-column">
      <div class="mat-drop-down">
        <mat-label>{{ 'COUNTRY_VALIDATION_TABLE.CODE_LABEL' | translate }}</mat-label>
        <i class="fa fa-circle-info"
          [matTooltip]="'Press ENTER or COMMA to enter a code, you can use * to define a wildcard'"></i>
        <mat-form-field>
          <mat-chip-grid #chipGrid>
            @for (filter of filters; track filter) {
            <mat-chip-row (removed)="removeChip(filter)" [removable]="true" [editable]="false" class="standard">
              {{ filter }}
              <button matChipRemove class="mat-chip-remove-code">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            }
            <input class="code-filter-input" matInput type="text" [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addChipValue($event)" #chipsFilterInputElement [disabled]="loading" />
          </mat-chip-grid>
        </mat-form-field>
      </div>
      <div class="mat-drop-down">
        <mat-label>{{ 'COUNTRY_VALIDATION_TABLE.YEAR_FROM' | translate }}</mat-label>
        <input matInput type="number" formControlName="yearFrom">
      </div>
      <div class="mat-drop-down">
        <mat-label>{{ 'COUNTRY_VALIDATION_TABLE.YEAR_TO' | translate }}</mat-label>
        <input matInput type="number" formControlName="yearTo">
      </div>
    </div>
    <div class="last-column">
      <button mat-raised-button color="primary" (click)="showDownloadPDFDialog(downloadDialog)" onclick="this.blur()">
        <span>
          {{ 'SURVEY_COUNTRY_TABLE.DOWNLOAD_PDF' | translate }}
        </span>
      </button>
    </div>
  </div>
</div>
<div class="table-holder mat-elevation-z8" tabindex="0">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="rowDescription">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div>{{ 'COUNTRY_VALIDATION_TABLE.CODE_HEADER' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element">
        <span class="row-line">
          {{ element.rowDescription }}
        </span>
      </td>
    </ng-container>
    @for (year of yearsToDisplay; track year) {
    <ng-container [matColumnDef]="year + '-old'">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div>Old {{ year }}</div>
      </th>
      <td mat-cell *matCellDef="let element"
        style="background-color: {{element[year + '-type']}}; {{getCellBorder(element, year + '-old')}}"
        (click)="selectCell(element, year + '-old', year)">
        <span class="row-line">
          {{ element[year + '-old'] }}
        </span>
      </td>
    </ng-container>
    <ng-container [matColumnDef]="year + '-new'">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div>New {{ year }}</div>
      </th>
      <td mat-cell *matCellDef="let element" style="background-color: {{element[year + '-type']}}">
        <span class="row-line">
          {{ element[year + '-new'] }}
        </span>
      </td>
    </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
  </table>
</div>
<div class="paginator">
  <mat-paginator class="mat-elevation-z8 hide-ripple" [length]="tableCount" [pageSizeOptions]="[50, 100, 150]">
  </mat-paginator>
</div>
<div class="history-container">
  <label>{{'COUNTRY_VALIDATION_TABLE.HISTORY_LABEL' | translate}}</label>
  <div class="history-data-container">
    @for (comment of cellHistory; track comment) {
    @if (comment.version === 0) {
    <p>First value for the cell is {{ comment.value }}, inserted on {{ comment.lastModifiedOn }} by
      "{{ comment.user }}"</p>
    } @else {
    @if (comment.value != null) {
    <p>user '{{ comment.user }}' changed value to '{{ comment.value }}' on '{{ comment.lastModifiedOn }}
      '</p>
    } @else {
    <p>user '{{ comment.user }}' deleted the value on '{{ comment.lastModifiedOn }}'</p>
    }
    }
    }
  </div>
</div>

<ng-template #downloadDialog>
  <h2 mat-dialog-title class="mat-dialog-title">{{ 'COUNTRY_VALIDATION_TABLE.DOWNLOAD_DIALOG_TITLE' | translate }}</h2>
  <mat-dialog-content>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <p style="margin-top: 10px">{{ 'COUNTRY_VALIDATION_TABLE.DOWNLOADING_DIALOG_MESSAGE' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" mat-dialog-close>{{ 'DIALOG.CANCEL' | translate }}</button>
  </mat-dialog-actions>
</ng-template>