import { IMapper } from '../../../models/i-mapper';
import { ValidationCellHistoryModel } from '../models/validation-cell-history-model';

export class getCellHistoryMapper implements IMapper {
  validationCellHistoryModelList: ValidationCellHistoryModel[] = [];

  fillFromJson(json: any): void {
    this.validationCellHistoryModelList = [];
    json.forEach((element: any) => {
      this.validationCellHistoryModelList.push({
        answerId: element.answerId,
        surveyId: element.surveyId,
        countryId: element.countryId,
        qualtricsSurveyId: element.qualtricsSurveyId,
        qualtricsResponseId: element.qualtricsResponseId,
        dataYear: element.dataYear,
        codeId: element.codeId,
        valueNumber: element.valueNumber,
        valueText: element.valueText,
        notes: element.notes,
        answerSource: element.answerSource,
        version: element.version,
        lastModifiedBy: element.lastModifiedBy,
        lastModifiedOn: element.lastModifiedOn,
      });
    });
  }

  fillToJson(): string {
    return '';
  }
}
