import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { SurveyModel } from '../survey-table/models/survey-model';
import { CountrySurveyModel } from '../survey-country-table/models/country-survey-model';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ProviderService } from '../../core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { debounceTime } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import { CountryValidationModel } from './models/country-validation-model';
import { MatDialog } from '@angular/material/dialog';
import { CellHistoryModel } from '../update-country-table/models/update-country-table-models';

@Component({
  selector: 'app-country-validation-table',
  templateUrl: './country-validation-table.component.html',
  styleUrl: './country-validation-table.component.scss',
})
// eslint-disable-next-line prettier/prettier
export class CountryValidationTableComponent extends AntiMemLeak implements AfterViewInit {
  @Input({ required: true }) selectedSurvey!: SurveyModel;
  @Input({ required: true }) selectedCountry!: CountrySurveyModel;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild('chipsFilterInputElement')
  chipsFilterInputElement!: ElementRef;
  loading = true;
  countLoading = true;
  filterCountryFormGroup = new FormGroup({
    yearFrom: new FormControl(moment().year() - 3),
    yearTo: new FormControl(moment().year() - 1),
  });
  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = [];
  tableCount = 0;
  yearsToDisplay: number[] = [];
  filters: string[] = [];
  isRemovingChip = false;
  codeFilterEntered: any[] = [];
  separatorKeysCodes: number[] = [13, 32, 188];
  downloadingPDF = false;
  previousSelectedCell?: { cellElement: any; columnDef: string };
  selectedCell?: { cellElement: any; columnDef: string };
  cellHistory: CellHistoryModel[] = [];
  private downloadDialog: any;
  private removeChipInterval?: any;
  private removeChipTimeout?: any;

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    super();
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.filterCountryFormGroup.controls.yearFrom.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterCountryFormGroup.controls.yearTo.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData();
        }
      })
    );
    this.getTableData();
  }

  selectCell(element: any, columnDef: string, year: number): void {
    this.previousSelectedCell = this.selectedCell;
    this.selectedCell = {
      cellElement: element,
      columnDef: columnDef,
    };
    this.getCellHistory(element[`${year}-dataID`], year);
  }

  getCellBorder(element: any, columnDef: string): string {
    if (this.selectedCell) {
      if (
        this.selectedCell.cellElement === element &&
        this.selectedCell.columnDef === columnDef
      ) {
        return 'border: 1px solid blue';
      }
    }
    return '';
  }

  removeChip(item: any): void {
    this.isRemovingChip = true;
    if (this.removeChipTimeout) {
      clearTimeout(this.removeChipTimeout);
    }
    const index = this.filters.indexOf(item);
    const codeFilterIndex = this.codeFilterEntered.indexOf(item);
    if (index >= 0) {
      this.filters.splice(index, 1);
    }
    if (codeFilterIndex >= 0) {
      this.codeFilterEntered.splice(codeFilterIndex, 1);
    }
    if (this.removeChipInterval === undefined) {
      this.removeChipInterval = setInterval(() => {
        if (!this.isRemovingChip) {
          this.getTableData();
          clearInterval(this.removeChipInterval);
          this.removeChipInterval = undefined;
        }
      }, 550);
    }
    this.removeChipTimeout = setTimeout(() => {
      this.isRemovingChip = false;
    }, 500);
  }

  addChipValue(event: MatChipInputEvent): void {
    const value = event.value;
    if ((value || '').trim()) {
      this.addChip(event.value);
    }
  }

  addChip(value: string): void {
    if ((value || '').trim()) {
      if (value.includes(',')) {
        this.filters.push(...value.split(','));
        this.codeFilterEntered.push(...value.split(','));
      } else {
        this.filters.push(value.trim());
        this.codeFilterEntered.push(value.trim());
      }
    }
    this.chipsFilterInputElement.nativeElement.value = '';
    this.chipsFilterInputElement.nativeElement.focus();
    this.getTableData();
  }

  private async getCellHistory(cellID: string, year: number): Promise<void> {
    if (!cellID) {
      this.cellHistory = [];
    }
    if (
      cellID &&
      cellID != this.previousSelectedCell?.cellElement[`${year}-dataID`]
    ) {
      try {
        this.cellHistory = (
          await this.providerService.countryTableService.getCellHistory(cellID)
        ).sort((one, two) => two.version - one.version);
        this.cellHistory.map((el) => {
          el.lastModifiedOn =
            this.providerService.utilService.humanReadableDate(
              el.lastModifiedOn,
              true
            );
        });
      } catch (e) {
        console.error(e);
        this.snackBar.open(
          'An error occured while retrieving the cell history',
          'X',
          {
            duration: 3000,
            panelClass: ['error-snackbar'],
          }
        );
      }
    }
  }

  private async getTableData(): Promise<void> {
    this.loading = true;
    try {
      const backendData =
        await this.providerService.countryValidationTableService.getCountryValidationTableListAndCount(
          this.selectedSurvey.surveyId,
          this.selectedCountry.countryID,
          this.filterCountryFormGroup.controls.yearFrom.value ??
            moment().year() - 3,
          this.filterCountryFormGroup.controls.yearTo.value ??
            moment().year() - 1,
          this.paginator ? this.paginator.pageIndex : 0,
          this.paginator ? this.paginator.pageSize : 0,
          this.codeFilterEntered.length > 0 ? this.codeFilterEntered : undefined
        );
      this.setDatasourceData(backendData.data);
      this.tableCount = backendData.count;
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  private setDatasourceData(backendData: CountryValidationModel[]): void {
    if (backendData.length > 0) {
      const yearFrom = this.filterCountryFormGroup.controls.yearFrom.value ?? 0;
      const yearTo = this.filterCountryFormGroup.controls.yearTo.value ?? 0;

      this.yearsToDisplay = Array.from(
        { length: yearTo - yearFrom + 1 },
        (_, i) => yearFrom + i
      );

      this.displayedColumns = [
        'rowDescription',
        ...this.yearsToDisplay.flatMap((year) => [
          `${year}-old`,
          `${year}-new`,
        ]),
      ];
    }
    this.dataSource.data =
      this.providerService.countryValidationTableService.elaborateCountryValidationModel(
        backendData,
        this.yearsToDisplay
      );
  }

  showDownloadPDFDialog(dialogRef: any): void {
    this.downloadDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
    this.downloadPDF();
  }

  async downloadPDF(): Promise<void> {
    this.downloadingPDF = true;
    try {
      const pdfData = (
        await this.providerService.countryValidationTableService.getListForPdfGeneration(
          this.selectedSurvey.surveyId,
          this.selectedCountry.countryID,
          this.filterCountryFormGroup.controls.yearFrom.value?.toString() ??
            (moment().year() - 3).toString(),
          this.filterCountryFormGroup.controls.yearTo.value?.toString() ??
            (moment().year() - 1).toString()
        )
      ).data;
      const elaboratedData =
        this.providerService.countryValidationTableService.elaborateCountryValidationModel(
          pdfData,
          this.yearsToDisplay
        );
      const generatePdfResult =
        await this.providerService.countryValidationTableService.generatePDF(
          elaboratedData,
          this.selectedSurvey,
          this.selectedCountry,
          this.yearsToDisplay
        );

      const url = window.URL.createObjectURL(generatePdfResult.blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = generatePdfResult.fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
      this.snackBar.open('An error occured while downloading the file', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.downloadDialog.close();
      this.downloadingPDF = false;
    }
  }
}
