@if (forceShow || (message && form && (errorCode ? form!.controls[name!].hasError(errorCode) : !form!.controls[name!].valid) && form!.controls[name!].dirty)) {
  <small class="{{forceShow ? ((errorCode ? (!form!.controls[name!].hasError(errorCode) && form!.controls[name!].value) : form!.controls[name!].valid) ? 'success' : '') : '' }}">
    @if (icon && (errorCode ? (form!.controls[name!].hasError(errorCode) || !form!.controls[name!].value) : !form!.controls[name!].valid)) {
      <i class="{{icon}}"></i>
    }
    @if (iconSuccess && forceShow && (errorCode ? (!form!.controls[name!].hasError(errorCode) && form!.controls[name!].value) : form!.controls[name!].valid)) {
      <i class="{{iconSuccess}}"></i>
    }
    {{message}}
  </small>
}
