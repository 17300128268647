import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UnitModel } from './models/unit-model';
import { FormControl, FormGroup } from '@angular/forms';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import { ProviderService } from 'src/app/core/provider.service';
import { debounceTime, distinctUntilChanged, Observable } from 'rxjs';

@Component({
  selector: 'app-unit-filter',
  templateUrl: './unit-filter.component.html',
  styleUrls: ['./unit-filter.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class UnitFilterComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  @Input()
  styleType: 'standard' | 'filters-bar' = 'filters-bar';
  @Input()
  resetFilterObservable?: Observable<any>;
  @Output()
  unitsChanged: EventEmitter<UnitModel[]> = new EventEmitter<UnitModel[]>();
  @ViewChild('unitInputElement')
  unitInputElement!: ElementRef;
  units: UnitModel[] = [];
  filteredUnits: UnitModel[] = [];

  unitsFormGroup = new FormGroup({
    unit: new FormControl([]),
    autocompleteUnit: new FormControl(''),
  });

  constructor(private providerService: ProviderService) {
    super();
  }

  ngOnInit(): void {
    this.getUnitList();
    this.subscriptions.add(
      this.resetFilterObservable?.subscribe(() => {
        this.unitsFormGroup.controls.autocompleteUnit.setValue('');
        this.unitsFormGroup.controls.unit.setValue([]);
      })
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.unitsFormGroup.controls.unit.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(200))
        .subscribe((units) => {
          this.unitsChanged.emit(units!);
        })
    );
  }

  displayMultipleUnitsFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0
        ? (result = `${this.displayUnitFn(v)}`)
        : (result = `${result}, ${this.displayUnitFn(v)}`);
    }
    return result;
  }

  displayUnitFn(value: any): string {
    return value
      ? this.units.find((unit) => unit.unitId === value.unitId)
          ?.unitDescription ?? ''
      : '';
  }

  onOpenedUnitChange(isOpened: boolean): void {
    if (isOpened) {
      this.unitInputElement.nativeElement.focus();
    }
  }

  removeUnitFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.unitsFormGroup.controls.unit.setValue([], {
      emitEvent: true,
    });
  }

  visuallyFilterUnits(unit: UnitModel): boolean {
    const unitDescription = unit.unitDescription?.toLowerCase() || '';
    const input = (
      this.unitsFormGroup.controls.autocompleteUnit.value || ''
    ).toLowerCase();
    return input === '' || unitDescription.includes(input);
  }

  async getUnitList(): Promise<void> {
    try {
      this.units = await this.providerService.unitFilterService.getUnitsList();
      this.filteredUnits = this.units;
    } catch (error) {
      console.error(error);
    }
  }
}
