<div class="table-ui-container">
  @if (datasource!.loading$ | async) {
    <div>
      <mat-spinner></mat-spinner>
    </div>
  }

  <mat-table matSort [dataSource]="datasource!">

    @for (item of tableDefinitions; track item) {
      <ng-container matColumnDef="{{item.def}}">
        @if (item.sortable) {
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{item.title}}</mat-header-cell>
        }
        @if (!item.sortable) {
          <mat-header-cell *matHeaderCellDef>{{item.title}}</mat-header-cell>
        }
        <mat-cell *matCellDef="let cell">{{cell[item.def]}}</mat-cell>
      </ng-container>
    }

    @if (actionDefinitions && actionDefinitions.length > 0) {
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>{{actionTitle}}</mat-header-cell>
        <mat-cell *matCellDef="let row;">
          <div class="action-menu" [matMenuTriggerFor]="profileMenu">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </div>
          <mat-menu #profileMenu="matMenu" class="table-action-menu">
            @for (action of actionDefinitions; track action) {
              <a mat-menu-item (click)="action.callback(row)" matTooltip="{{action?.tooltip}}">
                <i class="{{action.icon}} {{action.styleClass}}"></i>
                &nbsp;
                <span class="{{action.styleClass}}">{{action.text}}</span>
              </a>
            }
          </mat-menu>
        </mat-cell>
      </ng-container>
    }

    <mat-header-row *matHeaderRowDef="tableDefinitionHeaders"></mat-header-row>

    <mat-row *matRowDef="let row; columns: tableDefinitionHeaders" (click)="rowClickedAction(row);"></mat-row>

  </mat-table>

  <mat-paginator [length]="tableService.getTotalCount()" [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"></mat-paginator>
</div>
