import { Component } from '@angular/core';
import { ProviderService } from '../../core/provider.service';
import { Dataset, DatasetPathEquivalent } from './home.models';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends AntiMemLeak {
  dataSets: Dataset[] = [];
  filteredDataSets: Dataset[] = [];
  loading = false;
  isVisibleManagement = false;
  isVisibleDatasets = false;
  isVisibleExternal = false;
  debounceSubject = new BehaviorSubject<string>('');

  constructor(private providerService: ProviderService) {
    super();
    this.subscriptions.add(
      this.debounceSubject
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value): void => {
          this.loading = true;
          this.filteredDataSets = this.dataSets.filter((dataset: Dataset) =>
            dataset.name.toLowerCase().includes(value.toLowerCase())
          );
          this.loading = false;
        })
    );
    this.getDatasets();
  }

  toggleMenuManagement(): void {
    this.isVisibleManagement = !this.isVisibleManagement;
  }

  toggleMenuDatasets(): void {
    this.isVisibleDatasets = !this.isVisibleDatasets;
  }

  toggleMenuExternal(): void {
    this.isVisibleExternal = !this.isVisibleExternal;
  }

  private async getDatasets(): Promise<void> {
    this.loading = true;
    this.dataSets = this.providerService.homeService.getAvailableDatasets();
    this.loading = false;
  }

  navigateToDataset(name: string): void {
    if (Object.keys(DatasetPathEquivalent).includes(name)) {
      this.providerService.utilService.navigateTo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `app/datasets/${DatasetPathEquivalent[name]}`
      );
    }
  }

  navigateToDownloadModule(): void {
    this.providerService.utilService.navigateTo('app/download-module');
  }

  navigateToUploadModule(): void {
    this.providerService.utilService.navigateTo('app/upload-module');
  }

  sendFilterQuery(event: any): void {
    this.debounceSubject.next(event.target.value);
  }

  navigateToAdminRoute(_dataset: string): void {
    this.providerService.utilService.navigateTo('/app/admin-view');
  }
}
