import { Injectable } from '@angular/core';
import { IAuthService } from './auth-utils/i-auth-service';
import { CognitoService } from '../components/auth/cognito.service';
import { UtilsService } from './app-utils/utils/utils.service';
import { NetworkService } from './net-utils/network.service';
import { IamAuthenticatorService } from './net-utils/iam-authenticator.service';
import { HomeService } from '../components/home/home.service';
import { UpdateCountryTableService } from '../components/update-country-table/service/update-country-table-service';
import { ModifyOneRowService } from '../components/dialogues/modify-one-row/modify-one-row-service';
import { DownloadDataService } from '../components/update-country-table/service/download-data.service';
import { UploadDataService } from '../components/upload-module/service/upload-data.service';
import { RegionsManagementService } from '../components/regions-management/service/regions-management.service';
import { CodesManagementService } from '../components/codes-management/service/codes-management.service';
import { DownloadListService } from '../components/download-list/service/download-list.service';
import { SurveyTableService } from '../components/survey-table/service/survey-table.service';
import { SurveyCountryTableService } from '../components/survey-country-table/service/survey-country-table.service';
import { CountryValidationTableService } from '../components/country-validation-table/service/country-validation-table.service';
import { ValidationModuleCountryTableService } from '../components/validation-module-country-table/service/validation-module-country.service';
import { ManageDataService } from '../components/custom/upload-retrieve-file/service/manage-data.service';
import { ValidationModuleListCodesService } from '../components/validation-module-list-codes/service/validation-module-list-codes.service';
import { ValidationModuleCodeTableService } from '../components/validation-module-code-table/service/validation-module-code.service';
import { AdminService } from '../components/admin-section/service/admin.service';
import { ValidationModuleService } from '../components/validation-module/service/validation-module.service';
import { UnitFilterService } from '../components/filters/unit-filter/service/unit-filter-service.service';
import { CollectionFilterService } from '../components/filters/collection-filter/service/collection-filter-service.service';
import { DisaggregationFilterService } from '../components/filters/disaggregation-filter/service/disaggregation-filter-service.service';
import { ContactService } from '../components/contacts-module/service/contact.service';
import { AccountService } from '../components/contacts-module/service/account.service';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  constructor(
    private utilsService: UtilsService,
    private authServiceInstance: CognitoService,
    private netService: NetworkService,
    private iamAuthenticatorService: IamAuthenticatorService,
    private homeServiceInstance: HomeService,
    private updateCountryTableServiceInstance: UpdateCountryTableService,
    private modifyOneRowServiceInstance: ModifyOneRowService,
    private downloadDataServiceInstance: DownloadDataService,
    private uploadDataServiceInstance: UploadDataService,
    private regionsManagementServiceInstance: RegionsManagementService,
    private codesManagementServiceInstance: CodesManagementService,
    private downloadListServiceInstance: DownloadListService,
    private surveyTableServiceInstance: SurveyTableService,
    private surveyCountryTableServiceInstance: SurveyCountryTableService,
    private countryValidationTableServiceInstance: CountryValidationTableService,
    private validationModuleCountryServiceInstance: ValidationModuleCountryTableService,
    private manageDataServiceInstance: ManageDataService,
    private validationModuleListCodesServiceInstance: ValidationModuleListCodesService,
    private validationModuleCodeServiceInstance: ValidationModuleCodeTableService,
    private adminServiceInstance: AdminService,
    private validationModuleServiceInstance: ValidationModuleService,
    private unitFilterServiceInstance: UnitFilterService,
    private collectionFilterServiceInstance: CollectionFilterService,
    private disaggregationFilterServiceInstance: DisaggregationFilterService,
    private contactServiceInstance: ContactService,
    private accountServiceInstance: AccountService
  ) {}

  get authService(): IAuthService {
    return this.authServiceInstance;
  }

  get countryTableService(): UpdateCountryTableService {
    this.updateCountryTableServiceInstance.setNetworkService(
      this.networkService
    );
    return this.updateCountryTableServiceInstance;
  }
  get utilService(): UtilsService {
    return this.utilsService;
  }
  get modifyOneRowService(): ModifyOneRowService {
    this.modifyOneRowServiceInstance.setNetworkService(this.networkService);
    return this.modifyOneRowServiceInstance;
  }
  get homeService(): HomeService {
    return this.homeServiceInstance;
  }

  get networkService(): NetworkService {
    this.netService.setAuthenticator(this.iamAuthenticatorService);
    return this.netService;
  }

  get downloadDataService(): DownloadDataService {
    this.downloadDataServiceInstance.setNetworkService(this.networkService);
    return this.downloadDataServiceInstance;
  }

  get uploadDataService(): UploadDataService {
    this.uploadDataServiceInstance.setNetworkService(this.networkService);
    return this.uploadDataServiceInstance;
  }

  get regionsManagementService(): RegionsManagementService {
    this.regionsManagementServiceInstance.setNetworkService(
      this.networkService
    );
    return this.regionsManagementServiceInstance;
  }

  get codesManagementService(): CodesManagementService {
    this.codesManagementServiceInstance.setNetworkService(this.networkService);
    return this.codesManagementServiceInstance;
  }

  get downloadListService(): DownloadListService {
    this.downloadListServiceInstance.setNetworkService(this.networkService);
    return this.downloadListServiceInstance;
  }

  get surveyTableService(): SurveyTableService {
    this.surveyTableServiceInstance.setNetworkService(this.networkService);
    return this.surveyTableServiceInstance;
  }

  get surveyCountryTableService(): SurveyCountryTableService {
    this.surveyCountryTableServiceInstance.setNetworkService(
      this.networkService
    );
    return this.surveyCountryTableServiceInstance;
  }

  get countryValidationTableService(): CountryValidationTableService {
    this.countryValidationTableServiceInstance.setNetworkService(
      this.networkService
    );
    return this.countryValidationTableServiceInstance;
  }

  get validationModuleCountryService(): ValidationModuleCountryTableService {
    this.validationModuleCountryServiceInstance.setNetworkService(
      this.networkService
    );
    return this.validationModuleCountryServiceInstance;
  }

  get validationModuleCodeService(): ValidationModuleCodeTableService {
    this.validationModuleCodeServiceInstance.setNetworkService(
      this.networkService
    );
    return this.validationModuleCodeServiceInstance;
  }

  get manageDataService(): ManageDataService {
    this.manageDataServiceInstance.setNetworkService(this.networkService);
    return this.manageDataServiceInstance;
  }

  get validationModuleListCodesService(): ValidationModuleListCodesService {
    this.validationModuleListCodesServiceInstance.setNetworkService(
      this.networkService
    );
    return this.validationModuleListCodesServiceInstance;
  }

  get adminService(): AdminService {
    this.adminServiceInstance.setNetworkService(this.networkService);
    return this.adminServiceInstance;
  }

  get validationModuleService(): ValidationModuleService {
    this.validationModuleServiceInstance.setNetworkService(this.networkService);
    return this.validationModuleServiceInstance;
  }

  get unitFilterService(): UnitFilterService {
    this.unitFilterServiceInstance.setNetworkService(this.networkService);
    return this.unitFilterServiceInstance;
  }

  get collectionFilterService(): CollectionFilterService {
    this.collectionFilterServiceInstance.setNetworkService(this.networkService);
    return this.collectionFilterServiceInstance;
  }

  get disaggregationFilterService(): DisaggregationFilterService {
    this.disaggregationFilterServiceInstance.setNetworkService(
      this.networkService
    );
    return this.disaggregationFilterServiceInstance;
  }

  get contactService(): ContactService {
    this.contactServiceInstance.setNetworkService(this.networkService);
    return this.contactServiceInstance;
  }

  get accountService(): AccountService {
    this.accountServiceInstance.setNetworkService(this.networkService);
    return this.accountServiceInstance;
  }
}
