import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ProviderService } from '../../core/provider.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { CodesListModel } from './models/codes-model';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { debounceTime } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatabasesListModel } from './models/databases-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CodeDetailComponent } from './code-detail/code-detail.component';
import { MatSelect } from '@angular/material/select';
import { CollectionsModel } from './models/collections-model';
import { UnitModel } from '../filters/unit-filter/models/unit-model';

@Component({
  selector: 'app-codes-management',
  templateUrl: './codes-management.component.html',
  styleUrl: './codes-management.component.scss',
})
// eslint-disable-next-line prettier/prettier
export class CodesManagementComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;
  @ViewChild('unitEditInputElement')
  unitEditInputElement!: ElementRef;
  @ViewChild('databaseEditInputElement')
  databaseEditInputElement!: ElementRef;
  @ViewChild('databaseInputElement')
  databaseInputElement!: ElementRef;
  @ViewChild(CodeDetailComponent)
  codeDetailComponent!: CodeDetailComponent;
  @ViewChild('cssfix2')
  cssFix2!: MatSelect;
  codeFiltersFormGroup = new FormGroup({
    code: new FormControl(''),
    description: new FormControl(''),
    unit: new FormControl([] as string[]),
    database: new FormControl([]),
    baseCode: new FormControl(''),
    autocompleteUnit: new FormControl(''),
    autocompleteDatabase: new FormControl(''),
  });
  editCodeFormGroup = new FormGroup({
    code: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    definition: new FormControl('', Validators.required),
    database: new FormControl(''),
    unit: new FormControl(''),
    baseCode: new FormControl(''),
    baseCodeDescription: new FormControl(''),
    autocompleteUnit: new FormControl(''),
    autocompleteDatabase: new FormControl(''),
    decimalNumber: new FormControl(2, [Validators.min(0), Validators.max(10)]),
  });
  createCodeFormGroup = new FormGroup({
    code: new FormControl('', Validators.required),
    description: new FormControl(''),
    definition: new FormControl(''),
    database: new FormControl(''),
    unit: new FormControl(''),
    collection: new FormControl(''),
    baseCode: new FormControl(''),
    decimalNumber: new FormControl(2, [Validators.min(0), Validators.max(10)]),
  });
  displayedColumns = [
    'code',
    'description',
    'unit',
    'decimalNumber',
    'database',
  ];
  tableDataCount = 0;
  fetchingTableDataCount = true;
  loading = false;
  tableDataSource = new MatTableDataSource<any>();
  codesDataBE!: CodesListModel[];
  showTableError = false;
  unitsList: UnitModel[] = [];
  databasesList: DatabasesListModel[] = [];
  collectionsList: CollectionsModel[] = [];
  detailOpen = false;
  selectedCode!: CodesListModel | undefined;
  editCodeDialog: any;
  deleteCodeDialog: any;
  hasEditedCode = false;
  createCodeDialog: any;
  codesAutocompleteCreateList: any = [];
  loadingAutocomplete = false;

  constructor(
    private providerService: ProviderService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngOnInit(): void {
    this.getDatabases();
    this.getCollections();
    this.getTableData();
    this.getUnits();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.codeFiltersFormGroup.controls.code.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((value) => {
          if (value) {
            this.getCodesAutocompleteCreate();
          } else if (value === '') {
            this.getTableData();
            this.codesAutocompleteCreateList = [];
          } else {
            this.codesAutocompleteCreateList = [];
          }
        })
    );
    this.subscriptions.add(
      this.codeFiltersFormGroup.controls.description.valueChanges
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe(() => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.codeFiltersFormGroup.controls.unit.valueChanges.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.getTableData();
      })
    );
    this.subscriptions.add(
      this.codeFiltersFormGroup.controls.database.valueChanges
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe(() => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData(true);
        }
      })
    );
    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.getTableData(true);
      })
    );
  }

  unitsChanged($event: UnitModel[]): void {
    this.codeFiltersFormGroup.controls.unit.setValue(
      $event.map((value) => value.unitId)
    );
  }

  async getTableData(skipCount?: boolean): Promise<void> {
    this.loading = true;
    this.showTableError = false;
    this.changeDetectorRef.detectChanges();
    try {
      const filters = {
        codeFilter: this.codeFiltersFormGroup.controls.code.value,
        descriptionFilter: this.codeFiltersFormGroup.controls.description.value,
        unitFilter: this.codeFiltersFormGroup.controls.unit.value,
        baseCodeFilter: this.codeFiltersFormGroup.controls.baseCode.value,
        databaseFilter: this.codeFiltersFormGroup.controls.database.value,
      };
      this.codesDataBE =
        await this.providerService.codesManagementService.getCodesList(
          this.sort.direction.toUpperCase() !== ''
            ? this.sort.active ?? 'code'
            : 'code',
          this.sort.direction.toUpperCase() !== ''
            ? this.sort.direction.toUpperCase()
            : 'ASC',
          this.providerService.utilService.toBase64(JSON.stringify(filters)),
          this.paginator.pageIndex,
          this.paginator.pageSize
        );
      if (!skipCount) {
        this.getTableCount();
      }
      this.tableDataSource.data = this.codesDataBE.map((codesData) => ({
        codeID: codesData.codeId,
        code: codesData.code,
        description: codesData.description,
        unit: codesData.unitDescription,
        basecode: codesData.baseCode,
        decimalNumber: codesData.decimalNumber,
        database: codesData.databaseName,
      }));
    } catch (e) {
      console.error(e);
      this.showTableError = true;
      this.tableDataSource.data = [];
      this.tableDataCount = 0;
    } finally {
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  async getTableCount(): Promise<void> {
    const filters = {
      codeFilter: this.codeFiltersFormGroup.controls.code.value,
      descriptionFilter: this.codeFiltersFormGroup.controls.description.value,
      unitFilter: this.codeFiltersFormGroup.controls.unit.value,
      baseCodeFilter: this.codeFiltersFormGroup.controls.baseCode.value,
      databaseFilter: this.codeFiltersFormGroup.controls.database.value,
    };
    try {
      this.fetchingTableDataCount = true;
      this.tableDataCount =
        await this.providerService.codesManagementService.getCodesCount(
          this.providerService.utilService.toBase64(JSON.stringify(filters))
        );
      this.fetchingTableDataCount = false;
    } catch (error) {
      console.error(error);
    }
    this.changeDetectorRef.detectChanges();
  }

  async getUnits(): Promise<void> {
    try {
      this.unitsList =
        await this.providerService.unitFilterService.getUnitsList();
    } catch (error) {
      console.error(error);
    }
  }

  async getDatabases(): Promise<void> {
    try {
      this.databasesList =
        await this.providerService.codesManagementService.getDatabasesList();
    } catch (error) {
      console.error(error);
    }
  }

  async getCollections(): Promise<void> {
    try {
      this.collectionsList =
        await this.providerService.codesManagementService.getCollectionsList();
    } catch (error) {
      console.error(error);
    }
  }

  onOpenedUnitEditChange(isOpened: boolean): void {
    if (isOpened) {
      this.unitEditInputElement.nativeElement.focus();
    }
  }

  onOpenedDatabaseEditChange(isOpened: boolean): void {
    if (isOpened) {
      this.databaseEditInputElement.nativeElement.focus();
    }
  }

  displayUnitFn(value: any): string {
    return value
      ? this.unitsList.find((unit) => unit.unitId === value)?.unitDescription ??
          ''
      : '';
  }

  removeUnitEditFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.editCodeFormGroup.controls.unit.setValue('', {
      emitEvent: true,
    });
  }

  visuallyFilterEditUnits(unit: UnitModel): boolean {
    const unitDescription = unit.unitDescription?.toLowerCase() || '';
    const input = (
      this.editCodeFormGroup.controls.autocompleteUnit.value || ''
    ).toLowerCase();
    return input === '' || unitDescription.includes(input);
  }

  onOpenedDatabaseChange(isOpened: boolean): void {
    if (isOpened) {
      this.databaseInputElement.nativeElement.focus();
    }
  }

  displayMultipleDatabasesFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0
        ? (result = `${this.displayDatabaseFn(v)}`)
        : (result = `${result}, ${this.displayDatabaseFn(v)}`);
    }
    return result;
  }

  displayDatabaseFn(value: any): string {
    return value
      ? this.databasesList.find((db) => db.databaseID === value)
          ?.databaseName ?? ''
      : '';
  }

  removeDatabaseFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.codeFiltersFormGroup.controls.database.setValue([], {
      emitEvent: true,
    });
  }

  removeDatabaseEditFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.editCodeFormGroup.controls.database.setValue('', {
      emitEvent: true,
    });
  }

  visuallyFilterDatabases(db: DatabasesListModel): boolean {
    const unitDescription = db.databaseName?.toLowerCase() || '';
    const input = (
      this.codeFiltersFormGroup.controls.autocompleteDatabase.value || ''
    ).toLowerCase();
    return input === '' || unitDescription.includes(input);
  }

  visuallyFilterEditDatabases(db: DatabasesListModel): boolean {
    const unitDescription = db.databaseName?.toLowerCase() || '';
    const input = (
      this.editCodeFormGroup.controls.autocompleteDatabase.value || ''
    ).toLowerCase();
    return input === '' || unitDescription.includes(input);
  }

  async openDetail(codeID: string): Promise<void> {
    this.loading = true;
    this.selectedCode = this.codesDataBE.find((c) => c.codeId === codeID);
    this.loading = false;
    this.detailOpen = true;
  }

  async goBack(): Promise<void> {
    this.detailOpen = false;
    if (this.hasEditedCode) {
      await this.getTableData();
    }
    this.hasEditedCode = false;
  }

  openEditCodeDialog(dialogRef: any): void {
    this.editCodeFormGroup.controls.code.setValue(
      this.selectedCode?.code || ''
    );
    this.editCodeFormGroup.controls.description.setValue(
      this.selectedCode?.description || ''
    );
    this.editCodeFormGroup.controls.definition.setValue(
      this.selectedCode?.definition || ''
    );
    this.editCodeFormGroup.controls.database.setValue(
      this.selectedCode?.databaseID || ''
    );
    this.editCodeFormGroup.controls.unit.setValue(
      this.selectedCode?.unitID || ''
    );
    this.editCodeFormGroup.controls.baseCode.setValue(
      this.selectedCode?.baseCode || ''
    );
    this.editCodeFormGroup.controls.baseCodeDescription.setValue(
      this.selectedCode?.baseCodeDescription || ''
    );
    this.editCodeDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  goBackToHome(): void {
    this.providerService.utilService.navigateTo('/app/home');
  }

  async deleteCode(): Promise<void> {
    this.detailOpen = false;
    this.loading = true;
    this.deleteCodeDialog?.close();
    try {
      const codeID = this.selectedCode?.codeId || '';
      await this.providerService.codesManagementService.deleteCode(codeID);
      await this.getTableData();
    } catch (e) {
      this.snackBar.open('An error occured while deleting the code', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.loading = false;
    }
  }

  async editCode(): Promise<void> {
    this.loading = true;
    this.editCodeDialog?.close();
    try {
      const codeID = this.selectedCode?.codeId || '';
      const code = this.editCodeFormGroup.controls.code.value || '';
      const description =
        this.editCodeFormGroup.controls.description.value || '';
      const definition = this.editCodeFormGroup.controls.definition.value || '';
      const databaseId = this.editCodeFormGroup.controls.database.value || '';
      const unitId = this.editCodeFormGroup.controls.unit.value || '';
      const decimalNumber =
        this.editCodeFormGroup.controls.decimalNumber.value || 2;
      if (this.selectedCode) {
        this.selectedCode.code = code;
        this.selectedCode.description = description;
        this.selectedCode.definition = definition;
        this.selectedCode.databaseID = databaseId;
        this.selectedCode.unitID = unitId;
        this.selectedCode.decimalNumber = decimalNumber;
      }
      await this.providerService.codesManagementService.editCode(
        codeID,
        code,
        description,
        definition,
        databaseId,
        unitId,
        decimalNumber
      );
      this.codeDetailComponent.updateDescriptions();
      this.snackBar.open('Code edited', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      this.hasEditedCode = true;
    } catch (e) {
      this.snackBar.open('An error occured while editing the code', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.loading = false;
    }
  }

  openCreateCodeDialog(dialogRef: any): any {
    this.createCodeDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  async createCode(): Promise<any> {
    this.loading = true;
    this.createCodeDialog?.close();
    try {
      const code = this.createCodeFormGroup.controls.code.value || '';
      const description =
        this.createCodeFormGroup.controls.description.value || '';
      const definition =
        this.createCodeFormGroup.controls.definition.value || '';
      const database = this.createCodeFormGroup.controls.database.value || '';
      const unit = this.createCodeFormGroup.controls.unit.value || '';
      const collection =
        this.createCodeFormGroup.controls.collection.value || '';
      const decimalNumber =
        this.editCodeFormGroup.controls.decimalNumber.value || 2;
      await this.providerService.codesManagementService.createCode(
        code,
        description,
        definition,
        database,
        unit,
        collection,
        decimalNumber
      );
      await this.getTableCount();
      this.snackBar.open('Code created succesfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
    } catch (e) {
      this.snackBar.open('An error occured while creating the code', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.reset();
      this.loading = false;
    }
  }

  async getCodesAutocompleteCreate(): Promise<void> {
    this.loadingAutocomplete = true;
    try {
      const codeName =
        this.codeFiltersFormGroup.controls.code.value || undefined;
      const filters = {
        codeFilter: codeName + '*',
      };
      if (codeName) {
        this.codesAutocompleteCreateList =
          await this.providerService.codesManagementService.getCodesList(
            'code',
            'ASC',
            this.providerService.utilService.toBase64(JSON.stringify(filters)),
            0,
            10
          );
      } else {
        this.codesAutocompleteCreateList = [];
      }
    } catch (e) {
      this.showTableError = true;
      this.tableDataSource.data = [];
      this.tableDataCount = 0;
      this.loadingAutocomplete = false;
      this.codesAutocompleteCreateList = [];
      this.changeDetectorRef.detectChanges();
    } finally {
      this.changeDetectorRef.detectChanges();
      this.loadingAutocomplete = false;
    }
  }

  reset(): void {
    this.createCodeFormGroup.reset();
  }

  selectedFromAutocomplete(): void {
    this.codesAutocompleteCreateList = [];
    this.getTableData();
  }
}
