import { IMapper } from '../../../models/i-mapper';
import {
  ValidationModuleCodeModel,
  ValidationModuleGroupByCodeModel,
} from '../models/validation-module-code-model';

export class ValidationModuleCodeMapper implements IMapper {
  response?: ValidationModuleGroupByCodeModel;
  fillFromJson(json: any): void {
    const groupedData: { [key: string]: ValidationModuleCodeModel } = {};

    json.data.forEach((item: any) => {
      if (!groupedData[item.countryId]) {
        groupedData[item.countryId] = {
          countryId: item.countryId,
          countryName: item.countryName,
          countryIso: item.countryIsoCode,
          validationStatus: item.validationStatus,
          years: [],
        };
      }
      groupedData[item.countryId].years.push({
        dataId: item.answerId,
        year: item.dataYear,
        value: item.value,
        percentageChange: item.valueDiffPercentage,
        source: item.answer,
        note: item.notes,
        checked: item.checked,
      });
    });

    this.response = {
      data: Object.values(groupedData),
      total: json.total,
    };
  }
  fillToJson(): string {
    return '';
  }
}
