import { IMapper } from '../../../models/i-mapper';
import {
  RegionDetailsModel,
  YearModel,
} from '../../regions-management/models/region-details-model';
import {
  RegionsCountriesListModel,
  RegionsListsModel,
} from '../../regions-management/models/region-filters-model';

export class RegionsListsMapper implements IMapper {
  regionsList: RegionsListsModel[] = [];
  fillFromJson(json: any): void {
    json.forEach((regionList: RegionsListsModel) => {
      this.regionsList.push(regionList);
    });
  }
  fillToJson(): string {
    return '';
  }
}

export class RegionsCountriesListsMapper implements IMapper {
  regionsCountriesList: RegionsCountriesListModel[] = [];
  fillFromJson(json: any): void {
    json.forEach((regionCountriesList: RegionsCountriesListModel) => {
      this.regionsCountriesList.push(regionCountriesList);
    });
  }
  fillToJson(): string {
    return '';
  }
}

export class RegionDetailsMapper implements IMapper {
  regionDetails: RegionDetailsModel[] = [];
  fillFromJson(json: any): void {
    this.regionDetails.push(json);
  }
  fillToJson(): string {
    return '';
  }
}

export class CreateRegionMapper implements IMapper {
  regionName = '';
  regionCode = '';
  parentRegionID = '';
  classID = '';
  years: any[] | undefined = [];
  value: any[] = [];

  fillFromJson(json: any): void {
    this.value.push(json);
  }

  fillToJson(): string {
    return JSON.stringify({
      regionName: this.regionName,
      regionCode: this.regionCode,
      parentRegionID:
        this.parentRegionID !== '' ? this.parentRegionID : undefined,
      classID: this.classID !== '' ? this.classID : undefined,
      years: this.years ? this.years : [],
    });
  }
}

export class ModifyRegionMapper implements IMapper {
  regionID = '';
  regionCode = '';
  regionName = '';
  classID?: string;
  parentRegionID?: string;
  years: YearModel[] = [];
  value: any[] = [];

  fillFromJson(json: any): void {
    this.value.push(json);
  }

  fillToJson(): string {
    return JSON.stringify({
      regionID: this.regionID,
      regionName: this.regionName,
      regionCode: this.regionCode,
      parentRegionID:
        this.parentRegionID !== '' ? this.parentRegionID : undefined,
      classID: this.classID !== '' ? this.classID : undefined,
      years: this.years,
    });
  }
}

export class DeleteRegionMapper implements IMapper {
  regionID = '';
  value: any[] = [];

  fillFromJson(json: any): void {
    this.value.push(json);
  }

  fillToJson(): string {
    return JSON.stringify({
      regionID: this.regionID,
    });
  }
}
