import { IMapper } from '../../../models/i-mapper';
import { AccountModel } from '../models/account-model';

export class AccountListMapper implements IMapper {
  filterRequestBody?: any;
  accounts: AccountModel[] = [];
  count = 0;

  fillFromJson(json: any): void {
    json.data.forEach((account: AccountModel) => {
      this.accounts?.push({
        accountId: account.accountId,
        oldAccountId: account.oldAccountId,
        countryId: account.countryId,
        countryShortName: account.countryShortName,
        countryIsoCode: account.countryIsoCode,
        surveySeriesId: account.surveySeriesId,
        source: account.source,
        language: account.language,
        accountName: account.accountName,
        password: account.password,
        internalNote: account.internalNote,
        lastModifiedBy: account.lastModifiedBy,
        lastModifiedOn: account.lastModifiedOn,
      });
    });
    this.count = json.total;
  }

  fillToJson(): string {
    return JSON.stringify(this.filterRequestBody);
  }
}

export class AccountDetailsMapper implements IMapper {
  account: any;

  fillFromJson(data: any): void {
    this.account = data;
  }

  fillToJson(): string {
    return '';
  }
}

export class CreateUpdateAccountMapper implements IMapper {
  countryId!: string;
  surveySeriesId!: string;
  source!: string;
  language!: string | null;
  accountName!: string;
  password!: string;
  internalNote!: string | null;
  value: any[] = [];

  fillFromJson(json: any): void {
    this.value.push(json);
  }

  fillToJson(): string {
    return JSON.stringify({
      countryId: this.countryId,
      surveySeriesId: this.surveySeriesId,
      source: this.source,
      language: this.language ?? null,
      accountName: this.accountName,
      password: this.password,
      internalNote: this.internalNote ?? null,
    });
  }
}

export class DeleteAccountMapper implements IMapper {
  fillFromJson(_json: any): string {
    return 'Account deleted succesfully';
  }

  fillToJson(): string {
    return '';
  }
}

export class AccountHistoryMapper implements IMapper {
  history: any;
  fillFromJson(data: any): void {
    this.history = data.history;
  }
  fillToJson(): string {
    return '';
  }
}
