import { Component } from '@angular/core';
import { ProviderService } from '../../core/provider.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(private providerService: ProviderService) {}
  navigateToHome(): void {
    this.providerService.utilService.navigateTo('app/home');
  }
}
