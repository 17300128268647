import { IMapper } from '../../../models/i-mapper';
import { CountriesListsModel } from '../models/country-filters-model';
export class CountriesListsMapper implements IMapper {
  countriesLists: CountriesListsModel[] = [];
  fillFromJson(json: any): void {
    json.forEach((countryList: any) => {
      this.countriesLists.push({
        isoCode: countryList.isoCode,
        shortName: countryList.shortName,
      });
    });
  }
  fillToJson(): string {
    return '';
  }
}
