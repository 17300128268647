<div class="container">
  @if (loading) {
    <app-loader></app-loader>
  }
  @if (!detailOpen) {
    <div class="back-button-container">
      <button mat-button mat-raised-button color="primary" (click)="goBackToHome()"><i
          class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE.BACK' | translate }}
      </button>
    </div>
    <div class="filters-class">
      <ng-container>
        <div class="filters-bar" [formGroup]="filterRegionFormGroup">
          <div class="first-column">
          <span class="mat-drop-down">
            <mat-label>Code: </mat-label>
            <input matInput type="text" formControlName="regionCode" (input)="applyFilter()">
          </span>
          <span class="mat-drop-down">
            <mat-label>Name: </mat-label>
            <input matInput type="text" formControlName="region" (input)="applyFilter()">
          </span>
          <span class="mat-drop-down">
            <mat-label>Classification: </mat-label>
            <input matInput type="text" formControlName="classification" (input)="applyFilter()">
          </span>
          <span class="mat-drop-down">
            <mat-label>Parent: </mat-label>
            <input matInput type="text" formControlName="parentRegion" (input)="applyFilter()">
          </span>
          <span class="mat-drop-down last-item">
            <div class="button-container" style="margin-right: 10px">
              <button mat-raised-button color="primary" type="button" class="create-region-button"
                      (click)="openCreateClassDialog(createClassDialog)" onclick="this.blur()">
                <span>Create Class</span>
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <div class="button-container">
              <button mat-raised-button color="primary" type="button" class="create-region-button"
                      (click)="openCreateRegionDialog(createRegionDialog)" onclick="this.blur()">
                <span>Create Region</span>
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="table-holder mat-elevation-z8" tabindex="0">
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="regionCode">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div mat-sort-header>Code</div>
          </th>
          <td mat-cell *matCellDef="let element" (click)="openDetail(element.regionID)">
        <span class="row-line">
          {{ element.regionCode }}
        </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="regionName">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div mat-sort-header>Name</div>
          </th>
          <td mat-cell *matCellDef="let element" (click)="openDetail(element.regionID)">
            <span class="row-line">{{ element.regionName }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="classification">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div mat-sort-header>Classification</div>
          </th>
          <td mat-cell *matCellDef="let element" (click)="openDetail(element.regionID)">
            <span class="row-line">{{ element.classificationName || '-' }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="classificationDescription">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div mat-sort-header>Description</div>
          </th>
          <td mat-cell *matCellDef="let element" (click)="openDetail(element.regionID)">
            <span class="row-line">{{ element.classificationDescription || '-' }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="parentRegion">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div mat-sort-header>Parent Region</div>
          </th>
          <td mat-cell *matCellDef="let element" (click)="openDetail(element.regionID)">
            <span class="row-line">{{ element.parentRegionName || '-' }}</span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
      </table>
    </div>
    <div class="placeholder-footer mat-elevation-z8"></div>
  } @else if (detailOpen) {
    <div class="region-details-container">
      <mat-card class="region-details-table">
      <mat-card-header>{{ regionDetails.regionName }} ({{ regionDetails.regionCode }}) - Details</mat-card-header>
        <app-region-detail [inputRegionDetails]="regionDetails"
                           [regionsList]="regions"
                           [classesList]="classes"
                           (changeDetails)="onDetailsChanged($event)"
                           (loadingDetails)="onLoadingDetailsChange($event)"></app-region-detail>
      </mat-card>
      <div class="action-buttons dual-button" style="width: 100%">
        <button mat-raised-button color="secondary" style="width: 200px"
                onclick="this.blur()" (click)="goBack()">Back</button>
        <button mat-raised-button color="primary" class="danger-button update-region-button"
                (click)="openDeleteRegionConfirmDialog(deleteRegionDialog)">
          <i class="fa fa-trash"></i>
          Delete Region
        </button>
      </div>
    </div>
  }
</div>

<ng-template #deleteRegionDialog>
  <div class="container-dialog-select-columns">
    <mat-dialog-content>
      Are you sure you want to delete this region?
    </mat-dialog-content>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
    <button class="confirm-deletion-button" mat-raised-button
      (click)="deleteRegion()">
      <i class="fa fa-trash"></i>
      Delete
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #createRegionDialog let-data>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">Insert Region details</h2>
      <mat-dialog-content [formGroup]="createRegionFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Region Name</mat-label>
            <input matInput type="text" formControlName="regionName">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Region Code</mat-label>
            <input matInput type="text" formControlName="regionCode">
          </mat-form-field>
        @if (manuallySelect) {
          <mat-form-field appearance="fill">
            <mat-label>Class</mat-label>
            <mat-select #classParent [formControl]="createRegionFormGroup.controls.regionClass" class="alt-select"
                        (selectionChange)="classParent.close(); this.isCreatingNewClass = false; this.createRegionFormGroup.controls.autocompleteClass.setValue('')"
                        (openedChange)="onOpenedClassChange($event)">
              <mat-select-trigger>
                  <span class="label" [matTooltip]="createRegionFormGroup.controls.regionClass.value">
                    {{displayClass(createRegionFormGroup.controls.regionClass.value || '')}}
                  </span>
                @if (createRegionFormGroup.controls.regionClass.value) {
                  <i (click)="removeRegionClass($event)" class="fa fa-x clear-filter"
                     [matTooltip]="'Remove'"></i>
                }
              </mat-select-trigger>
              <input matInput type="text" formControlName="autocompleteClass"
                     class="autocomplete-input"
                     (keydown)="$event.stopPropagation()"
                     #regionClassElement>
              @if (createRegionFormGroup.controls.autocompleteClass.value === '') {
                <span class="autocomplete-placeholder">Search...</span>
              }
              <mat-option (click)="classParent.close(); setupForNewClass()"
              [value]="'new-class'">
                Create New...
                <i class="fa fa-circle-plus"></i>
              </mat-option>
              @for (cl of classes; track cl) {
                <mat-option [ngClass]="visuallyFilterClass(cl.className) ? '' : 'hidden'"
                            [value]="cl.classID">
                  {{ cl.className | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          @if (isCreatingNewClass) {
            <mat-form-field appearance="fill">
              <mat-label>Class Name</mat-label>
              <input matInput type="text" formControlName="newClassName">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Class Code</mat-label>
              <input matInput type="text" formControlName="newClassCode">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Class Description</mat-label>
              <input matInput type="text" formControlName="newClassDescription">
            </mat-form-field>
          }
          <mat-form-field appearance="fill">
            <mat-label>Parent Region</mat-label>
            <mat-select #regionParent [formControl]="createRegionFormGroup.controls.regionParent" class="alt-select"
                        (selectionChange)="regionParent.close(); this.createRegionFormGroup.controls.autocompleteRegion.setValue('')"
                        (openedChange)="onOpenedRegionChange($event)">
              <mat-select-trigger>
                  <span class="label" [matTooltip]="createRegionFormGroup.controls.regionParent.value">
                    {{displayRegion(createRegionFormGroup.controls.regionParent.value || '')}}
                  </span>
                @if (createRegionFormGroup.controls.regionParent.value !== '') {
                  <i (click)="removeParentRegion($event)" class="fa fa-x clear-filter"
                     [matTooltip]="'Remove'"></i>
                }
              </mat-select-trigger>
              <input matInput type="text" formControlName="autocompleteRegion"
                     class="autocomplete-input"
                     (keydown)="$event.stopPropagation()"
                     #regionParentElement>
              @if (createRegionFormGroup.controls.autocompleteRegion.value === '') {
                <span class="autocomplete-placeholder">Search...</span>
              }
              @for (region of regions; track region) {
                <mat-option [ngClass]="visuallyFilterRegion(region.regionName) ? '' : 'hidden'"
                            [value]="region.regionID">
                  {{ region.regionName | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        @if (selectedCopyfrom) {
          <mat-form-field appearance="fill">
            <mat-label>Copy From</mat-label>
            <mat-select #copyFromRegions [formControl]="createRegionFormGroup.controls.copyFrom" class="alt-select"
                        (selectionChange)="copyFromRegions.close(); this.createRegionFormGroup.controls.autocompleteCopyFrom.setValue('');
                        selectCopyChangeRegion()"
                        (openedChange)="onOpenedCopyFromChange($event)">
              <mat-select-trigger>
                  <span class="label">
                    {{ displayRegion(createRegionFormGroup.controls.copyFrom.value || '') }}
                  </span>
                @if (createRegionFormGroup.controls.copyFrom.value !== '') {
                  <i (click)="removeCopyFrom($event)" class="fa fa-x clear-filter"
                     [matTooltip]="'Remove'"></i>
                }
              </mat-select-trigger>
              <input matInput type="text" formControlName="autocompleteCopyFrom"
                     class="autocomplete-input"
                     (keydown)="$event.stopPropagation()"
                     #copyFromElement>
              @if (createRegionFormGroup.controls.autocompleteCopyFrom.value === '') {
                <span class="autocomplete-placeholder">Search...</span>
              }
              @for (region of regions; track region) {
                <mat-option [ngClass]="visuallyFilterRegionCopyFrom(region.regionName) ? '' : 'hidden'"
                            [value]="region.regionID">
                  {{ displayRegion(region.regionID) }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
      </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
        <button class="select-columns-button" mat-raised-button color="primary"
                (click)="createRegion()" [disabled]="!createRegionFormGroup.valid">
          Create
        </button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #createClassDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">Insert Class details</h2>
    <mat-dialog-content [formGroup]="createClassFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Class Name</mat-label>
        <input matInput type="text" formControlName="className">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Class Code</mat-label>
        <input matInput type="text" formControlName="classCode">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Class Description</mat-label>
        <input matInput type="text" formControlName="classDescription">
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary"
              (click)="createClass(false)" [disabled]="!createClassFormGroup.valid">
        Create
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
