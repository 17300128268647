import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SurveyModel } from '../survey-table/models/survey-model';
import { CountrySurveyModel } from '../survey-country-table/models/country-survey-model';
import { MatTableDataSource } from '@angular/material/table';
import { ProviderService } from 'src/app/core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompareContactModuleValidationModel } from './models/contact-validation-model';
import { DataTypeEnum } from '../country-validation-table/enum/data-type-enum';
import { ContactValidationTableModel } from './models/contact-validation-table-model';
import { ContactDataTypeEnum } from './enum/contact-data-type-enum';
import { ContactModel } from '../contacts-module/models/contact-model';

@Component({
  selector: 'app-contact-validation',
  templateUrl: './contact-validation.component.html',
  styleUrls: ['./contact-validation.component.scss'],
})
export class ContactValidationComponent implements AfterViewInit {
  @Input({ required: true }) selectedSurvey!: SurveyModel;
  @Input({ required: true }) selectedCountry!: CountrySurveyModel;
  @Output()
  validationCompleted = new EventEmitter();
  loading = true;
  dataSource!: MatTableDataSource<ContactValidationTableModel>;
  contacts: ContactModel[] = [];
  displayedColumns: string[] = [];
  cellHistory: any;
  historyLoading = false;
  previousSelectedCell?: ContactValidationTableModel;
  selectedCell?: ContactValidationTableModel;

  constructor(
    public providerService: ProviderService,
    private snackBar: MatSnackBar
  ) {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit(): void {
    this.getTableData();
  }

  selectCell(element: ContactValidationTableModel): void {
    this.selectedCell = element;
    if (
      this.selectedCell.oldContact.contactId !==
      this.previousSelectedCell?.oldContact.contactId
    ) {
      this.getCellHistory(this.selectedCell?.oldContact?.contactId ?? null);
    }
    this.previousSelectedCell = element;
  }

  getSelectedCell(element: ContactValidationTableModel): boolean {
    if (this.selectedCell) {
      if (this.selectedCell === element) {
        return true;
      }
    }
    return false;
  }

  setCellValue(
    element: ContactValidationTableModel,
    $event: string | null
  ): void {
    try {
      if ($event) {
        $event = $event.replaceAll('\n', '');
      }
      (element.newContact as any)[element.key] = $event;
      this.providerService.contactValidationService.updateValidationContact(
        this.selectedSurvey.surveyId,
        this.selectedCountry.countryID,
        {
          stagingContactId: element.newContact.contactId!,
          oldCountryId: element.newContact.oldCountryId!,
          prefix: element.newContact.prefix,
          firstName: element.newContact.firstName,
          lastName: element.newContact.lastName,
          email: element.newContact.email,
          phoneNumber: element.newContact.phoneNumber,
          organisationName: element.newContact.organization,
          title: element.newContact.title,
          website: element.newContact.website,
        }
      );
      element.type = this.checkDifference(
        (element.oldContact as any)[element.key],
        (element.newContact as any)[element.key]
      );
    } catch (e) {
      console.error(e);
      this.snackBar.open('An error occured while updating the contact', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  async completeValidation(): Promise<void> {
    this.loading = true;
    try {
      if (
        await this.providerService.utilService.openConfirmationDialog(
          'CONTACT_VALIDATION_TABLE.CONFIRMATION_TITLE',
          'CONTACT_VALIDATION_TABLE.CONFIRMATION_MESSAGE'
        )
      ) {
        await this.providerService.contactValidationService.completeValidation(
          this.selectedSurvey.surveyId,
          this.selectedCountry.countryID
        );
        this.validationCompleted.emit();
      }
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while sending the data to the live table',
        'X',
        {
          duration: 6000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  getHistoryDifferencesString(history: any): string {
    let changes = ': ';
    for (const property in history) {
      if (
        property !== 'version' &&
        property !== 'lastModifiedBy' &&
        property !== 'lastModifiedOn'
      ) {
        if (property === 'password' || property === 'surveySeriesId') {
          changes += `${property} changed, `;
        } else {
          changes += `${property} changed to ${
            history[property] === '' ? `''` : history[property]
          }, `;
        }
      }
    }
    return changes.slice(0, -2);
  }

  private async getCellHistory(cellID: string | null): Promise<void> {
    if (!cellID) {
      this.cellHistory = [];
    } else {
      this.historyLoading = true;
      try {
        const history =
          await this.providerService.contactService.getContactHistory(cellID);
        this.cellHistory =
          this.providerService.contactService.calculateHistoryDifferences(
            history
          );
      } catch (e) {
        console.error(e);
        this.snackBar.open(
          'An error occured while retrieving the cell history',
          'X',
          {
            duration: 3000,
            panelClass: ['error-snackbar'],
          }
        );
      } finally {
        this.historyLoading = false;
      }
    }
  }

  private async getTableData(): Promise<void> {
    this.loading = true;
    try {
      const backendData =
        await this.providerService.contactValidationService.getContact(
          this.selectedSurvey.surveyId,
          this.selectedCountry.countryID
        );
      this.contacts = backendData.map(
        (value) =>
          ({
            contactId: value.oldContact.contactId,
            oldContactId: value.newContact.contactId,
            countryId: null,
            surveySeriesIds: null,
            prefix: value.oldContact.prefix,
            firstName: value.oldContact.firstName,
            lastName: value.oldContact.lastName,
            title: value.oldContact.title,
            organisationName: null,
            phoneNumber: value.oldContact.phoneNumber,
            email: value.oldContact.email,
            website: value.oldContact.website,
            internalNote: null,
            linkedToAccount: null,
            lastModifiedBy: null,
            lastModifiedOn: null,
            countryShortName: null,
            surveySeriesNames: null,
          } as any)
      );
      if (this.contacts.length > 0) {
        this.getCellHistory(this.contacts[0].contactId);
      }
      this.setDatasourceData(backendData);
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  private setDatasourceData(
    backendData: CompareContactModuleValidationModel[]
  ): void {
    this.displayedColumns = ['rowDescription', 'oldContact', 'newContact'];
    const data: ContactValidationTableModel[] = [];
    for (const rowData of backendData) {
      for (const key of Object.keys(ContactDataTypeEnum)) {
        data.push({
          rowDescription:
            ContactDataTypeEnum[key as keyof typeof ContactDataTypeEnum],
          key,
          type: this.checkDifference(
            (rowData.oldContact as any)[key],
            (rowData.newContact as any)[key]
          ),
          ...rowData,
        });
      }
    }
    this.dataSource.data = data;
  }

  private checkDifference(oldData: string, newData: string): DataTypeEnum {
    if (oldData !== newData) {
      if (oldData && newData) {
        return DataTypeEnum.NewData;
      } else if (!newData && oldData) {
        return DataTypeEnum.dataDeleted;
      } else {
        return DataTypeEnum.dataChanged;
      }
    } else {
      return DataTypeEnum.NoChange;
    }
  }
}
