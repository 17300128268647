@if (loading) {
<app-loader></app-loader>
}
<div class="container">
  <div class="filters-class">
    <div class="filters-bar" [formGroup]="filterFormGroup">
      <div class="first-column">
        <div class="mat-drop-down">
          <mat-label>{{ 'VALIDATION_MODULE_TABLE.PERCENTAGE_CHANGE' | translate }}</mat-label>
          <input class="short-number-input" (input)="enforceMinMax($event)" type="number" min="-200" max="200"
            formControlName="percentageChange">
        </div>
        <div class="mat-drop-down">
          <mat-label>{{ 'VALIDATION_MODULE_TABLE.YEAR_FROM' | translate }}</mat-label>
          <input matInput type="number" formControlName="yearFrom">
        </div>
        <div class="mat-drop-down">
          <mat-label>{{ 'VALIDATION_MODULE_TABLE.YEAR_TO' | translate }}</mat-label>
          <input matInput type="number" formControlName="yearTo">
        </div>
        <div class="mat-drop-down">
          <app-region-country-filter [styleType]="'filters-bar'" (countriesChanged)="countryFilterChanged($event)"></app-region-country-filter>
        </div>
      </div>
    </div>
  </div>
  <div class="table-holder mat-elevation-z8" tabindex="0">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="countryName">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-header>{{ 'VALIDATION_MODULE_TABLE.COUNTRY_NAME_HEADER' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="row-line">
            {{ element.countryName }}
          </span>
        </td>
      </ng-container>
      @for (year of yearsToDisplay; track year) {
      <ng-container [matColumnDef]="year">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div>{{ year }}</div>
        </th>
        <td mat-cell *matCellDef="let element"
          class="input-cell {{isCellSelected(element, year) ? 'cell-selected': ''}}">
          @if(element.validationStatus === 'CONTACT_VALIDATION_COMPLETED') {
          <div class="input-container" (click)="setSelectedCell(element, year)">
            <input matInput type="text" appFormatNumberInput class="form-control custom-number-input" [value]="element.values[year]"
              (dataChanged)="setCellValue(element, year, $event.value)" />
          </div>
          } @else {
          <div class="input-container">
            <input matInput type="text" appFormatNumberInput class="form-control custom-number-input" [value]="element.values[year]"
              style="cursor: default;" readonly />
          </div>
          }
        </td>
      </ng-container>
      }
      @for (year of yearsToDisplay; track year) {
      <ng-container [matColumnDef]="year + ' %'">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div>{{ year + ' %' }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="input-cell">
          <span class="row-line">
            {{ element.percentages[year] }}
          </span>
        </td>
      </ng-container>
      }
      <ng-container matColumnDef="validationStatus">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-header>{{ 'VALIDATION_MODULE_TABLE.VALIDATION_STATUS_HEADER' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="row-line">
            {{ 'VALIDATION_MODULE_TABLE.' + element.validationStatus | translate}}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-header>{{ 'VALIDATION_MODULE_TABLE.ACTIONS_HEADER' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="row-line">
            @if(element.validationStatus === SurveyValidationStatusEnum.NOT_STARTED) {
            <button mat-raised-button color="primary" (click)="startValidation(element)">
              <span>{{'VALIDATION_MODULE_TABLE.START_VALIDATION' | translate}}</span>
            </button>
            }
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
    </table>
  </div>
  <div class="paginator">
    <mat-paginator class="hide-ripple" [length]="tableCount" [pageSizeOptions]="[50, 100, 150]">
    </mat-paginator>
  </div>
  <div class="data-info-container">
    @if(!selectedCell) {
    <div class="no-cell-warning">
      <span>{{'VALIDATION_MODULE_TABLE.NO_CELL_SELECTED' | translate}}</span>
    </div>
    } @else if (!historyLoading && !selectedCellHasId) {
    <div class="no-cell-warning">
      <span>{{'VALIDATION_MODULE_TABLE.NO_VALUE_SELECTED' | translate}}</span>
    </div>
    }
    <div class="data-info">
      <div class="notes-group">
        <div class="notes-container">
          <div class="notes-row">
            <span class="first">{{'VALIDATION_MODULE_TABLE.NOTES' | translate}}</span>
            <input #publishedNote matInput [disabled]="!selectedCell" (input)="notesSelectionChanged($event)"
              (focusout)="publishedNote.value.length > 0 ? updateNotes() : updateNotes('')"
              (keydown.enter)="publishedNote.value.length > 0 ? updateNotes() : updateNotes('')" type="text"
              [value]="selectedCell === undefined ? '' : selectedCell.cell.notes[selectedCell.year]">

          </div>
        </div>
      </div>
      <div class="data-history">
        <span>{{'VALIDATION_MODULE_TABLE.DATA_HISTORY' | translate}}</span>
        @if (historyLoading) {
        <i class="fa-solid fa-ellipsis ellipses-loader"
          style="position: absolute; left: 120px; top: 15px; margin-left: 40px; margin-top: 5px"></i>
        }
        <div class="data-history-list">
          @if (!historyLoading) {
          @for (comment of cellHistory; track comment) {
          @if(comment.type === 'insert') {
          <span>
            {{'VALIDATION_MODULE_TABLE.VALUE' | translate}} {{comment.value ?? 'null'}}
            {{'VALIDATION_MODULE_TABLE.INSERTED' | translate}} {{'VALIDATION_MODULE_TABLE.BY' | translate}}
            {{comment.modifiedBy}} {{'VALIDATION_MODULE_TABLE.ON' | translate}} {{comment.modifiedOn}}
          </span>
          }
          @if(comment.type === 'modification') {
          <span>
            {{'VALIDATION_MODULE_TABLE.VALUE' | translate}} {{comment.value ?? 'null'}}
            {{'VALIDATION_MODULE_TABLE.MODIFIED' | translate}} {{'VALIDATION_MODULE_TABLE.BY' | translate}}
            {{comment.modifiedBy}} {{'VALIDATION_MODULE_TABLE.ON' | translate}} {{comment.modifiedOn}}
          </span>
          }
          @if(comment.type === 'note') {
          <span>
            {{'VALIDATION_MODULE_TABLE.NOTE' | translate}} {{comment.note}} {{'VALIDATION_MODULE_TABLE.MODIFIED' |
            translate}} {{'VALIDATION_MODULE_TABLE.BY' | translate}} {{comment.modifiedBy}}
            {{'VALIDATION_MODULE_TABLE.ON' | translate}} {{comment.modifiedOn}}
          </span>
          }
          }
          }
        </div>
      </div>
    </div>
  </div>
</div>