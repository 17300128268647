import { Injectable } from '@angular/core';
import {
  UploadCheckDataMapper,
  UploadDataMapper,
} from '../mappers/upload-data-mapper';
import { environment } from '../../../../environments/environment';
import { NetworkService } from '../../../core/net-utils/network.service';
import { InsertsModel } from '../models/inserts.model';
import { DifferencesModel } from '../models/differences.model';

@Injectable({
  providedIn: 'root',
})
export class UploadDataService {
  networkService?: NetworkService;
  baseUrl = `${environment.backend.endpoint}/upload-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async uploadCheckData(csvData: any): Promise<any> {
    try {
      const mapper = new UploadCheckDataMapper();
      mapper.payload = csvData;
      await this.networkService?.post(
        `${this.baseUrl}/upload-data-check/`,
        mapper,
        mapper
      );
      return { differences: mapper.differences, inserts: mapper.inserts };
    } catch (e) {
      //TODO: handle specific errors
      throw new Error();
    }
  }

  async uploadData(
    inserts: InsertsModel[],
    differences: DifferencesModel[]
  ): Promise<any> {
    try {
      const mapper = new UploadDataMapper();
      mapper.inserts = inserts;
      mapper.differences = differences;
      await this.networkService?.post(
        `${this.baseUrl}/upload-data-csv/`,
        mapper,
        mapper
      );
      return mapper;
    } catch (e) {
      //TODO: handle specific errors
      throw new Error();
    }
  }
}
