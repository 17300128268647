import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-delete-row-error-dialog',
  templateUrl: './delete-row-error-dialog.component.html',
  styleUrls: ['./delete-row-error-dialog.component.scss']
})
export class DeleteRowErrorDialogComponent {
  constructor(private dialog: MatDialogRef<DeleteRowErrorDialogComponent>) {
    dialog.disableClose = true;
  }
  closeDialog(): void {
    this.dialog.close();
  }
}
