import { Injectable } from '@angular/core';
import { Dataset } from './home.models';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  getAvailableDatasets(): Dataset[] {
    return [
      {
        name: 'Country View',
      },
    ];
  }
}
