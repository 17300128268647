import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutOutAppComponent } from './layout/layout-out-app/layout-out-app.component';
import { LayoutInAppComponent } from './layout/layout-in-app/layout-in-app.component';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { AuthGuardService } from './core/auth-utils/auth-guard.service';
import { AdminViewComponent } from './components/admin-section/admin-view/admin-view.component';
import { UpdateCountryTableComponent } from './components/update-country-table/update-country-table.component';
import { DownloadModuleComponent } from './components/download-module/download-module.component';
import { UploadModuleComponent } from './components/upload-module/upload-module.component';
import { RegionsManagementComponent } from './components/regions-management/regions-management.component';
import { HouseholdViewComponent } from './components/household-view/household-view.component';

const routes: Routes = [
  {
    path: 'auth',
    component: LayoutOutAppComponent,
    children: [{ path: 'sign-in', component: SignInComponent }],
  },
  {
    path: 'app',
    component: LayoutInAppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'home', component: HomeComponent },
      {
        path: 'datasets/country-view',
        component: UpdateCountryTableComponent,
      },
      {
        path: 'datasets/household-view',
        component: HouseholdViewComponent,
      },
      { path: 'admin-view', component: AdminViewComponent },
      { path: 'download-module', component: DownloadModuleComponent },
      { path: 'upload-module', component: UploadModuleComponent },
      {
        path: 'data-management-regions',
        component: RegionsManagementComponent,
      },
    ],
  },
  { path: '', redirectTo: '/app/home', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
