import { IMapper } from 'src/app/models/i-mapper';
import { CompareContactModuleValidationModel } from '../models/contact-validation-model';

export class ContactModuleValidationMapper implements IMapper {
  contacts: CompareContactModuleValidationModel[] = [];
  fillFromJson(json: any): void {
    this.contacts = [];
    json.forEach((element: any) => {
      this.contacts.push({
        oldContact: {
          contactId: element.contactId,
          oldCountryId: null,
          organization: element.oldOrganisationName,
          prefix: element.oldPrefix,
          firstName: element.oldFirstName,
          lastName: element.oldLastName,
          email: element.oldEmail,
          phoneNumber: element.oldPhoneNumber,
          title: element.oldTitle,
          website: element.oldWebsite,
        },
        newContact: {
          contactId: element.stagingContactId,
          oldCountryId: element.oldCountryId,
          organization: element.newOrganisationName,
          prefix: element.newPrefix,
          firstName: element.newFirstName,
          lastName: element.newLastName,
          email: element.newEmail,
          phoneNumber: element.newPhoneNumber,
          title: element.newTitle,
          website: element.newWebsite,
        },
      });
    });
  }

  fillToJson(): string {
    return '';
  }
}
