<div class="form-container {{styleType}}" [formGroup]="unitsFormGroup">
  @if(styleType === 'filters-bar') {
    <div class="mat-drop-down">
      <mat-label>{{'FILTERS.UNIT_LABEL' | translate}}</mat-label>
      <mat-select [formControl]="unitsFormGroup.controls.unit" (openedChange)="onOpenedUnitChange($event)" multiple>
        <mat-select-trigger>
          <span class="label" [matTooltip]="displayMultipleUnitsFn(unitsFormGroup.controls.unit.value)">
            {{displayUnitFn(unitsFormGroup.controls.unit.value?.[0]) || ''}}
            @if ((unitsFormGroup.controls.unit.value?.length || 0) > 1) {
            <span>
              (+{{(unitsFormGroup.controls.unit.value?.length || 0) - 1}}
              {{unitsFormGroup.controls.unit.value?.length === 2 ? 'other' : 'others'}})
            </span>
            }
          </span>
          @if ((unitsFormGroup.controls.unit.value?.length || 0) > 0) {
          <i (click)="removeUnitFilter($event)" class="fa fa-x clear-filter"
            matTooltip="{{'FILTERS.CLEAR_FILTERS' | translate}}"></i>
          }
        </mat-select-trigger>
        <input matInput type="text" formControlName="autocompleteUnit" class="autocomplete-input"
          (keydown)="$event.stopPropagation()" #unitInputElement
          placeholder="{{ 'FILTERS.AUTOCOMPLETE_PLACEHOLDER' | translate }}">
        @for (unit of units; track unit) {
        <mat-option [ngClass]="visuallyFilterUnits(unit) ? '' : 'hidden'" [value]="unit">
          {{ unit.unitDescription }}
        </mat-option>
        }
      </mat-select>
    </div>
  } @else {
    <mat-form-field appearance="fill">
      <mat-label>{{'FILTERS.UNIT_LABEL' | translate}}</mat-label>
      <mat-select [formControl]="unitsFormGroup.controls.unit" (openedChange)="onOpenedUnitChange($event)" multiple>
      <mat-select-trigger>
          <span class="label" [matTooltip]="displayMultipleUnitsFn(unitsFormGroup.controls.unit.value)">
            {{displayUnitFn(unitsFormGroup.controls.unit.value?.[0]) || ''}}
            @if ((unitsFormGroup.controls.unit.value?.length || 0) > 1) {
              <span>
              (+{{(unitsFormGroup.controls.unit.value?.length || 0) - 1}}
                {{unitsFormGroup.controls.unit.value?.length === 2 ? 'other' : 'others'}})
            </span>
            }
          </span>
        @if ((unitsFormGroup.controls.unit.value?.length || 0) > 0) {
          <i (click)="removeUnitFilter($event)" class="fa fa-x clear-filter"
             matTooltip="{{'FILTERS.CLEAR_FILTERS' | translate}}"></i>
        }
      </mat-select-trigger>
      <input matInput type="text" formControlName="autocompleteUnit" class="autocomplete-input"
             (keydown)="$event.stopPropagation()" #unitInputElement
             placeholder="{{ 'FILTERS.AUTOCOMPLETE_PLACEHOLDER' | translate }}">
      @for (unit of units; track unit) {
        <mat-option [ngClass]="visuallyFilterUnits(unit) ? '' : 'hidden'" [value]="unit">
          {{ unit.unitDescription }}
        </mat-option>
      }
    </mat-select>
    </mat-form-field>
  }
</div>
