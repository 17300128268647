<div class="info-region-detail">
  <b>Class Name:</b> {{ classInfo ? classInfo.className + ' (Code: ' + classInfo.classCode +')' : 'none' }}
</div>
<div class="info-region-detail">
  <b>Class Description:</b> {{ classInfo ? classInfo.classDescription : 'none' }}
</div>
<div class="info-region-detail last">
  <b>Parent Region:</b> {{ parentRegionInfo ? parentRegionInfo.regionName : 'none' }}
</div>
<div class="actions-bar">
  @if (!hasNoYears) {
    <mat-label>Selected Year: </mat-label>
    <button color="primary" mat-raised-button type="button" onclick="this.blur()"
            class="selected-year-button" [matMenuTriggerFor]="showTypeMenu">
      {{ selectedYear === 0 ? 'default' : selectedYear }}
      <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>
  }
  <button color="primary" mat-raised-button onclick="this.blur()"
          class="add-year-button" (click)="openAddYearDialog(addYearDialog)">
    <i class="fa fa-plus"></i>
    Add year
  </button>
  <div style="margin-left: auto; display: flex; flex-direction: row; gap: 20px">
    <button color="primary" mat-raised-button onclick="this.blur()"
            class="update-region-button" (click)="openEditRegionDialog(editRegion)">
      <i class="fa fa-pen"></i>
      Edit Region Info
    </button>
  </div>
  <mat-menu #showTypeMenu="matMenu">
    @for (yearsList of alreadyExistingYears; track yearsList) {
      @if (yearsList.year !== null) {
        <button class="mat-menu-show-type" mat-menu-item (click)="switchToYear(yearsList.year)"
                onclick="this.blur()">
          {{ yearsList.year === 0 ? 'default' : yearsList.year || 'default' }}
        </button>
      }
    }
  </mat-menu>
</div>
@if (hasNoYears) {
  <div style="display: flex; justify-content: center">
    <span style="margin: 30px">No years info</span>
  </div>
}
@else {
  <div class="table-container mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef onclick="this.blur()" style="width: 30px;">
          <button mat-raised-button color="primary" class="add-country-current-year" onclick="this.blur()"
                  (click)="openAddCountryDialog(addCountriesToCurrentYear)" [matTooltip]="'Add more countries'">
            <i class="fa fa-plus"></i>
          </button>
        </th>
        <td mat-cell *matCellDef="let element" onclick="this.blur()"
            [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}"
            style="width: 40px">
          <mat-checkbox
            class="no-focus-outline"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element) : null; checkIfPartialSelection()"
            [checked]="selection.isSelected(element)"
            onclick="this.blur()"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="year">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="width: 200px">Year</th>
        <td mat-cell *matCellDef="let element" style="width: 200px; text-align: center"
            (click)="selection.toggle(element); checkIfPartialSelection()">
            <span class="row-line">
              {{ element.year === 0 ? 'default' : element.year }}
            </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="regionCountryCode">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="width: 200px">Country Code</th>
        <td mat-cell *matCellDef="let element" style="width: 200px; text-align: center"
            (click)="selection.toggle(element); checkIfPartialSelection()">
            <span class="row-line">
              {{ element.isoCode }}
            </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="regionCountryName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Country Name</th>
        <td mat-cell *matCellDef="let element"
            (click)="selection.toggle(element); checkIfPartialSelection()">
        <span class="row-line">
          {{ element.shortName }}
        </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="z-index-fix"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
    </table>
  </div>
  <div class="paginator">
    <mat-paginator class="mat-elevation-z8 hide-ripple"
                   [length]="dataSource.data.length"
                   [pageSizeOptions]="[10, 25, 50, 100, 150]">
    </mat-paginator>
  </div>
}

@if (selectionIncomplete) {
  <div class="actions-bar" style="width: 100%; justify-content: center; margin-top: 15px">
    <button mat-raised-button color="primary" style="width: 200px; font-size: 16px; height: 30px"
            onclick="this.blur()" (click)="removeUpdateCountries()">
      <i class="fa fa-calendar-pen" style="padding-left: 0"></i>
      Update Countries
    </button>
  </div>
}

<ng-template #addYearDialog let-data>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title checkbox-margin">Add Year</h2>
    <mat-dialog-content [formGroup]="addYearFormGroup">
      <mat-checkbox formControlName="default" (change)="onDefaultCountriesChange()"
        style="">
        Default
      </mat-checkbox>
      @if (!hideYears) {
        <mat-form-field appearance="fill">
          <mat-label>Year</mat-label>
          <input matInput type="number" formControlName="year" placeholder="YYYY" min="0"
                 (keydown)="($event.key === '-' || $event.key === '.'|| $event.key === 'e') ? $event.preventDefault() : ''">
        </mat-form-field>
      }
      @if (!hasNoYears) {
        @if (selectedCopyfrom) {
          <mat-form-field appearance="fill">
            <mat-label>Copy Countries From</mat-label>
            <mat-select #existingYears [formControl]="addYearFormGroup.controls.countries" class="alt-select"
                        (selectionChange)="existingYears.close(); this.addYearFormGroup.controls.autocomplete.setValue('');
                      this.selectedCopyfrom = true; this.manuallySelect = false"
                        (openedChange)="onOpenedYearChange($event)">
              <mat-select-trigger>
                  <span class="label">
                    {{ copyFromSelectedYear === 0 ? 'default' : copyFromSelectedYear }}
                  </span>
                @if (addYearFormGroup.controls.countries.value !== []) {
                  <i (click)="removeYear($event)" class="fa fa-x clear-filter"
                     [matTooltip]="'Remove'"></i>
                }
              </mat-select-trigger>
              <input matInput type="text" formControlName="autocomplete"
                     class="autocomplete-input"
                     (keydown)="$event.stopPropagation()"
                     #yearElement>
              @if (addYearFormGroup.controls.autocomplete.value === '') {
                <span class="autocomplete-placeholder">Search...</span>
              }
              @for (year of alreadyExistingYears; track year) {
                @if (year.year !== null) {
                  <mat-option [ngClass]="visuallyFilterYear(year.year) ? '' : 'hidden'"
                              [value]="year.countries" (click)="copyFromSelectedYear = year.year">
                    {{ year.year === 0 ? 'default' : year.year}}
                  </mat-option>
                }
              }
            </mat-select>
          </mat-form-field>
        }
      }
      @if (manuallySelect) {
        <mat-form-field appearance="fill">
          <mat-label>Select Countries</mat-label>
          <mat-select [formControl]="addYearFormGroup.controls.countries" class="alt-select"
                      (selectionChange)="this.addYearFormGroup.controls.autocompleteCountries.setValue(''); countriesElement.focus();
                    this.selectedCopyfrom = false; this.manuallySelect = true"
                      (openedChange)="onOpenedCountryChange($event)" multiple>
            <mat-select-trigger>
            <span class="label" [matTooltip]="displayMultipleFn(addYearFormGroup.controls.countries.value)">
              {{displayFn(addYearFormGroup.controls.countries.value?.[0] || '')}}
              @if ((addYearFormGroup.controls.countries.value?.length || 0) > 1) {
                <span class="example-additional-selection">
                  (+{{(addYearFormGroup.controls.countries.value?.length || 0) - 1}} {{addYearFormGroup.controls.countries.value?.length === 2 ? 'other' : 'others'}})
                </span>
              }
            </span>
              @if ((addYearFormGroup.controls.countries.value?.length || 0) > 0) {
                <i (click)="removeCountryOrRegionFilter($event)" class="fa fa-x clear-filter"
                   [matTooltip]="'Clear filter'"></i>
              }
            </mat-select-trigger>
            <input matInput type="text" formControlName="autocompleteCountries"
                   class="autocomplete-input"
                   (keydown)="$event.stopPropagation()"
                   #countriesElement>
            @if (addYearFormGroup.controls.autocompleteCountries.value === '') {
              <span class="autocomplete-placeholder">Search...</span>
            }
            @for (country of countries; track country) {
              <mat-option [ngClass]="visuallyFilterCountries(country) ? '' : 'hidden'" [value]="country">
                {{ country.isoCode }} - {{ country.shortName }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="resetRegionInfo(); resetAddYear();">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary" [disabled]="!addYearFormGroup.valid" (click)="addYear()">
        Add
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #editRegion let-data>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">Edit Region Info</h2>
    <mat-dialog-content [formGroup]="editRegionFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Region Name</mat-label>
        <input matInput type="text" formControlName="regionName" placeholder="Region Name">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Region Code</mat-label>
        <input matInput type="text" formControlName="regionCode" placeholder="Region Code">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Class</mat-label>
        <mat-select #selectedClass [formControl]="editRegionFormGroup.controls.class" class="alt-select"
                    (selectionChange)="selectedClass.close(); this.isCreatingNewClass = false; this.editRegionFormGroup.controls.autocomplete.setValue('')"
                    (openedChange)="onOpenedClassChange($event)">
          <mat-select-trigger>
                  <span class="label">
                    {{ displayClassFn(editRegionFormGroup.controls.class.value) }}
                  </span>
            @if (editRegionFormGroup.controls.class.value !== '') {
              <i (click)="removeClass($event)" class="fa fa-x clear-filter"
                 [matTooltip]="'Remove'"></i>
            }
          </mat-select-trigger>
          <input matInput type="text" formControlName="autocomplete"
                 class="autocomplete-input"
                 (keydown)="$event.stopPropagation()"
                 #classElement>
          @if (editRegionFormGroup.controls.autocomplete.value === '') {
            <span class="autocomplete-placeholder">Search...</span>
          }
          <mat-option (click)="selectedClass.close(); setupForNewClass()"
                      [value]="'new-class'">
            Create New...
            <i class="fa fa-circle-plus"></i>
          </mat-option>
          @for (cl of classesList; track cl) {
            <mat-option [ngClass]="visuallyFilterClass(cl.className) ? '' : 'hidden'"
                        [value]="cl.classID">
              {{ displayClassFn(cl.classID) }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (isCreatingNewClass) {
        <mat-form-field appearance="fill">
          <mat-label>Class Name</mat-label>
          <input matInput type="text" formControlName="newClassName">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Class Code</mat-label>
          <input matInput type="text" formControlName="newClassCode">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Class Description</mat-label>
          <input matInput type="text" formControlName="newClassDescription">
        </mat-form-field>
      }

      <mat-form-field appearance="fill">
        <mat-label>Parent Region</mat-label>
        <mat-select #selectedParentRegion [formControl]="editRegionFormGroup.controls.parentRegion" class="alt-select"
                    (selectionChange)="selectedParentRegion.close(); this.editRegionFormGroup.controls.autocompleteParent.setValue('')"
                    (openedChange)="onOpenedParentChange($event)">
          <mat-select-trigger>
                  <span class="label">
                    {{ displayParentFn(editRegionFormGroup.controls.parentRegion.value) }}
                  </span>
            @if (editRegionFormGroup.controls.parentRegion.value !== '') {
              <i (click)="removeParentRegion($event)" class="fa fa-x clear-filter"
                 [matTooltip]="'Remove'"></i>
            }
          </mat-select-trigger>
          <input matInput type="text" formControlName="autocompleteParent"
                 class="autocomplete-input"
                 (keydown)="$event.stopPropagation()"
                 #parentRegionElement>
          @if (editRegionFormGroup.controls.autocompleteParent.value === '') {
            <span class="autocomplete-placeholder">Search...</span>
          }
          @for (region of regionsList; track region) {
            <mat-option [ngClass]="visuallyFilterRegion(region.regionName) ? '' : 'hidden'"
                        [value]="region.regionID">
              {{ displayParentFn(region.regionID) }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="resetRegionInfo(); resetAddYear();">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary" [disabled]="!editRegionFormGroup.valid" (click)="submitEditRegion()">
        Edit
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #addCountriesToCurrentYear let-data>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">Add countries to current year</h2>
    <mat-dialog-content [formGroup]="addCountriesFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Select Countries</mat-label>
        <mat-select [formControl]="addCountriesFormGroup.controls.countries" class="alt-select"
                    (selectionChange)="this.addCountriesFormGroup.controls.autocomplete.setValue(''); countriesElement2.focus();"
                    (openedChange)="onOpenedAddCountryChange($event)" multiple>
          <mat-select-trigger><span class="label" [matTooltip]="displayMultipleFn(addCountriesFormGroup.controls.countries.value)">
            {{displayFn(addCountriesFormGroup.controls.countries.value?.[0] || '')}}
            @if ((addCountriesFormGroup.controls.countries.value?.length || 0) > 1) {
              <span class="example-additional-selection">
                (+{{(addCountriesFormGroup.controls.countries.value?.length || 0) - 1}} {{addCountriesFormGroup.controls.countries.value?.length === 2 ? 'other' : 'others'}})
              </span>
            }
          </span>
            @if ((addCountriesFormGroup.controls.countries.value?.length || 0) > 0) {
              <i (click)="removeCountries($event)" class="fa fa-x clear-filter"
                 [matTooltip]="'Clear filter'"></i>
            }
          </mat-select-trigger>
          <input matInput type="text" formControlName="autocomplete"
                 class="autocomplete-input"
                 (keydown)="$event.stopPropagation()"
                 #countriesElement2>
          @if (addCountriesFormGroup.controls.autocomplete.value === '') {
            <span class="autocomplete-placeholder">Search...</span>
          }
          @for (country of addableCountries; track country) {
            <mat-option [ngClass]="visuallyFilterCountries2(country) ? '' : 'hidden'" [value]="country">
              {{ country.isoCode }} - {{ country.shortName }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="resetAddCountry();">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary" [disabled]="!addCountriesFormGroup.valid" (click)="addCountries()">
        Add
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
