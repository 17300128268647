import { IMapper } from '../../../models/i-mapper';
import { CellSourcesModel } from './models';
export class CellSourcesMapper implements IMapper {
  cellSources: CellSourcesModel[] = [];
  fillFromJson(json: any): void {
    json.forEach((cellSource: any) => {
      this.cellSources.push({
        source: cellSource.source,
        sourceId: cellSource.sourceId,
        dataId: cellSource.dataId,
      });
    });
  }
  fillToJson(): string {
    return '';
  }
}

export class UpdateSourceMapper implements IMapper {
  sourcesToBeModified: CellSourcesModel[] = [];

  fillFromJson(_json: any): string {
    return 'Updated the sources successfully';
  }

  fillToJson(): string {
    return JSON.stringify(this.sourcesToBeModified);
  }
}
