import { IMapper } from '../../../models/i-mapper';
import { ValidationModuleListCodesModel } from '../../validation-module-list-codes/models/validation-module-list-codes-models';


export class getCodeMapper implements IMapper {
  codeModel: ValidationModuleListCodesModel = {} as ValidationModuleListCodesModel;
  fillFromJson(json: any): void {
    this.codeModel = {
      codeId: json['codeId'],
      code: json['code'],
      codeDescription: json['description'],
    };
  }
  fillToJson(): string {
    return '';
  }
}
