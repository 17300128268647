<div class="container">
  <div class="header">
    <h1>Delete record</h1>
    <i (click)="closeDialog()" matTooltip="Close" class="fa fa-window-close" aria-hidden="true"></i>
  </div>
  <div class="message-body">
    <h1>Attention</h1>
    <p>
      You are deleting a full record
    </p>
  </div>
  <div class="buttons">
    <button mat-raised-button color="primary">Confirm</button>
    <button mat-raised-button class="cancel-button" (click)="closeDialog()">Cancel</button>
  </div>
</div>
