<div class="container">
  @if (loading) {
  <app-loader></app-loader>
  }
  @if (!detailOpen) {
  <div class="back-button-container">
    <button class="left-positioned" mat-button mat-raised-button color="primary" (click)="goBackToHome()"><i
        class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE.BACK' | translate }}
    </button>
    <div class="navigation-buttons-container">
      <button mat-button mat-raised-button color="primary" (click)="switchView(true)">
        Contacts View
      </button>
      <button mat-button mat-raised-button color="primary" (click)="switchView(false)">
        Accounts View
      </button>
    </div>
  </div>
  <div class="filters-class">
    @if (showContacts) {
    <ng-container>
      <div class="filters-bar" [formGroup]="filterContactsFormGroup">
        <div class="first-column">
          <span class="mat-drop-down">
            <mat-label>Survey: </mat-label>
            <mat-form-field>
              <mat-select formControlName="surveySeries" multiple>
                @for (surveySeries of surveySeries; track surveySeries) {
                <mat-option [value]="surveySeries.surveySeriesId">
                  {{ surveySeries.surveySeriesName }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </span>
          <span class="mat-drop-down">
            <mat-label>Country: </mat-label>
            <mat-form-field>
              @if (filterContactsFormGroup.controls.country.value !== '') {
              <i (click)="removeCountryFilterContacts($event)" class="fa fa-x clear-filter" style="top: -2px"
                matTooltip="{{'COUNTRY_VIEW_DOWNLOAD.CLEAR_FILTERS' | translate}}"></i>
              }
              <mat-select formControlName="country" (openedChange)="countriesElement.focus()">
                <input matInput type="text" formControlName="autocompleteCountries" class="autocomplete-input"
                  (keydown)="$event.stopPropagation()" #countriesElement>
                @if (filterContactsFormGroup.controls.autocompleteCountries.value === '') {
                <span class="autocomplete-placeholder">Search...</span>
                }
                @for (country of countries; track country) {
                <mat-option [ngClass]="visuallyFilterContactCountries(country) ? '' : 'hidden'"
                  [value]="country.countryID">
                  {{ country.shortName }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </span>
          <span class="mat-drop-down">
            <mat-label>Main Contact: </mat-label>
            <mat-checkbox #checkBox [indeterminate]="true" (change)="toggleCheckboxState(checkBox)">
            </mat-checkbox>
          </span>
          <span class="mat-drop-down last-item">
            <div class="button-container" style="margin-right: 10px">
              <button mat-raised-button color="primary" type="button" class="create-region-button"
                (click)="export('contacts')" onclick="this.blur()">
                <span>Export</span>
                <i class="fa fa-download" style="padding-right: 0"></i>
              </button>
            </div>
            <div class="button-container">
              <button mat-raised-button color="primary" type="button" class="create-region-button"
                (click)="openCreateContactDialog(createContactDialog)" onclick="this.blur()">
                <span>Create Contact</span>
                <i class="fa fa-plus" style="padding-right: 0"></i>
              </button>
            </div>
          </span>
        </div>
      </div>
    </ng-container>
    } @else {
    <ng-container>
      <div class="filters-bar" [formGroup]="filterAccountsFormGroup">
        <div class="first-column">
          <span class="mat-drop-down">
            <mat-label>Survey: </mat-label>
            <mat-form-field>
              <mat-select formControlName="surveySeries" multiple>
                @for (surveySeries of surveySeries; track surveySeries) {
                <mat-option [value]="surveySeries.surveySeriesId">
                  {{ surveySeries.surveySeriesName }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </span>
          <span class="mat-drop-down">
            <mat-label>Country: </mat-label>
            <mat-form-field>
              @if (filterAccountsFormGroup.controls.country.value !== '') {
              <i (click)="removeCountryFilterAccounts($event)" class="fa fa-x clear-filter" style="top: -2px"
                matTooltip="{{'COUNTRY_VIEW_DOWNLOAD.CLEAR_FILTERS' | translate}}"></i>
              }
              <mat-select formControlName="country" (openedChange)="accountCountriesElement.focus()">
                <input matInput type="text" formControlName="autocompleteCountries" class="autocomplete-input"
                  (keydown)="$event.stopPropagation()" #accountCountriesElement>
                @if (filterAccountsFormGroup.controls.autocompleteCountries.value === '') {
                <span class="autocomplete-placeholder">Search...</span>
                }
                @for (country of countries; track country) {
                <mat-option [ngClass]="visuallyFilterAccountCountries(country) ? '' : 'hidden'"
                  [value]="country.countryID">
                  {{ country.shortName }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </span>
          <span class="mat-drop-down">
            <mat-label>Name: </mat-label>
            <input matInput type="text" formControlName="name">
          </span>
          <span class="mat-drop-down">
            <mat-label>Language: </mat-label>
            <input matInput type="text" formControlName="language">
          </span>
          <span class="mat-drop-down last-item">
            <div class="button-container" style="margin-right: 10px">
              <button mat-raised-button color="primary" type="button" class="create-region-button"
                (click)="export('accounts')" onclick="this.blur()">
                <span>Export</span>
                <i class="fa fa-download" style="padding-right: 0"></i>
              </button>
            </div>
            <div class="button-container">
              <button mat-raised-button color="primary" type="button" class="create-region-button"
                (click)="openCreateAccountDialog(createAccountDialog)" onclick="this.blur()">
                <span>Create Account</span>
                <i class="fa fa-plus" style="padding-right: 0"></i>
              </button>
            </div>
          </span>
        </div>
      </div>
    </ng-container>
    }
  </div>
  @if (showContacts) {
  <div class="table-holder mat-elevation-z8" tabindex="0">
    <table mat-table matSort [dataSource]="contactsTableDataSource" #contactsMatSort="matSort"
      (matSortChange)="this.getContactsTableData()">
      <ng-container matColumnDef="prefix">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Prefix</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedContactId === element.contactId ? '#BFDEF7' : ''}"
          (click)="getContactHistory(element.contactId)">
          <span class="row-line">
            {{ element.prefix || '-' }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Name</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedContactId === element.contactId ? '#BFDEF7' : ''}"
          (click)="getContactHistory(element.contactId)">
          <span class="row-line">
            {{ element.firstName || '-' }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Surname</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedContactId === element.contactId ? '#BFDEF7' : ''}"
          (click)="getContactHistory(element.contactId)">
          <span class="row-line">{{ element.lastName || '-' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="organisation_name">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Organisation</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedContactId === element.contactId ? '#BFDEF7' : ''}"
          (click)="getContactHistory(element.contactId)">
          <span class="row-line">{{ element.organisationName || '-' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Title</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedContactId === element.contactId ? '#BFDEF7' : ''}"
          (click)="getContactHistory(element.contactId)">
          <span class="row-line">{{ element.title || '-' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="country_short_name">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Country</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedContactId === element.contactId ? '#BFDEF7' : ''}"
          (click)="getContactHistory(element.contactId)">
          <span class="row-line">{{ element.countryShortName || '-' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="surveySeries">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div>Survey</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedContactId === element.contactId ? '#BFDEF7' : ''}"
          (click)="getContactHistory(element.contactId)">
          <span class="row-line">{{ element.surveySeriesNames }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="linkedToAccount">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div>Main Contact</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedContactId === element.contactId ? '#BFDEF7' : ''}"
          (click)="getContactHistory(element.contactId)">
          <span class="row-line">{{ element.linkedToAccount ? 'Yes' : 'No' }}</span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsContacts; sticky: true" class="mat-header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsContacts;" class="table-row"></tr>
    </table>
  </div>
  <div class="paginator">
    <button style="width: 200px; font-size: 14px" mat-raised-button color="primary" (click)="openSyncWithQualtricsDialog(syncWithQualtricsDialog)">
      <i class="fa-solid fa-rotate"></i>
      Sync with qualtrics
    </button>
    <mat-paginator #contactsPaginator class="mat-elevation-z8 hide-ripple" [length]="tableDataCountContacts"
      [pageSizeOptions]="[50, 100, 150]" (page)="this.getContactsTableData()">
    </mat-paginator>
  </div>
  <div class="data-info-container">
    <div class="data-info">
      <div class="data-history">
        <span>History: </span>
        @if (historyLoading && selectedContactId) {
        <i class="fa-solid fa-ellipsis ellipses-loader"
          style="position: absolute; left: 120px; top: 15px; margin-left: 40px; margin-top: 5px"></i>
        }
        <div>
          @if (!historyLoading && selectedContactHistory?.length === 0) {
            <p>No history found.</p>
          }
          @for (history of selectedContactHistory; track history) {
            @if(!historyLoading && selectedContactId) {
              <p>Contact edited{{ history.lastModifiedBy ? ' by ' + history.lastModifiedBy : ''}}
                 on {{ formatHistoryDate(history.lastModifiedOn) }}{{ getHistoryDifferencesString(history) || '' }}</p>
            }
          }
        </div>
        <button class="open-detail-button" (click)="openContactDetail(selectedContactId)"
          [disabled]="!selectedContactId" mat-button mat-raised-button color="primary">
          Open Detail
          <i style="padding: 0" class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
  } @else {
  <div class="table-holder mat-elevation-z8" tabindex="0">
    <table mat-table matSort [dataSource]="accountsTableDataSource" #accountsMatSort="matSort"
      (matSortChange)="this.getAccountsTableData()">
      <ng-container matColumnDef="account_name">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Account Name</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedAccountId === element.accountId ? '#BFDEF7' : ''}"
          (click)="getAccountHistory(element.accountId)">
          <span class="row-line">
            {{ element.accountName }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="country_short_name">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Country</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedAccountId === element.accountId ? '#BFDEF7' : ''}"
          (click)="getAccountHistory(element.accountId)">
          <span class="row-line">{{ element.countryShortName }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Language</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedAccountId === element.accountId ? '#BFDEF7' : ''}"
          (click)="getAccountHistory(element.accountId)">
          <span class="row-line">{{ element.language || '-' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="surveySeries">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div>Survey</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedAccountId === element.accountId ? '#BFDEF7' : ''}"
          (click)="getAccountHistory(element.accountId)">
          <span class="row-line">{{ displaySurvey(element.surveySeriesId) }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>Source</div>
        </th>
        <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': selectedAccountId === element.accountId ? '#BFDEF7' : ''}"
          (click)="getAccountHistory(element.accountId)">
          <span class="row-line">{{ element.source }}</span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsAccounts; sticky: true" class="mat-header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsAccounts;" class="table-row"></tr>
    </table>
  </div>
  <div class="paginator">
    <mat-paginator #accountsPaginator class="mat-elevation-z8 hide-ripple" [length]="tableDataCountAccounts"
      [pageSizeOptions]="[50, 100, 150]" (page)="this.getAccountsTableData()">
    </mat-paginator>
  </div>
  <div class="data-info-container">
    <div class="data-info">
      <div class="data-history">
        <span>History: </span>
        @if (historyLoading && selectedAccountId) {
        <i class="fa-solid fa-ellipsis ellipses-loader"
          style="position: absolute; left: 120px; top: 15px; margin-left: 40px; margin-top: 5px"></i>
        }
        <div>
          @if (!historyLoading && selectedAccountHistory?.length === 0) {
            <p>No history found.</p>
          }
          @for (history of selectedAccountHistory; track history) {
            @if(!historyLoading && selectedAccountId) {
              <p>Account edited{{ history.lastModifiedBy ? ' by ' + history.lastModifiedBy : ''}}
                on {{ formatHistoryDate(history.lastModifiedOn) }}{{ getHistoryDifferencesString(history) || '' }}</p>
            }
          }
        </div>
        <button class="open-detail-button" (click)="openAccountDetail(selectedAccountId)"
          [disabled]="!selectedAccountId" mat-button mat-raised-button color="primary">
          Open Detail
          <i style="padding: 0" class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
  }
  } @else if (detailOpen && showContacts && !showAccountFromContact) {
  <div class="contact-details-container">
    <div class="back-button-container">
      <button class="left-positioned" mat-button mat-raised-button color="primary" (click)="goBack('contact-module')"><i
          class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE.BACK' | translate }}
      </button>
    </div>
    <div class="contact-details">
      <mat-card class="contact-details-table">
        <mat-card-header>{{ currentContact?.firstName }} {{ currentContact?.lastName }} - Details</mat-card-header>
        <div class="info-contact-detail">
          <b>Prefix:</b> {{ currentContact?.prefix || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>First Name:</b> {{ currentContact?.firstName || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Last Name:</b> {{ currentContact?.lastName || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Country:</b> {{ currentContact?.countryShortName || '-' }}
        </div>
        <mat-divider style="padding-bottom: 10px"></mat-divider>
        <div class="info-contact-detail">
          <b>Organisation Name:</b> {{ currentContact?.organisationName || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Title:</b> {{ currentContact?.title || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Website:</b> {{ currentContact?.website || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Phone Number:</b> {{ currentContact?.phoneNumber || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Emails:</b> {{ currentContact?.email || '-' }}
        </div>
        <mat-divider style="padding-bottom: 10px"></mat-divider>
        <div class="info-contact-detail">
          <b>Notes:</b> {{ currentContact?.internalNote || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Survey Series:</b> {{ currentContact?.surveySeriesNames || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Main Contact:</b> {{ currentContact?.linkedToAccount ? 'YES' : 'NO' }}
        </div>
        <div class="info-contact-detail">
          <b>Last Modified on:</b> {{ currentContact?.lastModifiedOn | date: 'MMMM d y - h:mm a' }}
        </div>
        <mat-divider style="padding-bottom: 10px"></mat-divider>
        @if (currentContact?.accounts?.length > 0) {
        <div class="table-container inner-table mat-elevation-z8" style="margin-bottom: 30px;">
          <table mat-table [dataSource]="detailContactAccountsDataSource">
            <ng-container matColumnDef="accountName">
              <th mat-header-cell *matHeaderCellDef style="width: 200px">Account Name</th>
              <td mat-cell *matCellDef="let element" style="width: 200px"
                (click)="openAccountDetail(element.accountId, true)">
                <span class="row-line">
                  {{ element?.accountName || '-' }}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="country">
              <th mat-header-cell *matHeaderCellDef>Country</th>
              <td mat-cell *matCellDef="let element" (click)="openAccountDetail(element.accountId, true)">
                <span class="row-line">
                  {{ element?.countryShortName || '-' }}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="source">
              <th mat-header-cell *matHeaderCellDef>Source</th>
              <td mat-cell *matCellDef="let element" (click)="openAccountDetail(element.accountId, true)">
                <span class="row-line">
                  {{ element?.source || '-' }}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="language">
              <th mat-header-cell *matHeaderCellDef>Language</th>
              <td mat-cell *matCellDef="let element" (click)="openAccountDetail(element.accountId, true)">
                <span class="row-line">
                  {{ element?.language || '-' }}
                </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsDetailAccount; sticky: true" class="z-index-fix"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDetailAccount;" class="table-row"></tr>
          </table>
        </div>
        }
        @if (currentContact?.surveySeries?.length > 0) {
        <div class="table-container inner-table survey-series-table mat-elevation-z8">
          <table mat-table [dataSource]="detailContactSurveySeriesDataSource">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef style="width: 300px">Survey Name</th>
              <td mat-cell *matCellDef="let element" style="width: 300px;">
                <span class="row-line">
                  {{ element?.surveySeriesName || '-' }}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Survey Description</th>
              <td mat-cell *matCellDef="let element">
                <span class="row-line">
                  {{ element?.surveySeriesDescription || '-' }}
                </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsDetailSurveySeries; sticky: true" class="z-index-fix">
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDetailSurveySeries;" class="table-row"></tr>
          </table>
        </div>
        }
      </mat-card>
    </div>
    <div class="action-buttons dual-button" style="width: 100%">
      <button mat-button mat-raised-button style="width: 200px; background-color: #ff3434; color: white"
        (click)="openDeleteContactConfirmDialog(deleteContactDialog)">
        <i class="fa-solid fa-trash"></i>
        Delete
      </button>
      <button mat-raised-button color="primary" style="width: 200px" onclick="this.blur()"
        (click)="openUpdateContactDialog(updateContactDialog)">
        <i class="fa fa-pencil" style="padding-left: 0"></i>
        Edit
      </button>
    </div>
  </div>
  } @else if ((detailOpen && !showContacts) || (detailOpen && showAccountFromContact)) {
  <div class="contact-details-container">
    <div class="back-button-container">
      @if (showAccountFromContact) {
      <button class="left-positioned" mat-button mat-raised-button color="primary"
        (click)="goBackToContactFromAccount()"><i class="fa-solid fa-arrow-left"></i> Back to {{
        currentContact.firstName }} {{ currentContact.lastName }}
      </button>

      } @else {
      <button class="left-positioned" mat-button mat-raised-button color="primary" (click)="goBack('account-module')"><i
          class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE.BACK' | translate }}
      </button>
      }
    </div>
    <div class="contact-details">
      <mat-card class="contact-details-table">
        <mat-card-header>{{ currentAccount?.accountName }} - Details</mat-card-header>
        <div class="info-contact-detail">
          <b>Account Name:</b> {{ currentAccount?.accountName || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Account Id:</b>
          <button class="transparent password-button" matSuffix type="button" (click)="copyAccountIdToClipboard()">
            <i class="fa-regular fa-copy"></i>
          </button>
          {{ currentAccount?.accountId || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Country:</b> {{ currentAccount?.countryShortName || '-' }}
        </div>
        <mat-divider style="padding-bottom: 10px"></mat-divider>
        <div class="info-contact-detail">
          <b>Language:</b> {{ currentAccount?.language || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Source:</b> {{ currentAccount?.source || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Password:</b>
          <button class="transparent password-button" matSuffix type="button" (click)="showPassword = !showPassword">
            <i class="fa-regular {{ showPassword ? 'fa-eye-slash' : 'fa-eye' }}"></i>
          </button>
          <input class="password-section" [type]="showPassword ? 'text' : 'password'"
            value="{{ currentAccount?.password || '-' }}" readonly>
        </div>
        <mat-divider style="padding-bottom: 10px"></mat-divider>
        <div class="info-contact-detail">
          <b>Survey Series:</b> {{ displaySurvey(currentAccount?.surveySeriesId) }}
        </div>
        <div class="info-contact-detail">
          <b>Notes:</b> {{ currentAccount?.internalNote || '-' }}
        </div>
        <div class="info-contact-detail">
          <b>Last Modified on:</b> {{ currentAccount?.lastModifiedOn | date: 'MMMM d y - h:mm a' }}
        </div>
      </mat-card>
    </div>
    <div class="action-buttons dual-button" style="width: 100%">
      <button mat-button mat-raised-button style="width: 200px; background-color: #ff3434; color: white"
        (click)="openDeleteAccountConfirmDialog(deleteAccountDialog)">
        <i class="fa-solid fa-trash"></i>
        Delete
      </button>
      <button mat-raised-button color="primary" style="width: 200px" onclick="this.blur()"
        (click)="openUpdateAccountDialog(updateAccountDialog)">
        <i class="fa fa-pencil" style="padding-left: 0"></i>
        Edit
      </button>
    </div>
  </div>
  }
</div>

<ng-template #syncWithQualtricsDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">Sync with Qualtrics</h2>
    @if (syncingQualtrics) {
    <mat-dialog-content>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <p style="margin-top: 10px">Syncing with Qualtrics</p>
    </mat-dialog-content>
    } @else {
    <div class="two-columns-dialog-container">
      <mat-dialog-content [formGroup]="syncWithQualtricsFormGroup" style="overflow-y: hidden; padding-bottom: 0">
        <mat-form-field appearance="fill">
          <mat-label>Survey id</mat-label>
          <input matInput type="number" formControlName="surveyId">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Survey series</mat-label>
          <mat-select formControlName="surveySeries">
            <mat-option value="HH">Household</mat-option>
            <mat-option value="WTI">WTI</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
    </div>
    }
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary" (click)="syncWithQualtrics()"
        [disabled]="!syncWithQualtricsFormGroup.valid || syncingQualtrics">
        <span>Sync</span>
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #deleteContactDialog>
  <div class="container-dialog-select-columns">
    <mat-dialog-content>
      Are you sure you want to delete this contact?
    </mat-dialog-content>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
    <button class="confirm-deletion-button" mat-raised-button (click)="deleteContact()">
      @if (deletingContact) {
      <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
      } @else {
      <i class="fa fa-trash"></i>
      Delete
      }
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #deleteAccountDialog>
  <div class="container-dialog-select-columns">
    <mat-dialog-content>
      Are you sure you want to delete this account?
    </mat-dialog-content>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
    <button class="confirm-deletion-button" mat-raised-button (click)="deleteAccount()">
      @if (deletingAccount) {
      <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
      } @else {
      <i class="fa fa-trash"></i>
      Delete
      }
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #updateContactDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">Update Contact details</h2>
    <div class="two-columns-dialog-container">
      <mat-dialog-content [formGroup]="updateContactFormGroup" style="overflow-y: hidden; padding-bottom: 0">
        <mat-form-field appearance="fill">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="name">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Organisation</mat-label>
          <input matInput type="text" formControlName="organisationName">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput type="text" formControlName="email">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Linked Account</mat-label>
          <i class="fa fa-x clear-filter clear-link" (click)="$event.preventDefault();
                      $event.stopPropagation();
                      updateContactFormGroup.controls.linkedAccountIds.setValue('')" [matTooltip]="'Remove Link'"></i>
          <mat-select formControlName="linkedAccountIds" (openedChange)="linkedAccountUpdateElement.focus()">
            <input style="width: 90%" matInput type="text" formControlName="autocompleteLinkedAccount"
              class="autocomplete-input" (keydown)="$event.stopPropagation()" #linkedAccountUpdateElement>
            @if (!updateContactFormGroup.controls.autocompleteLinkedAccount.value) {
            <span class="autocomplete-placeholder">Search...</span>
            }
            @if (loadingAutocomplete && updateContactFormGroup.controls.autocompleteLinkedAccount.value !== '') {
            <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
            }
            <mat-option class="hidden" [value]="updateContactFormGroup.controls.linkedAccountIds.value">
              {{ currentContact?.accounts[0]?.accountName || '' }}
            </mat-option>
            @for (account of accountsAutocompleteUpdateList; track account) {
            <mat-option [value]="account.accountId">
              {{ account.accountName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Country</mat-label>
          <mat-select formControlName="country">
            @for (country of countries; track country) {
            <mat-option [value]="country.countryID">
              {{ country.shortName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-content [formGroup]="updateContactFormGroup" style="overflow-y: hidden; padding-bottom: 0">
        <mat-form-field appearance="fill">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="surname">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Website</mat-label>
          <input matInput type="text" formControlName="website">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" formControlName="phoneNumber">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Prefix</mat-label>
          <input matInput formControlName="prefix">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Survery Series</mat-label>
          <mat-select formControlName="surveySeries" multiple>
            @for (surveySeries of surveySeries; track surveySeries) {
            <mat-option [value]="surveySeries.surveySeriesId">
              {{ surveySeries.surveySeriesName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Notes</mat-label>
          <textarea matInput type="text" formControlName="notes"></textarea>
        </mat-form-field>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary" (click)="updateContact()"
        [disabled]="!updateContactFormGroup.valid">
        @if (updatingContact) {
        <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
        } @else {
        <span>Update</span>
        }
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #updateAccountDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">Update Contact details</h2>
    <div class="two-columns-dialog-container">
      <mat-dialog-content [formGroup]="updateAccountFormGroup" style="overflow-y: hidden; padding-bottom: 0">
        <mat-form-field appearance="fill">
          <mat-label>Account name</mat-label>
          <input matInput type="text" formControlName="accountName">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Country</mat-label>
          <mat-select formControlName="country">
            @for (country of countries; track country) {
            <mat-option [value]="country.countryID">
              {{ country.shortName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Source</mat-label>
          <input matInput type="text" formControlName="source">
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-content [formGroup]="updateAccountFormGroup" style="overflow-y: hidden; padding-bottom: 0">
        <mat-form-field appearance="fill">
          <mat-label>Password</mat-label>
          <button class="transparent password-button" matSuffix type="button" (click)="showPassword = !showPassword">
            <i class="fa-regular {{ showPassword ? 'fa-eye-slash' : 'fa-eye' }}"></i>
          </button>
          <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Language</mat-label>
          <input matInput type="text" formControlName="language">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Survery Series</mat-label>
          <mat-select formControlName="surveySeries">
            @for (surveySeries of surveySeries; track surveySeries) {
            <mat-option [value]="surveySeries.surveySeriesId">
              {{ surveySeries.surveySeriesName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
    </div>
    <mat-dialog-content [formGroup]="updateAccountFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Notes</mat-label>
        <textarea matInput type="text" formControlName="internalNote"></textarea>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary" (click)="updateAccount()"
        [disabled]="!updateAccountFormGroup.valid">
        @if (updatingAccount) {
        <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
        } @else {
        <span>Update</span>
        }
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #createContactDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">Insert Contact details</h2>
    <div class="two-columns-dialog-container">
      <mat-dialog-content [formGroup]="createContactFormGroup" style="overflow-y: hidden; padding-bottom: 0">
        <mat-form-field appearance="fill">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="name">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Organisation</mat-label>
          <input matInput type="text" formControlName="organisationName">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput type="text" formControlName="email">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Linked Account</mat-label>
          <mat-select formControlName="linkedAccountIds" (openedChange)="linkedAccountElement.focus()">
            <input style="width: 90%" matInput type="text" formControlName="autocompleteLinkedAccount"
              class="autocomplete-input" (keydown)="$event.stopPropagation()" #linkedAccountElement>
            @if (!createContactFormGroup.controls.autocompleteLinkedAccount.value) {
            <span class="autocomplete-placeholder">Search...</span>
            }
            @if (loadingAutocomplete && createContactFormGroup.controls.autocompleteLinkedAccount.value !== '') {
            <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
            }
            <mat-option class="hidden" [value]="'placeholder'"></mat-option>
            @for (account of accountsAutocompleteCreateList; track account) {
            <mat-option [value]="account.accountId">
              {{ account.accountName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Country</mat-label>
          <mat-select formControlName="country">
            @for (country of countries; track country) {
            <mat-option [value]="country.countryID">
              {{ country.shortName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-content [formGroup]="createContactFormGroup" style="overflow-y: hidden; padding-bottom: 0">
        <mat-form-field appearance="fill">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="surname">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Website</mat-label>
          <input matInput type="text" formControlName="website">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" formControlName="phoneNumber">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Prefix</mat-label>
          <input matInput formControlName="prefix">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Survery Series</mat-label>
          <mat-select formControlName="surveySeries" multiple>
            @for (surveySeries of surveySeries; track surveySeries) {
            <mat-option [value]="surveySeries.surveySeriesId">
              {{ surveySeries.surveySeriesName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Notes</mat-label>
          <textarea matInput type="text" formControlName="notes"></textarea>
        </mat-form-field>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary" (click)="createContact()"
        [disabled]="!createContactFormGroup.valid">
        @if (creatingContact) {
        <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
        } @else {
        <span>Create</span>
        }
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #createAccountDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">Insert Account details</h2>
    <div class="two-columns-dialog-container">
      <mat-dialog-content [formGroup]="createAccountFormGroup" style="overflow-y: hidden; padding-bottom: 0">
        <mat-form-field appearance="fill">
          <mat-label>Account name</mat-label>
          <input matInput type="text" formControlName="accountName">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Country</mat-label>
          <mat-select formControlName="country">
            @for (country of countries; track country) {
            <mat-option [value]="country.countryID">
              {{ country.shortName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Source</mat-label>
          <input matInput type="text" formControlName="source">
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-content [formGroup]="createAccountFormGroup" style="overflow-y: hidden; padding-bottom: 0">
        <mat-form-field appearance="fill">
          <mat-label>Password</mat-label>
          <button class="transparent password-button" matSuffix type="button" (click)="showPassword = !showPassword">
            <i class="fa-regular {{ showPassword ? 'fa-eye-slash' : 'fa-eye' }}"></i>
          </button>
          <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Language</mat-label>
          <input matInput type="text" formControlName="language">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Survery Series</mat-label>
          <mat-select formControlName="surveySeries">
            @for (surveySeries of surveySeries; track surveySeries) {
            <mat-option [value]="surveySeries.surveySeriesId">
              {{ surveySeries.surveySeriesName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
    </div>
    <mat-dialog-content [formGroup]="createAccountFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Internal Notes</mat-label>
        <textarea matInput type="text" formControlName="internalNote"></textarea>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary" (click)="createAccount()"
        [disabled]="!createAccountFormGroup.valid">
        @if (creatingAccount) {
        <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
        } @else {
        <span>Create</span>
        }
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
