import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface IAutoCompleteScrollEvent {
  matSelect: MatSelect;
  scrollEvent: Event;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-select[optionsScroll]',
  standalone: true,
})
export class OptionsScrollDirective implements OnDestroy {
  @Input() thresholdPercent = 0.8;
  @Output() optionsScroll = new EventEmitter<IAutoCompleteScrollEvent>();
  _onDestroy = new Subject();
  private timeout: any;
  private isWaiting = false;

  constructor(public matSelect: MatSelect) {
    this.matSelect.openedChange
      .pipe(
        tap(() => {
          setTimeout(() => {
            this.removeScrollEventListener();
            if (this.matSelect.panel && this.matSelect.panel.nativeElement) {
              this.matSelect.panel.nativeElement.addEventListener(
                'scroll',
                this.onScroll.bind(this)
              );
            }
          });
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe();

    this.matSelect._closedStream
      .pipe(
        tap(() => this.removeScrollEventListener()),
        takeUntil(this._onDestroy)
      )
      .subscribe();
  }

  onScroll(event: any): void {
    this.isWaiting = true;
    if (!this.timeout) {
      const threshold =
        (this.thresholdPercent * 100 * event.target.scrollHeight) / 100;
      const current = event.target.scrollTop + event.target.clientHeight;
      if (current > threshold) {
        this.optionsScroll.emit();
        this.timeout = setTimeout(() => {
          this.isWaiting = false;
          clearTimeout(this.timeout);
          this.timeout = null;
        }, 1000);
      }
    } else if (!this.isWaiting) {
      const threshold =
        (this.thresholdPercent * 100 * event.target.scrollHeight) / 100;
      const current = event.target.scrollTop + event.target.clientHeight;
      if (current > threshold) {
        this.optionsScroll.emit();
      }
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
    this.removeScrollEventListener();
  }

  private removeScrollEventListener() {
    if (
      this.matSelect &&
      this.matSelect.panel &&
      this.matSelect.panel.nativeElement
    ) {
      this.matSelect.panel.nativeElement.removeEventListener(
        'onScroll',
        this.onScroll
      );
    }
  }
}
