import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CoreModule } from '../core/core.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { LoaderComponent } from './loader/loader.component';
import { AdminViewComponent } from './admin-section/admin-view/admin-view.component';
import { UpdateCountryTableComponent } from './update-country-table/update-country-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { ModifyOneRowComponent } from './dialogues/modify-one-row/modify-one-row.component';
import { DeleteRowErrorDialogComponent } from './dialogues/delete-row-error-dialog/delete-row-error-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { ResizeColumnDirective } from './update-country-table/resize-column.directive';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ConditionalFormattingComponent } from './dialogues/conditional-formatting/conditional-formatting.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { DownloadModuleComponent } from './download-module/download-module.component';
import { UploadModuleComponent } from './upload-module/upload-module.component';
import { DragDropDirective } from './upload-module/utils/drag-drop.directive';
import { FileSizePipe } from './upload-module/utils/file-size.pipe';
import { MatListModule } from '@angular/material/list';
import { ColorPickerModule } from 'ngx-color-picker';
import { UploadModuleTableComponent } from './upload-module/upload-module-table/upload-module-table.component';
import { RegionsManagementComponent } from './regions-management/regions-management.component';
import { RegionDetailComponent } from './regions-management/region-detail/region-detail.component';
import { HouseholdViewComponent } from './household-view/household-view.component';
import { AutocompleteSelectComponent } from './custom/autocomplete-select/autocomplete-select.component';
import { CodesManagementComponent } from './codes-management/codes-management.component';
import {
  MatChipGrid,
  MatChipInput,
  MatChipRemove,
  MatChipRow,
} from '@angular/material/chips';
import { CodeDetailComponent } from './codes-management/code-detail/code-detail.component';
import { CountryViewDownloadComponent } from './country-view-download/country-view-download.component';
import { DownloadListComponent } from './download-list/download-list.component';
import { CountryValidationComponent } from './country-validation/country-validation.component';
import { SurveyTableComponent } from './survey-table/survey-table.component';
import { SurveyCountryTableComponent } from './survey-country-table/survey-country-table.component';
import { CountryValidationTableComponent } from './country-validation-table/country-validation-table.component';
import { RegionCountryFilterComponent } from './filters/region-country-filter/region-country-filter.component';
import { ValidationModuleComponent } from './validation-module/validation-module.component';
import { ValidationModuleCountryTableComponent } from './validation-module-country-table/validation-module-country-table.component';
import { MatSliderModule } from '@angular/material/slider';
import { UploadRetrieveFileComponent } from './custom/upload-retrieve-file/upload-retrieve-file.component';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { ScrollNearEndDirective } from '../core/form-utils/directives/options-scroll.directive';
import { FormatNumberInputDirective } from '../core/form-utils/directives/format-number-input.directive';
import { ValidationModuleCodeTableComponent } from './validation-module-code-table/validation-module-code-table.component';
import { ValidationModuleListCodesComponent } from './validation-module-list-codes/validation-module-list-codes.component';
import { CodeFilterComponent } from './filters/code-filter/code-filter.component';
import { OptionsScrollDirective } from '../core/form-utils/directives/mat-select-infinite-scroll.directive';
import { BaseChartDirective } from 'ng2-charts';
import { UnitFilterComponent } from './filters/unit-filter/unit-filter.component';
import { CollectionFilterComponent } from './filters/collection-filter/collection-filter.component';
import { DisaggregationFilterComponent } from './filters/disaggregation-filter/disaggregation-filter.component';
import { ContactsModuleComponent } from './contacts-module/contacts-module.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    HomeComponent,
    SignInComponent,
    SignUpComponent,
    NavbarComponent,
    LoaderComponent,
    AdminViewComponent,
    UpdateCountryTableComponent,
    ModifyOneRowComponent,
    DeleteRowErrorDialogComponent,
    ResizeColumnDirective,
    ConditionalFormattingComponent,
    DownloadModuleComponent,
    UploadModuleComponent,
    DragDropDirective,
    FileSizePipe,
    UploadModuleTableComponent,
    RegionsManagementComponent,
    RegionDetailComponent,
    HouseholdViewComponent,
    AutocompleteSelectComponent,
    CodesManagementComponent,
    CodeDetailComponent,
    CountryViewDownloadComponent,
    DownloadListComponent,
    CountryValidationComponent,
    SurveyTableComponent,
    SurveyCountryTableComponent,
    CountryValidationTableComponent,
    ValidationModuleComponent,
    UploadRetrieveFileComponent,
    ValidationModuleCountryTableComponent,
    ValidationModuleCodeTableComponent,
    ValidationModuleListCodesComponent,
    RegionCountryFilterComponent,
    DisaggregationFilterComponent,
    UnitFilterComponent,
    CollectionFilterComponent,
    CodeFilterComponent,
    ContactsModuleComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    CoreModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    MatCardModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    TranslateModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSortModule,
    MatAutocompleteModule,
    MatProgressBar,
    MatListModule,
    ColorPickerModule,
    MatChipGrid,
    MatChipRow,
    MatChipInput,
    MatChipRemove,
    MatSliderModule,
    MatTreeModule,
    MatButtonToggle,
    ScrollNearEndDirective,
    FormatNumberInputDirective,
    OptionsScrollDirective,
    BaseChartDirective,
  ],
  exports: [NavbarComponent],
})
export class ComponentsModule {}
