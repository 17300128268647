import { IMapper } from '../../../../models/i-mapper';
import { DisaggregationModel } from '../models/disaggregation-model';

export class ListDisaggregationsMapper implements IMapper {
  disaggregationsList: DisaggregationModel[] = [];
  fillFromJson(json: any): void {
    json.forEach((disaggregation: any) => {
      this.disaggregationsList.push({
        disaggregationId: disaggregation.disaggregationId,
        disaggregationDescription: disaggregation.disaggregationDescription,
      });
    });
  }
  fillToJson(): string {
    return '';
  }
}
