import { IMapper } from '../../../models/i-mapper';

export class UpdateCheckedMapper implements IMapper {
  body?: {
    answerIds: string[];
    checked: boolean;
  };
  fillFromJson(_json: any): void {
    return;
  }
  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}
