import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/net-utils/network.service';
import { environment } from 'src/environments/environment';
import { CompareContactModuleValidationModel } from '../models/contact-validation-model';
import { ContactModuleValidationMapper } from '../mappers/contact-validation-mapper';
import { UpdateContactMapper } from '../mappers/update-contact-mapper';

@Injectable({
  providedIn: 'root',
})
export class ContactValidationService {
  private networkService!: NetworkService;
  private baseUrl = `${environment.backend.endpoint}/validation-module`;
  private emptyMapper = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fillFromJson(_json: any): void {},
    fillToJson(): string {
      return '{}';
    },
    toString(): string {
      return '';
    },
  };

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getContact(
    surveyId: string,
    countryId: string
  ): Promise<CompareContactModuleValidationModel[]> {
    const mapper = new ContactModuleValidationMapper();
    await this.networkService.get(
      `${this.baseUrl}/survey/${surveyId}/country/${countryId}/contact`,
      mapper
    );
    return mapper.contacts;
  }

  async completeValidation(surveyId: string, countryId: string): Promise<void> {
    await this.networkService.put(
      `${this.baseUrl}/survey/${surveyId}/country/${countryId}/contact/complete`,
      this.emptyMapper,
      this.emptyMapper
    );
  }

  async updateValidationContact(
    surveyId: string,
    countryId: string,
    data: {
      stagingContactId: string;
      oldCountryId: string;
      prefix: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      organisationName: string;
      title: string;
      website: string;
    }
  ): Promise<void> {
    const mapper = new UpdateContactMapper();
    mapper.body = data;
    await this.networkService.put(
      `${this.baseUrl}/survey/${surveyId}/country/${countryId}/contact`,
      mapper,
      mapper
    );
  }
}
