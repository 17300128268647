<div class="container mat-typography">
  <div id="top" class="row">
    <div class="content">
      <app-navbar></app-navbar>
    </div>
  </div>
  <div id="bottom" class="row">
    <div id="right" class="column">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>



