import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RegionsListsModel } from '../../regions-management/models/region-filters-model';
import { CountriesListsModel } from '../../update-country-table/models/country-filters-model';
import { AntiMemLeak } from '../../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { ProviderService } from '../../../core/provider.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { debounceTime, Observable } from 'rxjs';

@Component({
  selector: 'app-region-country-filter',
  templateUrl: './region-country-filter.component.html',
  styleUrl: './region-country-filter.component.scss',
})
// eslint-disable-next-line prettier/prettier
export class RegionCountryFilterComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  @Output()
  countriesChanged: EventEmitter<CountriesListsModel[]> = new EventEmitter<
    CountriesListsModel[]
  >();
  @Input()
  styleType: 'standard' | 'filters-bar' = 'standard';
  @Input()
  resetFilterObservable?: Observable<any>;
  @ViewChild('inputElement')
  inputElement!: ElementRef;
  @ViewChild('regionInputElement')
  regionInputElement!: ElementRef;
  regions: RegionsListsModel[] = [];
  countries: CountriesListsModel[] = [];
  filteredCountries: CountriesListsModel[] = [];
  filteredRegions: RegionsListsModel[] = [];
  countriesFromSelectedRegions: CountriesListsModel[] = [];

  filterFormGroup = new FormGroup({
    countryNameFilter: new FormControl([]),
    regionsFilter: new FormControl([]),
    autocompleteRegion: new FormControl(''),
    autocompleteCountry: new FormControl(''),
  });

  get regionsFormControl(): FormControl {
    return this.filterFormGroup.controls.regionsFilter;
  }

  get countriesFormControl(): FormControl {
    return this.filterFormGroup.controls.countryNameFilter;
  }

  constructor(private providerService: ProviderService) {
    super();
  }

  ngOnInit(): void {
    this.getRegionList().then(() => {
      this.getCountriesList();
    });
    this.subscriptions.add(
      this.resetFilterObservable?.subscribe(() => {
        this.removeRegionFilter();
        this.removeCountryFilter();
      })
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.countriesFormControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(200))
        .subscribe((country) => {
          this._filterCountry(country);
          this.countriesChanged.emit(country);
        })
    );
    this.subscriptions.add(
      this.regionsFormControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(200))
        .subscribe((regionCode) => {
          this.regionFilterSelectionChange();
          this._filterRegion(regionCode);
        })
    );
  }

  async getRegionList(): Promise<void> {
    try {
      this.regions = this.regions.concat(
        await this.providerService.regionsManagementService.getRegionsList(true)
      );
      this.filteredRegions = this.regions;
    } catch (error) {
      console.error(error);
    }
  }

  async regionFilterSelectionChange(): Promise<void> {
    const regions = this.regionsFormControl.value;
    if (regions.length > 0) {
      const countries =
        await this.providerService.regionsManagementService.getCountriesByRegions(
          regions
        );
      const uniqueCountries = countries.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.countryID === value.countryID)
      );
      this.countriesFromSelectedRegions = uniqueCountries;
      this.countriesFormControl.setValue(uniqueCountries);
    } else {
      this.countriesFromSelectedRegions = [];
      this.countriesFormControl.setValue([]);
    }
  }

  async getCountriesList(): Promise<void> {
    try {
      this.countries = this.countries.concat(
        await this.providerService.regionsManagementService.getCountriesByRegions(
          this.regions.map((value) => value.regionID)
        )
      );
      this.filteredCountries = this.countries;
      this.countriesFromSelectedRegions = this.countries;
    } catch (error) {
      console.error(error);
    }
  }

  onOpenedChange(isOpened: boolean): void {
    if (isOpened) {
      this.inputElement.nativeElement.focus();
    }
  }

  displayFnRegions(value: any): string {
    return value
      ? this.regions.find((region) => region.regionID === value)?.regionName ??
          ''
      : '';
  }

  displayMultipleFnRegions(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0
        ? (result = `${this.displayFnRegions(v)}`)
        : (result = `${result}, ${this.displayFnRegions(v)}`);
    }
    return result;
  }

  displayFnCountries(value: any): string {
    return value
      ? this.filteredCountries.find(
          (country) => country.countryID === value.countryID
        )?.shortName ?? ''
      : 'No filter';
  }

  displayMultipleFnCountries(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0
        ? (result = `${this.displayFnCountries(v)}`)
        : (result = `${result}, ${this.displayFnCountries(v)}`);
    }
    return result;
  }

  removeRegionFilter(event?: any): void {
    event?.preventDefault();
    event?.stopPropagation();
    this.regionsFormControl.setValue([]);
    this.filterFormGroup.controls.autocompleteRegion.setValue('');
    this.inputElement.nativeElement.focus();
  }

  visuallyFilterRegions(region: any): boolean {
    const regionName = region.regionName.toLowerCase();
    const regionCode = region.regionCode.toLowerCase();
    const input = (
      this.filterFormGroup.controls.autocompleteRegion.value || ''
    ).toLowerCase();
    return (
      input === '' || regionName.includes(input) || regionCode.includes(input)
    );
  }

  visuallyFilterCountries(country: CountriesListsModel): boolean {
    const countryName = country.shortName.toLowerCase();
    const countryisoCode = (country.isoCode || '').toLowerCase();
    const input = (
      this.filterFormGroup.controls.autocompleteCountry.value || ''
    ).toLowerCase();
    return (
      input === '' ||
      countryName.includes(input) ||
      countryisoCode.includes(input)
    );
  }

  removeCountryFilter(event?: any): void {
    event?.preventDefault();
    event?.stopPropagation();
    this.countriesFormControl.setValue([]);
    this.filterFormGroup.controls.autocompleteCountry.setValue('');
    this.inputElement.nativeElement.focus();
  }

  deselectAllOptions(type: string): void {
    if (type === 'countries') {
      this.countriesFormControl.setValue([]);
      this.inputElement.nativeElement.focus();
    } else if (type === 'regions') {
      this.regionsFormControl.setValue([]);
      this.regionInputElement.nativeElement.focus();
    }
  }

  selectAllOptions(type: string): void {
    if (type === 'countries') {
      this.countriesFormControl.setValue(this.countriesFromSelectedRegions);
      this.inputElement.nativeElement.focus();
    } else if (type === 'regions') {
      this.regionsFormControl.setValue(
        this.regions.map((region) => region.regionID)
      );
      this.regionInputElement.nativeElement.focus();
    }
  }

  private _filterCountry(value: any): void {
    if (typeof value === 'string' && value.length > 0) {
      const toSearch = value.toLowerCase();
      this.filteredCountries = this.countries.filter(
        (country) =>
          country.isoCode.toLowerCase().includes(toSearch) ||
          country.shortName.toLowerCase().includes(toSearch)
      );
    } else {
      this.filteredCountries = this.countries;
    }
  }

  private _filterRegion(value: any): void {
    if (typeof value === 'string' && value.length > 0) {
      const toSearch = value.toLowerCase();
      this.filteredRegions = this.regions.filter(
        (country) =>
          country.regionCode.toLowerCase().includes(toSearch) ||
          country.regionName.toLowerCase().includes(toSearch)
      );
    } else {
      this.filteredRegions = this.regions;
    }
  }
}
