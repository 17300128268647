import { IMapper } from '../../../models/i-mapper';
import {
  CountryValidationModel,
  CountryValidationResponseModel,
} from '../models/country-validation-model';
import { CountryValidationYearModel } from '../models/country-validation-year-model';

export class CountryValidationMapper implements IMapper {
  countryList?: CountryValidationResponseModel;
  fillFromJson(json: any): void {
    if (!json) {
      return;
    }

    const groupedData = new Map<string, CountryValidationModel>();

    json.data.forEach((element: any) => {
      const yearData: CountryValidationYearModel = {
        dataID: element.dataId,
        year: element.dataYear,
        newValue: element.newValue,
        originalValue: element.originalValue,
        newNotes: element.newNotes,
        originalNotes: element.originalNotes,
        newSource: element.newAnswer,
        originalSource: element.originalAnswer,
      };

      if (!groupedData.has(element.codeId)) {
        groupedData.set(element.codeId, {
          codeID: element.codeId,
          codeName: element.code,
          codeDesc: element.codeDesc,
          years: [yearData],
        });
      } else {
        groupedData.get(element.codeId)?.years.push(yearData);
      }
    });

    this.countryList = {
      data: Array.from(groupedData.values()),
      count: json.total,
    };
  }

  fillToJson(): string {
    return '';
  }
}
