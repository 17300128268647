import { IMapper } from '../../../models/i-mapper';
export class TableDataCountMapper implements IMapper {
  tableDataCount = 0;
  fillFromJson(json: any): void {
    this.tableDataCount = json;
  }
  fillToJson(): string {
    return '';
  }
}
