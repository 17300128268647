import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProviderService } from '../provider.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private router: Router,
    private providerService: ProviderService
  ) {}

  async canActivate(): Promise<boolean> {
    if (!(await this.providerService.authService.isAuthenticated())) {
      this.router.navigate(['/', 'auth', 'sign-in']);
      return false;
    }
    return true;
  }
}
