export class Constants {
  static snackbarDuration = 3000;
  static storageKeyPrefix = 'ITU-';
  static apiEndpoint =
    'https://zlkwp1dvhe.execute-api.eu-west-1.amazonaws.com/prod';
  static downloadRawColumns = [
    { name: 'COUNTRY_COLUMN_NAMES.ALL_COLUMNS', value: 'All columns' },
    { name: 'COUNTRY_COLUMN_NAMES.DATA_YEAR', value: 'dataYear' },
    { name: 'COUNTRY_COLUMN_NAMES.OLD_DATA_ID', value: 'oldDataID' },
    { name: 'COUNTRY_COLUMN_NAMES.DATA_ID', value: 'dataID' },
    { name: 'COUNTRY_COLUMN_NAMES.CODE_ID', value: 'codeID' },
    { name: 'COUNTRY_COLUMN_NAMES.OLD_CODE_ID', value: 'oldCodeID' },
    { name: 'COUNTRY_COLUMN_NAMES.CODE', value: 'code' },
    { name: 'COUNTRY_COLUMN_NAMES.CODE_DESC', value: 'codeDesc' },
    { name: 'COUNTRY_COLUMN_NAMES.ISO_CODE', value: 'isoCode' },
    { name: 'COUNTRY_COLUMN_NAMES.COUNTRY_ID', value: 'countryID' },
    { name: 'COUNTRY_COLUMN_NAMES.OLD_COUNTRY_ID', value: 'oldCountryID' },
    { name: 'COUNTRY_COLUMN_NAMES.COUNTRY', value: 'country' },
    { name: 'COUNTRY_COLUMN_NAMES.VALUE_NUMBER', value: 'value' },
    { name: 'COUNTRY_COLUMN_NAMES.PUBLISH', value: 'publish' },
    { name: 'COUNTRY_COLUMN_NAMES.ESTIMATE', value: 'estimate' },
    { name: 'COUNTRY_COLUMN_NAMES.NOTE_ID', value: 'noteID' },
    { name: 'COUNTRY_COLUMN_NAMES.NOTES', value: 'notes' },
    {
      name: 'COUNTRY_COLUMN_NAMES.INTERNAL_NOTE_ID',
      value: 'internalNoteID',
    },
    { name: 'COUNTRY_COLUMN_NAMES.NOTES_INTERNAL', value: 'notesInternal' },
    { name: 'COUNTRY_COLUMN_NAMES.SOURCE_ID', value: 'sourceID' },
    { name: 'COUNTRY_COLUMN_NAMES.SOURCE', value: 'source' },
    {
      name: 'COUNTRY_COLUMN_NAMES.LAST_MODIFIED_BY',
      value: 'lastModifiedBy',
    },
    {
      name: 'COUNTRY_COLUMN_NAMES.LAST_MODIFIED_ON',
      value: 'lastModifiedOn',
    },
  ];
}
