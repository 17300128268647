import { IMapper } from '../../../models/i-mapper';

export class ClassesListsMapper implements IMapper {
  classesList: any[] = [];
  fillFromJson(json: any): void {
    json.forEach((classList: any) => {
      this.classesList.push(classList);
    });
  }
  fillToJson(): string {
    return '';
  }
}

export class CreateClassification implements IMapper {
  className = '';
  classCode = '';
  classDescription = '';
  classId: any;

  fillFromJson(json: any): void {
    this.classId = json;
  }

  fillToJson(): string {
    return JSON.stringify({
      className: this.className,
      classCode: this.classCode,
      classDescription: this.classDescription,
    });
  }
}
