<div class="container">
  @if (loading && !yearValueChanged) {
    <app-loader></app-loader>
  }
  <div class="filters-class">
    <ng-container>
      <div class="filters-bar" [formGroup]="countryFiltersFormGroup">
        <div class="first-column">
          <span class="mat-drop-down">
            <mat-label>Country: </mat-label>
            <mat-form-field>
              <mat-select #countryFilter [formControl]="countriesFormControl"
                          (selectionChange)="countryFilter.close(); this.getTableData()"
                          (openedChange)="onOpenedChange($event)" multiple >
                <mat-select-trigger>
                  <span class="label" [matTooltip]="displayMultipleFn(countriesFormControl.value)">
                    {{displayFn(countriesFormControl.value?.[0]) || ''}}
                    @if ((countriesFormControl.value?.length || 0) > 1) {
                      <span class="example-additional-selection">
                        (+{{(countriesFormControl.value?.length || 0) - 1}} {{countriesFormControl.value?.length === 2 ? 'other' : 'others'}})
                      </span>
                    }
                  </span>
                  @if ((countriesFormControl.value?.length || 0) > 0) {
                    <i (click)="removeCountryFilter($event)" class="fa fa-x clear-filter"
                       [matTooltip]="'Clear filter'"></i>
                  }
                </mat-select-trigger>
                <input matInput type="text" formControlName="autocompleteForm"
                       class="autocomplete-input"
                       (keydown)="$event.stopPropagation()"
                       #inputElement>
                @if (countryFiltersFormGroup.controls.autocompleteForm.value === '') {
                  <span class="autocomplete-placeholder">Search...</span>
                }
                @for (country of countries; track country) {
                  <mat-option [ngClass]="visuallyFilterCountries(country) ? '' : 'hidden'" [value]="country.isoCode">{{ country.shortName }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </span>
          <span class="mat-drop-down">
            <mat-label>Code: </mat-label>
            <input matInput type="text" formControlName="code">
          </span>
          <span class="mat-drop-down">
            <mat-label>Description: </mat-label>
            <input matInput type="text" formControlName="description">
          </span>
          <span class="mat-drop-down last-item">
            <div class="conditional-formatting-container">
              @if (isConditionalFormattingFilterOn) {
                <i class="fa-solid fa fa-trash" style="cursor: pointer" (click)="clearConditionalFormattingFilter()" matTooltip="Clear filter"></i>
              }
              <button color="primary" mat-raised-button type="button" onclick="this.blur()"
                        class="conditional-formatting-button" (click)="openConditionalFormattingDialog()">
                Conditional formatting
              </button>
              <div class="divider"></div>
              <button color="primary" mat-raised-button (click)="downloadShowedTableData(downloadDialog, false)"
                                  onclick="this.blur()" [disabled]="downloadTableDataLoader" type="button" class="selected-download-button">
                <span class="download-raw-data-button">Download</span>
              </button>
              <button color="primary" mat-raised-button (click)="downloadShowedTableData(downloadDialog, true)"
                      onclick="this.blur()" [disabled]="downloadTableDataLoader" type="button" class="selected-download-button">
                <span class="download-raw-data-button">Download (Raw)</span>
              </button>
            </div>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="table-holder">
    <form class="table-scroller" matSort autocomplete="off" #formElement>
      <ng-container>
        @if (countriesDataBE.length !== 0) {
          <table
            mat-table
            [dataSource]="tableDataSource"
            class="mat-elevation-z8"
            id="country-table"
          >
            <caption></caption>
            @for (column of columns; track column; let i = $index) {
              @if (column.field !== 'actions') {
                @if (stickyColumns.includes(column.field)) {
                  @if (column.readOnly) {
                    <ng-container matColumnDef="{{column.field}}" sticky>
                      <th mat-sort-header [style.width]="column.width" [columnModel]="column" [disabled]="resizing" appResizeColumn
                          (resizing)="this.resizingChanged($event)" mat-header-cell
                          *matHeaderCellDef>{{ column.title }}
                      </th>
                      <td
                        mat-cell
                        class="read-only"
                        *matCellDef="let element; let i = index"
                        [formGroup]="element"
                        [style.width]="column.width"
                      >
                        <input
                          matInput
                          type="text"
                          class="form-control"
                          readonly
                          formControlName="{{column.field}}"
                          (keydown.enter)="$event.preventDefault()"
                        />
                      </td>
                    </ng-container>
                  }
                } @else {
                  <ng-container matColumnDef="{{column.field}}">
                    <th appResizeColumn [columnModel]="column" [style.width]="column.width" mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                    <td
                      mat-footer-cell
                      *matCellDef="let cell; let i = index"
                      [formGroup]="cell"
                      class="numeric-cells  {{selectedCell === cell && selectedColumn === column ? 'selectedCell': ''}}"
                      (click)="setSelectedCell(cell, column); getCellInfo(cell, column.field);"
                      [style.width]="column.width"
                      [ngStyle]="{'background-color': checkConditions(cell.controls[column.field].value) ? color : 'inherit'}"
                    >
                      <div class="editable-cell-input-group">
                        <input
                          matInput
                          type="text"
                          class="form-control"
                          [ngStyle]="{'background-color': checkConditions(cell.controls[column.field].value) ? color : 'inherit'}"
                          [formControlName]="column.field"
                          [ngClass]="''"
                          (keydown.enter)="$event.preventDefault();updateCell(cell, column.field);"
                          (focusout)="updateCell(cell, column.field)"
                        />
                      </div>
                      @if (cell.controls[column.field].invalid) {
                        <div class="error-message">Only number accepted!</div>
                      }
                    </td>
                  </ng-container>
                }
              }
              @if (column.field === 'actions') {
                <ng-container matColumnDef="{{column.field}}" sticky>
                  <th mat-header-cell [style.width]="column.width" *matHeaderCellDef>{{ column.title }}</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroup]="element"
                    [style.width]="column.width"
                    class="sticky-border"
                  >
                    <button
                      mat-icon-button
                      class="material-icons app-toolbar-menu save-button"
                      (click)="openModificationDialog(element)"
                    >
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
              }
            }
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        }
      </ng-container>
    </form>
  </div>
  @if (countriesDataBE.length === 0 && !loading && !showTableError) {
    <div class="no-data-found">No data found for your search</div>
  }
  @if (showTableError && !loading) {
    <div class="no-data-found">Something went wrong</div>
  }
  <div class="paginator">
    <mat-paginator [length]="tableDataCount" [pageSizeOptions]="[20, 30, 40]">
    </mat-paginator>
  </div>
  <div class="data-info-container">
    @if (!cellInfoPresent && selectedCell) {
      <div class="no-cell-warning">
        <span>No value for selected year, insert a value to modify details</span>
      </div>
    }
    <div class="data-info">
      @if (cellInfoLoading) {
        <mat-spinner></mat-spinner>
      }
      <div class="notes-group">
        <div class="notes-container">
          <div class="notes-row">
            <span class="first">Internal notes: </span>
            <input #publishedNote matInput [disabled]="cellInfoLoading || !cellInfoPresent"
                   (input)="internalNotesSelectionChanged($event)"
                   (focusout)="publishedNote.value.length > 0 ? updateInternalNotes() : updateInternalNotes('')"
                   type="text" [value]="cellInfo === undefined ? '' : cellInfo.internalNotes">
          </div>
          <mat-divider></mat-divider>
          <div class="notes-row">
            <span>Published notes: </span>
            <input #internalNote matInput [disabled]="cellInfoLoading || !cellInfoPresent"
                   (input)="notesSelectionChanged($event)"
                   (focusout)="internalNote.value.length > 0 ? updatePublishedNotes() : updatePublishedNotes('')"
                   type="text" [value]="cellInfo === undefined ? '' : cellInfo.publishNotes">
          </div>
        </div>
        <div class="notes-states" [formGroup]="checkboxFormGroup">
          <div class="flex-row">
            <span>Published</span>
            <div>
              <mat-checkbox [disabled]="cellInfoLoading || !cellInfoPresent"
                            [formControl]="checkboxFormGroup.controls.published"
                            (change)="publishedChanged($event)"></mat-checkbox>
            </div>
          </div>
          <div class="flex-row">
            <span>Estimated</span>
            <div>
              <mat-checkbox [disabled]="cellInfoLoading || !cellInfoPresent"
                            [formControl]="checkboxFormGroup.controls.estimated"
                            (change)="estimatedChanged($event)"></mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="data-history">
        <span>Data history</span>
        <div>
          @for (comment of cellHistory; track comment) {
            @if (comment.version === 0) {
              <p>First value for the cell is {{ comment.value }}, inserted on {{ comment.lastModifiedOn }} by
                "{{ comment.user }}"</p>
            } @else {
              @if (comment.value != null) {
                <p>user '{{ comment.user }}' changed value to '{{ comment.value }}' on '{{ comment.lastModifiedOn }}
                  '</p>
              } @else {
                <p>user '{{ comment.user }}' deleted the value on '{{ comment.lastModifiedOn }}'</p>
              }
            }
          }
        </div>
        <div class="import">
          <input #fileInput hidden="hidden" type="file" onclick="this.value=null" class="file-upload" accept=".txt">
          <button mat-raised-button (click)="fileInput.click()">Import</button>
        </div>
      </div>
      <div class="data-source">
        <span>Source</span>
        <input #sourceInput [disabled]="cellInfoLoading || !cellInfoPresent" (input)="sourceSelectionChanged($event)"
               (focusout)="updateSource();deleteSource( false,sourceInput.value)" type="text"
               [value]="cellInfo === undefined ? '' : cellInfo.source">
      </div>
    </div>
  </div>
</div>

<ng-template #downloadDialog>
  <h2 mat-dialog-title class="mat-dialog-title">{{ 'DIALOG.DOWNLOAD_TITLE' | translate }}</h2>
  <mat-dialog-content>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <p>{{ 'DIALOG.DOWNLOAD_MESSAGE' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close (click)="cancelDownload()">{{ 'DIALOG.CANCEL' | translate }}</button>
  </mat-dialog-actions>
</ng-template>


