import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { AntiMemLeak } from '../../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import {
  ConditionalFormattingComponent,
  ConditionalFormattingOperation,
  LogicalOperators,
} from '../../dialogues/conditional-formatting/conditional-formatting.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-upload-module-table',
  templateUrl: './upload-module-table.component.html',
  styleUrl: './upload-module-table.component.scss',
})
export class UploadModuleTableComponent extends AntiMemLeak implements OnInit {
  @Input()
  inserts!: any;
  @Input()
  differences!: any;
  @Output()
  selectionChange = new EventEmitter<any[]>();
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
      this.dataSource.sortingDataAccessor = (item: any, property: any) => {
        const columnName = property;
        if (item[columnName]) {
          return item[columnName].toString().toLowerCase();
        } else if (columnName === 'oldValue') {
          if (!item.differencesData) return 0;
          const currentElement = item.differencesData.find((d: any) => {
            return d.valueType === 'value';
          });
          return parseFloat(currentElement?.originalValue || '0');
        } else if (columnName === 'newValue') {
          if (!item.differencesData) return 0;
          if (!item.differencesData) return 0;
          const currentElement = item.differencesData.find((d: any) => {
            return d.valueType === 'value';
          });
          return parseFloat(currentElement?.newValue || '0');
        } else if (columnName.startsWith('old')) {
          if (!item.differencesData) {
            return `-`.toLowerCase();
          }
          const currentElement = item.differencesData.find((d: any) => {
            const newColumnName = columnName.replace('old', '');
            const formattedColumnName =
              newColumnName.charAt(0).toLowerCase() + newColumnName.slice(1);
            return d.valueType === formattedColumnName;
          });
          return `${currentElement?.originalValue || 'none'}`
            .toString()
            .toLowerCase();
        } else if (columnName.startsWith('new')) {
          if (!item.differencesData) {
            const newColumnName = columnName.replace('new', '');
            const formattedColumnName =
              newColumnName.charAt(0).toLowerCase() + newColumnName.slice(1);
            return item[formattedColumnName].toString().toLowerCase();
          }
          const currentElement = item.differencesData.find((d: any) => {
            const newColumnName = columnName.replace('new', '');
            const formattedColumnName =
              newColumnName.charAt(0).toLowerCase() + newColumnName.slice(1);
            return d.valueType === formattedColumnName;
          });
          return `${currentElement?.newValue || 'none'}`
            .toString()
            .toLowerCase();
        } else if (columnName === 'valueDifference') {
          return parseFloat(this.getValueDiff(item));
        }
      };
    }
  }
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  isConditionalFormattingFilterOn = false;
  conditions: any[] = [];
  logicalOperators: LogicalOperators[] = [];
  color!: string;
  dataSource!: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns = [
    'select',
    'code',
    'dataYear',
    'isoCode',
    'oldNotes',
    'newNotes',
    'oldNotesInternal',
    'newNotesInternal',
    'oldSource',
    'newSource',
    'oldValue',
    'newValue',
    'valueDifference',
  ];
  filtersForm = new FormGroup({
    codeFilter: new FormControl(''),
    dataYearFilter: new FormControl(''),
    isoCodeFilter: new FormControl(''),
  });
  showAll = true;
  showDifferences = false;

  constructor(private dialog: MatDialog) {
    super();
    this.selection.changed.subscribe(() => {
      this.selectionChange.emit(this.selection.selected);
    });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(
      this.inserts.concat(this.differences)
    );
    this.selectAll();
  }

  selectAll(): void {
    this.selection.select(...this.dataSource.data);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.selectAll();
  }

  getOldValue(element: any, columnName: string): string {
    if (!element.differencesData) return '-';
    else {
      const currentElement = element.differencesData.find(
        (d: any) => d.valueType === columnName
      );
      return `${currentElement?.originalValue || 'none'}`;
    }
  }

  getNewValue(element: any, columnName: string): string {
    if (!element.differencesData) return element[columnName];
    else {
      const currentElement = element.differencesData.find(
        (d: any) => d.valueType === columnName
      );
      return `${currentElement?.newValue || 'none'}`;
    }
  }

  getValueDiff(element: any): string {
    if (element.differencesData) {
      const oldValue = parseFloat(this.getOldValue(element, 'value'));
      const newValue = parseFloat(this.getNewValue(element, 'value'));
      const diff = parseFloat((newValue - oldValue).toFixed(4));
      return ((diff < 0 ? '' : '+') + diff).toString();
    } else {
      return '-';
    }
  }

  clearConditionalFormattingFilter(): void {
    this.logicalOperators = [];
    this.conditions = [];
    this.color = '#ffd580';
    this.isConditionalFormattingFilterOn = false;
  }

  openConditionalFormattingDialog(): void {
    let config = {};
    if (this.isConditionalFormattingFilterOn) {
      config = {
        conditions: this.conditions,
        logicalOperators: this.logicalOperators,
        color: this.color,
      };
    }
    this.dialog
      .open(
        ConditionalFormattingComponent,
        this.isConditionalFormattingFilterOn ? { data: config } : undefined
      )
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.isConditionalFormattingFilterOn = true;
          this.conditions = [];
          for (const res of response.values) {
            this.conditions.push({
              conditionThresholdValue: res.inputValue,
              conditionOperation: res.conditionOperation,
            });
          }
          this.logicalOperators = response.logicalOperators;
          this.color = response.color;
        }
      });
  }

  checkConditions(value: string): boolean {
    const conditionResults = [];
    const valueNum = parseFloat(value);
    for (const condition of this.conditions) {
      const conditionThresholdValue = parseFloat(
        condition.conditionThresholdValue
      );
      if (
        condition.conditionOperation === ConditionalFormattingOperation.EQUAL
      ) {
        conditionResults.push(valueNum === conditionThresholdValue);
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.NOT_EQUAL
      ) {
        conditionResults.push(valueNum !== conditionThresholdValue);
      } else if (
        condition.conditionOperation === ConditionalFormattingOperation.GREATER
      ) {
        conditionResults.push(valueNum > conditionThresholdValue);
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.GREATER_OR_EQUAL
      ) {
        conditionResults.push(valueNum >= conditionThresholdValue);
      } else if (
        condition.conditionOperation === ConditionalFormattingOperation.LESS
      ) {
        conditionResults.push(valueNum < conditionThresholdValue);
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.LESS_OR_EQUAL
      ) {
        conditionResults.push(valueNum <= conditionThresholdValue);
      }
    }
    let result = conditionResults[0];
    for (let i = 0; i < this.logicalOperators.length; i++) {
      switch (this.logicalOperators[i]) {
        case LogicalOperators.AND:
          result = result && conditionResults[i + 1];
          break;
        case LogicalOperators.OR:
          result = result || conditionResults[i + 1];
          break;
        default:
          throw new Error(`Invalid operator: ${this.logicalOperators[i]}`);
      }
    }
    return (
      value !== '' &&
      value !== undefined &&
      value !== null &&
      result &&
      this.isConditionalFormattingFilterOn
    );
  }

  applyFilter(): void {
    const filtersValue = {
      code: this.filtersForm.controls.codeFilter.value?.toLowerCase(),
      isoCode: this.filtersForm.controls.isoCodeFilter.value?.toLowerCase(),
      dataYear: this.filtersForm.controls.dataYearFilter.value?.toLowerCase(),
    };
    this.dataSource.filterPredicate = (data, filterString: any) => {
      const filters = JSON.parse(filterString);
      return (
        data.code.toLowerCase().includes(filters.code) &&
        data.isoCode.toLowerCase().includes(filters.isoCode) &&
        data.dataYear.toString().toLowerCase().includes(filters.dataYear) &&
        (this.showAll
          ? true
          : this.showDifferences
          ? data.differencesData
          : !data.differencesData)
      );
    };
    this.dataSource.filter = JSON.stringify(filtersValue);
  }

  showType(type: string): void {
    if (type === 'all') {
      this.showAll = true;
      this.showDifferences = false;
    } else if (type === 'differences') {
      this.showAll = false;
      this.showDifferences = true;
    } else if (type === 'inserts') {
      this.showAll = false;
      this.showDifferences = false;
    }
    this.applyFilter();
  }
}
