@if(loading) {
<app-loader></app-loader>
}
<div class="back-button-container">
  <button mat-button mat-raised-button color="primary" (click)="goBackToHome()"><i class="fa-solid fa-arrow-left"></i>
    {{ 'ADMIN_VIEW.BACK' | translate }}
  </button>
</div>
<mat-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="baseCodeFormGroup" label="{{'BULK_CODE_CREATE.BASE_CODE_STEP_LABEL' | translate}}">
    <app-base-code-step [stepper]="stepper" [formGroup]="baseCodeFormGroup"></app-base-code-step>
  </mat-step>
  <mat-step [stepControl]="disaggregationsFormGroup"
    label="{{'BULK_CODE_CREATE.DISAGGREGATIONS_STEP_LABEL' | translate}}">
    <app-select-disaggregation-step [stepper]="stepper"
      [formGroup]="disaggregationsFormGroup"></app-select-disaggregation-step>
  </mat-step>
  <mat-step [stepControl]="unitsFormGroup" label="{{'BULK_CODE_CREATE.UNITS_STEP_LABEL' | translate}}">
    <app-select-unit-step [stepper]="stepper" [formGroup]="unitsFormGroup" (nextSelected)="populateCodesTable()"></app-select-unit-step>
  </mat-step>
  <mat-step label="{{'BULK_CODE_CREATE.LAST_STEP_LABEL' | translate}}">
    <div class="step-title">
      <h3>{{'BULK_CODE_CREATE.CHANGE_CODES_AND_DESCRIPTIONS' | translate}}</h3>
      <div class="step-subtitle">
        <span>{{'BULK_CODE_CREATE.COLLECTION' | translate}}
          {{baseCodeFormGroup.controls.collection.value?.collectionName ?? ('BULK_CODE_CREATE.NONE' |
          translate)}}</span>
        <div class="divider"></div>
        <span>{{'BULK_CODE_CREATE.DATABASE' | translate}}
          {{baseCodeFormGroup.controls.database.value?.databaseName}}</span>
        <div class="divider"></div>
        <span>{{'BULK_CODE_CREATE.TOTAL' | translate}} {{selection.selected.length}}</span>
      </div>
    </div>
    <div class="table-holder mat-elevation-z8" tabindex="0">
      <table mat-table [dataSource]="codeCreationModelDataSource">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef onclick="this.blur()" style="width: 40px">
            <mat-checkbox class="no-focus-outline" (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected" [indeterminate]="selection.hasValue() && !isAllSelected"
              onclick="this.blur()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element" onclick="this.blur()"
            [ngClass]="{'selected-row': selection.isSelected(element)}" style="width: 40px">
            <mat-checkbox class="no-focus-outline" (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null;" [checked]="selection.isSelected(element)"
              onclick="this.blur()">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="disaggregation">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div>{{'BULK_CODE_CREATE.DISAGGREGATION' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="row-line">{{ element.disaggregation.disaggregationDescription }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div>{{'BULK_CODE_CREATE.UNIT' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="row-line">{{ element.unit.unitDescription }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div>{{'BULK_CODE_CREATE.CODE' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element" class="input-cell">
            <div class="input-container">
              <input matInput type="text" class="form-control" [(ngModel)]="element.code"/>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div>{{'BULK_CODE_CREATE.DESCRIPTION' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element" class="input-cell">
            <div class="input-container">
              <input matInput type="text" class="form-control" [(ngModel)]="element.description"/>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="definition">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div>{{'BULK_CODE_CREATE.DEFINITION' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element" class="input-cell">
            <div class="input-container">
              <input matInput type="text" class="form-control" [(ngModel)]="element.definition"/>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="decimalNumber">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div>{{'BULK_CODE_CREATE.DECIMAL_NUMBER' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element" class="input-cell">
            <div class="input-container">
              <input matInput type="number" min="0" max="99" (input)="enforceMinMax($event)"
                [(ngModel)]="element.decimalNumber">
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
      </table>
    </div>
    <div class="step-buttons-container">
      <button mat-raised-button color="primary" (click)="stepperGoBack(stepper)">{{'BULK_CODE_CREATE.BACK' | translate}}</button>
      <button mat-raised-button color="primary" (click)="openCreateCodesDialog(createCodesDialog)">{{'BULK_CODE_CREATE.CREATE_CODES' | translate}}</button>
    </div>
  </mat-step>
</mat-stepper>


<ng-template #createCodesDialog>
  <h2 mat-dialog-title class="mat-dialog-title">{{'BULK_CODE_CREATE.CREATE_CODES_CONFIRMATION_TITLE' | translate}}</h2>
  <mat-dialog-content>
    @if (creatingCodes) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    } @else {
    <span>{{'BULK_CODE_CREATE.CREATE_CODES_CONFIRMATION_MESSAGE' | translate}} {{selection.selected.length}}</span>
    }
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>{{ 'DIALOG.CANCEL' | translate }}</button>
    <button class="confirm-deletion-button" color="{{creatingCodes ? 'disabled' : 'primary'}}" mat-raised-button (click)="createCodes()" [disabled]="creatingCodes">
      {{ 'BULK_CODE_CREATE.CREATE_CODES' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>