import { IMapper } from '../../../models/i-mapper';
import { UserModel, UsersRoles } from '../models/users-models';

export class UserInfoMapper implements IMapper {
  userInfo!: UserModel;
  fillFromJson(json: any): void {
    this.userInfo = json;
  }
  fillToJson(): string {
    return '';
  }
}

export class UsersListMapper implements IMapper {
  filterRequestBody?: any;
  users = [] as UserModel[];
  count = 0;

  fillFromJson(json: any): void {
    json.data.forEach((user: any) => {
      this.users?.push({
        id: user.id,
        name: user.name,
        surname: user.surname,
        email: user.email,
        countryId: user.countryId,
        role: user.role,
        cognitoSub: user.cognitoSub,
        lastModifiedBy: user.lastModifiedBy,
        lastModifiedOn: user.lastModifiedOn,
      });
    });
    this.count = json.total;
  }

  fillToJson(): string {
    return JSON.stringify(this.filterRequestBody);
  }
}

export class CreateUpdateUserMapper implements IMapper {
  name = '';
  surname = '';
  email!: string;
  role!: UsersRoles;
  countryId?: string | null;
  value: any[] = [];

  fillFromJson(json: any): void {
    this.value.push(json);
  }

  fillToJson(): string {
    return JSON.stringify({
      name: this.name,
      surname: this.surname,
      email: this.email,
      role: this.role,
      countryId: this.countryId ?? null,
    });
  }
}

export class DeleteUserMapper implements IMapper {
  fillFromJson(_json: any): string {
    return 'User deleted succesfully';
  }

  fillToJson(): string {
    return '';
  }
}
