import { IMapper } from '../../../models/i-mapper';
import { SurveyStatusEnum } from '../../survey-country-table/enums/survey-status-enum';
import { SurveyValidationStatusEnum } from '../../survey-country-table/enums/survey-validation-status-enum';
import { CountrySurveyModel } from '../../survey-country-table/models/country-survey-model';

export class getCountryMapper implements IMapper {
  countryModel: CountrySurveyModel = {} as CountrySurveyModel;
  fillFromJson(json: any): void {
    this.countryModel = {
      countryID: json['countryID'],
      countryName: json['shortName'],
      isoCode: json['isoCode'],
      surveyStatus: SurveyStatusEnum.INCOMPLETE,
      validationStatus: SurveyValidationStatusEnum.COMPLETED,
    };
  }
  fillToJson(): string {
    return '';
  }
}