import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/net-utils/network.service';
import { environment } from 'src/environments/environment';
import { ValidationModuleListCodesResponseModel } from '../models/validation-module-list-codes-models';
import { ValidationModuleListCodesMapper } from '../mappers/validation-module-list-codes-mapper';

@Injectable({
  providedIn: 'root',
})
export class ValidationModuleListCodesService {
  private networkService!: NetworkService;
  baseUrl = `${environment.backend.endpoint}/validation-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async listCodes(
    surveyId: string,
    pageNumber: number,
    pageSize: number,
    code?: string[]
  ): Promise<ValidationModuleListCodesResponseModel> {
    const mapper = new ValidationModuleListCodesMapper();
    await this.networkService.get(
      `${this.baseUrl}/survey/${surveyId}/code`,
      mapper,
      {
        code: code ? code.join(',') : undefined,
        offset: pageNumber,
        limit: pageSize,
      }
    );
    return mapper.response!;
  }
}
