import { IMapper } from '../../../models/i-mapper';

export class InsertCellMapper implements IMapper {
  body?: {
    countryId: string;
    codeId: string;
    dataYear: number;
    value: number | null;
    notes: string | null;
    source: string;
  };
  returnedId?: string;
  fillFromJson(json: any): void {
    this.returnedId = json;
  }
  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}
