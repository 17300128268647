import { IMapper } from '../../../models/i-mapper';
import { ContactModel } from '../models/contact-model';

export class ContactListMapper implements IMapper {
  filterRequestBody?: any;
  contacts: ContactModel[] = [];
  count = 0;

  fillFromJson(json: any): void {
    json.data.forEach((contact: ContactModel) => {
      this.contacts?.push({
        contactId: contact.contactId,
        oldContactId: contact.oldContactId,
        countryId: contact.countryId,
        surveySeriesIds: contact.surveySeriesIds,
        prefix: contact.prefix,
        firstName: contact.firstName,
        lastName: contact.lastName,
        title: contact.title,
        organisationName: contact.organisationName,
        phoneNumber: contact.phoneNumber,
        email: contact.email,
        website: contact.website,
        internalNote: contact.internalNote,
        linkedToAccount: contact.linkedToAccount,
        lastModifiedBy: contact.lastModifiedBy,
        lastModifiedOn: contact.lastModifiedOn,
        countryShortName: contact.countryShortName,
        surveySeriesNames: contact.surveySeriesNames,
      });
    });
    this.count = json.total;
  }

  fillToJson(): string {
    return JSON.stringify(this.filterRequestBody);
  }
}

export class CreateUpdateContactMapper implements IMapper {
  countryId!: string;
  surveySeriesIds!: string;
  prefix!: string;
  firstName!: string;
  lastName!: string;
  title!: string;
  organisationName!: string;
  phoneNumber!: string;
  email!: string;
  website!: string;
  internalNote?: string | null;
  value: any[] = [];

  fillFromJson(json: any): void {
    this.value.push(json);
  }

  fillToJson(): string {
    return JSON.stringify({
      countryId: this.countryId,
      surveySeriesIds: this.surveySeriesIds,
      prefix: this.prefix,
      firstName: this.firstName,
      lastName: this.lastName,
      title: this.title,
      organisationName: this.organisationName,
      phoneNumber: this.phoneNumber,
      email: this.email,
      website: this.website,
      internalNote: this.internalNote ?? null,
    });
  }
}

export class DeleteContactMapper implements IMapper {
  fillFromJson(_json: any): string {
    return 'Contact deleted succesfully';
  }

  fillToJson(): string {
    return '';
  }
}

export class ContactDetailsMapper implements IMapper {
  contact: any;

  fillFromJson(data: any): void {
    this.contact = data;
  }

  fillToJson(): string {
    return '';
  }
}
