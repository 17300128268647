import { IMapper } from '../../../models/i-mapper';
import { SurveyModel } from '../../survey-table/models/survey-model';


export class getSurveyMapper implements IMapper {
  surveyModel: SurveyModel = {} as SurveyModel;
  fillFromJson(json: any): void {
    this.surveyModel = {
      surveyId: json['surveyId'],
      oldSurveyId: json['oldSurveyId'],
      surveyYear: json['surveyYear'],
      surveyName: json['surveyName'],
      surveyDescription: json['surveyDescription'],
    };
  }
  fillToJson(): string {
    return '';
  }
}
