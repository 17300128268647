import { Injectable } from '@angular/core';
import { DownloadDataMapper } from '../mappers/download-data-mapper';
import { environment } from '../../../../environments/environment';
import { NetworkService } from '../../../core/net-utils/network.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadDataService {
  networkService?: NetworkService;
  baseUrl = `${environment.backend.endpoint}/download-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async downloadRawTableData(
    params: any,
    viewType: 'country' | 'household'
  ): Promise<any> {
    try {
      const mapper = new DownloadDataMapper();
      await this.networkService?.get(
        `${this.baseUrl}/raw-data-download/`,
        mapper,
        {
          ...params,
          viewType,
        }
      );
      return mapper.preSignedUrl;
    } catch (e) {
      //TODO: handle specific errors
      throw new Error();
    }
  }

  async downloadTableData(
    params: any,
    viewType: 'country' | 'household'
  ): Promise<any> {
    try {
      const mapper = new DownloadDataMapper();
      await this.networkService?.get(`${this.baseUrl}/data-download/`, mapper, {
        ...params,
        viewType,
      });
      return mapper.preSignedUrl;
    } catch (e) {
      //TODO: handle specific errors
      throw new Error();
    }
  }
}
