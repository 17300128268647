import { getAwsAuthPlugin } from "@aws-sdk/middleware-signing";
import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { SMITHY_CONTEXT_KEY } from "@smithy/types";
import { de_UnlinkDeveloperIdentityCommand, se_UnlinkDeveloperIdentityCommand } from "../protocols/Aws_json1_1";
export { $Command };
export class UnlinkDeveloperIdentityCommand extends $Command {
  static getEndpointParameterInstructions() {
    return {
      UseFIPS: {
        type: "builtInParams",
        name: "useFipsEndpoint"
      },
      Endpoint: {
        type: "builtInParams",
        name: "endpoint"
      },
      Region: {
        type: "builtInParams",
        name: "region"
      },
      UseDualStack: {
        type: "builtInParams",
        name: "useDualstackEndpoint"
      }
    };
  }
  constructor(input) {
    super();
    this.input = input;
  }
  resolveMiddleware(clientStack, configuration, options) {
    this.middlewareStack.use(getSerdePlugin(configuration, this.serialize, this.deserialize));
    this.middlewareStack.use(getEndpointPlugin(configuration, UnlinkDeveloperIdentityCommand.getEndpointParameterInstructions()));
    this.middlewareStack.use(getAwsAuthPlugin(configuration));
    const stack = clientStack.concat(this.middlewareStack);
    const {
      logger
    } = configuration;
    const clientName = "CognitoIdentityClient";
    const commandName = "UnlinkDeveloperIdentityCommand";
    const handlerExecutionContext = {
      logger,
      clientName,
      commandName,
      inputFilterSensitiveLog: _ => _,
      outputFilterSensitiveLog: _ => _,
      [SMITHY_CONTEXT_KEY]: {
        service: "AWSCognitoIdentityService",
        operation: "UnlinkDeveloperIdentity"
      }
    };
    const {
      requestHandler
    } = configuration;
    return stack.resolve(request => requestHandler.handle(request.request, options || {}), handlerExecutionContext);
  }
  serialize(input, context) {
    return se_UnlinkDeveloperIdentityCommand(input, context);
  }
  deserialize(output, context) {
    return de_UnlinkDeveloperIdentityCommand(output, context);
  }
}