export enum UsersRoles {
  ADMIN = 'admin_user',
  INTERNAL = 'internal_user',
  EXTERNAL = 'country_user',
}

export interface UserModel {
  id: string;
  name: string;
  surname: string;
  email: string;
  countryId: string;
  role: UsersRoles;
  cognitoSub: string | null;
  lastModifiedBy: string | null;
  lastModifiedOn: string;
}

export interface UsersListModel {
  users: UserModel[];
  count: number;
}
