import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CellSourcesModel, FormDataModel, UserModification } from './models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProviderService } from '../../../core/provider.service';
import { ColumnsModel } from '../../update-country-table/models/update-country-table-models';

@Component({
  selector: 'app-modify-one-row',
  templateUrl: './modify-one-row.component.html',
  styleUrls: ['./modify-one-row.component.scss'],
})
export class ModifyOneRowComponent {
  formData: FormDataModel[] = [];
  formNumericData: FormDataModel[] = [];
  form: FormGroup = {} as FormGroup;
  formTitles: string[] = [];
  showError = false;
  userModifications = [] as UserModification[];
  excludedControls = ['actions'];
  headerColumns = ['description', 'code', 'codeId', 'countryId', 'countryName'];
  headerData: any;
  isLoading = false;
  cellSourcesBE: CellSourcesModel[] = [];
  cellSourcesToBeUpdated: CellSourcesModel[] = [];
  dataIdsForEmptyYears: string[] = [];
  cellIdForCellWithNoId = '';

  constructor(
    public dialogRef: MatDialogRef<ModifyOneRowComponent>,
    private formBuilder: FormBuilder,
    private _formBuilder: FormBuilder,
    private providerService: ProviderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    this.form = this._formBuilder.group({
      formGroups: this._formBuilder.array([]),
    });
    this.headerData = this.data.columns
      .filter((column: any) =>
        this.headerColumns.includes(column.title.toLowerCase())
      )
      .map((headerColumn: any) => ({
        title: headerColumn.title,
        value: this.data.data.value[headerColumn.field],
      }));
    this.prepareFormControlsAndFormData();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  validateFormControls(): void {
    this.showError = false;
    const controls = this.form.controls;
    for (const control in controls) {
      if (controls[control].invalid) {
        this.showError = true;
      }
    }
  }
  async getCellSources(): Promise<void> {
    this.isLoading = true;
    const filteredCellIds = this.data.cellIds.filter(
      (el: any) => el !== undefined && el !== null
    );
    this.cellSourcesBE =
      await this.providerService.modifyOneRowService.getCellSources(
        filteredCellIds
      );
    this.isLoading = false;
  }
  removeCommasAndDots(numberString: string): string {
    return numberString.replace(/,|\.00$/g, '').trim();
  }
  async getUserModificationsForSources(year: string): Promise<void> {
    let existingSourceForCurrentCell = this.findCellSourceByYear(year);
    if (existingSourceForCurrentCell) {
      existingSourceForCurrentCell = {
        sourceId: existingSourceForCurrentCell.sourceId,
        dataId: existingSourceForCurrentCell.dataId,
        source: this.form.controls[`${year}-source`].value,
      };
      const index = this.cellSourcesToBeUpdated.findIndex(
        (el) => el.dataId === this.data.data.value[`id-${year}`]
      );
      if (index > -1) {
        this.cellSourcesToBeUpdated[index] = existingSourceForCurrentCell;
      } else {
        this.cellSourcesToBeUpdated.push(existingSourceForCurrentCell);
      }
    } else {
      if (this.data.data.value[`id-${year}`] !== null) {
        const index = this.cellSourcesToBeUpdated.findIndex(
          (el) => el.dataId === this.data.data.value[`id-${year}`]
        );
        if (index > -1) {
          this.cellSourcesToBeUpdated[index] = {
            source: this.form.controls[`${year}-source`].value,
            sourceId: '',
            dataId: this.data.data.value[`id-${year}`],
          };
        } else {
          this.cellSourcesToBeUpdated.push({
            source: this.form.controls[`${year}-source`].value,
            sourceId: '',
            dataId: this.data.data.value[`id-${year}`],
          });
        }
      }
    }
  }
  async updateSources(userModifications: CellSourcesModel[]): Promise<void> {
    await this.providerService.modifyOneRowService.updateSources(
      userModifications
    );
  }

  findSourcesIdsForEmptyYears(): string[] {
    this.userModifications
      .filter((el: UserModification) => el.value === null)
      .map((el: UserModification) => {
        if (el.id) {
          this.dataIdsForEmptyYears.push(el.id);
        }
      });
    return this.cellSourcesBE
      .map((el: CellSourcesModel) => {
        if (this.dataIdsForEmptyYears.includes(el.dataId)) {
          return el.sourceId;
        }
        return '';
      })
      .filter((el) => el !== '');
  }

  setUserModificationsForYears(year: string, event: any): void {
    const yearValueChanged =
      !this.showError && this.data.data.value[year] !== event.target.value;
    if (yearValueChanged) {
      const controls = this.form.controls;
      let yearId = '';
      Object.keys(this.data.data.value).forEach((k: any) => {
        if (`id-${k}` === `id-${year}`) {
          yearId = this.data.data.value[`id-${k}`];
        }
      });
      const indexOfExistingYear = this.userModifications.findIndex(
        (element) => element.id === yearId
      );
      if (indexOfExistingYear > -1) {
        if (controls[year].value.length === 0) {
          this.userModifications[indexOfExistingYear] = {
            id: yearId,
            year: Number(year),
            countryId: this.data.data.value['countryId'],
            codeId: this.data.data.value['codeId'],
          };
        } else {
          this.userModifications[indexOfExistingYear] = {
            id: yearId,
            value:
              controls[year].value.length === 0 ? null : controls[year].value,
            year: Number(year),
            countryId: this.data.data.value['countryId'],
            codeId: this.data.data.value['codeId'],
          };
        }
      } else {
        if (controls[year].value.length === 0) {
          this.userModifications.push({
            id: yearId,
            year: Number(year),
            countryId: this.data.data.value['countryId'],
            codeId: this.data.data.value['codeId'],
          });
        } else {
          this.userModifications.push({
            id: yearId,
            value:
              controls[year].value.length === 0 ? null : controls[year].value,
            year: Number(year),
            countryId: this.data.data.value['countryId'],
            codeId: this.data.data.value['codeId'],
          });
        }
      }
    }
  }
  findCellSourceByYear(year: string): CellSourcesModel | null {
    return (
      this.cellSourcesBE.find(
        (elBE) => elBE.dataId === this.data.data.value['id-' + year]
      ) ?? null
    );
  }
  fillFormTitles(columns: ColumnsModel[]): void {
    columns.forEach((c: any) => {
      if (!this.excludedControls.includes(c.field)) {
        this.formTitles.push(c.field);
      }
    });
  }
  async prepareFormControlsAndFormData(): Promise<void> {
    if (this.data) {
      await this.getCellSources();
      this.fillFormTitles(this.data.columns);
      Object.keys(this.data.data.value).forEach((k: any) => {
        if (this.formTitles.includes(k) && !this.headerColumns.includes(k)) {
          const cellSource = this.findCellSourceByYear(k);
          this.formNumericData.push({
            key: k,
            value: this.data.data.value[k],
            dataSource: cellSource?.source ?? '',
            dataSourceId: cellSource?.sourceId ?? '',
            dataId:
              cellSource?.dataId ??
              (this.data.data.value[k] && this.data.data.value[`id-${k}`]) ??
              this.data.data.value[`id-${k}`] ??
              '',
          });
          this.form.addControl(
            k,
            this.formBuilder.control(
              this.data.data.value[k],
              k !== 'countryName' ? [Validators.pattern(/^[\d,.]+$/)] : []
            )
          );
          this.form.addControl(
            k + '-source',
            this.formBuilder.control(cellSource?.source ?? '')
          );
        }
      });
      this.formNumericData.forEach((el) => {
        this.formData.push(el);
      });
    }
  }

  async modifyData(): Promise<void> {
    this.isLoading = true;
    if (this.form.valid) {
      const toModify = this.userModifications.filter(
        (value) => value.id !== null
      );
      for (const el of toModify) {
        if (el.value) {
          el.value = Number(this.removeCommasAndDots(el.value.toString()));
        }
        if (el.id === null) {
          this.cellIdForCellWithNoId =
            await this.providerService.countryTableService.insertID({
              codeId: this.data.data.controls['codeId'],
              countryId: this.data.data.controls['countryId'],
              year: Number(el.year),
              value: this.form.controls[`${el.year}`].value,
            });
          const index = this.cellSourcesToBeUpdated.findIndex(
            (el) => el.dataId === this.cellIdForCellWithNoId
          );
          if (index > -1) {
            this.cellSourcesToBeUpdated[index] = {
              source: this.form.controls[`${el.year}-source`].value,
              sourceId: '',
              dataId: this.cellIdForCellWithNoId,
            };
          } else {
            this.cellSourcesToBeUpdated.push({
              source: this.form.controls[`${el.year}-source`].value,
              sourceId: '',
              dataId: this.cellIdForCellWithNoId,
            });
          }
        }
      }
      try {
        if (this.cellSourcesToBeUpdated.length !== 0) {
          await this.updateSources(this.cellSourcesToBeUpdated);
        }
        const sourcesToBeDeleted = this.findSourcesIdsForEmptyYears();
        if (sourcesToBeDeleted.length !== 0) {
          await this.providerService.countryTableService.deleteSourceById(
            sourcesToBeDeleted
          );
        }
        await this.providerService.countryTableService.modifyRow(
          this.userModifications
        );
        this.data.callback();
        this.closeDialog();
      } catch (e) {
        console.log(e);
      }
    }
    this.isLoading = false;
  }
}
