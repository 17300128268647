import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/net-utils/network.service';
import { environment } from 'src/environments/environment';
import { DisaggregationModel } from '../models/disaggregation-model';
import { ListDisaggregationsMapper } from '../mappers/list-disaggregations-mapper';

@Injectable({
  providedIn: 'root',
})
export class DisaggregationFilterService {
  networkService?: NetworkService;
  baseUrl = `${environment.backend.endpoint}/data-management-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getDisaggregationsList(level: number): Promise<DisaggregationModel[]> {
    const disaggregationsListMapper = new ListDisaggregationsMapper();
    await this.networkService?.get(
      `${this.baseUrl}/disaggregation`,
      disaggregationsListMapper,
      {
        level,
      }
    );
    return disaggregationsListMapper.disaggregationsList;
  }
}
