@if (loading) {
<app-loader></app-loader>
}
<div class="filters-class">
  <div class="filters-bar">
    <div class="first-column"></div>
    <div class="last-column">
      <button mat-raised-button color="primary" (click)="completeValidation()">
        <span>
          {{ 'CONTACT_VALIDATION_TABLE.COMPLETE_VALIDATION' | translate }}
        </span>
      </button>
    </div>
  </div>
</div>
<div class="table-holder mat-elevation-z8" tabindex="0">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="rowDescription">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div>{{ 'CONTACT_VALIDATION_TABLE.DESCRIPTION_HEADER' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element; let i = index;" class="{{i !== 0 ? element['key']: ''}}">
        <span class="row-line">
          {{ 'CONTACT_VALIDATION_TABLE.' + element['rowDescription'] | translate }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="oldContact">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div>{{ 'CONTACT_VALIDATION_TABLE.OLD_CONTACT_HEADER' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element; let i = index;"
        class="{{getSelectedCell(element) ? 'selected-cell' : ''}} {{i !== 0 ? element['key']: ''}}"
        [style]="'background-color: ' + element['type'] + ';'" (click)="selectCell(element)">
        <span class="row-line">
          {{ element.oldContact[element['key']] }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="newContact">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div>{{ 'CONTACT_VALIDATION_TABLE.NEW_CONTACT_HEADER' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element; let i = index;" class="input-cell {{i !== 0 ? element['key']: ''}}"
        [style]="'background-color: ' + element['type'] + ';'">
        <div class="input-container">
          @if(element['key'] === 'email') {
            <textarea matInput type="text" appFormatStringInput class="form-control"
            [value]="element.newContact[element['key']]" (dataChanged)="setCellValue(element, $event)">
            </textarea>
          } @else {
            <input matInput type="text" appFormatStringInput class="form-control"
            [value]="element.newContact[element['key']]" (dataChanged)="setCellValue(element, $event)" />
          }
        </div>
      </td>
    </ng-container>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="displayedColumns.length">
        @if(!loading) { {{ 'CONTACT_VALIDATION_TABLE.NO_DATA_FOUND' | translate}} }
      </td>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
  </table>
</div>
<div class="history-container">
  <label>{{'CONTACT_VALIDATION_TABLE.HISTORY_LABEL' | translate}}</label>
  <div class="history-data-container">
    @if (historyLoading && selectedCell) {
    <i class="fa-solid fa-ellipsis ellipses-loader"
      style="position: absolute; left: 120px; top: 15px; margin-left: 40px; margin-top: 5px"></i>
    }
    <div>
      @if (!historyLoading && cellHistory?.length === 0) {
      <p>No history found.</p>
      }
      @for (history of cellHistory; track history) {
      @if(!historyLoading) {
      @if (history.version === 0) {
      <p>Contact added by {{ history.lastModifiedBy }} on {{
        providerService.utilService.humanReadableDate(history.lastModifiedOn, true) }}</p>
      } @else {
      <p>Contact edited by {{ history.lastModifiedBy }} on {{
        providerService.utilService.humanReadableDate(history.lastModifiedOn, true) }}{{
        getHistoryDifferencesString(history) || '' }}</p>
      }
      }
      }
    </div>
  </div>
</div>

<ng-template #downloadDialog>
  <h2 mat-dialog-title class="mat-dialog-title">{{ 'CONTACT_VALIDATION_TABLE.DOWNLOAD_DIALOG_TITLE' | translate }}</h2>
  <mat-dialog-content>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <p style="margin-top: 10px">{{ 'CONTACT_VALIDATION_TABLE.DOWNLOADING_DIALOG_MESSAGE' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" mat-dialog-close>{{ 'DIALOG.CANCEL' | translate }}</button>
  </mat-dialog-actions>
</ng-template>
