import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CellSourcesModel, FormDataModel, UserModification } from './models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProviderService } from '../../../core/provider.service';
import { DataSourceModel } from '../../update-country-table/update-country-table.component';

@Component({
  selector: 'app-modify-one-row',
  templateUrl: './modify-one-row.component.html',
  styleUrls: ['./modify-one-row.component.scss'],
})
export class ModifyOneRowComponent {
  formData: FormDataModel[] = [];
  form: FormGroup = {} as FormGroup;
  showError = false;
  userModifications = [] as UserModification[];
  isLoading = false;
  cellSourcesBE: CellSourcesModel[] = [];
  cellSourcesToBeUpdated: CellSourcesModel[] = [];
  dataIdsForEmptyYears: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModifyOneRowComponent>,
    private formBuilder: FormBuilder,
    private _formBuilder: FormBuilder,
    private providerService: ProviderService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      data: DataSourceModel;
      years: number[];
      callback: any;
    }
  ) {
    dialogRef.disableClose = true;
    this.form = this._formBuilder.group({
      formGroups: this._formBuilder.array([]),
    });

    this.prepareFormControlsAndFormData();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    _event: KeyboardEvent
  ): void {
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  validateFormControls(): void {
    this.showError = false;
    const controls = this.form.controls;
    for (const control in controls) {
      if (controls[control].invalid) {
        this.showError = true;
      }
    }
  }

  async getCellSources(): Promise<void> {
    this.isLoading = true;
    const values = Object.values(this.dialogData.data.dataIds);
    const filteredCellIds = values.filter(
      (value) => value !== null
    ) as string[];
    this.cellSourcesBE =
      await this.providerService.modifyOneRowService.getCellSources(
        filteredCellIds
      );
    this.isLoading = false;
  }

  async getUserModificationsForSources(year: string): Promise<void> {
    let existingSourceForCurrentCell = this.findCellSourceByYear(year);
    if (existingSourceForCurrentCell) {
      existingSourceForCurrentCell = {
        sourceId: existingSourceForCurrentCell.sourceId,
        dataId: existingSourceForCurrentCell.dataId,
        source: this.form.controls[`${year}-source`].value,
      };
      const index = this.cellSourcesToBeUpdated.findIndex(
        (el) => el.dataId === this.dialogData.data.dataIds[year]
      );
      if (index > -1) {
        this.cellSourcesToBeUpdated[index] = existingSourceForCurrentCell;
      } else {
        this.cellSourcesToBeUpdated.push(existingSourceForCurrentCell);
      }
    } else {
      if (this.dialogData.data.dataIds[year] !== null) {
        const index = this.cellSourcesToBeUpdated.findIndex(
          (el) => el.dataId === this.dialogData.data.dataIds[year]
        );
        if (index > -1) {
          this.cellSourcesToBeUpdated[index] = {
            source: this.form.controls[`${year}-source`].value,
            sourceId: '',
            dataId: this.dialogData.data.dataIds[year]!,
          };
        } else {
          this.cellSourcesToBeUpdated.push({
            source: this.form.controls[`${year}-source`].value,
            sourceId: '',
            dataId: this.dialogData.data.dataIds[year]!,
          });
        }
      }
    }
  }

  async updateSources(userModifications: CellSourcesModel[]): Promise<void> {
    await this.providerService.modifyOneRowService.updateSources(
      userModifications
    );
  }

  findSourcesIdsForEmptyYears(): string[] {
    this.userModifications
      .filter((el: UserModification) => el.value === null)
      .map((el: UserModification) => {
        if (el.id) {
          this.dataIdsForEmptyYears.push(el.id);
        }
      });
    return this.cellSourcesBE
      .map((el: CellSourcesModel) => {
        if (this.dataIdsForEmptyYears.includes(el.dataId)) {
          return el.sourceId;
        }
        return '';
      })
      .filter((el) => el !== '');
  }

  setUserModificationsForYears(year: string, event: any): void {
    const yearValueChanged =
      !this.showError &&
      this.dialogData.data.values[year] !== event.target.value;
    if (yearValueChanged) {
      const controls = this.form.controls;
      const dataId = this.dialogData.data.dataIds[year];
      const indexOfExistingYear = this.userModifications.findIndex(
        (element) => element.year.toString() === year
      );
      if (indexOfExistingYear > -1) {
        if (controls[year].value.length === 0) {
          this.userModifications[indexOfExistingYear] = {
            id: dataId ?? '',
            year: Number(year),
            countryId: this.dialogData.data.countryId,
            codeId: this.dialogData.data.codeId,
          };
        } else {
          this.userModifications[indexOfExistingYear] = {
            id: dataId ?? '',
            value:
              controls[year].value.length === 0 ? null : controls[year].value,
            year: Number(year),
            countryId: this.dialogData.data.countryId,
            codeId: this.dialogData.data.codeId,
          };
        }
      } else {
        if (controls[year].value.length === 0) {
          this.userModifications.push({
            id: dataId ?? '',
            year: Number(year),
            countryId: this.dialogData.data.countryId,
            codeId: this.dialogData.data.codeId,
          });
        } else {
          this.userModifications.push({
            id: dataId ?? '',
            value:
              controls[year].value.length === 0 ? null : controls[year].value,
            year: Number(year),
            countryId: this.dialogData.data.countryId,
            codeId: this.dialogData.data.codeId,
          });
        }
      }
      const formdata = this.formData.find((value) => value.key === year);
      if (formdata) {
        formdata.value = controls[year].value;
      }
      this.form.updateValueAndValidity();
    }
  }

  findCellSourceByYear(year: string): CellSourcesModel | null {
    return (
      this.cellSourcesBE.find(
        (elBE) => elBE.dataId === this.dialogData.data.dataIds[year]
      ) ?? null
    );
  }

  async prepareFormControlsAndFormData(): Promise<void> {
    if (this.dialogData) {
      await this.getCellSources();
      const formNumericData: FormDataModel[] = [];
      this.dialogData.years.forEach((k) => {
        const cellSource = this.findCellSourceByYear(k.toString());
        formNumericData.push({
          key: k.toString(),
          value: this.dialogData.data.values[k],
          dataSource: cellSource?.source ?? '',
          dataSourceId: cellSource?.sourceId ?? '',
          dataId: this.dialogData.data.dataIds[k],
        });
        this.form.addControl(
          k.toString(),
          this.formBuilder.control(this.dialogData.data.values[k], [
            Validators.pattern(/^[\d,.]+$/),
          ])
        );
        this.form.addControl(
          `${k}-source`,
          this.formBuilder.control(cellSource?.source ?? '')
        );
      });
      this.formData.push(...formNumericData);
    }
  }

  async modifyData(): Promise<void> {
    this.isLoading = true;
    if (this.userModifications.length === 0) {
      this.isLoading = false;
      this.closeDialog();
    }
    if (this.form.valid) {
      for (const el of this.userModifications) {
        if (!el.id) {
          const cellIdForCellWithNoId =
            await this.providerService.countryTableService.insertID({
              codeId: this.dialogData.data.codeId,
              countryId: this.dialogData.data.countryId,
              year: Number(el.year),
              value: this.form.controls[`${el.year}`].value,
            });
          el.id = cellIdForCellWithNoId;
          this.cellSourcesToBeUpdated.push({
            source: this.form.controls[`${el.year}-source`].value,
            sourceId: '',
            dataId: cellIdForCellWithNoId,
          });
        }
      }
      try {
        if (this.cellSourcesToBeUpdated.length !== 0) {
          await this.updateSources(this.cellSourcesToBeUpdated);
        }
        const sourcesToBeDeleted = this.findSourcesIdsForEmptyYears();
        if (sourcesToBeDeleted.length !== 0) {
          await this.providerService.countryTableService.deleteSourceById(
            sourcesToBeDeleted
          );
        }
        await this.providerService.countryTableService.modifyRow(
          this.userModifications
        );
        this.dialogData.callback();
        this.closeDialog();
      } catch (e) {
        console.error(e);
      }
    }
    this.isLoading = false;
  }
}
