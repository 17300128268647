@if (loading) {
<app-loader></app-loader>
}
<div class="container">
  <div class="filters-class">
    <div class="filters-bar" [formGroup]="filterFormGroup">
      <div class="first-column">
        <div class="mat-drop-down">
          <mat-label>{{ 'VALIDATION_MODULE_TABLE.PERCENTAGE_CHANGE' | translate }}</mat-label>
          <input class="short-number-input" (input)="enforceMinMax($event)" type="number" min="0" max="200"
            formControlName="percentageChange">
        </div>
        <div class="mat-drop-down">
          <mat-label>{{ 'VALIDATION_MODULE_TABLE.YEAR_FROM' | translate }}</mat-label>
          <input matInput type="number" formControlName="yearFrom">
        </div>
        <div class="mat-drop-down">
          <mat-label>{{ 'VALIDATION_MODULE_TABLE.YEAR_TO' | translate }}</mat-label>
          <input matInput type="number" formControlName="yearTo">
        </div>
        <div class="mat-drop-down">
          <app-code-filter (codeSelectionChanged)="codeSelectionChanged($event)"></app-code-filter>
        </div>
      </div>
      <div class="last-column">
        <button mat-raised-button color="primary" (click)="completeSurvey()">
          <span>{{'VALIDATION_MODULE_TABLE.COMPLETE_SURVEY' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="table-holder mat-elevation-z8" tabindex="0">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="codeName">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-header>{{ 'VALIDATION_MODULE_TABLE.CODE_NAME_HEADER' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="row-line">
            {{ element.codeName }}
          </span>
        </td>
      </ng-container>
      @for (year of yearsToDisplay; track year) {
      <ng-container [matColumnDef]="year">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div>{{ year }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="input-cell {{isCellSelected(element, year) ? 'cell-selected': ''}}">
          <div class="input-container">
            <input matInput type="text" (click)="setSelectedCell(element, year)" appFormatNumberInput
              class="form-control" [value]="element.values[year]" (dataChanged)="setCellValue(element, year, $event)" />
          </div>
        </td>
      </ng-container>
      }
      @for (year of yearsToDisplay; track year) {
      <ng-container [matColumnDef]="year + ' %'">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div>{{ year + ' %' }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="input-cell">
          <span class="row-line">
            {{ element.percentages[year] }}
          </span>
        </td>
      </ng-container>
      }
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [colSpan]="displayedColumns?.length">
          @if(!loading) {{{ 'VALIDATION_MODULE_LIST_CODES.NO_DATA_FOUND' | translate}}}
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
    </table>
  </div>
  <div class="paginator">
    <mat-paginator class="hide-ripple" [length]="tableCount" [pageSizeOptions]="[50, 100, 150]">
    </mat-paginator>
  </div>
  <div class="data-info-container">
    @if(!selectedCell) {
    <div class="no-cell-warning">
      <span>{{'VALIDATION_MODULE_TABLE.NO_CELL_SELECTED' | translate}}</span>
    </div>
    } @else if (!historyLoading && !selectedCellHasId) {
    <div class="no-cell-warning">
      <span>{{'VALIDATION_MODULE_TABLE.NO_VALUE_SELECTED' | translate}}</span>
    </div>
    }
    <div class="data-info">
      <div class="notes-group">
        <div class="notes-container">
          <div class="notes-row">
            <span class="first">{{'VALIDATION_MODULE_TABLE.NOTES' | translate}}</span>
            <input #publishedNote matInput [disabled]="!selectedCell" (input)="notesSelectionChanged($event)"
              (focusout)="publishedNote.value.length > 0 ? updateNotes() : updateNotes('')"
              (keydown.enter)="publishedNote.value.length > 0 ? updateNotes() : updateNotes('')" type="text"
              [value]="selectedCell === undefined ? '' : selectedCell.cell.notes[selectedCell.year]">

          </div>
        </div>
      </div>
      <div class="data-history">
        <span>{{'VALIDATION_MODULE_TABLE.DATA_HISTORY' | translate}}</span>
        @if (historyLoading) {
        <i class="fa-solid fa-ellipsis ellipses-loader" style="position: absolute; left: 110px; top: 15px"></i>
        }
        <div class="data-history-list">
          @for (comment of cellHistory; track comment) {
          @if(comment.type === 'insert') {
          <span>
            {{'VALIDATION_MODULE_TABLE.VALUE' | translate}} "{{comment.value ? comment.value: null}}" {{'VALIDATION_MODULE_TABLE.INSERTED' |
            translate}} {{'VALIDATION_MODULE_TABLE.BY' | translate}} "{{comment.modifiedBy}}"
            {{'VALIDATION_MODULE_TABLE.ON' | translate}} "{{comment.modifiedOn}}"
          </span>
          }
          @if(comment.type === 'modification') {
          <span>
            {{'VALIDATION_MODULE_TABLE.VALUE' | translate}} "{{comment.value ? comment.value: null}}" {{'VALIDATION_MODULE_TABLE.MODIFIED' |
            translate}} {{'VALIDATION_MODULE_TABLE.BY' | translate}} "{{comment.modifiedBy}}"
            {{'VALIDATION_MODULE_TABLE.ON' | translate}} "{{comment.modifiedOn}}"
          </span>
          }
          @if(comment.type === 'note') {
          <span>
            {{'VALIDATION_MODULE_TABLE.NOTE' | translate}} "{{comment.note}}" {{'VALIDATION_MODULE_TABLE.MODIFIED' |
            translate}} {{'VALIDATION_MODULE_TABLE.BY' | translate}} "{{comment.modifiedBy}}"
            {{'VALIDATION_MODULE_TABLE.ON' | translate}} "{{comment.modifiedOn}}"
          </span>
          }
          }
        </div>
      </div>
    </div>
  </div>
</div>