import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/net-utils/network.service';
import { environment } from 'src/environments/environment';
import { UnitModel } from '../models/unit-model';
import { UnitsListMapper } from '../mappers/units-mapper';

@Injectable({
  providedIn: 'root',
})
export class UnitFilterService {
  networkService?: NetworkService;
  baseUrl = `${environment.backend.endpoint}/data-management-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getUnitsList(): Promise<UnitModel[]> {
    const unitsListMapper = new UnitsListMapper();
    await this.networkService?.get(`${this.baseUrl}/unit`, unitsListMapper);
    return unitsListMapper.unitsList;
  }
}
