import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/net-utils/network.service';
import { environment } from 'src/environments/environment';
import { getSurveyMapper } from '../mappers/get-survey-mapper';
import { SurveyModel } from '../../survey-table/models/survey-model';
import { CountrySurveyModel } from '../../survey-country-table/models/country-survey-model';
import { getCountryMapper } from '../mappers/get-country-mapper';
import { ValidationModuleListCodesModel } from '../../validation-module-list-codes/models/validation-module-list-codes-models';
import { getCodeMapper } from '../mappers/get-code-mapper';

@Injectable({
  providedIn: 'root',
})
export class ValidationModuleService {
  networkService?: NetworkService;
  validationBaseUrl = `${environment.backend.endpoint}/validation-module`;
  dataManagementBaseUrl = `${environment.backend.endpoint}/data-management-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getSurvey(surveyId: string): Promise<SurveyModel> {
    const mapper = new getSurveyMapper();
    await this.networkService?.get(
      `${this.validationBaseUrl}/survey/${surveyId}`,
      mapper
    );
    return mapper.surveyModel;
  }

  async getCountry(countryId: string): Promise<CountrySurveyModel> {
    const mapper = new getCountryMapper();
    await this.networkService?.get(
      `${this.dataManagementBaseUrl}/country/${countryId}`,
      mapper
    );
    return mapper.countryModel;
  }

  async getCode(codeId: string): Promise<ValidationModuleListCodesModel> {
    const mapper = new getCodeMapper();
    await this.networkService?.get(
      `${this.dataManagementBaseUrl}/code/${codeId}`,
      mapper
    );
    return mapper.codeModel;
  }
}
