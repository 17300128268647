import {
  Directive,
  Renderer2,
  Input,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ColumnsModel } from './models/update-country-table-models';

@Directive({
  selector: '[appResizeColumn]',
})
export class ResizeColumnDirective implements OnInit {
  @Input() columnModel?: ColumnsModel;

  @Output() resizing: EventEmitter<boolean> = new EventEmitter<boolean>();

  private startX = 0;

  private startWidth = 0;

  private column = {} as HTMLElement;

  private table = {} as HTMLElement;

  private pressed = false;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    this.column = this.el.nativeElement;
    const row = this.renderer.parentNode(this.column);
    const thead = this.renderer.parentNode(row);
    this.table = this.renderer.parentNode(thead);
    const resizer = this.renderer.createElement('div');
    this.renderer.addClass(resizer, 'resize-holder');
    this.renderer.appendChild(this.column, resizer);
    this.renderer.listen(resizer, 'mousedown', this.onMouseDown);
    this.renderer.listen(this.table, 'mousemove', this.onMouseMove);
    this.renderer.listen('document', 'mouseup', this.onMouseUp);
  }
  onMouseDown = (event: MouseEvent): void => {
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.column.offsetWidth;
  };

  onMouseMove = (event: MouseEvent): void => {
    if (this.pressed && event.buttons) {
      this.renderer.addClass(this.table, 'resizing');
      const width = this.startWidth + (event.pageX - this.startX);
      this.renderer.setStyle(this.column, 'width', `${width}px`);
      this.renderer.setStyle(this.column, 'max-width', `${width}px`);
      this.renderer.setStyle(this.column, 'min-width', `${width}px`);
      if (this.columnModel) {
        this.columnModel.width = width;
      }
      this.resizing.emit(true);
    }
  };

  onMouseUp = (): void => {
    if (this.pressed) {
      this.pressed = false;
      this.renderer.removeClass(this.table, 'resizing');
      setTimeout(() => {
        this.resizing.emit(false);
      }, 500);
    }
  };
}
