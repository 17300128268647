import { IMapper } from '../../../models/i-mapper';

export class getValidationStatusMapper implements IMapper {
  status?: string;

  fillFromJson(json: any): void {
    this.status = json.state;
  }

  fillToJson(): string {
    return '';
  }
}
