@if(loading) {
  <app-loader></app-loader>
}
<div class="step-title">
  <h3>{{'BULK_CODE_CREATE.UNITS' | translate}}</h3>
</div>
<div class="table-holder mat-elevation-z8" tabindex="0">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef onclick="this.blur()" style="width: 40px">
      </th>
      <td mat-cell *matCellDef="let element" onclick="this.blur()"
        [ngClass]="{'selected-row': selection.isSelected(element)}" style="width: 40px">
        <mat-checkbox class="no-focus-outline" (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(element) : null;" [checked]="selection.isSelected(element)"
          onclick="this.blur()">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="unitDescription">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div>{{'BULK_CODE_CREATE.UNIT_DESCRIPTION' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element" (click)="selection.toggle(element)"
        [ngClass]="{'selected-row': selection.isSelected(element)}">
        <span class="row-line">{{ element.unitDescription }}</span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
  </table>
</div>

<div class="step-buttons-container">
  <button mat-raised-button color="primary" (click)="stepper.previous()">{{'BULK_CODE_CREATE.BACK' | translate
    }}</button>
  <button mat-raised-button color="primary" (click)="stepper.next(); nextSelected.emit()">{{'BULK_CODE_CREATE.NEXT' | translate }}</button>
</div>