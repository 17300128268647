import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-code-filter',
  templateUrl: './code-filter.component.html',
  styleUrls: ['./code-filter.component.scss'],
})
export class CodeFilterComponent {
  @ViewChild('chipsFilterInputElement')
  chipsFilterInputElement!: ElementRef;
  @Output()
  codeSelectionChanged = new EventEmitter<string[]>();
  isRemovingChip = false;
  private removeChipInterval?: any;
  private removeChipTimeout?: any;
  codeFilterEntered: any[] = [];
  filters: string[] = [];
  separatorKeysCodes: number[] = [13, 32, 188];

  removeChip(item: any): void {
    this.isRemovingChip = true;
    if (this.removeChipTimeout) {
      clearTimeout(this.removeChipTimeout);
    }
    const index = this.filters.indexOf(item);
    const codeFilterIndex = this.codeFilterEntered.indexOf(item);
    if (index >= 0) {
      this.filters.splice(index, 1);
    }
    if (codeFilterIndex >= 0) {
      this.codeFilterEntered.splice(codeFilterIndex, 1);
    }
    if (this.removeChipInterval === undefined) {
      this.removeChipInterval = setInterval(() => {
        if (!this.isRemovingChip) {
          this.emitChange();
          clearInterval(this.removeChipInterval);
          this.removeChipInterval = undefined;
        }
      }, 550);
    }
    this.removeChipTimeout = setTimeout(() => {
      this.isRemovingChip = false;
    }, 500);
  }
  addChipValue(event: MatChipInputEvent): void {
    const value = event.value;
    if ((value || '').trim()) {
      this.addChip(event.value);
    }
  }

  addChip(value: string): void {
    if ((value || '').trim()) {
      if (value.includes(',')) {
        this.filters.push(...value.split(','));
        this.codeFilterEntered.push(...value.split(','));
      } else {
        this.filters.push(value.trim());
        this.codeFilterEntered.push(value.trim());
      }
    }
    this.chipsFilterInputElement.nativeElement.value = '';
    this.chipsFilterInputElement.nativeElement.focus();
    this.emitChange();
  }

  emitChange(): void {
    this.codeSelectionChanged.emit(this.codeFilterEntered);
  }
}
