import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ModifyOneRowComponent } from '../dialogues/modify-one-row/modify-one-row.component';
import { ProviderService } from '../../core/provider.service';
import { MatPaginator } from '@angular/material/paginator';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { MatSort } from '@angular/material/sort';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { debounceTime } from 'rxjs';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import {
  ConditionalFormattingComponent,
  ConditionalFormattingOperation,
  LogicalOperators,
} from '../dialogues/conditional-formatting/conditional-formatting.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelect } from '@angular/material/select';
import { Constants } from '../../models/constants';
import {
  CellHistoryModel,
  CellInfoModel,
  ColumnsModel,
  CountryYearsInfoModel,
  HouseholdDataModel,
} from '../update-country-table/models/update-country-table-models';
import { CountriesListsModel } from '../update-country-table/models/country-filters-model';
import { CellStatus } from '../update-country-table/enums/enums';
import { CustomDownloadSnackbarComponent } from '../dialogues/snackbars/custom-download-snackbar/custom-download-snackbar.component';
import { environment } from 'src/environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import { ChartOptions, ChartType } from 'chart.js';
import { UnitModel } from '../filters/unit-filter/models/unit-model';
import { CollectionModel } from '../filters/collection-filter/models/collection-model';
import { DisaggregationModel } from '../filters/disaggregation-filter/models/disaggregation-model';
import moment from 'moment';

export interface DataSourceModel {
  code: string;
  description: string;
  codeId: string;
  countryId: string;
  countryName: string;
  collectionId: string;
  collectionDesc: string;
  disaggregations1: {
    disaggregationId: string;
    disaggregationDesc: string;
  }[];
  disaggregations2: {
    disaggregationId: string;
    disaggregationDesc: string;
  }[];
  unitId: string;
  unitDesc: string;
  decimalNumber?: number;
  dataIds: { [key: string]: string | null };
  values: { [key: string]: number | null };
  published: { [key: string]: boolean };
  estimated: { [key: string]: boolean };
}

@Component({
  selector: 'app-household-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './household-view.component.html',
  styleUrls: ['./household-view.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class HouseholdViewComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  @ViewChild(MatTable)
  table!: MatTable<any>;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;
  @ViewChild(MatTable, { read: ElementRef })
  matTableRef!: ElementRef;
  @ViewChild('formElement', { read: ElementRef })
  formRef!: ElementRef;
  @ViewChild(MatSelect)
  matSelect!: MatSelect;
  @ViewChild('columnInputElement')
  columnInputElement!: ElementRef;
  columns: ColumnsModel[] = [];
  stickyColumns = [
    'actions',
    'description',
    'countryName',
    'code',
    'collectionDesc',
    'disaggregationDesc',
    'unitDesc',
  ];
  displayedColumns: string[] = [];
  tableDataSource = new MatTableDataSource<DataSourceModel>();
  countriesDataBE: HouseholdDataModel[] = [];
  countryFiltersFormGroup = new FormGroup({
    description: new FormControl(''),
    csvColumns: new FormControl([] as string[]),
    autocompleteColumn: new FormControl(''),
    fromYear: new FormControl('2010', [
      Validators.min(2010),
      Validators.max(moment().year()),
    ]),
    toYear: new FormControl((moment().year() - 1).toString(), [
      Validators.min(2010),
      Validators.max(moment().year()),
    ]),
  });
  countries: CountriesListsModel[] = [];
  loading = false;
  startYear = 2010;
  endYear = moment().year() - 1;
  yearsArray: number[] = [];
  tableDataCount = 0;
  descriptionFilterEntered = '';
  codeFilterEntered: any[] = [];
  unitsEntered: UnitModel[] = [];
  collectionsEntered: CollectionModel[] = [];
  disaggregationsEntered1: DisaggregationModel[] = [];
  disaggregationsEntered2: DisaggregationModel[] = [];
  cellInfo?: CellInfoModel;
  cellHistory: CellHistoryModel[] = [];
  cellInfoLoading = false;
  cellInfoPresent = false;
  previousCell?: DataSourceModel;
  selectedCell?: DataSourceModel;
  previousColumn: any;
  selectedColumn: any;
  showTableError = false;
  checkboxFormGroup = new FormGroup({
    published: new FormControl(false),
    estimated: new FormControl(false),
  });
  resizing = false;
  conditionThresholdValue = '';
  conditionOperation!: ConditionalFormattingOperation;
  conditions: any[] = [];
  isConditionalFormattingFilterOn = false;
  downloadTableDataLoader = false;
  logicalOperators: LogicalOperators[] = [];
  color!: string;
  downloadRawStep = 0;
  rawCountryViewDataColumns = Constants.downloadRawColumns;
  @ViewChild('publishedNote')
  publishedNoteInput!: ElementRef;
  @ViewChild('internalNote')
  internalNoteInput!: ElementRef;
  @ViewChild('sourceInput')
  sourceInput!: ElementRef;
  filters: string[] = [];
  fetchingTableDataCount = true;
  isRemovingChip = false;
  x1 = 0;
  y1 = 0;
  x2 = 0;
  y2 = 0;
  isDoingRectangularSelection = false;
  selectedStartingRow!: number;
  selectedStartingYear = this.startYear;
  selectedEndRow!: number;
  selectedEndYear = this.endYear;
  @ViewChild('rectangularSelection')
  rectangularSelection!: ElementRef;
  areCellsSelectedWithRectangularSelection = false;
  copyToClipboardDialog: any;
  columnCopyOption = false;
  codeCopyOption = false;
  copyExtraColumnsOption = false;
  contextualMenuVisible = false;
  hostElem!: any;
  contextualMenuElem!: any;
  chartDialog!: any;
  trendChartSelection = new SelectionModel<DataSourceModel>(true, []);
  trendChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  trendChartLabels: string[] = Array.from(
    { length: this.endYear - this.startYear + 1 },
    (_, index) => (this.startYear + index).toString()
  );
  previousStartYearTrendChartValue = this.startYear.toString();
  previousEndYearTrendChartValue = this.endYear.toString();
  trendChartYears = this.trendChartLabels;
  trendChartType: ChartType = 'line';
  trendChartTypeForm = new FormControl('line');
  trendChartStartYearForm = new FormControl(this.startYear.toString());
  trendChartEndYearForm = new FormControl(this.endYear.toString());
  trendChartLegend = true;
  trendChartData: any[] = [];
  _columnsHidden = false;
  changingDisplayedColumns = true;
  fromYearError = false;
  toYearError = false;
  clearFiltersEmitter = new EventEmitter();
  clearingFilters = false;
  estimateFilter: boolean | 'indeterminate' = 'indeterminate';
  publishedFilter: boolean | 'indeterminate' = 'indeterminate';

  get columnsHidden(): boolean {
    return this._columnsHidden;
  }
  set columnsHidden(value: boolean) {
    this.fillTableColumns(value);
    this.changingDisplayedColumns = true;
    this.setDisplayedColumns();
    this._columnsHidden = value;
  }
  private selectedCountries: CountriesListsModel[] = [];
  private currentCell?: CountryYearsInfoModel;
  private internalNote?: string;
  private note?: string;
  private source?: string;
  private downloadDialog: any;
  private removeChipInterval?: any;
  private removeChipTimeout?: any;

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(e: MouseEvent): void {
    if (e.button !== 2) {
      const countryViewTable = document.querySelector('table');
      const buttons = document.querySelectorAll('button');
      const dataInfo = document.querySelectorAll('.data-info');
      const copyDialog = document.querySelectorAll('.copy-to-clipboard-dialog');
      const contextualMenu = document.querySelectorAll(
        '.table-contextual-menu'
      );
      if (
        !(countryViewTable && countryViewTable.contains(e.target as Node)) &&
        !(
          copyDialog &&
          Array.from(copyDialog).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          buttons &&
          Array.from(buttons).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          dataInfo &&
          Array.from(dataInfo).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          contextualMenu &&
          Array.from(contextualMenu).some((c) => c.contains(e.target as Node))
        )
      ) {
        this.resetStartingEndRowYear();
        this.unselectCell();
      }
      if (
        !(
          contextualMenu &&
          Array.from(contextualMenu).some((c) => c.contains(e.target as Node))
        )
      ) {
        this.closeContextualMenu();
      }
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: MouseEvent): void {
    if (e.button !== 2) {
      const countryViewTable = document.querySelectorAll('.selectable-cell');
      const buttons = document.querySelectorAll('button');
      const dataInfo = document.querySelectorAll('.data-info');
      const copyDialog = document.querySelectorAll('.copy-to-clipboard-dialog');
      const contextualMenu = document.querySelectorAll(
        '.table-contextual-menu'
      );
      if (
        !(
          countryViewTable &&
          Array.from(countryViewTable).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          copyDialog &&
          Array.from(copyDialog).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          buttons &&
          Array.from(buttons).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          dataInfo &&
          Array.from(dataInfo).some((c) => c.contains(e.target as Node))
        ) &&
        !(
          contextualMenu &&
          Array.from(contextualMenu).some((c) => c.contains(e.target as Node))
        )
      ) {
        this.resetStartingEndRowYear();
        this.rectangularSelection.nativeElement.hidden = true;
        this.x1 = 0;
        this.y1 = 0;
        this.x2 = 0;
        this.y2 = 0;
        this.isDoingRectangularSelection = false;
        this.highlightCellsBehindRectangularSelection();
      }
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent): void {
    if (this.isDoingRectangularSelection) {
      if (e.buttons === 1) {
        this.rectangularSelection.nativeElement.hidden = false;
        if (this.x1 < this.x2) {
          this.x2 = e.clientX - 3;
        } else {
          this.x2 = e.clientX + 3;
        }
        this.y2 = e.clientY;
        this.reCalculateRectangle();
      }
    }
  }

  @HostListener('window:keydown.control.c', ['$event'])
  @HostListener('window:keydown.meta.c', ['$event'])
  async copyToClipboardShortcut(e: KeyboardEvent): Promise<void> {
    if (this.areCellsSelectedWithRectangularSelection) {
      e.preventDefault();
      await this.copyToClipboard();
    }
  }

  constructor(
    private providerService: ProviderService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private el: ElementRef
  ) {
    super();
  }

  get csvColumnsFormControl(): FormControl {
    return this.countryFiltersFormGroup.controls.csvColumns;
  }

  ngOnInit(): void {
    this.loading = true;
    this.getCountriesList();
    this.getTableData();
    this.countryFiltersFormGroup.controls.csvColumns.setValue([
      'dataYear',
      'code',
      'codeDesc',
      'isoCode',
      'country',
      'value',
      'publish',
      'estimate',
      'notes',
      'notesInternal',
      'source',
      'lastModifiedBy',
      'lastModifiedOn',
    ]);
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.countryFiltersFormGroup.controls.fromYear.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value) => {
          if (
            !this.countryFiltersFormGroup.controls.fromYear.valid ||
            value === null ||
            parseInt(value) > this.endYear
          ) {
            this.fromYearError = true;
            this.changeDetectorRef.detectChanges();
            return;
          }
          this.fromYearError = false;
          this.changingDisplayedColumns = true;
          this.startYear = value ? parseInt(value) : 2010;
          this.trendChartStartYearForm.setValue(this.startYear.toString());
          this.trendChartLabels = Array.from(
            { length: this.endYear - this.startYear + 1 },
            (_, index) => (this.startYear + index).toString()
          );
          this.trendChartYears = this.trendChartLabels;
          if (!this.clearingFilters) {
            this.getTableData();
          }
        })
    );
    this.subscriptions.add(
      this.countryFiltersFormGroup.controls.toYear.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value) => {
          if (
            !this.countryFiltersFormGroup.controls.toYear.valid ||
            value === null ||
            parseInt(value) < this.startYear
          ) {
            this.toYearError = true;
            this.changeDetectorRef.detectChanges();
            return;
          }
          this.toYearError = false;
          this.changingDisplayedColumns = true;
          this.endYear = value ? parseInt(value) : 2023;
          this.trendChartEndYearForm.setValue(this.endYear.toString());
          this.trendChartLabels = Array.from(
            { length: this.endYear - this.startYear + 1 },
            (_, index) => (this.startYear + index).toString()
          );
          this.trendChartYears = this.trendChartLabels;
          if (!this.clearingFilters) {
            this.getTableData();
          }
        })
    );
    this.subscriptions.add(
      this.countryFiltersFormGroup.controls.description.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value) => {
          this.descriptionFilterEntered = value ?? '';
          if (!this.clearingFilters) {
            this.getTableData();
          }
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData(true);
        }
      })
    );
    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.getTableData(true);
      })
    );
    this.trendChartTypeForm.valueChanges.subscribe(
      (value: any) => (this.trendChartType = value)
    );
    this.trendChartStartYearForm.valueChanges.subscribe((startYear: any) => {
      const endYear = this.trendChartEndYearForm.value || '';
      if (startYear > endYear) {
        this.snackBar.open('Invalid start year', 'X', {
          duration: 2000,
          panelClass: ['error-snackbar'],
        });
        this.trendChartStartYearForm.setValue(
          this.previousStartYearTrendChartValue
        );
        return;
      }
      this.trendChartLabels = this.trendChartYears.filter(
        (year) => year >= startYear && year <= endYear
      );
      this.previousStartYearTrendChartValue = startYear;
      this.calculateTrendChartData();
    });
    this.trendChartEndYearForm.valueChanges.subscribe((endYear: any) => {
      const startYear = this.trendChartStartYearForm.value || '';
      if (endYear < startYear) {
        this.snackBar.open('Invalid end year', 'X', {
          duration: 2000,
          panelClass: ['error-snackbar'],
        });
        this.trendChartEndYearForm.setValue(
          this.previousEndYearTrendChartValue
        );
        return;
      }
      this.trendChartLabels = this.trendChartYears.filter(
        (year) => year <= endYear && year >= startYear
      );
      this.previousEndYearTrendChartValue = endYear;
      this.calculateTrendChartData();
    });
  }

  columnCopyOptionChanged(checked: boolean): void {
    if (!checked) {
      this.copyExtraColumnsOption = checked;
    }
    this.columnCopyOption = checked;
  }

  clearFilters(): void {
    this.clearingFilters = true;
    this.clearFiltersEmitter.emit();
    this.paginator.pageIndex = 0;
    this.countryFiltersFormGroup.controls.description.setValue('', {
      emitEvent: false,
    });
    this.countryFiltersFormGroup.controls.fromYear.setValue('2010', {
      emitEvent: false,
    });
    this.publishedFilter = 'indeterminate';
    this.estimateFilter = 'indeterminate';
    this.countryFiltersFormGroup.controls.toYear.setValue(
      (moment().year() - 1).toString(),
      {
        emitEvent: false,
      }
    );
    setTimeout(() => {
      this.getTableData().then(() => {
        this.clearingFilters = false;
      });
    }, 550);
  }

  countriesSelectionChanged($event: CountriesListsModel[]): void {
    this.selectedCountries = $event;
    this.paginator.pageIndex = 0;
    if (!this.clearingFilters) {
      this.getTableData();
    }
  }

  codeSelectionChanged($event: string[]): void {
    this.codeFilterEntered = $event;
    this.paginator.pageIndex = 0;
    if (!this.clearingFilters) {
      this.getTableData();
    }
  }

  unitsChanged($event: UnitModel[]): void {
    this.unitsEntered = $event;
    this.paginator.pageIndex = 0;
    if (!this.clearingFilters) {
      this.getTableData();
    }
  }

  collectionsChanged($event: CollectionModel[]): void {
    this.collectionsEntered = $event;
    this.paginator.pageIndex = 0;
    if (!this.clearingFilters) {
      this.getTableData();
    }
  }

  disaggregationsChanged($event: DisaggregationModel[], level: number): void {
    if (level === 1) {
      this.disaggregationsEntered1 = $event;
    } else {
      this.disaggregationsEntered2 = $event;
    }
    this.paginator.pageIndex = 0;
    if (!this.clearingFilters) {
      this.getTableData();
    }
  }

  publishedFilterChanged(checkbox: MatCheckbox): void {
    if (!checkbox.indeterminate && checkbox.checked) {
      checkbox.checked = true;
      this.publishedFilter = true;
    } else if (!checkbox.indeterminate && !checkbox.checked) {
      checkbox.indeterminate = true;
      this.publishedFilter = 'indeterminate';
    } else if (checkbox.indeterminate && checkbox.checked) {
      checkbox.indeterminate = false;
      checkbox.checked = false;
      this.publishedFilter = false;
    }
    if (!this.clearingFilters) {
      this.getTableData();
    }
  }

  estimateFilterChanged(checkbox: MatCheckbox): void {
    if (!checkbox.indeterminate && checkbox.checked) {
      checkbox.checked = true;
      this.estimateFilter = true;
    } else if (!checkbox.indeterminate && !checkbox.checked) {
      checkbox.indeterminate = true;
      this.estimateFilter = 'indeterminate';
    } else if (checkbox.indeterminate && checkbox.checked) {
      checkbox.indeterminate = false;
      checkbox.checked = false;
      this.estimateFilter = false;
    }
    if (!this.clearingFilters) {
      this.getTableData();
    }
  }

  tableTrackBy(index: number, data: DataSourceModel): string {
    return data.countryId + data.codeId;
  }

  async getCellInfo(
    cellDataSource: DataSourceModel,
    selectedYear: number
  ): Promise<void> {
    if (
      this.selectedCell !== this.previousCell ||
      this.selectedColumn !== this.previousColumn
    ) {
      this.cellInfo = undefined;
      this.cellInfoPresent = true;
      const cellID = cellDataSource.dataIds[selectedYear];
      if (cellID && cellID.length > 0) {
        this.getCellHistory(cellID);
        this.cellInfoLoading = true;
        try {
          this.currentCell = {
            id: cellDataSource.dataIds[selectedYear]!,
            year: selectedYear,
            value: cellDataSource.values[selectedYear]?.toString(),
            published: cellDataSource.published[selectedYear],
            estimated: cellDataSource.estimated[selectedYear],
          };
          this.cellInfo =
            await this.providerService.countryTableService.getCellInfo(
              cellID,
              'household-view'
            );
          this.note = this.cellInfo.publishNotes;
          this.internalNote = this.cellInfo.internalNotes;
          this.source = this.cellInfo.source;
        } catch (e) {
          console.error(e);
        } finally {
          this.cellInfoLoading = false;
        }
        this.checkboxFormGroup.controls.estimated.setValue(
          this.cellInfo?.estimated ?? false
        );
        this.checkboxFormGroup.controls.published.setValue(
          this.cellInfo?.published ?? false
        );
      } else {
        this.currentCell = {
          id: '',
          year: selectedYear,
          value: '',
          published: false,
          estimated: false,
        };
        this.checkboxFormGroup.controls.estimated.setValue(false);
        this.checkboxFormGroup.controls.published.setValue(false);
        this.cellInfo = {
          internalNotes: '',
          publishNotes: '',
          published: false,
          estimated: false,
          source: '',
          sourceId: '',
        };
        this.cellHistory = [];
        this.cellInfoPresent = false;
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  async deleteSource(
    cellIsEmpty: boolean,
    sourceValue?: string
  ): Promise<void> {
    const deleteSourceByUser =
      sourceValue !== undefined && sourceValue.length === 0 && !cellIsEmpty;
    if (
      deleteSourceByUser &&
      this.cellInfo &&
      this.cellInfo.sourceId !== null
    ) {
      this.cellInfoLoading = true;
      const sourceId = [this.cellInfo.sourceId];
      await this.providerService.countryTableService.deleteSourceById(sourceId);
      this.cellInfoLoading = false;
    }
    if (cellIsEmpty && this.cellInfo && this.cellInfo.sourceId !== null) {
      const sourceId = [this.cellInfo.sourceId];
      await this.providerService.countryTableService.deleteSourceById(sourceId);
    }
  }

  async getCellHistory(cellId: string): Promise<void> {
    this.cellInfoLoading = true;
    try {
      this.cellHistory = (
        await this.providerService.countryTableService.getCellHistory(cellId)
      ).sort((one, two) => two.version - one.version);
      this.cellHistory.map((el) => {
        el.lastModifiedOn = this.providerService.utilService.humanReadableDate(
          el.lastModifiedOn,
          true
        );
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.cellInfoLoading = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  buildBackendData(countriesDataBE: HouseholdDataModel[]): DataSourceModel[] {
    const result: DataSourceModel[] = [];
    for (const data of countriesDataBE) {
      const dataIds: { [key: string]: string | null } = {};
      const values: { [key: string]: number | null } = {};
      const published: { [key: string]: boolean } = {};
      const estimated: { [key: string]: boolean } = {};

      for (const yearToDisplay of this.yearsArray) {
        const dataYear = data.years.find((year) => year.year === yearToDisplay);
        dataIds[yearToDisplay] = dataYear?.id ?? null;
        values[yearToDisplay] = dataYear?.value
          ? parseFloat(dataYear?.value || '0')
          : null;
        published[yearToDisplay] = dataYear?.published ?? false;
        estimated[yearToDisplay] = dataYear?.estimated ?? false;
      }
      result.push({
        code: data.code,
        description: data.description,
        codeId: data.codeId,
        countryId: data.countryId,
        countryName: data.countryName,
        collectionId: data.collectionId,
        collectionDesc: data.collectionDesc,
        disaggregations1: data.disaggregations.filter(
          (value) => value.level === 1
        ),
        disaggregations2: data.disaggregations.filter(
          (value) => value.level === 2
        ),
        unitId: data.unitId,
        unitDesc: data.unitDesc,
        decimalNumber: data.decimalNumber,
        dataIds,
        values,
        published,
        estimated,
      });
    }
    return result;
  }
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setColumnSizeOnResize(this.matTableRef.nativeElement.clientWidth);
  }

  buildArrayForYears(startYear: number, endYear: number): void {
    const array: number[] = [];
    for (let i = startYear; i <= endYear; i++) {
      array.push(i);
    }
    this.yearsArray = array;
  }

  async getCountriesList(): Promise<void> {
    try {
      this.countries = this.countries
        .concat(
          await this.providerService.regionsManagementService.getCountriesByRegions()
        )
        .sort((one, two) => (one.isoCode < two.isoCode ? -1 : 1));
    } catch (error) {
      console.error(error);
    }
  }

  setDisplayedColumns(): void {
    if (this.displayedColumns.length > 0 && !this.changingDisplayedColumns) {
      return;
    }
    this.displayedColumns = [];
    new Set(this.columns.map((value) => value.field)).forEach((c) => {
      this.displayedColumns.push(c);
    });
    this.displayedColumns =
      this.providerService.countryTableService.reorderColumns(
        this.displayedColumns
      );
    this.displayedColumns.push(
      ...this.yearsArray.map((value) => value.toString())
    );
    this.changingDisplayedColumns = false;
    this.changeDetectorRef.detectChanges();
  }

  fillTableColumns(notIncludeExtraColumns = false): void {
    this.columns = [
      {
        title: 'Actions',
        field: 'actions',
        width: 50,
        sortable: false,
        readOnly: true,
        status: CellStatus.running,
      },
      {
        title: 'Code',
        field: 'code',
        width: 50,
        sortable: true,
        readOnly: true,
        status: CellStatus.running,
      },
      {
        title: 'Description',
        field: 'description',
        width: 800,
        sortable: true,
        readOnly: true,
        status: CellStatus.running,
      },
      {
        title: 'Country Name',
        field: 'countryName',
        width: 50,
        sortable: true,
        readOnly: true,
        status: CellStatus.running,
      },
    ];
    if (!notIncludeExtraColumns) {
      this.columns.push({
        title: 'First disaggregations',
        field: 'disaggregations1',
        width: 80,
        sortable: false,
        readOnly: true,
        status: CellStatus.running,
      });
      this.columns.push({
        title: 'Second disaggregations',
        field: 'disaggregations2',
        width: 80,
        sortable: false,
        readOnly: true,
        status: CellStatus.running,
      });
      this.columns.push({
        title: 'Collection',
        field: 'collectionDesc',
        width: 80,
        sortable: false,
        readOnly: true,
        status: CellStatus.running,
      });
      this.columns.push({
        title: 'Unit',
        field: 'unitDesc',
        width: 80,
        sortable: false,
        readOnly: true,
        status: CellStatus.running,
      });
    }
  }

  async getTableCount(): Promise<void> {
    const filters = {
      codeFilter:
        this.codeFilterEntered.length === 0 ? '' : this.codeFilterEntered,
      countryCodeFilter: this.selectedCountries.map((value) => value.isoCode),
      codeDescriptionFilter:
        this.countryFiltersFormGroup.controls.description.value,
      unitFilter: this.unitsEntered.map((value) => value.unitId),
      collectionFilter: this.collectionsEntered.map(
        (value) => value.collectionID
      ),
      disaggregationFilter1: this.disaggregationsEntered1.map(
        (value) => value.disaggregationId
      ),
      disaggregationFilter2: this.disaggregationsEntered2.map(
        (value) => value.disaggregationId
      ),
      publishedFilter:
        this.publishedFilter === 'indeterminate'
          ? undefined
          : this.publishedFilter,
      estimatedFilter:
        this.estimateFilter === 'indeterminate'
          ? undefined
          : this.estimateFilter,
    };
    try {
      this.fetchingTableDataCount = true;
      this.tableDataCount =
        await this.providerService.countryTableService.householdTableCount(
          this.startYear,
          this.endYear,
          this.providerService.utilService.toBase64(JSON.stringify(filters))
        );
      this.fetchingTableDataCount = false;
    } catch (error) {
      console.error(error);
    }
    this.changeDetectorRef.detectChanges();
  }

  async updateCell(
    cellDataSource: DataSourceModel,
    selectedYear: number,
    value: number | null,
    fromEnter: boolean
  ): Promise<void> {
    const dataId = cellDataSource.dataIds[selectedYear];
    cellDataSource.values[selectedYear] = value;
    if (dataId) {
      if (
        value === null &&
        this.cellInfo &&
        cellDataSource.values[selectedYear] !== null
      ) {
        this.deleteSource(true);
        this.updatePublishedNotes('');
        this.updateInternalNotes('');
        this.estimatedChanged({ checked: false } as MatCheckboxChange);
        this.publishedChanged({ checked: false } as MatCheckboxChange);
      }
      await this.providerService.countryTableService.updateCell({
        id: dataId,
        value: value?.toString(),
      });
      if (fromEnter) {
        this.previousCell = undefined;
        this.getCellInfo(cellDataSource, selectedYear);
      }
    } else {
      if (value !== null) {
        const newDataId =
          await this.providerService.countryTableService.insertID({
            codeId: cellDataSource.codeId,
            countryId: cellDataSource.countryId,
            year: selectedYear,
            value: value,
          });
        cellDataSource.dataIds[selectedYear] = newDataId;
      }
      if (fromEnter) {
        this.previousCell = undefined;
        this.getCellInfo(cellDataSource, selectedYear);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  setColumnSizeOnResize(tableWidth: number): void {
    this.providerService.countryTableService.setTableResize(
      this.columns,
      this.displayedColumns,
      tableWidth,
      'mat-column-'
    );
  }

  openModificationDialog(cell: DataSourceModel): void {
    this.providerService.utilService.openDialog(ModifyOneRowComponent, {
      data: cell,
      years: this.yearsArray,
      callback: () => {
        this.tableDataSource = new MatTableDataSource<DataSourceModel>();
        this.getTableData();
      },
    });
  }

  openConditionalFormattingDialog(): void {
    let config = {};
    if (this.isConditionalFormattingFilterOn) {
      config = {
        conditions: this.conditions,
        logicalOperators: this.logicalOperators,
        allCountries: this.countries,
        color: this.color,
      };
    }
    this.dialog
      .open(
        ConditionalFormattingComponent,
        this.isConditionalFormattingFilterOn
          ? { data: config }
          : {
              data: {
                allCountries: this.countries,
              },
            }
      )
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.isConditionalFormattingFilterOn = true;
          this.conditions = [];
          for (const res of response.values) {
            this.conditions.push({
              conditionThresholdValue: res.inputValue,
              conditionOperation: res.conditionOperation,
              conditionCodes: res.codes,
              conditionCountries: res.countries,
            });
          }
          this.logicalOperators = response.logicalOperators;
          this.color = response.color;
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  removeCommasAndDots(numberString: string): string {
    if (numberString) {
      return numberString
        .toString()
        .replace(/,|\.00$/g, '')
        .trim();
    }
    return '';
  }

  checkConditions(value: string, cell: DataSourceModel): boolean {
    const code = cell.code;
    const country = cell.countryName;
    const conditionResults = [];
    const valueNum = parseFloat(this.removeCommasAndDots(value));
    for (const condition of this.conditions) {
      const conditionThresholdValue = parseFloat(
        condition.conditionThresholdValue
      );
      if (
        condition.conditionOperation === ConditionalFormattingOperation.EQUAL
      ) {
        conditionResults.push(
          valueNum === conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.NOT_EQUAL
      ) {
        conditionResults.push(
          valueNum !== conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      } else if (
        condition.conditionOperation === ConditionalFormattingOperation.GREATER
      ) {
        conditionResults.push(
          valueNum > conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.GREATER_OR_EQUAL
      ) {
        conditionResults.push(
          valueNum >= conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      } else if (
        condition.conditionOperation === ConditionalFormattingOperation.LESS
      ) {
        conditionResults.push(
          valueNum < conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.LESS_OR_EQUAL
      ) {
        conditionResults.push(
          valueNum <= conditionThresholdValue &&
            (condition.conditionCountries.length !== 0
              ? condition.conditionCountries.includes(country)
              : true) &&
            (condition.conditionCodes.length !== 0
              ? this.checkConditionCode(condition.conditionCodes, code)
              : true)
        );
      }
    }
    let result = conditionResults[0];
    for (let i = 0; i < this.logicalOperators.length; i++) {
      switch (this.logicalOperators[i]) {
        case LogicalOperators.AND:
          result = result && conditionResults[i + 1];
          break;
        case LogicalOperators.OR:
          result = result || conditionResults[i + 1];
          break;
        default:
          throw new Error(`Invalid operator: ${this.logicalOperators[i]}`);
      }
    }
    return (
      value !== '' &&
      value !== undefined &&
      value !== null &&
      result &&
      this.isConditionalFormattingFilterOn
    );
  }

  checkConditionCode(conditionCodes: any, code: any): boolean {
    return conditionCodes.some((conditionCode: any) => {
      if (conditionCode.includes('*')) {
        const regex = new RegExp(
          '^' + conditionCode.replace(/\*/g, '.*') + '$'
        );
        return regex.test(code);
      } else {
        return conditionCode === code;
      }
    });
  }

  clearConditionalFormattingFilter(): void {
    this.logicalOperators = [];
    this.conditions = [];
    this.color = '#ffd580';
    this.isConditionalFormattingFilterOn = false;
  }

  async updateInternalNotes(emptyNote?: string): Promise<void> {
    if (
      this.currentCell &&
      this.internalNote &&
      emptyNote === undefined &&
      this.cellInfo?.internalNotes !== this.internalNote
    ) {
      await this.providerService.countryTableService.updateInternalNotes(
        this.currentCell.id,
        this.internalNote
      );
    }
    if (
      emptyNote !== undefined &&
      this.currentCell &&
      this.cellInfo?.internalNotes !== this.internalNote
    ) {
      await this.providerService.countryTableService.updateInternalNotes(
        this.currentCell.id,
        emptyNote
      );
    }
    this.changeDetectorRef.detectChanges();
  }

  async updatePublishedNotes(emptyNote?: string): Promise<void> {
    if (
      this.currentCell &&
      this.note &&
      emptyNote === undefined &&
      this.cellInfo?.publishNotes !== this.note
    ) {
      await this.providerService.countryTableService.updatePublishedNotes(
        this.currentCell.id,
        this.note
      );
    }
    if (
      emptyNote !== undefined &&
      this.currentCell &&
      this.cellInfo?.publishNotes !== this.note
    ) {
      await this.providerService.countryTableService.updatePublishedNotes(
        this.currentCell.id,
        emptyNote
      );
    }
    this.changeDetectorRef.detectChanges();
  }

  internalNotesSelectionChanged($event: any): void {
    this.internalNote = $event.target.value;
  }

  notesSelectionChanged($event: any): void {
    this.note = $event.target.value;
  }

  sourceSelectionChanged($event: any): void {
    this.source = $event.target.value;
  }

  async updateSource(): Promise<void> {
    if (
      this.currentCell &&
      this.source &&
      this.source.length > 0 &&
      this.cellInfo?.source !== this.source
    ) {
      await this.providerService.countryTableService.updateSource(
        this.currentCell.id,
        this.source,
        this.cellInfo?.sourceId
      );
    }
  }

  async publishedChanged($event: MatCheckboxChange): Promise<void> {
    if (this.currentCell) {
      await this.providerService.countryTableService.updatePublished(
        this.currentCell.id,
        $event.checked
      );
      this.checkboxFormGroup.controls.published.setValue($event.checked);
    }
  }

  async estimatedChanged($event: MatCheckboxChange): Promise<void> {
    if (this.currentCell) {
      await this.providerService.countryTableService.updateEstimated(
        this.currentCell.id,
        $event.checked
      );
      if (this.selectedCell) {
        this.selectedCell.estimated[this.currentCell.year] = $event.checked;
      }
      this.checkboxFormGroup.controls.estimated.setValue($event.checked);
    }
  }

  setSelectedCell(element: DataSourceModel, column: any, currentTD: any): void {
    this.previousCell = this.selectedCell;
    this.previousColumn = this.selectedColumn;
    this.selectedCell = element;
    this.selectedColumn = column;
    this.changeDetectorRef.detectChanges();
    currentTD.children[0].children[0].focus();
  }

  async getTableData(ignoreCount?: boolean): Promise<void> {
    this.loading = true;
    this.showTableError = false;
    this.changeDetectorRef.detectChanges();
    try {
      const filters = {
        codeFilter:
          this.codeFilterEntered.length === 0 ? '' : this.codeFilterEntered,
        countryCodeFilter: this.selectedCountries.map((value) => value.isoCode),
        codeDescriptionFilter:
          this.countryFiltersFormGroup.controls.description.value,
        unitFilter: this.unitsEntered.map((value) => value.unitId),
        collectionFilter: this.collectionsEntered.map(
          (value) => value.collectionID
        ),
        disaggregationFilter1: this.disaggregationsEntered1.map(
          (value) => value.disaggregationId
        ),
        disaggregationFilter2: this.disaggregationsEntered2.map(
          (value) => value.disaggregationId
        ),
        publishedFilter:
          this.publishedFilter === 'indeterminate'
            ? undefined
            : this.publishedFilter,
        estimatedFilter:
          this.estimateFilter === 'indeterminate'
            ? undefined
            : this.estimateFilter,
      };

      if (!ignoreCount) {
        this.getTableCount();
      }
      this.tableDataSource.data = [];
      this.countriesDataBE =
        await this.providerService.countryTableService.getHouseholdTableData(
          this.startYear,
          this.endYear,
          this.sort.direction.toUpperCase() !== ''
            ? this.sort.active ?? 'code'
            : 'code',
          this.providerService.utilService.toBase64(JSON.stringify(filters)),
          this.sort.direction.toUpperCase() !== ''
            ? this.sort.direction.toUpperCase()
            : 'ASC',
          this.paginator.pageIndex,
          this.paginator.pageSize
        );
      if (this.countriesDataBE.length !== 0) {
        this.buildArrayForYears(this.startYear, this.endYear);
        this.fillTableColumns();
        this.setDisplayedColumns();
        this.tableDataSource.data = this.buildBackendData(this.countriesDataBE);
      }
    } catch (e) {
      console.error(e);
      this.showTableError = true;
    } finally {
      this.loading = false;
      this.changeDetectorRef.detectChanges();
      this.setFormScroll();
    }
  }

  resizingChanged(event: boolean): void {
    this.resizing = event;
    this.table.updateStickyColumnStyles();
    this.changeDetectorRef.detectChanges();
  }

  async getResultTableData(isRaw: boolean): Promise<any> {
    const filters = {
      codeFilter:
        this.codeFilterEntered.length === 0 ? '' : this.codeFilterEntered,
      countryCodeFilter: this.selectedCountries.map((value) => value.isoCode),
      codeDescriptionFilter:
        this.countryFiltersFormGroup.controls.description.value,
      unitFilter: this.unitsEntered.map((value) => value.unitId),
      collectionFilter: this.collectionsEntered.map(
        (value) => value.collectionID
      ),
      disaggregationFilter1: this.disaggregationsEntered1.map(
        (value) => value.disaggregationId
      ),
      disaggregationFilter2: this.disaggregationsEntered2.map(
        (value) => value.disaggregationId
      ),
    };
    const downloadDataParams: any = {
      filters: Object.keys(filters).length
        ? this.providerService.utilService.toBase64(JSON.stringify(filters))
        : '',
    };
    downloadDataParams.fromYear = this.startYear.toString();
    downloadDataParams.toYear = this.endYear.toString();
    if (isRaw) {
      if (this.csvColumnsFormControl.value.includes('All columns')) {
        downloadDataParams.columns = JSON.stringify(
          this.rawCountryViewDataColumns
            .map((v: any) => v.value)
            .filter((v: string) => v !== 'All columns')
        );
      } else {
        downloadDataParams.columns = JSON.stringify(
          this.csvColumnsFormControl.value
        );
      }
      const count =
        await this.providerService.countryTableService.getRawDataCountCountryUpdate(
          downloadDataParams
        );
      if (count > environment.maxRawDownloadCount) {
        throw new Error('Surpassed max count');
      }
      return await this.providerService.downloadDataService.downloadRawTableData(
        downloadDataParams,
        'household'
      );
    } else {
      if (this.tableDataCount > environment.maxDownloadCount) {
        throw new Error('Surpassed max count');
      }
      downloadDataParams.sortCol =
        this.sort.direction.toUpperCase() !== ''
          ? this.sort.active ?? 'code'
          : 'code';
      downloadDataParams.sortType =
        this.sort.direction.toUpperCase() !== ''
          ? this.sort.direction.toUpperCase()
          : 'ASC';
      return await this.providerService.downloadDataService.downloadTableData(
        downloadDataParams,
        'household'
      );
    }
  }

  async downloadShowedRawTableData(): Promise<void> {
    try {
      this.downloadRawStep = 1;
      try {
        await this.getResultTableData(true);
      } catch (e) {
        this.downloadDialog?.close();
        this.downloadTableDataLoader = false;
        this.snackBar.open(
          'You are trying to download more data than is permitted, you might have too many filters on',
          'X',
          {
            duration: 10000,
            panelClass: ['error-snackbar'],
          }
        );
        return;
      }
      this.downloadDialog.close();
      if (this.downloadTableDataLoader) {
        this.snackBar.openFromComponent(CustomDownloadSnackbarComponent, {
          duration: 10000,
          panelClass: ['custom-download-snackbar'],
        });
        this.downloadDialog?.close();
      }
      this.downloadTableDataLoader = false;
    } catch (e) {
      this.downloadDialog?.close();
      this.downloadTableDataLoader = false;
      this.snackBar.open('An error occured while downloading the file', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.downloadRawStep = 0;
    }
  }

  async downloadShowedTableData(dialogRef: any, isRaw: boolean): Promise<any> {
    try {
      this.downloadTableDataLoader = true;
      this.downloadDialog = this.dialog.open(dialogRef, {
        width: '500px',
        disableClose: true,
        autoFocus: false,
        data: { isRaw },
      });
      if (!isRaw) {
        try {
          await this.getResultTableData(isRaw);
        } catch (e) {
          this.downloadDialog?.close();
          this.downloadTableDataLoader = false;
          this.snackBar.open(
            'You are trying to download more data than is permitted, you might have too many filters on',
            'X',
            {
              duration: 10000,
              panelClass: ['error-snackbar'],
            }
          );
          return;
        }
        this.snackBar.openFromComponent(CustomDownloadSnackbarComponent, {
          duration: 10000,
          panelClass: ['custom-download-snackbar'],
        });
        this.downloadDialog?.close();
        this.downloadTableDataLoader = false;
      }
    } catch (e) {
      this.downloadDialog?.close();
      this.downloadTableDataLoader = false;
      this.snackBar.open('An error occured while downloading the file', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  cancelDownload(): void {
    this.downloadTableDataLoader = false;
  }

  visuallyFilterColumns(column: string): boolean {
    const countryName = column.toLowerCase();
    const input = (
      this.countryFiltersFormGroup.controls.autocompleteColumn.value || ''
    ).toLowerCase();
    return input === '' || countryName.includes(input);
  }

  allColumnsSelected(): void {
    this.csvColumnsFormControl.setValue(
      this.csvColumnsFormControl.value.filter(
        (v: string) => v === 'All columns'
      )
    );
  }

  otherColumnSelected(): void {
    this.csvColumnsFormControl.setValue(
      this.csvColumnsFormControl.value.filter(
        (v: string) => v !== 'All columns'
      )
    );
  }

  onOpenedColumnChange(isOpened: boolean): void {
    if (isOpened) {
      this.columnInputElement.nativeElement.focus();
    }
  }

  removeColumnFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.csvColumnsFormControl.setValue([]);
    this.countryFiltersFormGroup.controls.autocompleteColumn.setValue('');
    this.columnInputElement.nativeElement.focus();
  }

  displayMultipleColumnFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0 ? (result = `${v}`) : (result = `${result}, ${v}`);
    }
    return result;
  }

  displayMultipleDisaggregationsFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value.filter(
      (diss: any) => diss.disaggregationId !== null
    )) {
      index++ === 0
        ? (result = `${v.disaggregationDesc}`)
        : (result = `${result}, ${v.disaggregationDesc}`);
    }
    return result;
  }

  remove(item: any): void {
    this.isRemovingChip = true;
    if (this.removeChipTimeout) {
      clearTimeout(this.removeChipTimeout);
    }
    const index = this.filters.indexOf(item);
    const codeFilterIndex = this.codeFilterEntered.indexOf(item);
    if (index >= 0) {
      this.filters.splice(index, 1);
    }
    if (codeFilterIndex >= 0) {
      this.codeFilterEntered.splice(codeFilterIndex, 1);
    }
    if (this.removeChipInterval === undefined) {
      this.removeChipInterval = setInterval(() => {
        if (!this.isRemovingChip) {
          this.getTableData();
          clearInterval(this.removeChipInterval);
          this.removeChipInterval = undefined;
        }
      }, 550);
    }
    this.removeChipTimeout = setTimeout(() => {
      this.isRemovingChip = false;
    }, 500);
  }

  private setFormScroll(): void {
    this.formRef.nativeElement.scrollLeft =
      this.formRef.nativeElement.scrollWidth -
      this.formRef.nativeElement.clientWidth;
  }

  reCalculateRectangle(): void {
    const x3 = Math.min(this.x1, this.x2);
    const x4 = Math.max(this.x1, this.x2);
    const y3 = Math.min(this.y1, this.y2);
    const y4 = Math.max(this.y1, this.y2);
    this.rectangularSelection.nativeElement.style.left = x3 + 'px';
    this.rectangularSelection.nativeElement.style.top = y3 + 'px';
    this.rectangularSelection.nativeElement.style.width = x4 - x3 + 'px';
    this.rectangularSelection.nativeElement.style.height = y4 - y3 + 'px';
  }

  resetStartingEndRowYear(): void {
    this.selectedStartingRow = 0;
    this.selectedStartingYear = this.startYear;
    this.selectedEndRow = this.tableDataSource.filteredData.length || 25;
    this.selectedEndYear = this.endYear;
    this.areCellsSelectedWithRectangularSelection = false;
  }
  openCopyToClipboardDialog(dialogRef: any): void {
    this.copyToClipboardDialog = this.dialog.open(dialogRef, {
      width: '400px',
      disableClose: true,
      autoFocus: false,
    });
  }

  async copyToClipboard(): Promise<void> {
    if (this.selectedStartingRow > this.selectedEndRow) {
      const oldStartingRow = this.selectedStartingRow;
      this.selectedStartingRow = this.selectedEndRow;
      this.selectedEndRow = oldStartingRow;
    }
    if (this.selectedStartingYear > this.selectedEndYear) {
      const oldStartingYear = this.selectedStartingYear;
      this.selectedStartingYear = this.selectedEndYear;
      this.selectedEndYear = oldStartingYear;
    }
    let copiedCliboardString = '';
    const rowsNum = this.tableDataSource.filteredData.length;
    // Displayed columns includes 4 static columns (actions, code, description, country name) that are not needed
    const columnsNum = this.displayedColumns.length - 4;
    // Add the columns names
    if (this.columnCopyOption) {
      if (this.codeCopyOption) {
        copiedCliboardString = copiedCliboardString.concat(
          'Code\tDescription\tCountry\t'
        );
      }
      if (this.copyExtraColumnsOption) {
        copiedCliboardString = copiedCliboardString.concat(
          'First disaggregation\tSecond disaggregation\tCollection\tUnit\t'
        );
      }
      for (let column = 0; column < columnsNum; column++) {
        if (
          column + this.startYear >= this.selectedStartingYear &&
          column + this.startYear < this.selectedEndYear
        ) {
          copiedCliboardString = copiedCliboardString.concat(
            `${column + this.startYear}\t`
          );
        } else if (column + this.startYear === this.selectedEndYear) {
          copiedCliboardString = copiedCliboardString.concat(
            `${column + this.startYear}`
          );
        }
      }
      copiedCliboardString = copiedCliboardString.concat('\n');
    }
    for (let row = 0; row < rowsNum; row++) {
      if (row >= this.selectedStartingRow && row <= this.selectedEndRow) {
        if (this.codeCopyOption) {
          copiedCliboardString = copiedCliboardString.concat(
            `${this.tableDataSource.filteredData[row].code}\t${this.tableDataSource.filteredData[row].description}\t${this.tableDataSource.filteredData[row].countryName}\t`
          );
        }
        if (this.copyExtraColumnsOption) {
          copiedCliboardString = copiedCliboardString.concat(
            `${this.tableDataSource.filteredData[row].disaggregations1}\t${
              this.tableDataSource.filteredData[row].disaggregations2
            }\t${
              this.tableDataSource.filteredData[row].collectionDesc ?? ''
            }\t${this.tableDataSource.filteredData[row].unitDesc}\t`
          );
        }
        const yearsWithValues = this.tableDataSource.filteredData[row].values;
        let currentYear = this.startYear;
        for (let column = 0; column < columnsNum; column++) {
          if (
            column + this.startYear >= this.selectedStartingYear &&
            column + this.startYear <= this.selectedEndYear
          ) {
            const currentYearCellInfo = yearsWithValues[currentYear];
            if (currentYearCellInfo) {
              if (currentYear !== this.selectedEndYear) {
                copiedCliboardString = copiedCliboardString.concat(
                  `${currentYearCellInfo}\t`
                );
              } else {
                copiedCliboardString = copiedCliboardString.concat(
                  `${currentYearCellInfo}`
                );
              }
            } else {
              copiedCliboardString = copiedCliboardString.concat('\t');
            }
          }
          currentYear++;
        }
        if (row !== this.selectedEndRow) {
          copiedCliboardString = copiedCliboardString.concat('\n');
        }
      }
    }
    await navigator.clipboard.writeText(copiedCliboardString);
    this.snackBar.open('Content copied to clipboard', 'X', {
      duration: 3000,
      panelClass: ['success-snackbar'],
    });
  }

  downEventOnCell(e: any, row: any, column: any): void {
    if (e.button !== 2) {
      this.isDoingRectangularSelection = true;
      this.x1 = e.clientX;
      this.y1 = e.clientY;
      this.reCalculateRectangle();
      this.selectedStartingRow = row;
      this.selectedStartingYear = parseInt(column);
    }
  }

  upEventOnCell(e: any, row: any, column: any): void {
    if (e.button !== 2) {
      this.selectedEndRow = row;
      this.selectedEndYear = parseInt(column);
      this.highlightCellsBehindRectangularSelection();
      this.rectangularSelection.nativeElement.hidden = true;
      this.x1 = 0;
      this.y1 = 0;
      this.x2 = 0;
      this.y2 = 0;
      this.isDoingRectangularSelection = false;
      this.areCellsSelectedWithRectangularSelection = !(
        this.selectedStartingRow === this.selectedEndRow &&
        this.selectedStartingYear === this.selectedEndYear
      );
    }
  }

  isOverlapping(el1: HTMLElement, el2: HTMLElement): boolean {
    const rect1 = el1.getBoundingClientRect();
    const rect2 = el2.getBoundingClientRect();
    return !(
      rect1.top > rect2.bottom ||
      rect1.right < rect2.left ||
      rect1.bottom < rect2.top ||
      rect1.left > rect2.right
    );
  }

  highlightCellsBehindRectangularSelection(): void {
    const overlay = document.querySelector('#rectangular-div') as HTMLElement;
    const cells = document.querySelectorAll('td');
    cells.forEach((cell) => {
      if (this.isOverlapping(overlay, cell as HTMLElement)) {
        this.unselectCell();
        cell.classList.add('rectangular-selected');
      } else {
        cell.classList.remove('rectangular-selected');
      }
    });
  }

  unselectCell(): void {
    this.selectedCell = undefined;
    this.cellInfoPresent = false;
    this.publishedNoteInput.nativeElement.value = '';
    this.internalNoteInput.nativeElement.value = '';
    this.sourceInput.nativeElement.value = '';
  }

  formatDecimalDigits(value: any, numDigits: number): string {
    if (value) {
      const cleanValue = value.toString().replace(/,/g, '');
      const fixedValue = parseFloat(cleanValue).toFixed(numDigits);
      const parts = fixedValue.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    } else {
      return '';
    }
  }

  openContextualMenu(
    event: MouseEvent,
    menuTemplate: TemplateRef<any>,
    cell: any,
    column: any,
    currentTD: any,
    field: any,
    row: any
  ): void {
    event.preventDefault();
    this.closeContextualMenu();
    if (
      this.cellInfoPresent ||
      (!this.cellInfoPresent && !this.areCellsSelectedWithRectangularSelection)
    ) {
      this.setSelectedCell(cell, column, currentTD);
      this.getCellInfo(cell, field);
      this.selectedStartingRow = row;
      this.selectedStartingYear = parseInt(field);
      this.selectedEndRow = row;
      this.selectedEndYear = parseInt(field);
    }
    this.contextualMenuVisible = true;
    this.hostElem = this.el.nativeElement;
    this.contextualMenuElem = this.renderer.createElement('div');
    this.renderer.appendChild(this.hostElem, this.contextualMenuElem);
    const view = menuTemplate.createEmbeddedView(null);
    this.contextualMenuElem.appendChild(view.rootNodes[0]);
    this.renderer.setStyle(this.contextualMenuElem, 'position', 'absolute');
    this.renderer.setStyle(
      this.contextualMenuElem,
      'top',
      `${event.clientY}px`
    );
    this.renderer.setStyle(
      this.contextualMenuElem,
      'left',
      `${event.clientX}px`
    );
  }

  closeContextualMenu(): void {
    this.contextualMenuVisible = false;
    if (this.hostElem && this.contextualMenuElem) {
      this.renderer.removeChild(this.hostElem, this.contextualMenuElem);
    }
  }

  opendTrendChart(dialogRef: any): void {
    this.calculateTrendChartData();
    this.chartDialog = this.dialog.open(dialogRef, {
      width: '1200px',
      autoFocus: false,
    });
  }

  calculateTrendChartData(): void {
    const chartData: any[] = [];
    this.trendChartSelection.selected.forEach((row) => {
      const originalValues = row.values;
      const data = Object.keys(originalValues)
        .filter((key) => this.trendChartLabels.includes(key))
        .sort(
          (a, b) =>
            this.trendChartLabels.indexOf(a) - this.trendChartLabels.indexOf(b)
        )
        .filter((key) => key in originalValues)
        .map((key) => originalValues[key]);
      const newChartData = {
        label: row.countryName + ' (' + row.code + ')',
        data,
      };
      chartData.push(newChartData);
    });
    this.trendChartData = chartData;
  }

  exportTrendChartToImage(): void {
    const originalCanvas = document.getElementsByTagName('canvas')[0];
    const originalCtx = originalCanvas.getContext('2d');
    if (originalCtx) {
      const newCanvas = document.createElement('canvas');
      newCanvas.width = originalCanvas.width;
      newCanvas.height = originalCanvas.height;
      const newCtx = newCanvas.getContext('2d');
      if (newCtx) {
        newCtx.fillStyle = 'white';
        newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);
        newCtx.drawImage(originalCanvas, 0, 0);
        const canvasChart = newCanvas.toDataURL('image/jpeg');
        const link = document.createElement('a');
        link.href = canvasChart;
        link.download = 'trend_chart.jpeg';
        link.click();
        this.snackBar.open('Trend chart downloaded!', 'X', {
          duration: 3000,
          panelClass: ['success-snackbar'],
        });
      }
    }
  }

  validateYearFormDigits(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }
}
