<div class="sign-in-container">
  <div class="sign-in-logo-container">
    <img class="logo" src="assets/images/logo.svg" title="logo" alt="logo" />
  </div>
  <div class="sign-in-form-container">

    <h1>{{'SIGN_UP.SIGN_UP' | translate}}</h1>
    <h3>{{'SIGN_UP.PLEASE_REGISTER' | translate}}</h3>

    <div class="auth-form">
      <form [formGroup]="form">
        @if (!isConfirm) {
          <div>
            <mat-form-field appearance="fill">
              <mat-label>{{'SIGN_UP.EMAIL_LABEL' | translate}}</mat-label>
              <input matInput type="email" id="email" name="email" formControlName="email" placeholder="{{'SIGN_UP.EMAIL_PLACEHOLDER' | translate}}">
              <i class="fa fa-regular fa-envelope" matSuffix></i>
              <mat-hint>{{'SIGN_UP.EMAIL_HINT' | translate}}</mat-hint>
            </mat-form-field>
            <app-validator-ui [form]="form" [name]="'email'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'SIGN_UP.EMAIL_ERROR' | translate}}"></app-validator-ui>
            <mat-form-field appearance="fill">
              <mat-label>{{'SIGN_UP.PASSWORD_LABEL' | translate}}</mat-label>
              <input matInput [type]="showPassword ? 'text' : 'password'" id="password" name="password" formControlName="password" (keydown.enter)="signUp()">
              <button class="transparent" matSuffix type="button"  (click)="showPassword = !showPassword">
                <i class="fa-regular {{ showPassword ? 'fa-eye-slash' : 'fa-eye' }}"></i>
              </button>
              <mat-hint>{{'SIGN_UP.PASSWORD_HINT' | translate}}</mat-hint>
            </mat-form-field>
            <app-validator-ui
              [forceShow]="true"
              [form]="form"
              [name]="'password'"
              [icon]="'fa-regular fa-circle-exclamation'"
              [iconSuccess]="'fa-regular fa-shield-check'"
              message="{{'SIGN_UP.PASSWORD_MIN_LENGTH' | translate}}"
            [errorCode]="'minlength'"></app-validator-ui>
            <app-validator-ui
              [forceShow]="true"
              [form]="form"
              [name]="'password'"
              [icon]="'fa-regular fa-circle-exclamation'"
              [iconSuccess]="'fa-regular fa-shield-check'"
              message="{{'SIGN_UP.PASSWORD_UPPERCASE' | translate}}"
            [errorCode]="'hasCapitalCase'"></app-validator-ui>
            <app-validator-ui
              [forceShow]="true"
              [form]="form"
              [name]="'password'"
              [icon]="'fa-regular fa-circle-exclamation'"
              [iconSuccess]="'fa-regular fa-shield-check'"
              message="{{'SIGN_UP.PASSWORD_LOWERCASE' | translate}}"
            [errorCode]="'hasSmallCase'"></app-validator-ui>
            <app-validator-ui
              [forceShow]="true"
              [form]="form"
              [name]="'password'"
              [icon]="'fa-regular fa-circle-exclamation'"
              [iconSuccess]="'fa-regular fa-shield-check'"
              message="{{'SIGN_UP.PASSWORD_NUMBER' | translate}}"
            [errorCode]="'hasNumber'"></app-validator-ui>
            <app-validator-ui
              [forceShow]="true"
              [form]="form"
              [name]="'password'"
              [icon]="'fa-regular fa-circle-exclamation'"
              [iconSuccess]="'fa-regular fa-shield-check'"
              message="{{'SIGN_UP.PASSWORD_SPECIAL_CHARACTER' | translate}}"
            [errorCode]="'hasSpecialCharacters'"></app-validator-ui>
            <button color="primary" mat-raised-button type="button" (click)="signUp()" [disabled]="loading">
              @if (loading) {
                <span class="fa fa-spin fa-spinner" role="status" aria-hidden="true"></span>
              }
              <span>{{'SIGN_UP.SIGN_UP' | translate}}</span>
            </button>
          </div>
        }

        @if (isConfirm) {
          <div class="card-body">
            <mat-form-field appearance="fill">
              <mat-label>{{'SIGN_UP.CONFIRMATION_CODE_LABEL' | translate}}</mat-label>
              <input matInput type="text" id="code" name="code" formControlName="code" placeholder="{{'SIGN_UP.CONFIRMATION_CODE_PLACEHOLDER' | translate}}">
              <i class="fa fa-regular fa-envelope" matSuffix></i>
              <mat-hint>{{'SIGN_UP.CONFIRMATION_CODE_HINT' | translate}}</mat-hint>
            </mat-form-field>
            <app-validator-ui [form]="form" [name]="'code'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'SIGN_UP.CONFIRMATION_CODE_ERROR' | translate}}"></app-validator-ui>
            <button color="primary" mat-raised-button type="button" class="confirm-button" (click)="confirmSignUp()" [disabled]="loading">
              @if (loading) {
                <span class="fa fa-spin fa-spinner" role="status" aria-hidden="true"></span>
              }
              <span>{{'SIGN_UP.CONFIRM' | translate}}</span>
            </button>
          </div>
        }
      </form>
      <br>
        <br>
          <span>{{'SIGN_UP.ALREADY_REGISTERED_MESSAGE' | translate}} <a (click)="navigateToSignIn()">{{'SIGN_UP.HERE' | translate}}</a></span>
        </div>
      </div>
    </div>


