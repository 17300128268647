import { IMapper } from '../../../models/i-mapper';
import { SurveyModel, SurveyResponseModel } from '../models/survey-model';

export class SurveyMapper implements IMapper {
  surveyList?: SurveyResponseModel;
  fillFromJson(json: any): void {
    const data: SurveyModel[] = [];
    json.data.forEach((survey: any) => {
      data.push({
        oldSurveyId: survey.oldSurveyId,
        surveyId: survey.surveyId,
        surveyName: survey.surveyName,
        surveyDescription: survey.surveyDescription,
        surveyYear: survey.surveyYear,
      });
    });
    this.surveyList = {
      data: data,
      total: json.total,
    };
  }
  fillToJson(): string {
    return '';
  }
}
