import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ValidationModuleListCodesModel } from '../validation-module-list-codes/models/validation-module-list-codes-models';
import { SurveyModel } from '../survey-table/models/survey-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ValidationModuleCodeModel } from './models/validation-module-code-model';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { ProviderService } from 'src/app/core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  startWith,
} from 'rxjs';
import { ValidationModuleTableCountryData } from './models/validation-module-code-table-model';
import { SurveyValidationStatusEnum } from '../survey-country-table/enums/survey-validation-status-enum';
import { ValidationCellHistoryElaborated } from '../validation-module-country-table/models/validation-cell-history-elaborated';
import { ValidationModuleTableCodeData } from '../validation-module-country-table/models/validation-module-country-table-model';
import { CountriesListsModel } from '../update-country-table/models/country-filters-model';

@Component({
  selector: 'app-validation-module-code-table',
  templateUrl: './validation-module-code-table.component.html',
  styleUrls: ['./validation-module-code-table.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class ValidationModuleCodeTableComponent extends AntiMemLeak implements AfterViewInit {
  @Input({ required: true }) selectedSurvey!: SurveyModel;
  @Input({ required: true }) selectedCode!: ValidationModuleListCodesModel;
  @Input() selectedCodeChanged?: Observable<ValidationModuleListCodesModel>;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;

  loading = true;
  tableCount = 0;
  selectedCountries: CountriesListsModel[] = [];
  dataSource = new MatTableDataSource<ValidationModuleTableCountryData>();
  originalData: ValidationModuleTableCountryData[] = [];
  displayedColumns?: string[];

  filterFormGroup = new FormGroup({
    percentageChange: new FormControl(null as unknown as number),
    yearFrom: new FormControl(moment().year() - 3),
    yearTo: new FormControl(moment().year() - 1),
  });
  yearsToDisplay: string[] = [];
  previousCell?: { cell: ValidationModuleTableCountryData; year: string };
  selectedCell?: { cell: ValidationModuleTableCountryData; year: string };
  historyLoading = false;
  cellHistory: ValidationCellHistoryElaborated[] = [];
  protected readonly SurveyValidationStatusEnum = SurveyValidationStatusEnum;

  get selectedCellHasId(): boolean {
    return !!this.selectedCell?.cell?.dataIds[this.selectedCell?.year ?? ''];
  }

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.filterFormGroup.controls.yearFrom.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.setDisplayColumns();
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterFormGroup.controls.yearTo.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.setDisplayColumns();
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterFormGroup.controls.percentageChange.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData();
        }
      })
    );
    if (this.selectedCodeChanged) {
      this.subscriptions.add(
        this.selectedCodeChanged.subscribe((value) => {
          this.selectedCode = value;
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
      );
    }
    this.setDisplayColumns();
    this.getTableData();
  }

  groupHeaderClick(item: any): void {
    item.expanded = !item.expanded;
    this.dataSource.filter = Date.now().toString();
  }

  countryFilterChanged($event: CountriesListsModel[]): void {
    this.selectedCountries = $event;
    this.getTableData();
  }

  enforceMinMax(el: any): void {
    const srcElement = el.srcElement;
    if (srcElement.value != '') {
      if (parseInt(srcElement.value) < parseInt(srcElement.min)) {
        srcElement.value = srcElement.min;
      }
      if (parseInt(srcElement.value) > parseInt(srcElement.max)) {
        srcElement.value = srcElement.max;
      }
    }
  }

  async setCellValue(
    element: ValidationModuleTableCodeData,
    year: string,
    $event: number | null
  ): Promise<void> {
    if (element.dataIds[year]) {
      element.values[year] = $event;
      this.updateCell(element.dataIds[year] ?? '', $event, element.notes[year]);
    } else {
      if (this.selectedCell && $event) {
        const cellId = await this.insertCell(
          this.selectedCell.cell,
          Number(this.selectedCell.year),
          $event
        );
        element.dataIds[this.selectedCell.year] = cellId;
        this.getCellHistory(cellId);
      }
    }
    const nextYear = Number(year) + 1;
    const previousYear = Number(year) - 1;
    if ($event) {
      if (element.values[previousYear] !== null) {
        element.percentages[year] = this.calcPercentage(
          $event,
          element.values[previousYear]!
        );
      }
      if (element.values[nextYear] !== null) {
        element.percentages[year + 1] = this.calcPercentage(
          element.values[nextYear]!,
          $event
        );
      }
    } else {
      if (element.values[previousYear] !== null) {
        element.percentages[year] = null;
      }
      if (element.values[nextYear] !== null) {
        element.percentages[year + 1] = null;
      }
    }
  }

  setSelectedCell(cell: ValidationModuleTableCountryData, year: string): void {
    this.selectedCell = {
      cell,
      year,
    };
    if (
      JSON.stringify(this.previousCell) !== JSON.stringify(this.selectedCell)
    ) {
      this.previousCell = this.selectedCell;
      const dataId = cell.dataIds[year];
      if (dataId) {
        this.getCellHistory(dataId);
      } else {
        this.cellHistory = [];
      }
    }
  }

  isCellSelected(
    cell: ValidationModuleTableCountryData,
    year: string
  ): boolean {
    return this.selectedCell?.cell === cell && this.selectedCell?.year === year;
  }

  async getCellHistory(cellId: string): Promise<void> {
    this.historyLoading = true;
    try {
      const rawCellHistory =
        await this.providerService.validationModuleCountryService.getCellHistory(
          cellId
        );
      this.cellHistory = this.providerService.validationModuleCountryService
        .elaborateCellHistory(rawCellHistory)
        .sort((a, b) => b.order - a.order);
    } catch (e) {
      this.snackBar.open(
        'An error occured while retrieving the cell history',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.historyLoading = false;
    }
  }

  calcPercentage(x: number, y: number, fixed = 2): number | null {
    const percent = ((x - y) / y) * 100;

    if (!isNaN(percent)) {
      return Number(percent.toFixed(fixed));
    } else {
      return null;
    }
  }

  private async insertCell(
    cell: ValidationModuleTableCountryData,
    year: number,
    value: number | null
  ): Promise<string> {
    try {
      return await this.providerService.validationModuleCountryService.insertCellValue(
        this.selectedSurvey.surveyId,
        cell.countryId,
        Number(year),
        this.selectedCode.codeId,
        cell.checked !== 'indeterminate' ? cell.checked : false,
        value,
        cell.notes[year]
      );
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while updating the cell value',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    }
    return '';
  }

  private async updateCell(
    cellId: string,
    value: number | null,
    note: string | null
  ): Promise<void> {
    try {
      await this.providerService.validationModuleCountryService.updateValidationModuleCell(
        this.selectedSurvey.surveyId,
        cellId,
        value,
        note
      );
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while updating the cell value',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    }
  }

  private setDisplayColumns(): void {
    const yearFrom = this.filterFormGroup.controls.yearFrom.value ?? 0;
    const yearTo = this.filterFormGroup.controls.yearTo.value ?? 0;

    this.yearsToDisplay = Array.from(
      { length: yearTo - yearFrom + 1 },
      (_, i) => (yearFrom + i).toString()
    );

    this.displayedColumns = [
      'countryName',
      ...this.yearsToDisplay,
      ...this.yearsToDisplay.map((value) => value + ' %'),
      'validationStatus',
      'actions',
    ];
  }

  private async getTableData(): Promise<void> {
    this.loading = true;
    try {
      const selectedCountries = this.selectedCountries.map(
        (value) => value.countryID
      );
      const backendData =
        await this.providerService.validationModuleCodeService.getValidationModuleCodeTableListAndCount(
          this.selectedSurvey.surveyId,
          selectedCountries.length > 0 ? selectedCountries : undefined,
          this.filterFormGroup.controls.percentageChange.value ?? undefined,
          this.selectedCode.codeId,
          this.filterFormGroup.controls.yearFrom.value ?? moment().year() - 3,
          this.filterFormGroup.controls.yearTo.value ?? moment().year() - 1,
          this.paginator.pageIndex,
          this.paginator.pageSize
        );
      const elaboratedData = this.convertToValidationModuleTableData(
        backendData.data
      );
      this.originalData = JSON.parse(JSON.stringify(elaboratedData));
      this.dataSource.data = elaboratedData;
      this.tableCount = backendData.total;
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  convertToValidationModuleTableData(
    response: ValidationModuleCodeModel[]
  ): ValidationModuleTableCountryData[] {
    const tableData: ValidationModuleTableCountryData[] = [];

    for (const data of response) {
      const dataIds: { [key: string]: string | null } = {};
      const values: { [key: string]: number | null } = {};
      const percentages: { [key: string]: number | null } = {};
      const sources: { [key: string]: string | null } = {};
      const notes: { [key: string]: string | null } = {};
      let checked: boolean | 'indeterminate' = false;
      if (data.years.every((value) => value.checked === true)) {
        checked = true;
      } else if (data.years.every((value) => value.checked === false)) {
        checked = false;
      } else {
        checked = 'indeterminate';
      }
      for (const yearToDisplay of this.yearsToDisplay) {
        const dataYear = data.years.find(
          (year) => year.year === Number(yearToDisplay)
        );
        dataIds[yearToDisplay] = dataYear?.dataId ?? null;
        values[yearToDisplay] = dataYear?.value
          ? parseFloat(dataYear?.value)
          : null;
        percentages[yearToDisplay] =
          dataYear?.percentageChange !== null &&
          dataYear?.percentageChange !== undefined
            ? parseFloat(dataYear?.percentageChange)
            : null;
        sources[yearToDisplay] = dataYear?.source ? dataYear?.source : null;
        notes[yearToDisplay] = dataYear?.note ? dataYear?.note : null;
      }
      tableData.push({
        checked,
        countryId: data.countryId,
        countryName: data.countryName,
        countryIso: data.countryIso,
        validationStatus: data.validationStatus,
        dataIds,
        values,
        percentages,
        sources,
        notes,
      });
    }

    return tableData;
  }

  async startValidation(row: ValidationModuleTableCountryData): Promise<void> {
    this.loading = true;
    try {
      await this.providerService.surveyCountryTableService.startCountryValidation(
        this.selectedSurvey.surveyId,
        row.countryId
      );
      this.getTableData();
    } catch (e) {
      console.error(e);
      this.snackBar.open('An error occured while starting the survey', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.loading = false;
    }
  }

  notesSelectionChanged(event: any): void {
    const value = event.target.value;
    if (value) {
      if (value !== this.selectedCell!.cell.notes[this.selectedCell!.year]) {
        this.selectedCell!.cell.notes[this.selectedCell!.year] = value;
      }
    }
  }

  async updateNotes(note?: string): Promise<void> {
    if (
      this.selectedCell &&
      this.selectedCell.cell.dataIds[this.selectedCell.year]
    ) {
      const originalDataPoint = this.originalData.find(
        (value) => value.countryId === this.selectedCell!.cell.countryId
      );
      const selectedNote =
        note === undefined
          ? this.selectedCell.cell.notes[this.selectedCell.year]
          : '';
      if (selectedNote !== originalDataPoint?.notes[this.selectedCell.year]) {
        await this.updateCell(
          this.selectedCell.cell.dataIds[this.selectedCell.year]!,
          this.selectedCell.cell.values[this.selectedCell.year]!,
          selectedNote
        );
        this.getCellHistory(
          this.selectedCell.cell.dataIds[this.selectedCell.year]!
        );
        if (originalDataPoint) {
          originalDataPoint.notes[this.selectedCell.year] = selectedNote;
        }
      }
    }
  }
}
