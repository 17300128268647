import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProviderService } from '../../core/provider.service';
import { CountriesListsModel } from '../update-country-table/models/country-filters-model';
import { distinctUntilChanged } from 'rxjs/operators';
import { debounceTime } from 'rxjs';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { MatSelect } from '@angular/material/select';
import { Constants } from '../../models/constants';

export enum DownloadViewType {
  CountryView = 'countryView',
  HouseholdView = 'householdView',
}

@Component({
  selector: 'app-download-module',
  templateUrl: './download-module.component.html',
  styleUrls: ['./download-module.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class DownloadModuleComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  downloadFormGroup = new FormGroup({
    fromYear: new FormControl('', [Validators.min(1900), Validators.max(2999)]),
    toYear: new FormControl('', [Validators.min(1900), Validators.max(2999)]),
    codeFilter: new FormControl(''),
    descriptionFilter: new FormControl(''),
    countryNameFilter: new FormControl([]),
    dataFormat: new FormControl('', Validators.required),
    sortColumn: new FormControl(''),
    sortType: new FormControl(''),
    csvColumns: new FormControl([], Validators.required),
    autocompleteCountry: new FormControl(''),
    autocompleteColumn: new FormControl(''),
  });
  showYearsAsColumnsOptions = false;
  showError = false;
  downloadTableDataLoader = false;
  countries: CountriesListsModel[] = [];
  filteredCountries: CountriesListsModel[] = [];
  selectedDownloadViewType: DownloadViewType | undefined;
  eDownloadViewType = DownloadViewType;
  rawCountryViewDataColumns = Constants.downloadRawColumns;
  @ViewChild('inputElement')
  inputElement!: ElementRef;
  @ViewChild('columnInputElement')
  columnInputElement!: ElementRef;
  @ViewChild(MatSelect)
  matSelect!: MatSelect;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private providerService: ProviderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedDownloadViewType = undefined;
    this.getCountriesList().then();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.downloadFormGroup.controls.dataFormat.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(100))
        .subscribe((value) => {
          this.onDataFormatChange(value);
        })
    );
    this.subscriptions.add(
      this.downloadFormGroup.controls.countryNameFilter.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(50))
        .subscribe((countryCode) => {
          this._filter(countryCode);
        })
    );
  }

  onDataFormatChange(value: any): void {
    this.downloadFormGroup.markAsUntouched();
    if (value === 'yearsAsColumns') {
      this.downloadFormGroup.controls.fromYear.setValidators([
        Validators.required,
        Validators.max(new Date().getFullYear()),
      ]);
      this.downloadFormGroup.controls.toYear.setValidators([
        Validators.required,
        Validators.max(new Date().getFullYear()),
      ]);
      this.downloadFormGroup.controls.sortColumn.setValidators([
        Validators.required,
      ]);
      this.downloadFormGroup.controls.sortType.setValidators([
        Validators.required,
      ]);
      this.downloadFormGroup.controls.csvColumns.clearValidators();
    } else {
      this.downloadFormGroup.controls.csvColumns.setValidators([
        Validators.required,
      ]);
      this.downloadFormGroup.controls.fromYear.clearValidators();
      this.downloadFormGroup.controls.toYear.clearValidators();
      this.downloadFormGroup.controls.sortColumn.clearValidators();
      this.downloadFormGroup.controls.sortType.clearValidators();
    }

    this.downloadFormGroup.controls.csvColumns.updateValueAndValidity();
    this.downloadFormGroup.controls.fromYear.updateValueAndValidity();
    this.downloadFormGroup.controls.toYear.updateValueAndValidity();
    this.downloadFormGroup.controls.sortColumn.updateValueAndValidity();
    this.downloadFormGroup.controls.sortType.updateValueAndValidity();
    this.showYearsAsColumnsOptions = value === 'yearsAsColumns';
  }

  async downloadData(dialogRef: any): Promise<void> {
    this.downloadFormGroup.markAllAsTouched();
    if (this.downloadFormGroup.valid) {
      const filters: any = {};
      const codeFilter = this.downloadFormGroup.controls.codeFilter.value;
      if (codeFilter) {
        filters.codeFilter = codeFilter;
      }
      const descriptionFilter =
        this.downloadFormGroup.controls.descriptionFilter.value;
      if (descriptionFilter) {
        filters.codeDescriptionFilter = descriptionFilter;
      }
      const countryNameFilter =
        this.downloadFormGroup.controls.countryNameFilter.value;
      if (countryNameFilter) {
        filters.countryCodeFilter = countryNameFilter;
      }
      const downloadDataParams: any = {
        filters: Object.keys(filters).length
          ? btoa(JSON.stringify(filters))
          : '',
      };
      const fromYear = this.downloadFormGroup.controls.fromYear.value;
      if (fromYear) {
        downloadDataParams.fromYear = fromYear.toString();
      }
      const toYear = this.downloadFormGroup.controls.toYear.value;
      if (toYear) {
        downloadDataParams.toYear = toYear.toString();
      }
      const dataFormat = this.downloadFormGroup.controls.dataFormat.value;
      if (dataFormat === 'yearsAsColumns') {
        const sortCol = this.downloadFormGroup.controls.sortColumn.value;
        if (sortCol) {
          downloadDataParams.sortCol = sortCol;
        }
        const sortType = this.downloadFormGroup.controls.sortType.value;
        if (sortType) {
          downloadDataParams.sortType = sortType;
        }
      }
      if (dataFormat === 'rawData') {
        if (this.csvColumnsFormControl.value.includes('All columns')) {
          downloadDataParams.columns = JSON.stringify(
            this.rawCountryViewDataColumns
              .map((v: any) => v.value)
              .filter((v: string) => v !== 'All columns')
          );
        } else {
          downloadDataParams.columns = JSON.stringify(
            this.csvColumnsFormControl.value
          );
        }
      }
      let dialog;
      try {
        this.downloadTableDataLoader = true;
        dialog = this.dialog.open(dialogRef, {
          width: '500px',
          disableClose: true,
          autoFocus: false,
        });
        let result;
        if (dataFormat === 'yearsAsColumns') {
          result =
            await this.providerService.downloadDataService.downloadTableData(
              downloadDataParams
            );
        } else if (dataFormat === 'rawData') {
          result =
            await this.providerService.downloadDataService.downloadRawTableData(
              downloadDataParams
            );
        }
        dialog.close();
        if (this.downloadTableDataLoader) {
          const link = document.createElement('a');
          link.href = result;
          link.setAttribute('download', 'raw-data.csv');
          link.click();
          link.remove();
        }
      } catch (e) {
        dialog?.close();
        this.snackBar.open('An error occured while downloading the file', 'X', {
          duration: 3000,
          panelClass: ['error-snackbar'],
        });
        this.showError = true;
      } finally {
        this.downloadTableDataLoader = false;
      }
    }
  }

  async getCountriesList(): Promise<void> {
    try {
      this.countries = this.countries.concat(
        await this.providerService.regionsManagementService.getCountriesByRegions()
      );
      this.filteredCountries = this.countries;
    } catch (error) {
      console.log(error);
    }
  }

  displayFn(value: any): string {
    return value
      ? this.filteredCountries.find((country) => country.isoCode === value)
          ?.shortName ?? ''
      : 'No filter';
  }

  cancelDownload(): void {
    this.downloadTableDataLoader = false;
  }

  displayMultipleFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0
        ? (result = `${this.displayFn(v)}`)
        : (result = `${result}, ${this.displayFn(v)}`);
    }
    return result;
  }

  displayMultipleColumnFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0 ? (result = `${v}`) : (result = `${result}, ${v}`);
    }
    return result;
  }

  removeCountryFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.countriesFormControl.setValue([]);
    this.downloadFormGroup.controls.autocompleteCountry.setValue('');
    this.inputElement.nativeElement.focus();
  }

  removeColumnFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.csvColumnsFormControl.setValue([]);
    this.downloadFormGroup.controls.autocompleteColumn.setValue('');
    this.columnInputElement.nativeElement.focus();
  }

  onOpenedChange(isOpened: boolean): void {
    if (isOpened) {
      this.inputElement.nativeElement.focus();
    }
  }

  onOpenedColumnChange(isOpened: boolean): void {
    if (isOpened) {
      this.columnInputElement.nativeElement.focus();
    }
  }

  visuallyFilterCountries(country: CountriesListsModel): boolean {
    const countryName = country.shortName.toLowerCase();
    const countryisoCode = (country.isoCode || '').toLowerCase();
    const input = (
      this.downloadFormGroup.controls.autocompleteCountry.value || ''
    ).toLowerCase();
    return (
      input === '' ||
      countryName.includes(input) ||
      countryisoCode.includes(input)
    );
  }

  visuallyFilterColumns(column: string): boolean {
    const countryName = column.toLowerCase();
    const input = (
      this.downloadFormGroup.controls.autocompleteColumn.value || ''
    ).toLowerCase();
    return input === '' || countryName.includes(input);
  }

  allColumnsSelected(): void {
    this.csvColumnsFormControl.setValue(
      this.csvColumnsFormControl.value.filter(
        (v: string) => v === 'All columns'
      )
    );
  }

  otherColumnSelected(): void {
    this.csvColumnsFormControl.setValue(
      this.csvColumnsFormControl.value.filter(
        (v: string) => v !== 'All columns'
      )
    );
  }

  selectView(view: DownloadViewType | undefined): void {
    this.selectedDownloadViewType = view;
  }

  get countriesFormControl(): FormControl {
    return this.downloadFormGroup.controls.countryNameFilter;
  }

  get csvColumnsFormControl(): FormControl {
    return this.downloadFormGroup.controls.csvColumns;
  }

  private _filter(value: any): void {
    if (typeof value === 'string' && value.length > 0) {
      const toSearch = value.toLowerCase();
      this.filteredCountries = this.countries.filter(
        (country) =>
          country.isoCode.toLowerCase().includes(toSearch) ||
          country.shortName.toLowerCase().includes(toSearch)
      );
    } else {
      this.filteredCountries = this.countries;
    }
  }
}
