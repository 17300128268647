import { Component } from '@angular/core';
import { IUser } from '../../../models/i-user';
import { Router } from '@angular/router';
import { ProviderService } from '../../../core/provider.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  loading: boolean;
  showPassword = false;
  showNewPassword = false;
  showChangePasswordForm = false;
  showForgotPasswordEnterEmail = false;

  errorMessageVisible = false;
  errorUserNotFoundVisibile = false;
  errorNewPasswordMatch = false;

  isResettingPassword = false;
  temporaryUser!: any;
  user: IUser;

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });
  resetPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    code: new FormControl('', [Validators.required]),
  });
  newPasswordForm = new FormGroup(
    {
      password: new FormControl('', [
        Validators.minLength(8),
        Validators.required,
      ]),
      confirmPassword: new FormControl('', [
        Validators.minLength(8),
        Validators.required,
      ]),
    },
    { validators: this.passwordMatchValidator }
  );

  constructor(
    private router: Router,
    private providerService: ProviderService,
    private snackbar: MatSnackBar
  ) {
    this.loading = false;
    this.user = {} as IUser;
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    return password &&
      confirmPassword &&
      password.value !== confirmPassword.value
      ? { passwordMismatch: true }
      : null;
  }

  signIn(): void {
    if (this.form.valid) {
      this.user.email = this.form.controls.email.value ?? '';
      this.user.password = this.form.controls.password.value ?? '';
      this.loading = true;
      this.providerService.authService
        .signIn(this.user)
        .then((afterSignIn: any) => {
          if (afterSignIn?.action === 'NEW_PASSWORD_REQUIRED') {
            this.temporaryUser = afterSignIn.user;
            this.loading = false;
            this.showChangePasswordForm = true;
          } else {
            this.router.navigate(['/', 'app', 'home']);
          }
        })
        .catch((error: any) => {
          if (error.toString().includes('UserNotFoundException')) {
            this.errorMessageVisible = false;
            this.errorUserNotFoundVisibile = true;
          } else {
            this.errorUserNotFoundVisibile = false;
            this.errorMessageVisible = true;
          }
          this.loading = false;
        });
    }
  }

  startForgotPasswordSteps(): void {
    this.showForgotPasswordEnterEmail = true;
    this.showChangePasswordForm = true;
    this.errorUserNotFoundVisibile = false;
    this.errorMessageVisible = false;
  }

  sendResetPasswordCode(): void {
    this.errorMessageVisible = false;
    this.resetPasswordForm.controls.email.markAsTouched();
    if (this.resetPasswordForm.controls.email.valid) {
      const userEmail = this.resetPasswordForm.controls.email.value;
      if (userEmail) {
        this.loading = true;
        this.providerService.authService
          .sendForgotPasswordCode(userEmail)
          .then(() => {
            this.snackbar.open('Email with verification code sent', 'X', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
            this.showForgotPasswordEnterEmail = false;
            this.isResettingPassword = true;
            this.showChangePasswordForm = true;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.errorMessageVisible = true;
          });
      }
    }
  }

  goBack(): void {
    this.showForgotPasswordEnterEmail = false;
    this.showChangePasswordForm = false;
    this.errorUserNotFoundVisibile = false;
    this.errorMessageVisible = false;
  }

  async submitNewPassword(): Promise<void> {
    this.errorMessageVisible = false;
    this.errorNewPasswordMatch = false;
    this.newPasswordForm.markAllAsTouched();
    if (
      this.newPasswordForm.controls.password.value !==
      this.newPasswordForm.controls.confirmPassword.value
    ) {
      this.errorNewPasswordMatch = true;
      return;
    }
    if (this.isResettingPassword) {
      this.resetPasswordForm.controls.code.markAsTouched();
      if (
        this.newPasswordForm.valid &&
        this.resetPasswordForm.controls.code.valid
      ) {
        this.loading = true;
        const email = this.resetPasswordForm.controls.email.value;
        const code = this.resetPasswordForm.controls.code.value;
        const newPassword = this.newPasswordForm.controls.password.value;
        if (email && code && newPassword) {
          this.providerService.authService
            .resetPassword(email, code, newPassword)
            .then(() => {
              this.snackbar.open('Password successfully changed!', 'X', {
                duration: 3000,
                panelClass: ['success-snackbar'],
              });
              this.loading = false;
              this.showChangePasswordForm = false;
              this.isResettingPassword = false;
              this.showForgotPasswordEnterEmail = false;
              this.resetPasswordForm.reset();
              this.form.reset();
              this.newPasswordForm.reset();
              // this.router.navigate(['/', 'app', 'home']);
            })
            .catch((_error: any) => {
              this.loading = false;
              this.errorMessageVisible = true;
            });
        }
      }
    } else if (!this.isResettingPassword) {
      if (this.newPasswordForm.valid) {
        this.loading = true;
        this.errorNewPasswordMatch = false;
        const newPassword = this.newPasswordForm.controls.password.value;
        if (newPassword) {
          this.providerService.authService
            .completeSignIn(this.temporaryUser, newPassword)
            .then(() => {
              this.router.navigate(['/', 'app', 'home']);
            })
            .catch((_error: any) => {
              this.loading = false;
              this.errorMessageVisible = true;
            });
        }
      }
    }
  }

  navigateToSignUp(): void {
    this.providerService.utilService.navigateTo('/auth/sign-up');
  }
}
