import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/net-utils/network.service';
import { environment } from 'src/environments/environment';
import { ValidationModuleGroupByCodeModel } from '../models/validation-module-code-model';
import { ValidationModuleCodeMapper } from '../mappers/validation-module-code-mapper';

@Injectable({
  providedIn: 'root',
})
export class ValidationModuleCodeTableService {
  private networkService!: NetworkService;
  baseUrl = `${environment.backend.endpoint}/validation-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getValidationModuleCodeTableListAndCount(
    surveyId: string,
    countries: string[] | undefined,
    percentage: number | undefined,
    codeId: string,
    fromYear: number,
    toYear: number,
    pageNumber: number,
    pageSize: number
  ): Promise<ValidationModuleGroupByCodeModel> {
    const mapper = new ValidationModuleCodeMapper();
    await this.networkService.get(
      `${this.baseUrl}/survey/${surveyId}/from_year/${fromYear}/to_year/${toYear}`,
      mapper,
      {
        percentage,
        codeId,
        countries: countries?.join(',') ?? undefined,
        offset: pageNumber,
        limit: pageSize,
      }
    );
    return mapper.response!;
  }

  async updateValidationModuleCell(
    _cellID: string,
    _value: number
  ): Promise<void> {
    return;
  }
}
