import { IMapper } from '../../../models/i-mapper';
import { InsertsModel } from '../models/inserts.model';
import { DifferencesModel } from '../models/differences.model';

export class UploadCheckDataMapper implements IMapper {
  payload?: string;
  inserts: InsertsModel[] = [];
  differences: DifferencesModel[] = [];

  fillFromJson(json: any): void {
    json.inserts.forEach((insert: any) => {
      this.inserts.push({
        dataYear: insert?.dataYear,
        codeID: insert?.codeID,
        code: insert?.code,
        countryID: insert?.countryID,
        isoCode: insert?.isoCode,
        value: insert?.value,
        notes: insert?.notes,
        notesInternal: insert?.notesInternal,
        source: insert?.source,
      });
    });

    json.differences.forEach((difference: any) => {
      this.differences.push({
        dataID: difference?.dataID,
        dataYear: difference?.dataYear,
        codeID: difference?.codeID,
        code: difference?.code,
        countryID: difference?.countryID,
        isoCode: difference?.isoCode,
        differencesData: difference?.differencesData,
      });
    });
  }

  fillToJson(): string {
    return JSON.stringify(this.payload);
  }
}

export class UploadDataMapper implements IMapper {
  inserts: InsertsModel[] = [];
  differences: DifferencesModel[] = [];

  fillFromJson(): string {
    return 'Data uploaded successfully';
  }

  fillToJson(): string {
    return JSON.stringify({
      inserts: this.inserts,
      differences: this.differences,
    });
  }
}
