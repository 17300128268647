export enum ContactDataTypeEnum {
  organization = 'ORGANIZATION',
  prefix = 'PREFIX',
  firstName = 'FIRSTNAME',
  lastName = 'LASTNAME',
  email = 'EMAIL',
  phoneNumber = 'PHONENUMBER',
  title = 'TITLE',
  website = 'WEBSITE',
}
