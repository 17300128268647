import { IMapper } from '../../../models/i-mapper';
import {
  CellHistoryModel,
  CellInfoModel,
  cellUpdatePayloadModel,
  getIdPayload,
  ModifyRowModel,
} from '../models/update-country-table-models';

export class updateCellMapper implements IMapper {
  cellModification: cellUpdatePayloadModel[] = [];
  fillFromJson(_json: any): void {}
  fillToJson(): string {
    return JSON.stringify(this.cellModification);
  }
}

export class insertIdMapper implements IMapper {
  payload: getIdPayload = {} as getIdPayload;
  id = '';
  fillFromJson(json: any): void {
    this.id = json['id'];
  }
  fillToJson(): string {
    return JSON.stringify(this.payload);
  }
}

export class GetCellInfoMapper implements IMapper {
  cellInfo?: CellInfoModel;

  fillFromJson(json: any): void {
    this.cellInfo = {
      internalNotes: json['internalNotes'],
      publishNotes: json['publishNotes'],
      published: json['published'],
      estimated: json['estimated'],
      source: json['source'],
      sourceId: json['sourceId'],
    };
  }

  fillToJson(): string {
    return '';
  }
}

export class GetCellHistoryMapper implements IMapper {
  cellHistory: CellHistoryModel[] = [];

  fillFromJson(json: any): void {
    this.cellHistory = [];
    for (const value of json) {
      this.cellHistory?.push({
        value: value['value'],
        user: value['user'],
        lastModifiedOn: value['lastModifiedOn'].toString(),
        version: value['version'],
      });
    }
  }

  fillToJson(): string {
    return '';
  }
}

export class UpdateInternalNotesMapper implements IMapper {
  cellId?: string;
  internalNote?: string;

  fillFromJson(_json: any): void {}

  fillToJson(): string {
    return JSON.stringify({
      cellId: this.cellId,
      internalNote: this.internalNote,
    });
  }
}

export class UpdateNotesMapper implements IMapper {
  cellId?: string;
  note?: string;

  fillFromJson(_json: any): void {}

  fillToJson(): string {
    return JSON.stringify({
      cellId: this.cellId,
      note: this.note,
    });
  }
}

export class UpdateSourceMapper implements IMapper {
  dataId?: string;
  source?: string;
  sourceId?: string;

  fillFromJson(_json: any): void {}

  fillToJson(): string {
    return JSON.stringify([this]);
  }
}

export class UpdateResponseMapper implements IMapper {
  message?: string;

  fillFromJson(json: any): void {
    this.message = json;
  }

  fillToJson(): string {
    return '';
  }
}

export class ModifyRowMapper implements IMapper {
  row: ModifyRowModel[] = [];

  fillFromJson(_json: any): void {
    return;
  }

  fillToJson(): string {
    return JSON.stringify(this.row);
  }
}

export class UpdatePublishMapper implements IMapper {
  cellId?: string;
  published?: boolean;

  fillFromJson(_json: any): void {
    return;
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }
}

export class DeleteSourceMapper implements IMapper {
  sourceIds?: string[];
  message?: string;

  fillFromJson(_json: any): void {
    this.message = _json;
  }

  fillToJson(): string {
    return JSON.stringify({
      sourceId: this.sourceIds,
    });
  }
}

export class UpdateEstimatedMapper implements IMapper {
  cellId?: string;
  estimated?: boolean;

  fillFromJson(_json: any): void {
    return;
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }
}
