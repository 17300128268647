<div class="form-container {{styleType}}" [formGroup]="disaggregationsFormGroup">
  @if(styleType === 'filters-bar') {
  <div class="mat-drop-down">
    <mat-label class="label">{{'FILTERS.DISAGGREGATION_LABEL' | translate}}{{level}}:</mat-label>
    <mat-select [formControl]="disaggregationsFormGroup.controls.disaggregation"
      (openedChange)="onOpenedUnitChange($event)" multiple>
      <mat-select-trigger>
        <span [matTooltip]="displayMultipleDisaggregationsFn(disaggregationsFormGroup.controls.disaggregation.value)">
          {{displayDisaggregationFn(disaggregationsFormGroup.controls.disaggregation.value?.[0]) || ''}}
          @if ((disaggregationsFormGroup.controls.disaggregation.value?.length || 0) > 1) {
          <span>
            (+{{(disaggregationsFormGroup.controls.disaggregation.value?.length || 0) - 1}}
            {{disaggregationsFormGroup.controls.disaggregation.value?.length === 2 ? 'other' : 'others'}})
          </span>
          }
        </span>
        @if ((disaggregationsFormGroup.controls.disaggregation.value?.length || 0) > 0) {
        <i (click)="removeDisaggregationFilter($event)" class="fa fa-x clear-filter"
          matTooltip="{{'FILTERS.CLEAR_FILTERS' | translate}}"></i>
        }
      </mat-select-trigger>
      <input matInput type="text" formControlName="autocompleteDisaggregation" class="autocomplete-input"
        (keydown)="$event.stopPropagation()" #disaggregationInputElement
        placeholder="{{ 'FILTERS.AUTOCOMPLETE_PLACEHOLDER' | translate }}">
      @if (level === 1) {
        <div class="disaggregations-buttons-container">
          <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                  (click)="selectDisaggregationsByName('Age')">
            Age
          </button>
          <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                  (click)="selectDisaggregationsByName('Education')">
            Education
          </button>
        </div>
        <div class="disaggregations-buttons-container">
          <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                  (click)="selectDisaggregationsByName('Family')">
            Family
          </button>
          <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                  (click)="selectDisaggregationsByName('Gender')">
            Gender
          </button>
        </div>
        <div class="disaggregations-buttons-container">
          <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                  (click)="selectDisaggregationsByName('Labour force')">
            Labour
          </button>
          <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                  (click)="selectDisaggregationsByName('Occupation')">
            Occupation
          </button>
        </div>
        <div class="disaggregations-buttons-container">
          <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                  (click)="selectDisaggregationsByName('Location')">
            Location
          </button>
        </div>
      }
      @if (level === 2) {
        <div class="disaggregations-buttons-container">
          <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                  (click)="selectDisaggregationsByName('Family')">
            Family
          </button>
          <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                  (click)="selectDisaggregationsByName('Gender')">
            Gender
          </button>
        </div>
        <div class="disaggregations-buttons-container">
          <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                  (click)="selectDisaggregationsByName('Location')">
            Location
          </button>
        </div>
      }
      <mat-option [value]="{ disaggregationId: '' }">
        <span>
          None
        </span>
      </mat-option>
      @for (disaggregation of disaggregations; track disaggregation) {
      <mat-option [ngClass]="visuallyFilterDisaggregations(disaggregation) ? '' : 'hidden'" [value]="disaggregation">
        <span>
          {{ disaggregation.disaggregationDescription }}
        </span>
      </mat-option>
      }
    </mat-select>
  </div>
  } @else {
    <mat-form-field appearance="fill">
      <mat-label class="label">{{'FILTERS.DISAGGREGATION_LABEL' | translate}} {{level}}:</mat-label>
      <mat-select [formControl]="disaggregationsFormGroup.controls.disaggregation"
                  (openedChange)="onOpenedUnitChange($event)" multiple>
        <mat-select-trigger>
          <span [matTooltip]="displayMultipleDisaggregationsFn(disaggregationsFormGroup.controls.disaggregation.value)">
            {{displayDisaggregationFn(disaggregationsFormGroup.controls.disaggregation.value?.[0]) || ''}}
            @if ((disaggregationsFormGroup.controls.disaggregation.value?.length || 0) > 1) {
              <span>
              (+{{(disaggregationsFormGroup.controls.disaggregation.value?.length || 0) - 1}}
                {{disaggregationsFormGroup.controls.disaggregation.value?.length === 2 ? 'other' : 'others'}})
            </span>
            }
          </span>
          @if ((disaggregationsFormGroup.controls.disaggregation.value?.length || 0) > 0) {
            <i (click)="removeDisaggregationFilter($event)" class="fa fa-x clear-filter"
               matTooltip="{{'FILTERS.CLEAR_FILTERS' | translate}}"></i>
          }
        </mat-select-trigger>
        <input matInput type="text" formControlName="autocompleteDisaggregation" class="autocomplete-input"
               (keydown)="$event.stopPropagation()" #disaggregationInputElement
               placeholder="{{ 'FILTERS.AUTOCOMPLETE_PLACEHOLDER' | translate }}">
        @if (level === 1) {
          <div class="disaggregations-buttons-container">
            <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                    (click)="selectDisaggregationsByName('Age')">
              Age
            </button>
            <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                    (click)="selectDisaggregationsByName('Education')">
              Education
            </button>
          </div>
          <div class="disaggregations-buttons-container">
            <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                    (click)="selectDisaggregationsByName('Family')">
              Family
            </button>
            <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                    (click)="selectDisaggregationsByName('Gender')">
              Gender
            </button>
          </div>
          <div class="disaggregations-buttons-container">
            <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                    (click)="selectDisaggregationsByName('Labour force')">
              Labour
            </button>
            <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                    (click)="selectDisaggregationsByName('Occupation')">
              Occupation
            </button>
          </div>
          <div class="disaggregations-buttons-container">
            <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                    (click)="selectDisaggregationsByName('Location')">
              Location
            </button>
          </div>
        }
        @if (level === 2) {
          <div class="disaggregations-buttons-container">
            <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                    (click)="selectDisaggregationsByName('Family')">
              Family
            </button>
            <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                    (click)="selectDisaggregationsByName('Gender')">
              Gender
            </button>
          </div>
          <div class="disaggregations-buttons-container">
            <button color="primary" mat-raised-button type="button" class="select-deselect-buttons"
                    (click)="selectDisaggregationsByName('Location')">
              Location
            </button>
          </div>
        }
        <mat-option [value]="{ disaggregationId: '' }">
          <span>
            None
          </span>
        </mat-option>
        @for (disaggregation of disaggregations; track disaggregation) {
          <mat-option [ngClass]="visuallyFilterDisaggregations(disaggregation) ? '' : 'hidden'" [value]="disaggregation">
          <span>
            {{ disaggregation.disaggregationDescription }}
          </span>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
</div>
