@if(loading) {
<app-loader></app-loader>
} @else {
<div class="validation-module-container">
  @if (currentStep === ValidationModuleSteps.selectSurvey) {
  <div class="select-container">
    <div class="back-button-container">
      <button mat-button mat-raised-button color="primary" (click)="goBackToHome()"><i
          class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE.BACK' | translate }}
      </button>
    </div>
    <span class="step-label">{{ 'VALIDATION_MODULE.SELECT_SURVEY' | translate }}</span>
    <app-survey-table class="step-selection" (surveySelected)="this.selectSurvey($event)"></app-survey-table>
  </div>
  }
  @if (currentStep === ValidationModuleSteps.selectCountry
  || currentStep === ValidationModuleSteps.selectCode
  || currentStep === ValidationModuleSteps.showCountryValidationTable
  || currentStep === ValidationModuleSteps.showCodeValidationTable
  || currentStep === ValidationModuleSteps.validateContact) {
  <div class="select-container {{currentStep !== ValidationModuleSteps.selectCountry ? 'hidden' : ''}}">
    <div class="back-button-container">
      <button mat-button mat-raised-button color="primary" (click)="goBackTo(ValidationModuleSteps.selectSurvey)"><i
          class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE.BACK' | translate }}
      </button>
    </div>
    <div class="top-part-container">
      <span class="step-label">{{ 'VALIDATION_MODULE.SURVEY' | translate }} {{ selectedSurvey?.surveyName }}</span>
      <div class="select-by-radio-button">
        <mat-radio-group aria-label="Select by:" [(ngModel)]="radioButtonSelectedOption"
          (change)="selectByChanged($event)">
          <span>{{ 'VALIDATION_MODULE.SELECT_BY' | translate }}</span>
          <mat-radio-button value="country">{{'VALIDATION_MODULE.COUNTRY' | translate}}</mat-radio-button>
          <mat-radio-button value="code">{{'VALIDATION_MODULE.CODE' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <app-survey-country-table class="step-selection" [selectedSurvey]="selectedSurvey!" [omitDownloadPdf]="true"
      (countrySelected)="$event.validateContact ? this.validateContact($event.country) : this.selectCountry($event.country)"></app-survey-country-table>
  </div>
  }
  @if (currentStep === ValidationModuleSteps.selectCode
  || currentStep === ValidationModuleSteps.selectCountry
  || currentStep === ValidationModuleSteps.showCountryValidationTable
  || currentStep === ValidationModuleSteps.showCodeValidationTable
  || currentStep === ValidationModuleSteps.validateContact) {
  <div class="select-container {{currentStep !== ValidationModuleSteps.selectCode ? 'hidden' : ''}}">
    <div class="back-button-container">
      <button mat-button mat-raised-button color="primary" (click)="goBackTo(ValidationModuleSteps.selectSurvey)"><i
          class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE.BACK' | translate }}
      </button>
    </div>
    <div class="top-part-container">
      <span class="step-label">{{ 'VALIDATION_MODULE.SURVEY' | translate }} {{ selectedSurvey?.surveyName }}</span>
      <div class="select-by-radio-button">
        <mat-radio-group aria-label="Select by:" [(ngModel)]="radioButtonSelectedOption"
          (change)="selectByChanged($event)">
          <span>{{ 'VALIDATION_MODULE.SELECT_BY' | translate }}</span>
          <mat-radio-button value="country">{{'VALIDATION_MODULE.COUNTRY' | translate}}</mat-radio-button>
          <mat-radio-button value="code">{{'VALIDATION_MODULE.CODE' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <app-validation-module-list-codes class="step-selection" [selectedSurvey]="selectedSurvey!"
      (codeSelected)="selectCode($event)"></app-validation-module-list-codes>
  </div>
  }
  @if (currentStep === ValidationModuleSteps.validateContact) {
  <div class="select-container">
    <div class="back-button-container">
      <button mat-button mat-raised-button color="primary" (click)="goBackTo(ValidationModuleSteps.selectCountry)"><i
          class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE.BACK' | translate }}
      </button>
    </div>
    <span class="step-label">{{ 'VALIDATION_MODULE.SURVEY' | translate }} {{ selectedSurvey?.surveyName }} | {{
      'VALIDATION_MODULE.COUNTRY' | translate }} {{ selectedCountry?.countryName }}</span>
    <app-contact-validation class="step-selection" [selectedSurvey]="selectedSurvey!"
      [selectedCountry]="selectedCountry!" (validationCompleted)="validationCompleted()"></app-contact-validation>
  </div>
  }
  @if (currentStep === ValidationModuleSteps.showCountryValidationTable) {
  <div class="select-container {{currentStep !== ValidationModuleSteps.showCountryValidationTable ? 'hidden' : ''}}">
    <div class="back-button-container">
      <button mat-button mat-raised-button color="primary" (click)="goBackTo(ValidationModuleSteps.selectCountry)"><i
          class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE.BACK' | translate }}
      </button>
    </div>
    <span class="step-label">{{ 'VALIDATION_MODULE.SURVEY' | translate }} {{ selectedSurvey?.surveyName }} | {{
      'VALIDATION_MODULE.COUNTRY' | translate }} {{ selectedCountry?.countryName }}</span>
    <app-validation-module-country-table class="step-selection" [selectedSurvey]="selectedSurvey!"
      [selectedCountry]="selectedCountry!" (validationCompleted)="validationCompleted()"></app-validation-module-country-table>
  </div>
  }
  @if (currentStep === ValidationModuleSteps.showCodeValidationTable) {
  <div class="select-container {{currentStep !== ValidationModuleSteps.showCodeValidationTable ? 'hidden' : ''}}">
    <div class="back-button-container">
      <button mat-button mat-raised-button color="primary" (click)="goBackTo(ValidationModuleSteps.selectCode)"><i
          class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE.BACK' | translate }}
      </button>
    </div>
    <div class="top-part-container">
      <span class="step-label">{{ 'VALIDATION_MODULE.SURVEY' | translate }} {{ selectedSurvey?.surveyName }}</span>
      <div class="code-select" [formGroup]="codeFormGroup">
        <mat-label>{{'VALIDATION_MODULE.CODE' | translate}}</mat-label>
        <mat-select formControlName="code" optionsScroll (optionsScroll)="nearEndCodeScroll()"
          (selectionChange)="this.codeFormGroup.controls.autocompleteCode.setValue('')" [compareWith]="compareCodes">
          <input formControlName="autocompleteCode" style="width: 90%; border: none; margin-left: 10px;"
            placeholder="{{ 'VALIDATION_MODULE.INPUT_PLACEHOLDER' | translate }}">
          <mat-option *ngFor="let code of filteredCodes" [value]="code">{{code.code}} - {{code.codeDescription}}</mat-option>
        </mat-select>
      </div>
    </div>
    <app-validation-module-code-table class="step-selection" [selectedSurvey]="selectedSurvey!"
      [selectedCode]="selectedCode!"
      [selectedCodeChanged]="selectedCodeChangedSubject.asObservable()"></app-validation-module-code-table>
  </div>
  }
</div>
}