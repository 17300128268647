<mat-card [formGroup]="downloadFormGroup">

  <mat-card-header>
    <mat-card-title>{{'COUNTRY_VIEW_DOWNLOAD.TITLE' | translate}}</mat-card-title>
  </mat-card-header>

  <mat-card-content>

    <mat-form-field appearance="fill">
      <mat-label>{{'COUNTRY_VIEW_DOWNLOAD.DATA_FORMAT_LABEL' | translate}}</mat-label>
      <mat-select formControlName="dataFormat">
        <mat-option value="rawData">{{'COUNTRY_VIEW_DOWNLOAD.RAW_DATA' | translate}}</mat-option>
        <mat-option value="yearsAsColumns">{{'COUNTRY_VIEW_DOWNLOAD.YEARS_AS_COLUMNS' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="year-container">
      <mat-form-field appearance="fill">
        <mat-label>{{'COUNTRY_VIEW_DOWNLOAD.FROM_YEAR_LABEL' | translate}}</mat-label>
        <input matInput type="number" formControlName="fromYear" placeholder="YYYY" min="0"
               (keydown)="($event.key === '-' || $event.key === '.'|| $event.key === 'e') ? $event.preventDefault() : ''">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{'COUNTRY_VIEW_DOWNLOAD.TO_YEAR_LABEL' | translate}}</mat-label>
        <input matInput type="number" formControlName="toYear" placeholder="YYYY" min="0"
               (keydown)="($event.key === '-' || $event.key === '.'|| $event.key === 'e') ? $event.preventDefault() : ''">
      </mat-form-field>
    </div>

    @if (!showYearsAsColumnsOptions) {
      <mat-form-field appearance="fill">
        <mat-label>{{'COUNTRY_VIEW_DOWNLOAD.CSV_COLUMNS_LABEL' | translate}}</mat-label>
        <mat-select #columnFilter [formControl]="csvColumnsFormControl"
                    (selectionChange)="columnFilter.close(); this.downloadFormGroup.controls.autocompleteColumn.setValue('')"
                    (openedChange)="onOpenedColumnChange($event)" multiple>
          <mat-select-trigger>
                  <span class="label" [matTooltip]="displayMultipleColumnFn(csvColumnsFormControl.value)">
                    {{ csvColumnsFormControl.value?.[0] || '' }}
                    @if ((csvColumnsFormControl.value?.length || 0) > 1) {
                      <span class="example-additional-selection">
                        (+{{ (csvColumnsFormControl.value?.length || 0) - 1 }} {{ csvColumnsFormControl.value?.length === 2 ? 'other' : 'others' }}
                        )
                      </span>
                    }
                  </span>
            @if ((csvColumnsFormControl.value?.length || 0) > 0) {
              <i (click)="removeColumnFilter($event)" class="fa fa-x clear-filter"
                 matTooltip="{{'COUNTRY_VIEW_DOWNLOAD.CLEAR_FILTERS' | translate}}"></i>
            }
          </mat-select-trigger>
          <input matInput type="text" formControlName="autocompleteColumn"
                 class="autocomplete-input"
                 (keydown)="$event.stopPropagation()"
                 #columnInputElement>
          @if (downloadFormGroup.controls.autocompleteColumn.value === '') {
            <span class="autocomplete-placeholder">{{'COUNTRY_VIEW_DOWNLOAD.SEARCH' | translate}}</span>
          }
          @for (column of rawCountryViewDataColumns; track column) {
            @if (column.value === 'All columns') {
              <mat-option [ngClass]=" visuallyFilterColumns(column.value) ? '' : 'hidden'"
                          (click)="allColumnsSelected()" [value]="column.value">
                {{ column.name | translate }}
              </mat-option>
            } @else {
              <mat-option [ngClass]="visuallyFilterColumns(column.value) ? '' : 'hidden'"
                          (click)="otherColumnSelected()" [value]="column.value">
                {{ column.name | translate }}
              </mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    }

    <app-region-country-filter (countriesChanged)="countriesSelectionChanged($event)"></app-region-country-filter>

    <span class="mat-drop-down">
      <mat-label>Code: </mat-label>
      <i class="info fa fa-circle-info"
         matTooltip="{{'COUNTRY_VIEW_DOWNLOAD.CODE_TOOLTIP' | translate}}"></i>
      <mat-form-field>
        <mat-chip-grid #chipGrid>
          @for (filter of filters; track filter) {
            <mat-chip-row
              (removed)="removeChip(filter)"
              [removable]="true"
              [editable]="false"
              class="standard"
            >
              {{ filter }}
              <button matChipRemove class="mat-chip-remove-code">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          }
          <input
            class="code-filter-input"
            matInput type="text" formControlName="codeFilter"
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addValue($event)"
            #inputFilterElement
          />
        </mat-chip-grid>
      </mat-form-field>
    </span>

    <mat-form-field appearance="fill">
      <mat-label>{{'COUNTRY_VIEW_DOWNLOAD.DESCRIPTION_LABEL' | translate}}</mat-label>
      <input matInput type="text" formControlName="descriptionFilter" placeholder="{{'COUNTRY_VIEW_DOWNLOAD.DESCRIPTION_PLACEHOLDER' | translate}}">
    </mat-form-field>

    @if (showYearsAsColumnsOptions) {
      <div class="sort-options">
        <mat-form-field appearance="fill">
          <mat-label>{{'COUNTRY_VIEW_DOWNLOAD.SORT_COLUMN_LABEL' | translate}}</mat-label>
          <mat-select formControlName="sortColumn">
            <mat-option value="code">{{'COUNTRY_VIEW_DOWNLOAD.CODE' | translate}}</mat-option>
            <mat-option value="description">{{'COUNTRY_VIEW_DOWNLOAD.DESCRIPTION' | translate}}</mat-option>
            <mat-option value="countryName">{{'COUNTRY_VIEW_DOWNLOAD.COUNTRY_NAME' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{'COUNTRY_VIEW_DOWNLOAD.SORT_TYPE_LABEL' | translate}}</mat-label>
          <mat-select formControlName="sortType">
            <mat-option value="ASC">{{'COUNTRY_VIEW_DOWNLOAD.ASCENDING' | translate}}</mat-option>
            <mat-option value="DESC">{{'COUNTRY_VIEW_DOWNLOAD.DESCENDING' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    }

    <div class="download-button">
      <button mat-raised-button (click)="downloadData(downloadDialog)"
              onclick="this.blur()" color="primary">
        <span class="download-raw-data-button">{{'COUNTRY_VIEW_DOWNLOAD.DOWNLOAD' | translate}}</span>
      </button>
    </div>

  </mat-card-content>
</mat-card>

<ng-template #downloadDialog>
  <h2 mat-dialog-title class="mat-dialog-title">{{ 'DIALOG.DOWNLOAD_TITLE' | translate }}</h2>
  <mat-dialog-content>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <p>{{ 'DIALOG.DOWNLOAD_MESSAGE' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close (click)="cancelDownload()">{{ 'DIALOG.CANCEL' | translate }}</button>
  </mat-dialog-actions>
</ng-template>
