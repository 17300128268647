@if (loading) {
<app-loader></app-loader>
}
<div class="filters-class">
  <ng-container>
    <div class="filters-bar">
      <div class="first-column">
        <span class="mat-drop-down">
          <app-code-filter (codeSelectionChanged)="this.codeSelectionChanged($event)"></app-code-filter>
        </span>
      </div>
    </div>
  </ng-container>
</div>
<div class="table-holder mat-elevation-z8" tabindex="0">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef appResizeColumn style="width: 20%;">
        <div>{{ 'VALIDATION_MODULE_LIST_CODES.CODE' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element">
        <span class="row-line">
          {{ element.code }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="codeDescription">
      <th mat-header-cell *matHeaderCellDef appResizeColumn style="width: 80%;">
        <div>{{ 'VALIDATION_MODULE_LIST_CODES.CODE_DESCRIPTION' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element">
        <span class="row-line">
          {{ element.codeDescription }}
        </span>
      </td>
    </ng-container>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="displayedColumns.length">
        @if(!loading) {{{ 'VALIDATION_MODULE_LIST_CODES.NO_DATA_FOUND' | translate}}}
      </td>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row clickable" (click)="selectCode(row)"></tr>
  </table>
</div>
<div class="paginator">
  <mat-paginator class="mat-elevation-z8 hide-ripple" [length]="tableCount" [pageSizeOptions]="[50, 100, 150]">
  </mat-paginator>
</div>