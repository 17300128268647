import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogLevel } from '../../../models/log-level';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomSnackbarComponent } from '../../form-utils/custom-snackbar/custom-snackbar.component';
import { firstValueFrom } from 'rxjs';
import { CustomConfirmationDialogComponent } from 'src/app/components/dialogues/custom-confirmation-dialog/custom-confirmation-dialog.component';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private dialogRef: any;
  confirmationDialogRef: any = null;
  constructor(
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) {}

  showMessage(message: string, type: LogLevel, showTime?: number): void {
    this.snackbar.openFromComponent(CustomSnackbarComponent, {
      data: { message, type },
      duration: showTime ? showTime : 10000,
    });
  }

  openDialog(
    component: any,
    data: any,
    panelClass?: string
  ): MatDialogRef<any> {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.dialog.open(component, {
      data,
      panelClass,
    });
    return this.dialogRef;
  }

  humanReadableDate(dateString: string, includeTime: boolean): string {
    const date = new Date(dateString);
    if (includeTime) {
      return `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()} - ${this.pad(date.getHours(), 2)}:${this.pad(
        date.getMinutes(),
        2
      )}:${this.pad(date.getSeconds(), 2)}`;
    } else {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  }

  async openConfirmationDialog(
    title: string,
    message: string
  ): Promise<boolean> {
    if (this.confirmationDialogRef) {
      this.confirmationDialogRef.close();
    }
    this.confirmationDialogRef = this.dialog.open(
      CustomConfirmationDialogComponent,
      {
        data: {
          message,
          title,
        },
      }
    );
    return (
      (await firstValueFrom(this.confirmationDialogRef.afterClosed())) ?? false
    );
  }

  pad(num: number | string, size: number): string {
    let s: string = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  toBase64(input: string): string {
    return Buffer.from(input).toString('base64');
  }

  fromBase64(input: string): string {
    return Buffer.from(input, 'base64').toString('ascii');
  }

  async navigateTo(href: string, data?: string): Promise<void> {
    if (data) {
      await this.router.navigate([href, data]);
    } else {
      await this.router.navigate(href.split('/'));
    }
  }
}
