@if (loading) {
<app-loader></app-loader>
}
<div class="filters-class">
  <ng-container>
    <div class="filters-bar" [formGroup]="filterCountrySurveyFormGroup">
      <div class="first-column">
        <span class="mat-drop-down">
          <mat-label>{{ 'SURVEY_COUNTRY_TABLE.ISO_CODE' | translate }}</mat-label>
          <input matInput type="text" formControlName="isoCode">
        </span>
        <span class="mat-drop-down">
          <mat-label>{{ 'SURVEY_COUNTRY_TABLE.COUNTRY_NAME' | translate }}</mat-label>
          <input matInput type="text" formControlName="countryName">
        </span>
        <span class="mat-drop-down">
          <mat-label class="wide-label">{{ 'SURVEY_COUNTRY_TABLE.SURVEY_STATUS' | translate }}</mat-label>
          <mat-form-field>
            <mat-select formControlName="surveyStatus">
              <mat-option [value]="null">
                {{ 'SURVEY_COUNTRY_TABLE.NO_FILTER' | translate }}
              </mat-option>
              <mat-option [value]="SurveyStatusEnum.SENT">
                {{ 'SURVEY_COUNTRY_TABLE.SENT' | translate }}
              </mat-option>
              <mat-option [value]="SurveyStatusEnum.INCOMPLETE">
                {{ 'SURVEY_COUNTRY_TABLE.INCOMPLETE' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span class="mat-drop-down">
          <mat-label class="wide-label">{{ 'SURVEY_COUNTRY_TABLE.VALIDATION_STATUS' | translate }}</mat-label>
          <mat-form-field>
            <mat-select formControlName="validationStatus">
              <mat-option [value]="null">
                {{ 'SURVEY_COUNTRY_TABLE.NO_FILTER' | translate }}
              </mat-option>
              <mat-option [value]="SurveyValidationStatusEnum.RUNNING">
                {{ 'SURVEY_COUNTRY_TABLE.PENDING' | translate }}
              </mat-option>
              <mat-option [value]="SurveyValidationStatusEnum.NOT_STARTED">
                {{ 'SURVEY_COUNTRY_TABLE.NOT_STARTED' | translate }}
              </mat-option>
              <mat-option [value]="SurveyValidationStatusEnum.COMPLETED">
                {{ 'SURVEY_COUNTRY_TABLE.COMPLETE' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
      @if(!omitDownloadPdf) {
        <div class="last-column">
          <button mat-raised-button color="primary" (click)="showDownloadPDFDialog(downloadDialog)" onclick="this.blur()">
            <span>
              {{ 'SURVEY_COUNTRY_TABLE.DOWNLOAD_PDF' | translate }}
            </span>
          </button>
        </div>
      }
    </div>
  </ng-container>
</div>
<div class="table-holder mat-elevation-z8" tabindex="0">
  <table mat-table matSort [dataSource]="dataSource" matSortActive="isoCode" matSortDirection="asc">
    <ng-container matColumnDef="isoCode">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>{{ 'SURVEY_COUNTRY_TABLE.ISO_CODE_HEADER' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element">
        @if (element.validationStatus !== SurveyValidationStatusEnum.NOT_STARTED) {
        <span class="row-line">
          {{ element.isoCode }}
        </span>
        } @else {
        <span class="row-line">
          {{ element.isoCode }}
        </span>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="countryName">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>{{ 'SURVEY_COUNTRY_TABLE.COUNTRY_NAME_HEADER' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element">
        @if (element.validationStatus !== SurveyValidationStatusEnum.NOT_STARTED) {
        <span class="row-line">
          {{ element.countryName }}
        </span>
        } @else {
        <span class="row-line">
          {{ element.countryName }}
        </span>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="surveyStatus">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>{{ 'SURVEY_COUNTRY_TABLE.SURVEY_STATUS_HEADER' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element">
        @if (element.validationStatus !== SurveyValidationStatusEnum.NOT_STARTED) {
        <span class="row-line">
          {{ 'SURVEY_COUNTRY_TABLE.' + element.surveyStatus | translate}}
        </span>
        } @else {
        <span class="row-line">
          {{ 'SURVEY_COUNTRY_TABLE.' + element.surveyStatus | translate}}
        </span>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="validationStatus">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>{{ 'SURVEY_COUNTRY_TABLE.VALIDATION_STATUS_HEADER' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element">
        @if (element.validationStatus !== SurveyValidationStatusEnum.NOT_STARTED) {
        <span class="row-line">
          {{ 'SURVEY_COUNTRY_TABLE.' + element.validationStatus | translate}}
        </span>
        } @else {
        <span class="row-line">
          {{ 'SURVEY_COUNTRY_TABLE.' + element.validationStatus | translate}}
        </span>
        }
      </td>
    </ng-container>
    @if(!omitActions) {
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>{{ 'SURVEY_COUNTRY_TABLE.ACTIONS' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          @if (element.validationStatus === SurveyValidationStatusEnum.NOT_STARTED) {
          <span class="row-line">
            <button type="button" color="primary" mat-raised-button (click)="startSurveyValidation(element.countryID)">
              {{ 'SURVEY_COUNTRY_TABLE.START_SURVEY_VALIDATION' | translate }}
            </button>
          </span>
          }
        </td>
      </ng-container>
    }
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="displayedColumns.length">
        @if(!loading) {{{ 'SURVEY_COUNTRY_TABLE.NO_DATA_FOUND' | translate}}}
      </td>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      class="table-row {{row.validationStatus !== SurveyValidationStatusEnum.NOT_STARTED ? 'clickable': ''}}"
      (click)="row.validationStatus !== SurveyValidationStatusEnum.NOT_STARTED ? selectCountry(row) : ''"></tr>
  </table>
</div>
<div class="paginator">
  <mat-paginator class="mat-elevation-z8 hide-ripple" [length]="tableCount" [pageSizeOptions]="[50, 100, 150]">
  </mat-paginator>
</div>

<ng-template #downloadDialog>
  <h2 mat-dialog-title class="mat-dialog-title">{{ 'SURVEY_COUNTRY_TABLE.DOWNLOAD_DIALOG_TITLE' | translate }}</h2>
  @if (downloadingPDF) {
  <mat-dialog-content>
    <mat-progress-bar mode="determinate" [value]="progressBarValue"></mat-progress-bar>
    <p style="margin-top: 10px">{{ 'SURVEY_COUNTRY_TABLE.DOWNLOADING_DIALOG_MESSAGE' | translate }}</p>
  </mat-dialog-content>
  } @else {
  <mat-dialog-content [formGroup]="filterCountryFormGroup" class="download-pdf-dialog">
    <mat-label>{{ 'SURVEY_COUNTRY_TABLE.YEAR_FROM' | translate }}</mat-label>
    <mat-form-field appearance="outline">
      <input matInput type="number" formControlName="yearFrom">
    </mat-form-field>
    <mat-label>{{ 'SURVEY_COUNTRY_TABLE.YEAR_TO' | translate }}</mat-label>
    <mat-form-field appearance="outline">
      <input matInput type="number" formControlName="yearTo">
    </mat-form-field>
  </mat-dialog-content>
  }
  <mat-dialog-actions>
    <button mat-raised-button color="accent" mat-dialog-close>{{ 'DIALOG.CANCEL' | translate }}</button>
    <button mat-raised-button color="{{downloadPdfEnabled ? 'primary': 'secondary'}}" [disabled]="!downloadPdfEnabled"
      (click)="downloadPDF()">{{ 'DIALOG.DOWNLOAD' | translate }}</button>
  </mat-dialog-actions>
</ng-template>