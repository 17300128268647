<div class="upload-container">
  @if(uploadStep === 1) {
    <mat-card class="upload-drag-and-drop-zone">
      <mat-card-header>
        <mat-card-title>
          <button mat-button mat-raised-button color="primary" class="back-button" (click)="goBackToHome()"><i
            class="fa-solid fa-arrow-left"></i> {{ 'UPLOAD_MODULE.BACK' | translate }}
          </button>
          <span>{{ 'UPLOAD_MODULE.UPLOAD_DATA' | translate }}</span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="upload-drop-zone" appDragDrop (fileDropped)="onFileDropped($event)">
          <p>{{ 'UPLOAD_MODULE.DRAG_DROP_HERE' | translate }}</p>
          <i class="fas fa-cloud-upload-alt"></i>
        </div>
        <input type="file" multiple (change)="onFileSelected($event)" hidden #fileInput>
      </mat-card-content>
      <mat-card-actions>
        <div class="upload-button">
          @if (files.length === 0) {
            <button mat-raised-button color="primary" onclick="this.blur()"
                    (click)="selectFile(fileInput)">{{ 'UPLOAD_MODULE.SELECT_FILE' | translate }}</button>
          } @else {
            <button mat-raised-button color="primary"
                    onclick="this.blur()" (click)="checkFile(uploadDialog)">{{ 'UPLOAD_MODULE.UPLOAD_FILE' | translate }}</button>
          }
          <button mat-raised-button color="primary" onclick="this.blur()" (click)="downloadExampleCSV()">
            {{ 'UPLOAD_MODULE.DOWNLOAD_EXAMPLE' | translate }}
            </button>
        </div>
      </mat-card-actions>
    </mat-card>
  } @else if (uploadStep === 2) {
    <mat-card class="inserts-differences-table">
      <app-upload-module-table [inserts]="insertsDataSource" [differences]="differencesDataSource"
                               (selectionChange)="onSelectionChange($event)">
      </app-upload-module-table>

    </mat-card>
    <div class="upload-button dual-button" style="width: 100%">
      <button mat-raised-button color="secondary" style="width: 200px"
              onclick="this.blur()" (click)="cancelUpload()">Cancel</button>
      <button mat-raised-button color="primary" style="width: 200px"
              onclick="this.blur()" (click)="uploadFile(uploadDialog)">
              {{ 'UPLOAD_MODULE.UPLOAD' | translate }} {{selectedInserts.length + selectedDifferences.length}} {{ 'UPLOAD_MODULE.ITEMS' | translate }}
      </button>
    </div>
  }

  @if (files.length > 0) {
    <mat-list class="file-list">
      @for (file of files; track file;) {
        <mat-list-item>
          <div class="uploaded-file">
            <span> {{ file.name }} ({{ file.size | fileSizePipe }})</span>
            <button mat-icon-button (click)="removeFile(file)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </mat-list-item>
      }
    </mat-list>
  }
</div>

<ng-template #uploadDialog>
  <h2 mat-dialog-title class="mat-dialog-title">{{ 'DIALOG.UPLOAD_TITLE' | translate }}</h2>
  <mat-dialog-content>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    @if (uploadStep === 1) {
      <p>{{ 'DIALOG.UPLOAD_CHECK_MESSAGE' | translate }}</p>
    } @else if (uploadStep === 2) {
      <p>{{ 'DIALOG.UPLOADING_MESSAGE' | translate }}</p>
    }
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>{{ 'DIALOG.CANCEL' | translate }}</button>
  </mat-dialog-actions>
</ng-template>
