import { IMapper } from 'src/app/models/i-mapper';

export class UpdateContactMapper implements IMapper {
  body?: {
    stagingContactId: string;
    oldCountryId: string;
    prefix: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    organisationName: string;
    title: string;
    website: string;
  };
  fillFromJson(_json: any): void {
    return;
  }

  fillToJson(): string {
    return JSON.stringify([this.body]);
  }
}
