<div class="home-container">
  @if (!loading && user) {
    <div class="container">
      @if (user.role === eUserRole.ADMIN || user.role === eUserRole.INTERNAL) {
        <mat-card class="menu-section">
          <h2>Datasets</h2>
          <div class="menu-datasets">
            <div class="dataset-button-container">
              @if (user.role === eUserRole.ADMIN) {
                <i class="fa-solid fa fa-cog" style="cursor: pointer" (click)="navigateToAdminRoute('test')"
                   matTooltip="Admin view"></i>
              }
              <button class="dataset-button" mat-raised-button color="primary"
                      (click)="navigateToCountryView()">
                <span>{{ 'HOME.Country View' | translate }}</span>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
            <div class="dataset-button-container">
              @if (user.role === eUserRole.ADMIN) {
                <i class="fa-solid fa fa-cog" style="cursor: pointer" (click)="navigateToAdminRoute('test')"
                   matTooltip="Admin view"></i>
              }
              <button class="dataset-button" mat-raised-button color="primary"
                      (click)="navigateToHouseholdView()">
                <span>{{ 'HOME.HOUSEHOLD' | translate }}</span>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
          </div>
        </mat-card>
      }
      @if (user.role === eUserRole.ADMIN) {
        <mat-card class="menu-section">
          <h2>Upload/Download</h2>
          <div class="menu-upload-download">
            <div class="upload-download-button-container">
              <button mat-raised-button color="primary" class="dataset-button" (click)="navigateToUploadModule()">
                <div class="button-label-container">
                  <i class="fa fa-upload"></i>
                  <span class="button-label">{{ 'HOME.UPLOAD_MODULE' | translate }}</span>
                </div>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
            <div class="upload-download-button-container">
              <button mat-raised-button color="primary" class="dataset-button" (click)="navigateToDownloadModule()">
                <div class="button-label-container">
                  <i class="fa fa-download"></i>
                  <span class="button-label">{{ 'HOME.DOWNLOAD_MODULE' | translate }}</span>
                </div>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
          </div>
        </mat-card>
      }
    </div>
    <div class="container">
      @if (user.role === eUserRole.ADMIN) {
        <mat-card class="menu-section">
          <h2>Data management</h2>
          <div class="menu-data-management">
            <div class="data-management-button-container">
              <button mat-raised-button class="dataset-button" color="primary" (click)="navigateToDataManagementCodes()">
                <div class="button-label-container">
                  <i class="fa fa-tag"></i>
                  <span class="button-label">{{ 'HOME.CODES' | translate }}</span>
                </div>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
            <div class="data-management-button-container">
              <button mat-raised-button class="dataset-button" color="primary" (click)="navigateToDataManagementRegions()">
                <div class="button-label-container">
                  <i class="fa fa-earth-america"></i>
                  <span class="button-label">{{ 'HOME.REGIONS' | translate }}</span>
                </div>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
            <div class="data-management-button-container">
              <button mat-raised-button class="dataset-button" color="primary" (click)="navigateToDataManagementContacts()">
                <div class="button-label-container">
                  <i class="fa fa-address-book"></i>
                  <span class="button-label">{{ 'HOME.CONTACTS' | translate }}</span>
                </div>
                <i class="fa-solid fa-angle-right"></i>
              </button>
              <button mat-raised-button class="dataset-button" color="primary" (click)="navigateToDataManagementAccounts()">
                <div class="button-label-container">
                  <i class="fa fa-user-tie"></i>
                  <span class="button-label">{{ 'HOME.ACCOUNTS' | translate }}</span>
                </div>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
          </div>
        </mat-card>
      }
      @if (user.role === eUserRole.ADMIN || user.role === eUserRole.INTERNAL || user.role === eUserRole.EXTERNAL) {
        <mat-card class="menu-section">
          <h2>External User Functionalities</h2>
          <div class="menu-user-functionalities">
            <div class="user-functionalities-button-container">
              <button mat-raised-button class="dataset-button" color="primary" (click)="navigateToAdminRoute('test')">
                <div class="button-label-container">
                  <i class="fa fa-bars"></i>
                  <span class="button-label">ICT Prices</span>
                </div>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
          </div>
        </mat-card>
      }
    </div>
    <div class="container">
      @if (user.role === eUserRole.ADMIN) {
        <mat-card class="menu-section">
          <h2>{{ 'HOME.VALIDATION_MODULE' | translate }}</h2>
          <div class="menu-data-management">
            <div class="data-management-button-container">
              <button mat-raised-button class="dataset-button" color="primary" (click)="navigateToCountryValidation()">
                <div class="button-label-container">
                  <i class="fa fa-earth-america"></i>
                  <span class="button-label">{{ 'HOME.COUNTRY_VALIDATION' | translate }}</span>
                </div>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
            <div class="data-management-button-container">
              <button mat-raised-button class="dataset-button" color="primary" (click)="navigateToValidation()">
                <div class="button-label-container">
                  <i class="fa fa-check"></i>
                  <span class="button-label">{{ 'HOME.VALIDATION' | translate }}</span>
                </div>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
          </div>
        </mat-card>
      }
    </div>
  }
  @if (loading) {
    <app-loader></app-loader>
  }
</div>
@if (user && user.role === eUserRole.ADMIN) {
  <div>
    <div>
      <button mat-raised-button color="primary"
              class="fixed-footer-btn" (click)="navigateToAdminRoute('admin')"
              [matTooltip]="'Admin Panel'">
        <span>{{ 'HOME.ADMIN' | translate }}</span>
        <i class="fa-solid fa-solid fa fa-cog"></i>
      </button>
    </div>
  </div>
}
