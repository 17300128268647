@if (loading) {
  <app-loader></app-loader>
}
@if (!detailOpen) {
  <div class="back-button-container">
    <button mat-button mat-raised-button color="primary" (click)="goBackToHome()"><i
        class="fa-solid fa-arrow-left"></i> {{ 'ADMIN_VIEW.BACK' | translate }}
    </button>
  </div>
  <div class="filters-class" [formGroup]="filterFormGroup">
    <ng-container>
      <div class="filters-bar">
        <div class="first-column">
          <div class="mat-drop-down">
            <mat-label>{{ 'ADMIN_VIEW.NAME_LABEL' | translate }} </mat-label>
            <input matInput type="text" formControlName="name">
          </div>
          <div class="mat-drop-down">
            <mat-label>{{ 'ADMIN_VIEW.EMAIL_LABEL' | translate }} </mat-label>
            <input matInput type="text" formControlName="email">
          </div>
          <div class="mat-drop-down">
            <mat-label>{{ 'ADMIN_VIEW.ROLE_LABEL' | translate }} </mat-label>
            <mat-form-field appearance="fill">
              @if ((filterFormGroup.controls.role.value !== '')) {
                <i class="fa fa-x clear-filter" style="top: -2px; z-index: 100"
                   (click)="clearRole($event)"
                   [matTooltip]="'Clear filter'"></i>
              }
              <mat-select formControlName="role">
                <mat-option [value]="eUsersRole.ADMIN">
                  {{ 'ADMIN_VIEW.ADMIN_USER' | translate }}
                </mat-option>
                <mat-option [value]="eUsersRole.INTERNAL">
                  {{ 'ADMIN_VIEW.INTERNAL_USER' | translate }}
                </mat-option>
                <mat-option [value]="eUsersRole.EXTERNAL">
                  {{ 'ADMIN_VIEW.COUNTRY_USER' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <span class="mat-drop-down last-item">
              <div class="button-container">
                <button mat-raised-button color="primary" type="button" class="create-user-button"
                        onclick="this.blur()" (click)="openCreateUserDialog(createUserDialog)">
                  <span>{{ 'ADMIN_VIEW.CREATE_USER' | translate }}</span>
                  <i class="fa fa-user-plus"></i>
                </button>
              </div>
            </span>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="table-holder mat-elevation-z8" tabindex="0">
    <table mat-table matSort [dataSource]="tableDataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>{{ 'ADMIN_VIEW.NAME_COLUMN' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" (click)="openDetail(element.id)">
          <span class="row-line">{{ element.name }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="surname">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>{{ 'ADMIN_VIEW.SURNAME_COLUMN' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" (click)="openDetail(element.id)">
          <span class="row-line">{{ element.surname }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>{{ 'ADMIN_VIEW.EMAIL_COLUMN' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" (click)="openDetail(element.id)">
          <span class="row-line">{{ element.email }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div mat-sort-header>{{ 'ADMIN_VIEW.ROLE_COLUMN' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" (click)="openDetail(element.id)">
          <span class="row-line">{{ displayRole(element.role) }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef appResizeColumn>
          <div>{{ 'ADMIN_VIEW.COUNTRY_COLUMN' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" (click)="openDetail(element.id)">
          <span class="row-line">{{ displayCountry(element.countryId) }}</span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
    </table>
  </div>
  <div class="paginator">
    <mat-paginator class="mat-elevation-z8 hide-ripple"
                   [length]="tableDataCount"
                   [pageSizeOptions]="[25, 50, 100, 150]">
    </mat-paginator>
  </div>
  @if (showTableError && !loading) {
    <div class="no-data-found">{{ 'ADMIN_VIEW.NO_DATA_FOUND' | translate }}</div>
  }
} @else if (detailOpen) {
  <div class="user-details-container">
      <div class="user-card">
        <mat-card style="padding-bottom: 10px; padding-left: 15px">
          <mat-card-header style="margin-bottom: 20px">
            <div mat-card-avatar class="user-avatar">
              <i class="fa fa-user"></i>
            </div>
            <mat-card-title>{{ selectedUser.name }} {{ selectedUser.surname }}</mat-card-title>
            <mat-card-subtitle>{{ selectedUser.email }}</mat-card-subtitle>
                  <button mat-raised-button color="warn" style="width: 50px; left: 150px" (click)="openDeleteUserDialog(deleteUserDialog)">
                    <i class="fa fa-trash"></i>
                  </button>
          </mat-card-header>
          <mat-card-content>
            <p><span class="detail-name">{{ 'ADMIN_VIEW.ROLE_LABEL' | translate }} </span> {{ displayRole(selectedUser.role) }}</p>
            <p><span class="detail-name">{{ 'ADMIN_VIEW.COUNTRY_LABEL' | translate }} </span> {{ displayCountry(selectedUser.countryId) }}</p>
            <p><span class="detail-name">{{ 'ADMIN_VIEW.COGNITO_LABEL' | translate }} </span> {{ selectedUser.cognitoSub ? selectedUser.cognitoSub : 'N/A' }}</p>
            <p><span class="detail-name">{{ 'ADMIN_VIEW.MODIFIED_LABEL' | translate }} </span> {{ selectedUser.lastModifiedOn | date: 'MMMM d y - h:mm a'  }}</p>
          </mat-card-content>
        </mat-card>
      </div>
    <div class="action-buttons dual-button" style="width: 100%">
      <button mat-raised-button color="secondary" style="width: 200px"
              onclick="this.blur()" (click)="goBack()">{{ 'ADMIN_VIEW.BACK' | translate }}</button>
      <button mat-raised-button color="primary" style="width: 200px"
              onclick="this.blur()" (click)="openUpdateUserDialog(updateUserDialog)">
        <i class="fa fa-pencil" style="padding-left: 0"></i>
        {{ 'ADMIN_VIEW.EDIT' | translate }}
      </button>
    </div>
  </div>
}

<ng-template #createUserDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">{{ 'ADMIN_VIEW.INSERT_USER_DETAILS' | translate }}</h2>
    <mat-dialog-content [formGroup]="createUserFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ADMIN_VIEW.NAME_LABEL' | translate }}</mat-label>
        <input matInput type="text" formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ADMIN_VIEW.SURNAME_LABEL' | translate }}</mat-label>
        <input matInput type="text" formControlName="surname">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ADMIN_VIEW.EMAIL_LABEL' | translate }}</mat-label>
        <input matInput type="text" formControlName="email">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ADMIN_VIEW.ROLE_LABEL' | translate }}</mat-label>
        <mat-select formControlName="userRole">
          <mat-option [value]="eUsersRole.ADMIN">
            {{ 'ADMIN_VIEW.ADMIN_USER' | translate }}
          </mat-option>
          <mat-option [value]="eUsersRole.INTERNAL">
            {{ 'ADMIN_VIEW.INTERNAL_USER' | translate }}
          </mat-option>
          <mat-option [value]="eUsersRole.EXTERNAL">
            {{ 'ADMIN_VIEW.COUNTRY_USER' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      @if (createUserFormGroup.controls.userRole.value === eUsersRole.EXTERNAL) {
        <mat-form-field appearance="fill">
          <mat-label>{{ 'ADMIN_VIEW.COUNTRY_LABEL' | translate }}</mat-label>
          <mat-select formControlName="countryID">
            <mat-option *ngFor="let country of countries" [value]="country.countryID">
              {{ country.shortName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      }
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary" style="width: 120px"
              (click)="createUser()" [disabled]="!createUserFormGroup.valid || creatingUser">
        @if (creatingUser) {
          <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
        } @else {
          <span>{{ 'ADMIN_VIEW.CREATE_USER' | translate }}</span>
        }
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #updateUserDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">{{ 'ADMIN_VIEW.UPDATE_USER_DETAILS' | translate }}</h2>
    <mat-dialog-content [formGroup]="updateUserFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ADMIN_VIEW.NAME_LABEL' | translate }}</mat-label>
        <input matInput type="text" formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ADMIN_VIEW.EMAIL_LABEL' | translate }}</mat-label>
        <input matInput type="text" formControlName="surname">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ADMIN_VIEW.ROLE_LABEL' | translate }}</mat-label>
        <mat-select formControlName="userRole">
          <mat-option [value]="eUsersRole.ADMIN">
            {{ 'ADMIN_VIEW.ADMIN_USER' | translate }}
          </mat-option>
          <mat-option [value]="eUsersRole.INTERNAL">
            {{ 'ADMIN_VIEW.INTERNAL_USER' | translate }}
          </mat-option>
          <mat-option [value]="eUsersRole.EXTERNAL">
            {{ 'ADMIN_VIEW.COUNTRY_USER' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      @if (updateUserFormGroup.controls.userRole.value === eUsersRole.EXTERNAL) {
        <mat-form-field appearance="fill">
          <mat-label>{{ 'ADMIN_VIEW.COUNTRY_LABEL' | translate }}</mat-label>
          <mat-select formControlName="countryID">
            <mat-option *ngFor="let country of countries" [value]="country.countryID">
              {{ country.shortName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      }
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="reset()">{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary" style="width: 100px"
              (click)="updateUser()" [disabled]="!updateUserFormGroup.valid || updatingUser">
        @if (updatingUser) {
          <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
        } @else {
          <span>{{ 'ADMIN_VIEW.UPDATE' | translate }}</span>
        }
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #deleteUserDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">{{ 'ADMIN_VIEW.DELETE_USER' | translate }}</h2>
    <mat-dialog-content>
      <p>{{ 'ADMIN_VIEW.DELETE_USER_CONFIRMATION' | translate }}</p>
    </mat-dialog-content>
    <mat-dialog-actions style="display: flex; justify-content: space-between">
      <button mat-raised-button mat-dialog-close>{{ 'DIALOG.CANCEL' | translate }}</button>
      <button mat-raised-button color="warn" style="width: 80px"
              (click)="deleteUser()" [disabled]="deletingUser">
        @if (deletingUser) {
          <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
        } @else {
          <span>{{ 'ADMIN_VIEW.DELETE' | translate }}</span>
        }
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>

