import { IMapper } from '../../../models/i-mapper';

export class GetJobProgressMapper implements IMapper {
  progress?: string;

  fillFromJson(data: any): void {
    this.progress = data;
  }

  fillToJson(): string {
    return '';
  }
}
