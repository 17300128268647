import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { CountrySurveyResponseModel } from '../models/country-survey-model';
import { SurveyStatusEnum } from '../enums/survey-status-enum';
import { SurveyValidationStatusEnum } from '../enums/survey-validation-status-enum';
import { environment } from 'src/environments/environment';
import { CountrySurveyMapper } from '../mappers/country-survey-mapper';

@Injectable({
  providedIn: 'root',
})
export class SurveyCountryTableService {
  private networkService!: NetworkService;
  private emptyMapper = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fillFromJson(_json: any): void {},
    fillToJson(): string {
      return '{}';
    },
    toString(): string {
      return '';
    },
  };
  private baseUrl = `${environment.backend.endpoint}/validation-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getCountryList(
    pageIndex: number,
    pageSize: number,
    sortCol: string,
    sortType: string,
    surveyID: string,
    countries?: string[],
    surveyStatus?: SurveyStatusEnum,
    validationStatus?: SurveyValidationStatusEnum
  ): Promise<CountrySurveyResponseModel> {
    const mapper = new CountrySurveyMapper();
    await this.networkService?.get(
      `${this.baseUrl}/survey/${surveyID}/list`,
      mapper,
      {
        countries: countries?.join(','),
        surveyStatus: surveyStatus,
        validationStatus: validationStatus,
        orderBy: sortCol,
        order: sortType,
        limit: pageSize.toString(),
        offset: pageIndex.toString(),
      }
    );
    return mapper.countrySurveyList!;
  }

  async startCountryValidation(
    surveyID: string,
    countryID: string
  ): Promise<void> {
    await this.networkService?.post(
      `${this.baseUrl}/survey/${surveyID}/country/${countryID}/start`,
      this.emptyMapper,
      this.emptyMapper
    );
  }
}
