import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatorUiComponent } from './form-utils/validator-ui/validator-ui.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TableUiComponent } from './form-utils/table/table-ui/table-ui.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomSnackbarComponent } from './form-utils/custom-snackbar/custom-snackbar.component';
import {MatButtonModule} from "@angular/material/button";

@NgModule({ declarations: [
        ValidatorUiComponent,
        TableUiComponent,
        CustomSnackbarComponent,
    ],
    exports: [ValidatorUiComponent, TableUiComponent], imports: [CommonModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatButtonModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule {}
