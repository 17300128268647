import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DatabasesListModel } from '../../codes-management/models/databases-model';
import { CollectionsModel } from '../../codes-management/models/collections-model';
import { ProviderService } from 'src/app/core/provider.service';

@Component({
  selector: 'app-base-code-step',
  templateUrl: './base-code-step.component.html',
  styleUrls: ['./base-code-step.component.scss'],
})
export class BaseCodeStepComponent implements OnInit {
  @Input({ required: true })
  stepper!: MatStepper;
  @Input({ required: true })
  formGroup!: FormGroup;
  databasesList: DatabasesListModel[] = [];
  collectionsList: CollectionsModel[] = [];
  loading = false;

  constructor(private providerService: ProviderService) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.getDatabases();
    await this.getCollections();
    this.loading = false;
  }

  enforceMinMax(el: any): void {
    const srcElement = el.srcElement;
    if (srcElement.value != '') {
      if (parseInt(srcElement.value) < parseInt(srcElement.min)) {
        srcElement.value = srcElement.min;
      }
      if (parseInt(srcElement.value) > parseInt(srcElement.max)) {
        srcElement.value = srcElement.max;
      }
    }
  }

  async getDatabases(): Promise<void> {
    try {
      this.databasesList =
        await this.providerService.codesManagementService.getDatabasesList();
      this.formGroup.controls['database'].setValue(
        this.databasesList.find((value) => (value.databaseName = 'HOUSEHOLDS'))
      );
      this.formGroup.controls['database'].disable();
    } catch (error) {
      console.error(error);
    }
  }

  async getCollections(): Promise<void> {
    try {
      this.collectionsList =
        await this.providerService.codesManagementService.getCollectionsList();
    } catch (error) {
      console.error(error);
    }
  }
}
