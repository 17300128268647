@if(loading) {
  <app-loader></app-loader>
}
<div class="step-title">
  <h3>{{'BULK_CODE_CREATE.BASE_CODE' | translate}}</h3>
</div>
<div class="content-container">
  <form [formGroup]="formGroup">
    <div class="mat-drop-down">
      <mat-form-field appearance="fill">
        <mat-label>{{'BULK_CODE_CREATE.CODE_LABEL' | translate}}</mat-label>
        <input matInput type="text" formControlName="codeName">
      </mat-form-field>
    </div>
    <div class="mat-drop-down">
      <mat-form-field appearance="fill">
        <mat-label>{{'BULK_CODE_CREATE.CODE_DESC_LABEL' | translate}}</mat-label>
        <textarea matInput cdkTextareaAutosize formControlName="codeDescription"></textarea>
      </mat-form-field>
    </div>
    <div class="mat-drop-down">
      <mat-form-field appearance="fill">
        <mat-label>{{'BULK_CODE_CREATE.DEFINITION_LABEL' | translate}}</mat-label>
        <input matInput type="text" formControlName="definition">
      </mat-form-field>
    </div>
    <div class="mat-drop-down">
      <mat-form-field appearance="fill">
        <mat-label>{{'BULK_CODE_CREATE.DATABASE_LABEL' | translate}}</mat-label>
        <mat-select formControlName="database">
          <mat-option *ngFor="let db of databasesList" [value]="db">
            {{ db.databaseName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="mat-drop-down">
      <mat-form-field appearance="fill">
        <mat-label>{{'BULK_CODE_CREATE.COLLECTION_LABEL' | translate}}</mat-label>
        <mat-select formControlName="collection">
          <mat-option *ngFor="let collection of collectionsList" [value]="collection">
            {{ collection.collectionName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="mat-drop-down">
      <mat-form-field appearance="fill">
        <mat-label>{{'BULK_CODE_CREATE.DECIMAL_NUMBER_LABEL' | translate}}</mat-label>
        <input matInput type="number" min="0" max="99" (input)="enforceMinMax($event)" formControlName="decimalNumber">
      </mat-form-field>
    </div>
  </form>
</div>
<div class="step-buttons-container">
  <button style="visibility: hidden;"></button>
  <button mat-raised-button color="primary" (click)="stepper.next()">{{'BULK_CODE_CREATE.NEXT' | translate }}</button>
</div>