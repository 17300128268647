import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { CellSourcesModel } from './models';
import { CellSourcesMapper, UpdateSourceMapper } from './getCellSourcesMapper';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ModifyOneRowService {
  networkService?: NetworkService;
  baseUrl = `${environment.backend.endpoint}/country-update`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getCellSources(cellIds: string[]): Promise<CellSourcesModel[]> {
    const param = {
      cellIds: cellIds,
    };
    const mapper = new CellSourcesMapper();
    await this.networkService?.get(`${this.baseUrl}/list-sources`, mapper, {
      cellIds: btoa(JSON.stringify(param)),
    });
    return mapper.cellSources;
  }

  async updateSources(userModifications: CellSourcesModel[]): Promise<void> {
    const mapper = new UpdateSourceMapper();
    mapper.sourcesToBeModified = userModifications;
    await this.networkService?.post(
      `${this.baseUrl}/update-sources`,
      mapper,
      mapper
    );
  }
}
