import { Component } from '@angular/core';
import { ProviderService } from '../../core/provider.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-upload-module',
  templateUrl: './upload-module.component.html',
  styleUrl: './upload-module.component.scss',
})
export class UploadModuleComponent {
  uploadTableDataLoader = false;
  uploadStep = 1;
  files: File[] = [];
  checkDataResult: any;
  insertsDataSource: any;
  differencesDataSource: any;
  displayedColumns = [
    'code',
    'dataYear',
    'isoCode',
    'notes',
    'notesInternal',
    'source',
    'value',
  ];

  constructor(
    private providerService: ProviderService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  onFileDropped(files: FileList): void {
    this.handleFileInput(files);
  }

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    this.handleFileInput(files);
  }

  handleFileInput(files: FileList): void {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
    }
  }

  removeFile(file: File): void {
    this.files = this.files.filter((f) => f !== file);
  }

  async checkFile(dialogRef: any): Promise<void> {
    let dialog;
    try {
      this.uploadTableDataLoader = true;
      dialog = this.dialog.open(dialogRef, {
        width: '500px',
        disableClose: true,
        autoFocus: false,
      });
      const file = this.files[0];
      const csvPayload = (await this.readFileAsText(file)).trim();
      this.checkDataResult =
        await this.providerService.uploadDataService.uploadCheckData(
          csvPayload
        );
      this.uploadStep = 2;
      this.insertsDataSource = this.checkDataResult.inserts;
      this.differencesDataSource = this.checkDataResult.differences;
      dialog.close();
      this.files = [];
    } catch (e) {
      dialog?.close();
      this.snackBar.open('An error occured while uploading the file', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.uploadTableDataLoader = false;
    }
  }

  async uploadFile(dialogRef: any): Promise<void> {
    let dialog;
    try {
      this.uploadTableDataLoader = true;
      dialog = this.dialog.open(dialogRef, {
        width: '500px',
        disableClose: true,
        autoFocus: false,
      });
      console.log(this.checkDataResult);
      await this.providerService.uploadDataService.uploadData(
        this.checkDataResult.inserts,
        this.checkDataResult.differences
      );
      dialog.close();
      this.files = [];
      this.snackBar.open('Data uploaded successfully!', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
    } catch (e) {
      dialog?.close();
      this.snackBar.open('An error occured while uploading the file', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.uploadTableDataLoader = false;
      this.uploadStep = 1;
    }
  }

  getOldAndNewValues(element: any, columnName: string): string {
    const currentElement = element.differencesData.find(
      (d: any) => d.valueType === columnName
    );
    return `${currentElement?.originalValue} → ${currentElement?.newValue}`;
  }

  readFileAsText(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsText(file);
    });
  }

  cancelUpload(): void {
    this.uploadTableDataLoader = false;
    this.uploadStep = 1;
    this.files = [];
  }
}
