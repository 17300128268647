import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appFormatNumberInput]',
  standalone: true,
})
export class FormatNumberInputDirective {
  @Input() numDigits = 2;
  @Output() dataChanged = new EventEmitter<number | null>();

  private el: any;
  private actualValue?: number;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(): void {
    this.el.value = this.transform(this.actualValue?.toString() ?? '', true);
  }

  @HostListener('change', ['$event.target.value'])
  onChange(value: any): void {
    this.actualValue = value;
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: any): void {
    if (value.length === 0) {
      value = undefined;
      if (value !== this.actualValue) {
        this.dataChanged.emit(null);
        this.actualValue = value;
      }
      setTimeout(() => {
        this.el.value = null;
      });
    } else {
      const numberValue = parseFloat(this.removeCommasAndDots(value));
      if (numberValue !== this.actualValue) {
        this.dataChanged.emit(value.length > 0 ? numberValue : null);
        this.actualValue = numberValue;
      }
      setTimeout(() => {
        this.el.value = this.transform(value);
      });
    }
  }

  removeCommasAndDots(numberString: string): string {
    return numberString.replace(/,|\.00$/g, '').trim();
  }

  ngOnInit(): void {
    setTimeout(() => {
      const numberValue = parseFloat(this.el.value);
      if (!isNaN(numberValue)) {
        this.actualValue = numberValue;
      }
      this.el.value = this.transform(this.el.value);
    });
  }

  transform(value: string, ignoreDigits = false): string {
    const cleanValue = this.removeCommasAndDots(value);
    const floatValue = parseFloat(cleanValue);
    if (!isNaN(floatValue)) {
      if (value) {
        const fixedValue = ignoreDigits
          ? parseFloat(cleanValue).toString()
          : parseFloat(cleanValue).toFixed(this.numDigits);
        const parts = fixedValue.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
      } else {
        return '';
      }
    } else {
      if (this.actualValue) {
        return ignoreDigits
          ? this.actualValue.toString() ?? ''
          : this.actualValue.toFixed(this.numDigits) ?? '';
      } else {
        return '';
      }
    }
  }
}
