import { IMapper } from '../../../../models/i-mapper';
import { UnitModel } from '../models/unit-model';

export class UnitsListMapper implements IMapper {
  unitsList: UnitModel[] = [];
  fillFromJson(json: any): void {
    json.forEach((units: any) => {
      this.unitsList.push({
        unitId: units.unitID,
        unitDescription: units.unitDescription,
        unitAbb: units.unitAbb,
      });
    });
  }
  fillToJson(): string {
    return '';
  }
}
