import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appFormatStringInput]',
  standalone: true,
})
export class FormatStringInputDirective implements OnInit {
  @Input() numDigits = 2;
  @Output() dataChanged = new EventEmitter<string | null>();

  private el: any;
  private oldValue?: string | null;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.oldValue = this.el.value;
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: any): void {
    if (value.length === 0) {
      if (value !== this.oldValue) {
        this.dataChanged.emit(null);
        this.oldValue = value;
      }
      setTimeout(() => {
        this.el.value = null;
      });
    } else {
      if (value !== this.oldValue) {
        this.dataChanged.emit(value);
        this.oldValue = value;
      }
    }
  }
}
