@if (loading) {
<app-loader></app-loader>
}
<mat-tab-group [(selectedIndex)]="selectedTabIndex">
  <mat-tab label="{{'VALIDATION_MODULE_TABLE.VALIDATE' | translate}}">
    <div class="container">
      <div class="filters-class">
        <div class="filters-bar" [formGroup]="filterFormGroup">
          <div class="first-column">
            <div class="mat-drop-down">
              <mat-label>{{ 'VALIDATION_MODULE_TABLE.PERCENTAGE_CHANGE' | translate }}</mat-label>
              <input class="short-number-input" (input)="enforceMinMax($event)" type="number" min="-200" max="200"
                formControlName="percentageChange">
            </div>
            <div class="mat-drop-down">
              <mat-label>{{ 'VALIDATION_MODULE_TABLE.YEAR_FROM' | translate }}</mat-label>
              <input matInput type="number" formControlName="yearFrom">
            </div>
            <div class="mat-drop-down">
              <mat-label>{{ 'VALIDATION_MODULE_TABLE.YEAR_TO' | translate }}</mat-label>
              <input matInput type="number" formControlName="yearTo">
            </div>
            <div class="mat-drop-down">
              <app-code-filter (codeSelectionChanged)="codeSelectionChanged($event)"></app-code-filter>
            </div>
          </div>
          <div class="last-column">
            <button mat-raised-button color="primary" (click)="openCompleteValidationDialog(completeValidationDialog)">
              <span>{{'VALIDATION_MODULE_TABLE.COMPLETE_SURVEY' | translate}}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="table-holder mat-elevation-z8" tabindex="0">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (click)="$event.stopPropagation()" [checked]="allRowsSelected"
                [indeterminate]="allRowsSelected === 'indeterminate'" (change)="checkAllRows($event)">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" class="checkbox-cell">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="row.checked" [indeterminate]="row.checked === 'indeterminate'"
                (change)="checkedChanged(row, $event)">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="codeName">
            <th mat-header-cell *matHeaderCellDef appResizeColumn>
              <div mat-header>{{ 'VALIDATION_MODULE_TABLE.CODE_NAME_HEADER' | translate }}</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="row-line">
                {{ element.codeName }}
              </span>
            </td>
          </ng-container>
          @for (year of yearsToDisplay; track year) {
          <ng-container [matColumnDef]="year">
            <th mat-header-cell *matHeaderCellDef appResizeColumn>
              <div>{{ year }}</div>
            </th>
            <td mat-cell *matCellDef="let element"
              class="input-cell {{isCellSelected(element, year) ? 'cell-selected': ''}}">
              <div class="input-container">
                <input matInput type="text" (click)="setSelectedCell(element, year)" appFormatNumberInput
                  class="form-control custom-number-input" [value]="element.values[year]"
                  (dataChanged)="setCellValue(element, year, $event.value)" />
              </div>
            </td>
          </ng-container>
          }
          @for (year of yearsToDisplay; track year) {
          <ng-container [matColumnDef]="year + ' %'">
            <th mat-header-cell *matHeaderCellDef appResizeColumn>
              <div>{{ year + ' %' }}</div>
            </th>
            <td mat-cell *matCellDef="let element" class="input-cell">
              <span class="row-line">
                {{ element.percentages[year] }}
              </span>
            </td>
          </ng-container>
          }
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [colSpan]="displayedColumns?.length">
              @if(!loading) {{{ 'VALIDATION_MODULE_LIST_CODES.NO_DATA_FOUND' | translate}}}
            </td>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
        </table>
      </div>
      <div class="paginator">
        <mat-paginator class="hide-ripple" [length]="tableCount" [pageSizeOptions]="[50, 100, 150]">
        </mat-paginator>
      </div>
      <div class="data-info-container">
        @if(!selectedCell) {
        <div class="no-cell-warning">
          <span>{{'VALIDATION_MODULE_TABLE.NO_CELL_SELECTED' | translate}}</span>
        </div>
        } @else if (!historyLoading && !selectedCellHasId) {
        <div class="no-cell-warning">
          <span>{{'VALIDATION_MODULE_TABLE.NO_VALUE_SELECTED' | translate}}</span>
        </div>
        }
        <div class="data-info">
          <div class="notes-group">
            <div class="notes-container">
              <div class="notes-row">
                <span class="first">{{'VALIDATION_MODULE_TABLE.NOTES' | translate}}</span>
                <input #publishedNote matInput [disabled]="!selectedCell" (input)="notesSelectionChanged($event)"
                  (focusout)="publishedNote.value.length > 0 ? updateNotes() : updateNotes('')"
                  (keydown.enter)="publishedNote.value.length > 0 ? updateNotes() : updateNotes('')" type="text"
                  [value]="selectedCell === undefined ? '' : selectedCell.cell.notes[selectedCell.year]">
              </div>
            </div>
          </div>
          <div class="data-history">
            <span>{{'VALIDATION_MODULE_TABLE.DATA_HISTORY' | translate}}</span>
            @if (historyLoading) {
            <i class="fa-solid fa-ellipsis ellipses-loader"
              style="position: absolute; left: 120px; top: 15px; margin-left: 40px; margin-top: 5px"></i>
            }
            <div class="data-history-list">
              @if (!historyLoading) {
              @for (comment of cellHistory; track comment) {
              @if(comment.type === 'insert') {
              <span>
                {{'VALIDATION_MODULE_TABLE.VALUE' | translate}} {{comment.value ? comment.value: null}}
                {{'VALIDATION_MODULE_TABLE.INSERTED' |
                translate}} {{'VALIDATION_MODULE_TABLE.BY' | translate}} {{comment.modifiedBy}}
                {{'VALIDATION_MODULE_TABLE.ON' | translate}} {{comment.modifiedOn}}
              </span>
              }
              @if(comment.type === 'modification') {
              <span>
                {{'VALIDATION_MODULE_TABLE.VALUE' | translate}} {{comment.value ? comment.value: null}}
                {{'VALIDATION_MODULE_TABLE.MODIFIED' |
                translate}} {{'VALIDATION_MODULE_TABLE.BY' | translate}} {{comment.modifiedBy}}
                {{'VALIDATION_MODULE_TABLE.ON' | translate}} {{comment.modifiedOn}}
              </span>
              }
              @if(comment.type === 'note') {
              <span>
                {{'VALIDATION_MODULE_TABLE.NOTE' | translate}} {{comment.note}} {{'VALIDATION_MODULE_TABLE.MODIFIED' |
                translate}} {{'VALIDATION_MODULE_TABLE.BY' | translate}} {{comment.modifiedBy}}
                {{'VALIDATION_MODULE_TABLE.ON' | translate}} {{comment.modifiedOn}}
              </span>
              }
              }
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{'VALIDATION_MODULE_TABLE.COMPARE' | translate}}">
    @if(compareStep === 0) {
    <app-survey-country-table [selectedSurvey]="selectedSurvey!" [omitActions]="true"
      (countrySelected)="this.selectCompareCountry($event.country)"></app-survey-country-table>
    } @else {
    <button mat-button mat-raised-button color="primary" class="back-button" (click)="goBackToCompare(0)"><i
        class="fa-solid fa-arrow-left"></i> {{ 'VALIDATION_MODULE_TABLE.SELECT_COUNTRY' | translate }}
    </button>
    <app-country-validation-table [selectedSurvey]="selectedSurvey" [selectedCountry]="selectedCountry"
      [selectedCountryChanged]="compareSelectedCountryChanged.asObservable()"></app-country-validation-table>
    }
  </mat-tab>
</mat-tab-group>

<ng-template #completeValidationDialog>
  <div class="container-dialog-select-columns">
    <h2 mat-dialog-title class="mat-dialog-title">{{'VALIDATION_MODULE_TABLE.CONFIRMATION_TITLE' | translate}}</h2>
    @if (validationCompleting) {
    <mat-dialog-content>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <p style="margin-top: 10px">{{'VALIDATION_MODULE_TABLE.SENDING_DATA_TO_LIVE' | translate}}</p>
    </mat-dialog-content>
    } @else {
    <mat-dialog-content>
      <span>
        {{'VALIDATION_MODULE_TABLE.CONFIRMATION_MESSAGE' | translate}}
      </span>
    </mat-dialog-content>
    }
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close>{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="{{validationCompleting ? 'disabled': 'primary'}}"
        (click)="completeValidation()" [disabled]="validationCompleting">
        <span>{{'VALIDATION_MODULE_TABLE.COMPLETE_SURVEY' | translate}}</span>
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>