import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { environment } from '../../../../environments/environment';
import {
  ContactDetailsMapper,
  ContactHistoryMapper,
  ContactListMapper,
  CreateUpdateContactMapper,
  DeleteContactMapper,
} from '../mappers/contact-mapper';
import { ContactModel, ContactsListModel } from '../models/contact-model';
import { SurveySeriesModel } from '../models/survey-series-model';
import { SurveySeriesListMapper } from '../mappers/survey-series-mapper';
import { SyncQualtricsMapper } from '../mappers/sync-qualtrics-mapper';
import { GetJobProgressMapper } from '../mappers/get-job-progress-mapper';
import { AccountModel } from '../models/account-model';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private networkService!: NetworkService;
  baseUrl = `${environment.backend.endpoint}/contact-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getContactAndCount(
    limit?: number,
    offset?: number,
    orderBy?: string,
    order?: 'asc' | 'desc',
    countryId?: string,
    surveySeriesIds?: string,
    linkedToAccount?: string | null
  ): Promise<ContactsListModel> {
    const contactListMapper = new ContactListMapper();
    await this.networkService?.get(
      `${this.baseUrl}/contact`,
      contactListMapper,
      {
        limit: limit?.toString(),
        offset: offset?.toString(),
        orderBy: orderBy ?? 'first_name',
        order: order ?? 'asc',
        countryId: countryId,
        surveySeriesIds:
          surveySeriesIds?.length === 0 ? undefined : surveySeriesIds,
        linkedToAccount: linkedToAccount,
      }
    );
    return {
      contacts: contactListMapper.contacts,
      count: contactListMapper.count,
    };
  }

  async getContactDetails(contactId: string): Promise<ContactModel> {
    const contactDetailsMapper = new ContactDetailsMapper();
    await this.networkService?.get(
      `${this.baseUrl}/contact/${contactId}`,
      contactDetailsMapper
    );
    return contactDetailsMapper.contact;
  }

  async getContactHistory(contactId: string): Promise<any> {
    const contactHistoryMapper = new ContactHistoryMapper();
    await this.networkService?.get(
      `${this.baseUrl}/history`,
      contactHistoryMapper,
      { contactId }
    );
    return contactHistoryMapper.history;
  }

  async getSurverySeries(): Promise<SurveySeriesModel[]> {
    const surveySeriesListMapper = new SurveySeriesListMapper();
    await this.networkService?.get(
      `${this.baseUrl}/survey_series`,
      surveySeriesListMapper
    );
    return surveySeriesListMapper.surveySeries;
  }

  async createContact(
    countryId: string,
    surveySeriesIds: string,
    prefix: string,
    firstName: string,
    lastName: string,
    title: string,
    organisationName: string,
    phoneNumber: string,
    email: string,
    website: string,
    internalNote?: string | undefined,
    linkedAccountIds?: string | undefined
  ): Promise<void> {
    const createContactMapper = new CreateUpdateContactMapper();
    createContactMapper.countryId = countryId;
    createContactMapper.surveySeriesIds = surveySeriesIds;
    createContactMapper.prefix = prefix;
    createContactMapper.firstName = firstName;
    createContactMapper.lastName = lastName;
    createContactMapper.title = title;
    createContactMapper.organisationName = organisationName;
    createContactMapper.phoneNumber = phoneNumber;
    createContactMapper.email = email;
    createContactMapper.website = website;
    createContactMapper.internalNote = internalNote ?? null;
    if (linkedAccountIds) {
      createContactMapper.linkedAccountIds = linkedAccountIds
        .replace(/;/g, ',')
        .split(',');
    } else {
      createContactMapper.linkedAccountIds = null;
    }
    await this.networkService?.post(
      `${this.baseUrl}/contact`,
      createContactMapper,
      createContactMapper
    );
  }

  async updateContact(
    contactId: string,
    countryId: string,
    surveySeriesIds: string,
    prefix: string,
    firstName: string,
    lastName: string,
    title: string,
    organisationName: string,
    phoneNumber: string,
    email: string,
    website: string,
    internalNote?: string | undefined,
    linkedAccountIds?: string | undefined
  ): Promise<void> {
    const updateContactMapper = new CreateUpdateContactMapper();
    updateContactMapper.countryId = countryId;
    updateContactMapper.surveySeriesIds = surveySeriesIds;
    updateContactMapper.prefix = prefix;
    updateContactMapper.firstName = firstName;
    updateContactMapper.lastName = lastName;
    updateContactMapper.title = title;
    updateContactMapper.organisationName = organisationName;
    updateContactMapper.phoneNumber = phoneNumber;
    updateContactMapper.email = email;
    updateContactMapper.website = website;
    updateContactMapper.internalNote = internalNote ?? null;
    if (linkedAccountIds) {
      updateContactMapper.linkedAccountIds = linkedAccountIds
        .replace(/;/g, ',')
        .split(',');
    } else {
      updateContactMapper.linkedAccountIds = null;
    }
    await this.networkService?.put(
      `${this.baseUrl}/contact/${contactId}`,
      updateContactMapper,
      updateContactMapper
    );
  }

  async deleteContact(id: string): Promise<void> {
    const deleteContactMapper = new DeleteContactMapper();
    await this.networkService?.delete(
      `${this.baseUrl}/contact/${id}`,
      deleteContactMapper
    );
  }

  async syncWithQualtrics(surveyId: number, type: string): Promise<string> {
    const mapper = new SyncQualtricsMapper();
    mapper.body = {
      type,
    };
    await this.networkService.put(
      `${this.baseUrl}/qualtrics-survey/${surveyId}/sync-person-contact-list`,
      mapper,
      mapper
    );
    return mapper.jobId!;
  }

  async getJobProgress(jobId: string): Promise<string> {
    const mapper = new GetJobProgressMapper();
    await this.networkService.get(
      `${this.baseUrl}/sync-qualtrics-contacts/${jobId}/state`,
      mapper
    );
    return mapper.progress!;
  }

  calculateHistoryDifferences(arr: any[]): any[] {
    let diffArray: any[] = [];
    if (arr.length > 0) {
      diffArray = [];
      for (let i = 0; i < arr.length; i++) {
        const diffObj: any = {
          lastModifiedOn: arr[i].lastModifiedOn,
          lastModifiedBy:
            arr[i].userName && arr[i].userSurname
              ? arr[i].userName + ' ' + arr[i].userSurname
              : arr[i].userName || arr[i].userSurname || undefined,
          version: arr[i].version,
        };
        if (arr[i].version > 0) {
          for (const key in arr[i]) {
            if (
              arr[i][key] !== arr[i - 1][key] &&
              key !== 'lastModifiedOn' &&
              key !== 'lastModifiedBy' &&
              key !== 'userName' &&
              key !== 'userSurname'
            ) {
              diffObj[key] = arr[i][key];
            }
          }
        }
        diffArray.push(diffObj);
      }
    }
    return diffArray.reverse();
  }
}
