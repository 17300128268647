// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.
const environment = {
  identityPoolId: 'eu-central-1:8da5b2f3-0072-4e2c-ac77-e83220332792',
  backend: {
    endpoint: 'https://8lxh7jbuof.execute-api.eu-central-1.amazonaws.com/prod',
  },
  cognito: {
    userPoolId: 'eu-central-1_LbHB5veQI',
    userPoolWebClientId: '7rhi84hrb3cusbpc7cjjr9rugm',
  },
  ituClassId: 'cc37a1f6-b85a-45c4-b95b-508e66110c6b',
};

export { environment };
