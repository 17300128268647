<div class="form-container filters-bar" [formGroup]="disaggregationsFormGroup">
  <div class="mat-drop-down">
    <mat-label class="label">{{'FILTERS.DISAGGREGATION_LABEL' | translate}} {{level}}:</mat-label>
    <mat-select [formControl]="disaggregationsFormGroup.controls.disaggregation"
      (openedChange)="onOpenedUnitChange($event)" multiple>
      <mat-select-trigger>
        <span [matTooltip]="displayMultipleDisaggregationsFn(disaggregationsFormGroup.controls.disaggregation.value)">
          {{displayDisaggregationFn(disaggregationsFormGroup.controls.disaggregation.value?.[0]) || ''}}
          @if ((disaggregationsFormGroup.controls.disaggregation.value?.length || 0) > 1) {
          <span>
            (+{{(disaggregationsFormGroup.controls.disaggregation.value?.length || 0) - 1}}
            {{disaggregationsFormGroup.controls.disaggregation.value?.length === 2 ? 'other' : 'others'}})
          </span>
          }
        </span>
        @if ((disaggregationsFormGroup.controls.disaggregation.value?.length || 0) > 0) {
        <i (click)="removeDisaggregationFilter($event)" class="fa fa-x clear-filter"
          matTooltip="{{'FILTERS.CLEAR_FILTERS' | translate}}"></i>
        }
      </mat-select-trigger>
      <input matInput type="text" formControlName="autocompleteDisaggregation" class="autocomplete-input"
        (keydown)="$event.stopPropagation()" #disaggregationInputElement
        placeholder="{{ 'FILTERS.AUTOCOMPLETE_PLACEHOLDER' | translate }}">
      @for (disaggregation of disaggregations; track disaggregation) {
      <mat-option [ngClass]="visuallyFilterDisaggregations(disaggregation) ? '' : 'hidden'" [value]="disaggregation">
        <span>
          {{ disaggregation.disaggregationDescription }}
        </span>
      </mat-option>
      }
    </mat-select>
  </div>
</div>