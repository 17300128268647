import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { environment } from '../../../../environments/environment';
import {
  AccountDetailsMapper,
  AccountListMapper,
  CreateUpdateAccountMapper,
  DeleteAccountMapper,
} from '../mappers/account-mapper';
import { AccountModel, AccountsListModel } from '../models/account-model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private eventSubject = new Subject<string>();
  private networkService!: NetworkService;
  baseUrl = `${environment.backend.endpoint}/account-module`;
  event$ = this.eventSubject.asObservable();

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  changeTitle(data: string): void {
    this.eventSubject.next(data);
  }

  async getAccountAndCount(
    limit?: number,
    offset?: number,
    orderBy?: string,
    order?: 'asc' | 'desc',
    countryId?: string,
    surveySeriesIds?: string,
    name?: string,
    language?: string
  ): Promise<AccountsListModel> {
    const accountListMapper = new AccountListMapper();
    await this.networkService?.get(
      `${this.baseUrl}/account`,
      accountListMapper,
      {
        limit: limit?.toString(),
        offset: offset?.toString(),
        orderBy: orderBy ?? 'account_name',
        order: order ?? 'asc',
        countryId: countryId,
        surveySeriesIds:
          surveySeriesIds?.length === 0 ? undefined : surveySeriesIds,
        name: name,
        language: language,
      }
    );
    return {
      accounts: accountListMapper.accounts,
      count: accountListMapper.count,
    };
  }

  async getAccountDetails(accountId: string): Promise<AccountModel> {
    const accountDetailsMapper = new AccountDetailsMapper();
    await this.networkService?.get(
      `${this.baseUrl}/account/${accountId}`,
      accountDetailsMapper
    );
    return accountDetailsMapper.account;
  }

  async createAccount(
    countryId: string,
    surveySeriesIds: string,
    source: string,
    accountName: string,
    password: string,
    language?: string | undefined,
    internalNote?: string | undefined
  ): Promise<void> {
    const createAccountMapper = new CreateUpdateAccountMapper();
    createAccountMapper.countryId = countryId;
    createAccountMapper.surveySeriesId = surveySeriesIds;
    createAccountMapper.source = source;
    createAccountMapper.accountName = accountName;
    createAccountMapper.password = password;
    createAccountMapper.language = language ?? null;
    createAccountMapper.internalNote = internalNote ?? null;
    await this.networkService?.post(
      `${this.baseUrl}/account`,
      createAccountMapper,
      createAccountMapper
    );
  }

  async updateAccount(
    accountId: string,
    countryId: string,
    surveySeriesIds: string,
    source: string,
    accountName: string,
    password: string,
    language?: string | undefined,
    internalNote?: string | undefined
  ): Promise<void> {
    const updateAccountMapper = new CreateUpdateAccountMapper();
    updateAccountMapper.countryId = countryId;
    updateAccountMapper.surveySeriesId = surveySeriesIds;
    updateAccountMapper.source = source;
    updateAccountMapper.accountName = accountName;
    updateAccountMapper.password = password;
    updateAccountMapper.language = language ?? null;
    updateAccountMapper.internalNote = internalNote ?? null;
    await this.networkService?.put(
      `${this.baseUrl}/account/${accountId}`,
      updateAccountMapper,
      updateAccountMapper
    );
  }

  async deleteAccount(accountId: string): Promise<void> {
    const deleteAccountMapper = new DeleteAccountMapper();
    await this.networkService?.delete(
      `${this.baseUrl}/account/${accountId}`,
      deleteAccountMapper
    );
  }
}
