import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  RegionsCountriesListModel,
  RegionsListsModel,
} from '../regions-management/models/region-filters-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ProviderService } from '../../core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { debounceTime, interval, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ContactModel } from './models/contact-model';
import { SurveySeriesModel } from './models/survey-series-model';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AccountModel } from './models/account-model';
import { MatCheckbox } from '@angular/material/checkbox';
import { CountriesListsModel } from '../update-country-table/models/country-filters-model';

@Component({
  selector: 'app-contacts-management',
  templateUrl: './contacts-module.component.html',
  styleUrl: './contacts-module.component.scss',
})
// eslint-disable-next-line prettier/prettier
export class ContactsModuleComponent extends AntiMemLeak implements AfterViewInit {
  @ViewChild('contactsPaginator')
  contactsPaginator!: MatPaginator;
  @ViewChild('accountsPaginator')
  accountsPaginator!: MatPaginator;
  @ViewChild('contactsMatSort', { static: false })
  contactsMatSort!: MatSort;
  @ViewChild('accountsMatSort', { static: false })
  accountsMatSort!: MatSort;
  filterContactsFormGroup = new FormGroup({
    surveySeries: new FormControl([]),
    country: new FormControl(''),
    linkedToAccount: new FormControl(''),
    autocompleteCountries: new FormControl(''),
  });
  filterAccountsFormGroup = new FormGroup({
    surveySeries: new FormControl([]),
    country: new FormControl(''),
    name: new FormControl(''),
    language: new FormControl(''),
    autocompleteCountries: new FormControl(''),
  });
  createContactFormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    organisationName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    website: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern('^\\+?\\d+$'),
    ]),
    prefix: new FormControl('', Validators.required),
    surveySeries: new FormControl('', Validators.required),
    notes: new FormControl(''),
    linkedAccountIds: new FormControl(''),
    autocompleteLinkedAccount: new FormControl(''),
  });
  accountsAutocompleteCreateList: any = [];
  accountsAutocompleteUpdateList: any = [];
  createAccountFormGroup = new FormGroup({
    accountName: new FormControl('', Validators.required),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    country: new FormControl('', Validators.required),
    language: new FormControl(''),
    source: new FormControl('', Validators.required),
    surveySeries: new FormControl('', Validators.required),
    internalNote: new FormControl(''),
  });
  updateContactFormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    organisationName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    title: new FormControl(''),
    country: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    website: new FormControl(''),
    phoneNumber: new FormControl(''),
    prefix: new FormControl(''),
    surveySeries: new FormControl('', Validators.required),
    notes: new FormControl(''),
    linkedAccountIds: new FormControl(''),
    autocompleteLinkedAccount: new FormControl(''),
  });
  updateAccountFormGroup = new FormGroup({
    accountName: new FormControl('', Validators.required),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    country: new FormControl('', Validators.required),
    language: new FormControl(''),
    source: new FormControl('', Validators.required),
    surveySeries: new FormControl('', Validators.required),
    internalNote: new FormControl(''),
  });
  syncWithQualtricsFormGroup = new FormGroup({
    surveyId: new FormControl(null as unknown as number, [Validators.required]),
    surveySeries: new FormControl('', Validators.required),
  });
  surveySeries!: SurveySeriesModel[];
  countries!: RegionsCountriesListModel[];
  regions: RegionsListsModel[] = [];
  contacts!: ContactModel[];
  accounts!: AccountModel[];
  detailContactAccountsDataSource!: MatTableDataSource<any>;
  detailContactSurveySeriesDataSource: MatTableDataSource<any>;
  contactsTableDataSource!: MatTableDataSource<ContactModel>;
  accountsTableDataSource!: MatTableDataSource<AccountModel>;
  tableDataCountContacts = 0;
  tableDataCountAccounts = 0;
  displayedColumnsContacts = [
    'prefix',
    'first_name',
    'last_name',
    'organisation_name',
    'title',
    'country_short_name',
    'surveySeries',
    'linkedToAccount',
  ];
  displayedColumnsAccounts = [
    'account_name',
    'country_short_name',
    'language',
    'source',
    'surveySeries',
  ];
  displayedColumnsDetailAccount = [
    'accountName',
    'country',
    'source',
    'language',
  ];
  displayedColumnsDetailSurveySeries = ['name', 'description'];
  loading = false;
  loadingAutocomplete = false;
  detailOpen = false;
  currentContact!: any;
  currentAccount!: any;
  createContactDialog!: any;
  updateContactDialog!: any;
  deleteContactDialog!: any;
  syncWithQualtricsDialog!: any;
  createAccountDialog!: any;
  updateAccountDialog!: any;
  deleteAccountDialog!: any;
  creatingContact = false;
  updatingContact = false;
  deletingContact = false;
  creatingAccount = false;
  syncingQualtrics = false;
  updatingAccount = false;
  deletingAccount = false;
  showContacts = true;
  showAccountFromContact = false;
  showPassword = false;
  selectedContactId!: any;
  selectedAccountId!: any;
  historyLoading = false;
  selectedContactHistory!: any;
  selectedAccountHistory!: any;
  syncProgressBarValue = '';
  intervalSub?: Subscription;

  constructor(
    private dialog: MatDialog,
    private providerService: ProviderService,
    private snackBar: MatSnackBar,
    private router: Router,
    private location: Location
  ) {
    super();
    this.contactsTableDataSource = new MatTableDataSource<ContactModel>([]);
    this.accountsTableDataSource = new MatTableDataSource<any>([]);
    this.detailContactAccountsDataSource = new MatTableDataSource<any>([]);
    this.detailContactSurveySeriesDataSource = new MatTableDataSource<any>([]);
  }

  ngOnInit(): void {
    this.getSurveySeries();
    this.getCountries();
    this.router.events.subscribe(async (event: any) => {
      if (event?.routerEvent?.url === '/app/account-module') {
        this.providerService.accountService.changeTitle('Account Module');
        this.detailOpen = false;
        this.showContacts = false;
        if (!this.detailOpen) {
          this.getAccountsTableData();
        }
      } else if (event?.routerEvent?.url === '/app/contact-module') {
        this.providerService.accountService.changeTitle('Contact Module');
        this.detailOpen = false;
        this.showContacts = true;
        if (!this.detailOpen) {
          this.getContactsTableData();
        }
      } else if (
        event?.routerEvent?.url.startsWith('/app/account-module?accountId')
      ) {
        const accountId = event?.routerEvent?.url.split('?accountId=')[1];
        this.showAccountFromContact = false;
        this.detailOpen = true;
        this.showContacts = false;
        this.openAccountDetail(accountId, false, true);
        this.getAccountsTableData();
      } else if (
        event?.routerEvent?.url.startsWith('/app/contact-module?contactId')
      ) {
        const contactId = event?.routerEvent?.url.split('?contactId=')[1];
        this.showAccountFromContact = false;
        this.detailOpen = true;
        this.showContacts = true;
        this.openContactDetail(contactId, true);
        this.getContactsTableData();
      }
    });
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.filterContactsFormGroup.controls.country.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.filterContactsFormGroup.controls.autocompleteCountries.setValue(
            ''
          );
          this.contactsPaginator.pageIndex = 0;
          this.getContactsTableData();
        })
    );
    this.subscriptions.add(
      this.filterContactsFormGroup.controls.surveySeries.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.contactsPaginator.pageIndex = 0;
          this.getContactsTableData();
        })
    );
    this.subscriptions.add(
      this.filterContactsFormGroup.controls.linkedToAccount.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.contactsPaginator.pageIndex = 0;
          this.getContactsTableData();
        })
    );
    this.subscriptions.add(
      this.filterAccountsFormGroup.controls.country.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.filterAccountsFormGroup.controls.autocompleteCountries.setValue(
            ''
          );
          this.accountsPaginator.pageIndex = 0;
          this.getAccountsTableData();
        })
    );
    this.subscriptions.add(
      this.filterAccountsFormGroup.controls.surveySeries.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.accountsPaginator.pageIndex = 0;
          this.getAccountsTableData();
        })
    );
    this.subscriptions.add(
      this.filterAccountsFormGroup.controls.name.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.accountsPaginator.pageIndex = 0;
          this.getAccountsTableData();
        })
    );
    this.subscriptions.add(
      this.filterAccountsFormGroup.controls.language.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.accountsPaginator.pageIndex = 0;
          this.getAccountsTableData();
        })
    );
    this.subscriptions.add(
      this.createContactFormGroup.controls.autocompleteLinkedAccount.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.getAccountsAutocompleteCreate();
        })
    );
    this.subscriptions.add(
      this.updateContactFormGroup.controls.autocompleteLinkedAccount.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.getAccountsAutocompleteUpdate();
        })
    );
  }

  switchView(showContactsValue: boolean): void {
    if (this.showContacts !== showContactsValue) {
      this.showContacts = showContactsValue;
      if (this.showContacts) {
        this.providerService.accountService.changeTitle('Contact Module');
        this.location.go('/app/contact-module');
        this.getContactsTableData();
      } else {
        this.providerService.accountService.changeTitle('Account Module');
        this.location.go('/app/account-module');
        this.getAccountsTableData();
      }
    }
  }

  openUpdateContactDialog(dialogRef: any): void {
    this.updateContactFormGroup.controls.name.setValue(
      this.currentContact.firstName
    );
    this.updateContactFormGroup.controls.organisationName.setValue(
      this.currentContact.organisationName
    );
    this.updateContactFormGroup.controls.email.setValue(
      this.currentContact.email
    );
    this.updateContactFormGroup.controls.title.setValue(
      this.currentContact.title
    );
    this.updateContactFormGroup.controls.country.setValue(
      this.currentContact.countryId
    );
    this.updateContactFormGroup.controls.surname.setValue(
      this.currentContact.lastName
    );
    this.updateContactFormGroup.controls.website.setValue(
      this.currentContact.website
    );
    this.updateContactFormGroup.controls.phoneNumber.setValue(
      this.currentContact.phoneNumber
    );
    this.updateContactFormGroup.controls.prefix.setValue(
      this.currentContact.prefix
    );
    this.updateContactFormGroup.controls.surveySeries.setValue(
      this.currentContact.surveySeriesIds
    );
    this.updateContactFormGroup.controls.notes.setValue(
      this.currentContact.internalNote
    );
    this.updateContactFormGroup.controls.linkedAccountIds.setValue(
      this.currentContact.accounts.map((acc: any) => acc.accountId).join(',')
    );
    this.updateContactDialog = this.dialog.open(dialogRef, {
      width: '1000px',
      disableClose: true,
      autoFocus: false,
    });
  }

  openUpdateAccountDialog(dialogRef: any): void {
    this.updateAccountFormGroup.controls.accountName.setValue(
      this.currentAccount.accountName
    );
    this.updateAccountFormGroup.controls.language.setValue(
      this.currentAccount.language
    );
    this.updateAccountFormGroup.controls.password.setValue(
      this.currentAccount.password
    );
    this.updateAccountFormGroup.controls.source.setValue(
      this.currentAccount.source
    );
    this.updateAccountFormGroup.controls.internalNote.setValue(
      this.currentAccount.internalNote
    );
    this.updateAccountFormGroup.controls.surveySeries.setValue(
      this.currentAccount.surveySeriesId
    );
    this.updateAccountFormGroup.controls.country.setValue(
      this.currentAccount.countryId
    );
    this.updateAccountDialog = this.dialog.open(dialogRef, {
      width: '1000px',
      disableClose: true,
      autoFocus: false,
    });
  }

  openSyncWithQualtricsDialog(dialogRef: any): void {
    this.syncWithQualtricsDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  openDeleteContactConfirmDialog(dialogRef: any): void {
    this.deleteContactDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  openDeleteAccountConfirmDialog(dialogRef: any): void {
    this.deleteAccountDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  async getContactHistory(contactId: string): Promise<void> {
    this.selectedContactId = contactId;
    this.historyLoading = true;
    const history = await this.providerService.contactService.getContactHistory(
      contactId
    );
    this.selectedContactHistory =
      this.providerService.contactService.calculateHistoryDifferences(history);
    this.historyLoading = false;
  }

  async getAccountHistory(accountId: string): Promise<void> {
    this.selectedAccountId = accountId;
    this.historyLoading = true;
    const history = await this.providerService.accountService.getAccountHistory(
      accountId
    );
    this.selectedAccountHistory =
      this.providerService.contactService.calculateHistoryDifferences(history);
    this.historyLoading = false;
  }

  getHistoryDifferencesString(history: any): string {
    let changes = ': ';
    for (const property in history) {
      if (
        property !== 'version' &&
        property !== 'lastModifiedBy' &&
        property !== 'lastModifiedOn'
      ) {
        if (property === 'password' || property === 'surveySeriesId') {
          changes += `${property} changed, `;
        } else {
          changes += `${property} changed to ${
            history[property] === '' ? `''` : history[property]
          }, `;
        }
      }
    }
    return changes.slice(0, -2);
  }

  async openContactDetail(
    contactId: string,
    fromNavigation = false
  ): Promise<void> {
    this.loading = true;
    if (!fromNavigation) {
      this.location.go(`/app/contact-module?contactId=${contactId}`);
    }
    this.detailOpen = true;
    try {
      this.currentContact =
        await this.providerService.contactService.getContactDetails(contactId);
      this.detailContactAccountsDataSource.data = this.currentContact.accounts;
      this.detailContactSurveySeriesDataSource.data =
        this.currentContact.surveySeries;
    } catch (e) {
      this.snackBar.open(
        'An error occured while getting the contact details',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  async openAccountDetail(
    accountId: string,
    fromContact = false,
    fromNavigation = false
  ): Promise<void> {
    if (fromContact) {
      this.showAccountFromContact = true;
    }
    this.loading = true;
    if (!fromNavigation) {
      this.location.go(`/app/account-module?accountId=${accountId}`);
    }
    this.detailOpen = true;
    try {
      this.currentAccount =
        await this.providerService.accountService.getAccountDetails(accountId);
    } catch (e) {
      this.snackBar.open(
        'An error occured while getting the account details',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  async deleteContact(): Promise<void> {
    this.deletingContact = true;
    try {
      await this.providerService.contactService.deleteContact(
        this.currentContact.contactId
      );
      this.snackBar.open('Contact deleted', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      this.goBack('contact-module');
      this.getContactsTableData();
    } catch (e) {
      this.snackBar.open(
        'The Contact has linked Accounts, delete them first',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.dialog.closeAll();
      this.deletingContact = false;
    }
  }

  async deleteAccount(): Promise<void> {
    this.deletingAccount = true;
    try {
      await this.providerService.accountService.deleteAccount(
        this.currentAccount.accountId
      );
      this.snackBar.open('Account deleted', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      this.goBack('account-module');
      this.getAccountsTableData();
    } catch (e) {
      this.snackBar.open('Error while deleting the account', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.dialog.closeAll();
      this.deletingAccount = false;
    }
  }

  goBack(path: string): void {
    this.location.go(`/app/${path}`);
    this.currentContact = undefined;
    this.currentAccount = undefined;
    this.detailOpen = false;
    this.showAccountFromContact = false;
    this.detailContactAccountsDataSource.data = [];
    this.detailContactSurveySeriesDataSource.data = [];
    this.reset();
  }

  goBackToContactFromAccount(): void {
    this.showAccountFromContact = false;
    this.location.go(
      `/app/contact-module?contactId=${this.currentContact.contactId}`
    );
  }

  async updateContact(): Promise<void> {
    try {
      this.updatingContact = true;
      const name = this.updateContactFormGroup.controls.name.value || '';
      const organisationName =
        this.updateContactFormGroup.controls.organisationName.value || '';
      const email = this.updateContactFormGroup.controls.email.value || '';
      const title = this.updateContactFormGroup.controls.title.value || '';
      const country = this.updateContactFormGroup.controls.country.value || '';
      const surname = this.updateContactFormGroup.controls.surname.value || '';
      const website = this.updateContactFormGroup.controls.website.value || '';
      const phoneNumber =
        this.updateContactFormGroup.controls.phoneNumber.value || '';
      const prefix = this.updateContactFormGroup.controls.prefix.value || '';
      const surveySeries =
        this.updateContactFormGroup.controls.surveySeries.value || '';
      const notes = this.updateContactFormGroup.controls.notes.value || '';
      const linkedAccountIds =
        this.updateContactFormGroup.controls.linkedAccountIds.value || '';
      await this.providerService.contactService.updateContact(
        this.currentContact.contactId,
        country,
        surveySeries,
        prefix,
        name,
        surname,
        title,
        organisationName,
        phoneNumber,
        email,
        website,
        notes,
        linkedAccountIds
      );
      this.currentContact =
        await this.providerService.contactService.getContactDetails(
          this.currentContact.contactId
        );
      this.detailContactAccountsDataSource.data = this.currentContact.accounts;
      this.dialog.closeAll();
      this.snackBar.open('Contact updated successfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      await this.getContactsTableData();
    } catch (e) {
      this.snackBar.open('An error occured while updating the contact', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.updatingContact = false;
    }
  }

  async updateAccount(): Promise<void> {
    try {
      this.updatingAccount = true;
      const accountName =
        this.updateAccountFormGroup.controls.accountName.value || '';
      const password =
        this.updateAccountFormGroup.controls.password.value || '';
      const country = this.updateAccountFormGroup.controls.country.value || '';
      const source = this.updateAccountFormGroup.controls.source.value || '';
      const surveySeries =
        this.updateAccountFormGroup.controls.surveySeries.value || '';
      const language =
        this.updateAccountFormGroup.controls.language.value || '';
      const internalNote =
        this.updateAccountFormGroup.controls.internalNote.value || '';
      await this.providerService.accountService.updateAccount(
        this.currentAccount.accountId,
        country,
        surveySeries,
        source,
        accountName,
        password,
        language,
        internalNote
      );
      this.currentAccount =
        await this.providerService.accountService.getAccountDetails(
          this.currentAccount.accountId
        );
      this.dialog.closeAll();
      this.snackBar.open('Account updated successfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      await this.getAccountsTableData();
    } catch (e) {
      this.snackBar.open('An error occured while updating the account', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.updatingAccount = false;
    }
  }

  async createContact(): Promise<void> {
    try {
      this.creatingContact = true;
      const name = this.createContactFormGroup.controls.name.value || '';
      const organisationName =
        this.createContactFormGroup.controls.organisationName.value || '';
      const email = this.createContactFormGroup.controls.email.value || '';
      const title = this.createContactFormGroup.controls.title.value || '';
      const country = this.createContactFormGroup.controls.country.value || '';
      const surname = this.createContactFormGroup.controls.surname.value || '';
      const website = this.createContactFormGroup.controls.website.value || '';
      const phoneNumber =
        this.createContactFormGroup.controls.phoneNumber.value || '';
      const prefix = this.createContactFormGroup.controls.prefix.value || '';
      const surveySeries =
        this.createContactFormGroup.controls.surveySeries.value || '';
      const notes = this.createContactFormGroup.controls.notes.value || '';
      const linkedAccountIds =
        this.createContactFormGroup.controls.linkedAccountIds.value || '';
      await this.providerService.contactService.createContact(
        country,
        surveySeries,
        prefix,
        name,
        surname,
        title,
        organisationName,
        phoneNumber,
        email,
        website,
        notes,
        linkedAccountIds
      );
      this.dialog.closeAll();
      this.snackBar.open('Contact created successfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      await this.getContactsTableData();
    } catch (e) {
      this.snackBar.open('An error occured while creating the contact', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.creatingContact = false;
    }
  }

  async syncWithQualtrics(): Promise<void> {
    this.syncingQualtrics = true;
    try {
      if (this.syncWithQualtricsFormGroup.valid) {
        const jobId =
          await this.providerService.contactService.syncWithQualtrics(
            this.syncWithQualtricsFormGroup.controls.surveyId.value!,
            this.syncWithQualtricsFormGroup.controls.surveySeries.value!
          );
        this.intervalSub = interval(2000).subscribe(async () => {
          this.syncProgressBarValue =
            await this.providerService.contactService.getJobProgress(jobId);
          if (this.syncProgressBarValue === 'COMPLETED') {
            this.syncingQualtrics = false;
            this.syncProgressBarValue = '';
            this.intervalSub!.unsubscribe();
            this.syncWithQualtricsDialog.close();
            this.snackBar.open('Contacts synced correctly', 'X', {
              duration: 10000,
              panelClass: ['success-snackbar'],
            });
          } else if (this.syncProgressBarValue === 'FAILED') {
            this.syncingQualtrics = false;
            this.syncProgressBarValue = '';
            this.intervalSub!.unsubscribe();
            this.snackBar.open(
              'An error occured while syncing with qualtrics',
              'X',
              {
                duration: 6000,
                panelClass: ['error-snackbar'],
              }
            );
          }
        });
      }
    } catch (e) {
      this.snackBar.open('An error occured while syncing with qualtrics', 'X', {
        duration: 6000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  async createAccount(): Promise<void> {
    try {
      this.creatingAccount = true;
      const accountName =
        this.createAccountFormGroup.controls.accountName.value || '';
      const password =
        this.createAccountFormGroup.controls.password.value || '';
      const country = this.createAccountFormGroup.controls.country.value || '';
      const source = this.createAccountFormGroup.controls.source.value || '';
      const surveySeries =
        this.createAccountFormGroup.controls.surveySeries.value || '';
      const language =
        this.createAccountFormGroup.controls.language.value || '';
      const internalNote =
        this.createAccountFormGroup.controls.internalNote.value || '';
      await this.providerService.accountService.createAccount(
        country,
        surveySeries,
        source,
        accountName,
        password,
        language,
        internalNote
      );
      this.dialog.closeAll();
      this.snackBar.open('Account created successfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      await this.getAccountsTableData();
    } catch (e) {
      this.snackBar.open('An error occured while creating the account', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.creatingAccount = false;
    }
  }

  async getCountries(): Promise<any> {
    this.regions =
      await this.providerService.regionsManagementService.getRegionsList(true);
    this.countries = (
      await this.providerService.regionsManagementService.getCountriesByRegions(
        this.regions.map((value) => value.regionID)
      )
    ).sort((a, b) => {
      if (a.shortName < b.shortName) return -1;
      if (a.shortName > b.shortName) return 1;
      return 0;
    });
  }

  export(type: string): void {
    let csvString = '';
    if (type === 'contacts') {
      csvString = this.convertToCSV(this.contacts);
    } else if (type === 'accounts') {
      csvString = this.convertToCSV(this.accounts);
    }
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = (window as any).URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    if (type === 'contacts') {
      a.setAttribute('download', 'contacts.csv');
    } else if (type === 'accounts') {
      a.setAttribute('download', 'accounts.csv');
    }
    a.click();
    this.snackBar.open(
      `${type === 'contacts' ? 'Contacts' : 'Accounts'} exported`,
      'X',
      {
        duration: 3000,
        panelClass: ['success-snackbar'],
      }
    );
  }

  convertToCSV(objArray: any[]): string {
    const array =
      typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    const excludeKeys = ['countryId', 'surveySeriesIds', 'surveySeriesId'];
    const renameKeys: Record<string, string> = {
      countryShortName: 'country',
      surveySeriesNames: 'surveySeries',
    };
    let keys = Object.keys(array[0]).filter(
      (key) => !excludeKeys.includes(key)
    );
    keys = keys.map((key) => renameKeys[key] || key);
    str += keys.join(',') + '\n';
    array.forEach((obj: any) => {
      const line = keys.map((key: string) => {
        const originalKey =
          Object.keys(renameKeys).find((k) => renameKeys[k] === key) || key;
        const value =
          obj[originalKey] === null || obj[originalKey] === undefined
            ? ''
            : obj[originalKey].toString().replace(/"/g, '""');
        return `"${value}"`;
      });
      str += line.join(',') + '\n';
    });
    return str;
  }

  async getSurveySeries(): Promise<any> {
    this.surveySeries =
      await this.providerService.contactService.getSurverySeries();
  }

  async getContactsTableData(): Promise<void> {
    this.loading = true;
    try {
      this.selectedContactId = undefined;
      const countryId =
        this.filterContactsFormGroup.controls.country.value || undefined;
      const surveySeries =
        this.filterContactsFormGroup.controls.surveySeries.value || undefined;
      const linkedToAccount =
        this.filterContactsFormGroup.controls.linkedToAccount.value;
      const contactsBE =
        await this.providerService.contactService.getContactAndCount(
          this.contactsPaginator?.pageSize || 50,
          this.contactsPaginator?.pageIndex || 0,
          this.contactsMatSort?.active,
          this.contactsMatSort?.direction !== ''
            ? this.contactsMatSort?.direction
            : undefined,
          countryId,
          surveySeries && (surveySeries as any)?.length > 0
            ? JSON.stringify(surveySeries)
            : undefined,
          linkedToAccount === '' ? undefined : linkedToAccount
        );
      this.contactsTableDataSource.data = contactsBE.contacts;
      this.tableDataCountContacts = contactsBE.count;
      this.contacts = contactsBE.contacts;
    } catch (e) {
      this.contactsTableDataSource.data = [];
      console.error('error');
    } finally {
      this.loading = false;
    }
  }

  async getAccountsTableData(): Promise<void> {
    this.loading = true;
    try {
      this.selectedAccountId = undefined;
      const countryId =
        this.filterAccountsFormGroup.controls.country.value || undefined;
      const surveySeries =
        this.filterAccountsFormGroup.controls.surveySeries.value || undefined;
      const name =
        this.filterAccountsFormGroup.controls.name.value || undefined;
      const language =
        this.filterAccountsFormGroup.controls.language.value || undefined;
      const accountsBE =
        await this.providerService.accountService.getAccountAndCount(
          this.accountsPaginator?.pageSize || 50,
          this.accountsPaginator?.pageIndex || 0,
          this.accountsMatSort?.active,
          this.accountsMatSort?.direction !== ''
            ? this.accountsMatSort?.direction
            : undefined,
          countryId,
          surveySeries && (surveySeries as any)?.length > 0
            ? JSON.stringify(surveySeries)
            : undefined,
          name,
          language
        );
      this.accountsTableDataSource.data = accountsBE.accounts;
      this.tableDataCountAccounts = accountsBE.count;
      this.accounts = accountsBE.accounts;
    } catch (e) {
      this.accountsTableDataSource.data = [];
      console.error('error');
    } finally {
      this.loading = false;
    }
  }

  async getAccountsAutocompleteCreate(): Promise<void> {
    this.loadingAutocomplete = true;
    try {
      const name =
        this.createContactFormGroup.controls.autocompleteLinkedAccount.value ||
        undefined;
      if (name) {
        const accountsBE =
          await this.providerService.accountService.getAccountAndCount(
            10,
            0,
            'account_name',
            'asc',
            undefined,
            undefined,
            name,
            undefined
          );
        this.accountsAutocompleteCreateList = accountsBE.accounts;
      } else {
        this.accountsAutocompleteCreateList = [];
      }
    } catch (e) {
      this.accountsAutocompleteCreateList = [];
    } finally {
      this.loadingAutocomplete = false;
    }
  }

  async getAccountsAutocompleteUpdate(): Promise<void> {
    this.loadingAutocomplete = true;
    try {
      const name =
        this.updateContactFormGroup.controls.autocompleteLinkedAccount.value ||
        undefined;
      if (name) {
        const accountsBE =
          await this.providerService.accountService.getAccountAndCount(
            10,
            0,
            'account_name',
            'asc',
            undefined,
            undefined,
            name,
            undefined
          );
        this.accountsAutocompleteUpdateList = accountsBE.accounts;
      } else {
        this.accountsAutocompleteUpdateList = [];
      }
    } catch (e) {
      this.accountsAutocompleteUpdateList = [];
    } finally {
      this.loadingAutocomplete = false;
    }
  }

  async openCreateContactDialog(dialogRef: any): Promise<any> {
    this.createContactDialog = this.dialog.open(dialogRef, {
      width: '1000px',
      disableClose: true,
      autoFocus: false,
    });
  }

  async openCreateAccountDialog(dialogRef: any): Promise<any> {
    this.createAccountDialog = this.dialog.open(dialogRef, {
      width: '1000px',
      disableClose: true,
      autoFocus: false,
    });
  }

  displaySurvey(surveryId: string): string {
    return (
      this.surveySeries?.find((s) => s.surveySeriesId === surveryId)
        ?.surveySeriesName || '-'
    );
  }

  removeCountryFilterContacts(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.filterContactsFormGroup.controls.country.setValue('');
  }

  removeCountryFilterAccounts(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.filterAccountsFormGroup.controls.country.setValue('');
  }

  reset(): void {
    this.createContactFormGroup.reset();
    this.updateContactFormGroup.reset();
    this.createAccountFormGroup.reset();
    this.updateAccountFormGroup.reset();
    this.syncWithQualtricsFormGroup.reset();
  }

  copyAccountIdToClipboard(): void {
    const accountId = this.currentAccount?.accountId || '-';
    navigator.clipboard.writeText(accountId).then(() => {
      this.snackBar.open('Account ID copied', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
    });
  }

  toggleCheckboxState(checkbox: MatCheckbox): void {
    if (!checkbox.indeterminate && checkbox.checked) {
      checkbox.checked = true;
      this.filterContactsFormGroup.controls.linkedToAccount.setValue('true');
    } else if (!checkbox.indeterminate && !checkbox.checked) {
      checkbox.indeterminate = true;
      this.filterContactsFormGroup.controls.linkedToAccount.setValue('');
    } else if (checkbox.indeterminate && checkbox.checked) {
      checkbox.indeterminate = false;
      checkbox.checked = false;
      this.filterContactsFormGroup.controls.linkedToAccount.setValue('false');
    }
  }

  goBackToHome(): void {
    this.providerService.utilService.navigateTo('/app/home');
  }

  visuallyFilterContactCountries(country: CountriesListsModel): boolean {
    const countryName = country.shortName?.toLowerCase() || '';
    const input = (
      this.filterContactsFormGroup.controls.autocompleteCountries.value || ''
    ).toLowerCase();
    return input === '' || countryName.includes(input);
  }

  visuallyFilterAccountCountries(country: CountriesListsModel): boolean {
    const countryName = country.shortName?.toLowerCase() || '';
    const input = (
      this.filterAccountsFormGroup.controls.autocompleteCountries.value || ''
    ).toLowerCase();
    return input === '' || countryName.includes(input);
  }

  formatHistoryDate(date: string): string {
    return date.split('.')[0];
  }
}
