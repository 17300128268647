<div class="download-page-container" [formGroup]="downloadFormGroup">

  @if (!selectedDownloadViewType) {
    <div class="select-view-container">
      <mat-card-title class="action-message">Select View</mat-card-title>
      <div class="select-view-cards-container">
        <mat-card (click)="selectView(eDownloadViewType.CountryView)">
          <i class="fa fa-earth-america"></i>
          <mat-card-title>Country View</mat-card-title>
        </mat-card>
        <mat-card (click)="selectView(eDownloadViewType.HouseholdView)">
          <i class="fa fa-house-chimney-window"></i>
          <mat-card-title>Household View</mat-card-title>
        </mat-card>
      </div>
    </div>
  }

  @if (selectedDownloadViewType === eDownloadViewType.CountryView) {
    <mat-card>

      <mat-card-header>
        <mat-card-title>Download Country View</mat-card-title>
      </mat-card-header>

      <mat-card-content>

        <mat-form-field appearance="fill">
          <mat-label>Data Format</mat-label>
          <mat-select formControlName="dataFormat">
            <mat-option value="rawData">Raw Data</mat-option>
            <mat-option value="yearsAsColumns">Years as Columns</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="year-container">
          <mat-form-field appearance="fill">
            <mat-label>From Year</mat-label>
            <input matInput type="number" formControlName="fromYear" placeholder="YYYY" min="0"
                   (keydown)="($event.key === '-' || $event.key === '.'|| $event.key === 'e') ? $event.preventDefault() : ''">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>To Year</mat-label>
            <input matInput type="number" formControlName="toYear" placeholder="YYYY" min="0"
                   (keydown)="($event.key === '-' || $event.key === '.'|| $event.key === 'e') ? $event.preventDefault() : ''">
          </mat-form-field>
        </div>

        @if (!showYearsAsColumnsOptions) {
          <mat-form-field appearance="fill">
            <mat-label>CSV Columns</mat-label>
            <mat-select #columnFilter [formControl]="csvColumnsFormControl"
                        (selectionChange)="columnFilter.close(); this.downloadFormGroup.controls.autocompleteColumn.setValue('')"
                        (openedChange)="onOpenedColumnChange($event)" multiple >
              <mat-select-trigger>
                  <span class="label" [matTooltip]="displayMultipleColumnFn(csvColumnsFormControl.value)">
                    {{csvColumnsFormControl.value?.[0] || ''}}
                    @if ((csvColumnsFormControl.value?.length || 0) > 1) {
                      <span class="example-additional-selection">
                        (+{{(csvColumnsFormControl.value?.length || 0) - 1}} {{csvColumnsFormControl.value?.length === 2 ? 'other' : 'others'}})
                      </span>
                    }
                  </span>
                @if ((csvColumnsFormControl.value?.length || 0) > 0) {
                  <i (click)="removeColumnFilter($event)" class="fa fa-x clear-filter"
                     [matTooltip]="'Clear filter'"></i>
                }
              </mat-select-trigger>
              <input matInput type="text" formControlName="autocompleteColumn"
                     class="autocomplete-input"
                     (keydown)="$event.stopPropagation()"
                     #columnInputElement>
              @if (downloadFormGroup.controls.autocompleteColumn.value === '') {
                <span class="autocomplete-placeholder">Search...</span>
              }
              @for (columnName of rawCountryViewDataColumns; track columnName) {
                @if (columnName === 'All columns') {
                  <mat-option [ngClass]="visuallyFilterColumns(columnName) ? '' : 'hidden'"
                              (click)="allColumnsSelected()" [value]="columnName">
                    {{ columnName }}
                  </mat-option>
                }
                @else {
                  <mat-option [ngClass]="visuallyFilterColumns(columnName) ? '' : 'hidden'"
                              (click)="otherColumnSelected()" [value]="columnName">
                    {{ columnName }}
                  </mat-option>
                }
              }
            </mat-select>
          </mat-form-field>
        }

        <mat-form-field appearance="fill">
          <mat-label>Country Name</mat-label>
          <mat-select #countryFilter [formControl]="countriesFormControl"
                      (selectionChange)="countryFilter.close(); downloadFormGroup.controls.autocompleteCountry.setValue('')"
                      (openedChange)="onOpenedChange($event)" multiple >
            <mat-select-trigger>
                  <span class="label" [matTooltip]="displayMultipleFn(countriesFormControl.value)">
                    {{displayFn(countriesFormControl.value?.[0]) || ''}}
                    @if ((countriesFormControl.value?.length || 0) > 1) {
                      <span class="example-additional-selection">
                        (+{{(countriesFormControl.value?.length || 0) - 1}} {{countriesFormControl.value?.length === 2 ? 'other' : 'others'}})
                      </span>
                    }
                  </span>
              @if ((countriesFormControl.value?.length || 0) > 0) {
                <i (click)="removeCountryFilter($event)" class="fa fa-x clear-filter"
                   [matTooltip]="'Clear filter'"></i>
              }
            </mat-select-trigger>
            <input matInput type="text" formControlName="autocompleteCountry"
                   class="autocomplete-input"
                   (keydown)="$event.stopPropagation()"
                   #inputElement>
            @if (downloadFormGroup.controls.autocompleteCountry.value === '') {
              <span class="autocomplete-placeholder">Search...</span>
            }
            @for (country of countries; track country) {
              <mat-option [ngClass]="visuallyFilterCountries(country) ? '' : 'hidden'" [value]="country.isoCode">
                {{ country.shortName }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Code</mat-label>
          <input matInput type="text" formControlName="codeFilter" placeholder="Enter code filter">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Description</mat-label>
          <input matInput type="text" formControlName="descriptionFilter" placeholder="Enter description filter">
        </mat-form-field>

        @if (showYearsAsColumnsOptions) {
          <div class="sort-options">
            <mat-form-field appearance="fill">
              <mat-label>Sort Column</mat-label>
              <mat-select formControlName="sortColumn">
                <mat-option value="code">Code</mat-option>
                <mat-option value="description">Description</mat-option>
                <mat-option value="countryName">Country Name</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Sort Type</mat-label>
              <mat-select formControlName="sortType">
                <mat-option value="ASC">Ascending</mat-option>
                <mat-option value="DESC">Descending</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        }

        <div class="download-button">
          <button class="back-button" mat-raised-button (click)="selectView(undefined)">
            <i class="fa fa-arrow-left"></i>
            <span>Back</span>
          </button>
          <button mat-raised-button (click)="downloadData(downloadDialog)"
                  onclick="this.blur()" color="primary">
            <span class="download-raw-data-button">Download</span>
          </button>
        </div>

      </mat-card-content>
    </mat-card>
  }

  @else if(selectedDownloadViewType === eDownloadViewType.HouseholdView) {
    <mat-card>
      <div style="text-align: center; margin: 10px 10px">Under costruction...</div>
      <button class="back-button" mat-raised-button (click)="selectView(undefined)">
        <i class="fa fa-arrow-left"></i>
        <span>Back</span>
      </button>
    </mat-card>
  }

</div>

<ng-template #downloadDialog>
  <h2 mat-dialog-title class="mat-dialog-title">{{ 'DIALOG.DOWNLOAD_TITLE' | translate }}</h2>
  <mat-dialog-content>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <p>{{ 'DIALOG.DOWNLOAD_MESSAGE' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close (click)="cancelDownload()">{{ 'DIALOG.CANCEL' | translate }}</button>
  </mat-dialog-actions>
</ng-template>
