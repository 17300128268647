import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { UnitModel } from '../../filters/unit-filter/models/unit-model';
import { ProviderService } from 'src/app/core/provider.service';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-select-unit-step',
  templateUrl: './select-unit-step.component.html',
  styleUrls: ['./select-unit-step.component.scss'],
})
export class SelectUnitStepComponent extends AntiMemLeak implements OnInit {
  @Input({ required: true })
  stepper!: MatStepper;
  @Input({ required: true })
  formGroup!: FormGroup;
  @Output()
  nextSelected = new EventEmitter();
  dataSource!: MatTableDataSource<UnitModel>;
  displayedColumns = ['select', 'unitDescription'];
  selection = new SelectionModel<UnitModel>(true, []);
  loading = false;

  constructor(private providerService: ProviderService) {
    super();
  }

  ngOnInit(): void {
    this.getDisaggregationList();
    this.subscriptions.add(
      this.selection.changed
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(() => {
          this.formGroup.controls['units'].setValue(this.selection.selected);
        })
    );
  }

  async getDisaggregationList(): Promise<void> {
    this.loading = true;
    try {
      this.dataSource = new MatTableDataSource<UnitModel>(
        await this.providerService.unitFilterService.getUnitsList()
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}
