import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import { ProviderService } from 'src/app/core/provider.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { DisaggregationModel } from './models/disaggregation-model';

@Component({
  selector: 'app-disaggregation-filter',
  templateUrl: './disaggregation-filter.component.html',
  styleUrls: ['./disaggregation-filter.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class DisaggregationFilterComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  @Output()
  disaggregationsChanged: EventEmitter<DisaggregationModel[]> =
    new EventEmitter<DisaggregationModel[]>();
  @Input()
  level: 1 | 2 = 1;
  @ViewChild('disaggregationInputElement')
  disaggregationInputElement!: ElementRef;
  disaggregations: DisaggregationModel[] = [];
  filteredDisaggregations: DisaggregationModel[] = [];

  disaggregationsFormGroup = new FormGroup({
    disaggregation: new FormControl([]),
    autocompleteDisaggregation: new FormControl(''),
  });

  constructor(private providerService: ProviderService) {
    super();
  }

  ngOnInit(): void {
    this.getDisaggregationList();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.disaggregationsFormGroup.controls.disaggregation.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(200))
        .subscribe((disaggregations) => {
          this.disaggregationsChanged.emit(disaggregations!);
        })
    );
  }

  displayMultipleDisaggregationsFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0
        ? (result = `${this.displayDisaggregationFn(v)}`)
        : (result = `${result}, ${this.displayDisaggregationFn(v)}`);
    }
    return result;
  }

  displayDisaggregationFn(value: any): string {
    return value
      ? this.disaggregations.find(
          (disaggregation) =>
            disaggregation.disaggregationId === value.disaggregationId
        )?.disaggregationDescription ?? ''
      : '';
  }

  onOpenedUnitChange(isOpened: boolean): void {
    if (isOpened) {
      this.disaggregationInputElement.nativeElement.focus();
    }
  }

  removeDisaggregationFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.disaggregationsFormGroup.controls.disaggregation.setValue([], {
      emitEvent: true,
    });
  }

  visuallyFilterDisaggregations(unit: DisaggregationModel): boolean {
    const disaggregationDescription =
      unit.disaggregationDescription?.toLowerCase() || '';
    const input = (
      this.disaggregationsFormGroup.controls.autocompleteDisaggregation.value ||
      ''
    ).toLowerCase();
    return input === '' || disaggregationDescription.includes(input);
  }

  async getDisaggregationList(): Promise<void> {
    try {
      const disaggregations = [
        { disaggregationId: '', disaggregationDescription: 'Total' },
      ];
      disaggregations.push(
        ...(await this.providerService.disaggregationFilterService.getDisaggregationsList(
          this.level
        ))
      );
      this.disaggregations = disaggregations;
      this.filteredDisaggregations = this.disaggregations;
    } catch (error) {
      console.error(error);
    }
  }
}
