import { Component, OnInit } from '@angular/core';
import { SurveyModel } from '../survey-table/models/survey-model';
import { CountrySurveyModel } from '../survey-country-table/models/country-survey-model';
import { ProviderService } from 'src/app/core/provider.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';

export enum CountryValidationSteps {
  selectSurvey,
  selectCountry,
  showValidationTable,
}

@Component({
  selector: 'app-country-validation',
  templateUrl: './country-validation.component.html',
  styleUrl: './country-validation.component.scss',
})
export class CountryValidationComponent extends AntiMemLeak implements OnInit {
  protected readonly CountryValidationSteps = CountryValidationSteps;
  currentStep: CountryValidationSteps = CountryValidationSteps.selectSurvey;
  selectedSurvey?: SurveyModel;
  selectedCountry?: CountrySurveyModel;
  loading = true;

  constructor(
    private providerService: ProviderService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.router.events.subscribe(async (event) => {
        if (event instanceof NavigationStart) {
          if (event.navigationTrigger === 'popstate') {
            if (event.url.includes('?')) {
              const urlSearchParams = new URLSearchParams(
                event.url.split('?')[1]
              );
              const params = {
                surveyID: urlSearchParams.get('surveyID'),
                countryID: urlSearchParams.get('countryID'),
              };
              this.checkParams(params);
            } else {
              this.checkParams({});
            }
          }
        }
      })
    );
    this.checkParams();
  }

  selectSurvey(survey: SurveyModel): void {
    this.selectedSurvey = survey;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { surveyID: survey.surveyId },
      queryParamsHandling: 'merge',
    });
    this.currentStep = CountryValidationSteps.selectCountry;
  }

  selectCountry(country: CountrySurveyModel): void {
    this.selectedCountry = country;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { countryID: country.countryID, selectionType: 'country' },
      queryParamsHandling: 'merge',
    });
    this.currentStep = CountryValidationSteps.showValidationTable;
  }

  goBackTo(step: CountryValidationSteps): void {
    switch (step) {
      case CountryValidationSteps.selectSurvey:
        this.removeQueryParam(['selectionType', 'surveyID']);
        break;
      case CountryValidationSteps.selectCountry:
        this.removeQueryParam(['countryID']);
        break;
    }
    this.currentStep = step;
  }

  goBackToHome(): void {
    this.providerService.utilService.navigateTo('/app/home');
  }

  async checkParams(params = this.route.snapshot.queryParams): Promise<void> {
    this.loading = true;
    try {
      const surveyId = params['surveyID'];
      let selectedStep = CountryValidationSteps.selectSurvey;
      if (surveyId) {
        this.selectedSurvey =
          await this.providerService.validationModuleService.getSurvey(
            surveyId
          );
        selectedStep = CountryValidationSteps.selectCountry;

        const countryId = params['countryID'];
        if (countryId) {
          this.selectedCountry =
            await this.providerService.validationModuleService.getCountry(
              countryId
            );
          selectedStep = CountryValidationSteps.showValidationTable;
        }
      } else {
        this.removeAllQueryParams();
      }
      this.currentStep = selectedStep;
    } catch (e) {
      this.removeAllQueryParams();
      this.currentStep = CountryValidationSteps.selectSurvey;
    } finally {
      this.loading = false;
    }
  }

  removeQueryParam(params: string[]): void {
    const queryParams = { ...this.route.snapshot.queryParams };
    params.forEach((param) => {
      delete queryParams[param];
    });
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'replace',
    });
  }

  removeAllQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      queryParamsHandling: 'replace',
    });
  }
}
