import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './core/app-utils/storage/storage.service';
import { Constants } from './models/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    translate.addLangs(['en']);
    const storedLanguage = localStorage.getItem(
      `${Constants.storageKeyPrefix}-language`
    );
    if (storedLanguage) {
      translate.setDefaultLang(storedLanguage);
      translate.use(storedLanguage);
    }
  }
}
