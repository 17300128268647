import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { SurveyModel } from '../survey-table/models/survey-model';
import { ValidationModuleListCodesModel } from './models/validation-module-list-codes-models';
import { MatTableDataSource } from '@angular/material/table';
import { ProviderService } from 'src/app/core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import { startWith } from 'rxjs';

@Component({
  selector: 'app-validation-module-list-codes',
  templateUrl: './validation-module-list-codes.component.html',
  styleUrls: ['./validation-module-list-codes.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class ValidationModuleListCodesComponent extends AntiMemLeak implements AfterViewInit {
  @Input({ required: true }) selectedSurvey!: SurveyModel;
  @Output() codeSelected = new EventEmitter<ValidationModuleListCodesModel>();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  dataSource!: MatTableDataSource<ValidationModuleListCodesModel>;
  tableCount = 0;
  loading = true;
  selectedCodes: string[] = [];

  displayedColumns = ['code', 'codeDescription'];

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar
  ) {
    super();
    this.dataSource = new MatTableDataSource<ValidationModuleListCodesModel>();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData();
        }
      })
    );
    this.getTableData();
  }

  private async getTableData(): Promise<void> {
    this.loading = true;
    try {
      const backendData =
        await this.providerService.validationModuleListCodesService.listCodes(
          this.selectedSurvey.surveyId,
          this.paginator.pageIndex,
          this.paginator.pageSize,
          this.selectedCodes.length > 0 ? this.selectedCodes : undefined
        );
      this.dataSource.data = backendData.data;
      this.tableCount = backendData.total;
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the code list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  selectCode(code: ValidationModuleListCodesModel): void {
    this.codeSelected.emit(code);
  }

  codeSelectionChanged($event: string[]): void {
    this.selectedCodes = $event;
    this.getTableData();
  }
}
