import { IMapper } from '../../../models/i-mapper';

export class SyncQualtricsMapper implements IMapper {
  body?: {
    type: string;
  };
  jobId?: string;

  fillFromJson(data: any): void {
    this.jobId = data;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}
