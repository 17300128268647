<div class="form-container filters-bar" [formGroup]="collectionsFormGroup">
  <div class="mat-drop-down">
    <mat-label>{{'FILTERS.COLLECTION_LABEL' | translate}}</mat-label>
    <mat-select [formControl]="collectionsFormGroup.controls.collection" (openedChange)="onOpenedUnitChange($event)"
      multiple>
      <mat-select-trigger>
        <span class="label" [matTooltip]="displayMultipleCollectionsFn(collectionsFormGroup.controls.collection.value)">
          {{displayCollectionFn(collectionsFormGroup.controls.collection.value?.[0]) || ''}}
          @if ((collectionsFormGroup.controls.collection.value?.length || 0) > 1) {
          <span>
            (+{{(collectionsFormGroup.controls.collection.value?.length || 0) - 1}}
            {{collectionsFormGroup.controls.collection.value?.length === 2 ? 'other' : 'others'}})
          </span>
          }
        </span>
        @if ((collectionsFormGroup.controls.collection.value?.length || 0) > 0) {
        <i (click)="removeCollectionFilter($event)" class="fa fa-x clear-filter"
          matTooltip="{{'FILTERS.CLEAR_FILTERS' | translate}}"></i>
        }
      </mat-select-trigger>
      <input matInput type="text" formControlName="autocompleteCollection" class="autocomplete-input"
        (keydown)="$event.stopPropagation()" #collectionInputElement
        placeholder="{{ 'FILTERS.AUTOCOMPLETE_PLACEHOLDER' | translate }}">
      @for (collection of collections; track collection) {
      <mat-option [ngClass]="visuallyFilterCollections(collection) ? '' : 'hidden'" [value]="collection" style="padding: 10px;">
        <span>
          {{ collection.collectionDescription }}
        </span>
      </mat-option>
      }
    </mat-select>
  </div>
</div>