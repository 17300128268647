import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appStopBlur]',
  standalone: true,
})
export class StopBlurDirective {
  @HostListener('mousedown', ['$event'])
  onMousedown(event: MouseEvent): void {
    event.preventDefault();
  }
}
