import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import { CollectionModel } from './models/collection-model';
import { ProviderService } from 'src/app/core/provider.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-collection-filter',
  templateUrl: './collection-filter.component.html',
  styleUrls: ['./collection-filter.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class CollectionFilterComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  @Output()
  collectionsChanged: EventEmitter<CollectionModel[]> = new EventEmitter<
    CollectionModel[]
  >();
  @ViewChild('collectionInputElement')
  collectionInputElement!: ElementRef;
  collections: CollectionModel[] = [];
  filteredCollections: CollectionModel[] = [];

  collectionsFormGroup = new FormGroup({
    collection: new FormControl([]),
    autocompleteCollection: new FormControl(''),
  });

  constructor(private providerService: ProviderService) {
    super();
  }

  ngOnInit(): void {
    this.getUnitList();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.collectionsFormGroup.controls.collection.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(200))
        .subscribe((collections) => {
          this.collectionsChanged.emit(collections!);
        })
    );
  }

  displayMultipleCollectionsFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0
        ? (result = `${this.displayCollectionFn(v)}`)
        : (result = `${result}, ${this.displayCollectionFn(v)}`);
    }
    return result;
  }

  displayCollectionFn(value: any): string {
    return value
      ? this.collections.find(
          (collection) => collection.collectionID === value.collectionID
        )?.collectionDescription ?? ''
      : '';
  }

  onOpenedUnitChange(isOpened: boolean): void {
    if (isOpened) {
      this.collectionInputElement.nativeElement.focus();
    }
  }

  removeCollectionFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.collectionsFormGroup.controls.collection.setValue([], {
      emitEvent: true,
    });
  }

  visuallyFilterCollections(unit: CollectionModel): boolean {
    const collectionDescription =
      unit.collectionDescription?.toLowerCase() || '';
    const input = (
      this.collectionsFormGroup.controls.autocompleteCollection.value || ''
    ).toLowerCase();
    return input === '' || collectionDescription.includes(input);
  }

  async getUnitList(): Promise<void> {
    try {
      this.collections =
        await this.providerService.collectionFilterService.getCollectionsList();
      this.filteredCollections = this.collections;
    } catch (error) {
      console.error(error);
    }
  }
}
