import { Component } from '@angular/core';
import { IUser } from '../../../models/i-user';
import { Router } from '@angular/router';
import { ProviderService } from '../../../core/provider.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/core/form-utils/custom-validators';
import { LogLevel } from '../../../models/log-level';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent {
  loading: boolean;
  isConfirm: boolean;
  showPassword: boolean;
  user: IUser;

  form = new FormGroup({
    code: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      // 1. Password Field is Required
      Validators.required,
      // 2. check whether the entered password has a number
      CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      // 3. check whether the entered password has upper case letter
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      // 4. check whether the entered password has a lower-case letter
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      // 5. check whether the entered password has a special character
      CustomValidators.patternValidator(/[^a-zA-Z0-9]+/, {
        hasSpecialCharacters: true,
      }),
      // 6. Has a minimum length of 8 characters
      Validators.minLength(8),
    ]),
  });

  constructor(
    private router: Router,
    private providerService: ProviderService
  ) {
    this.loading = false;
    this.isConfirm = false;
    this.showPassword = false;
    this.user = {} as IUser;
  }

  signUp(): void {
    if (this.form.controls.email.valid && this.form.controls.password.valid) {
      this.user.name = this.form.controls.email.value ?? '';
      this.user.email = this.form.controls.email.value ?? '';
      this.user.password = this.form.controls.password.value ?? '';
      this.loading = true;

      this.providerService.authService
        .signUp(this.user)
        .then(() => {
          this.loading = false;
          this.isConfirm = true;
        })
        .catch((error) => {
          this.providerService.utilService.showMessage(error, LogLevel.error);
          this.form.reset();
          this.loading = false;
        });
    }
  }

  confirmSignUp(): void {
    if (this.form.controls.code.valid) {
      this.user.email = this.form.controls.email.value ?? '';
      this.user.password = this.form.controls.password.value ?? '';
      this.user.code = this.form.controls.code.value ?? '';

      this.loading = true;

      this.providerService.authService
        .confirmSignUp(this.user)
        .then(() => {
          this.router.navigate(['/', 'auth', 'sign-in']);
        })
        .catch((error) => {
          this.providerService.utilService.showMessage(error, LogLevel.error);
          this.form.controls.code.setValue('');
          this.loading = false;
        });
    }
  }

  navigateToSignIn(): void {
    this.providerService.utilService.navigateTo('/auth/sign-in');
  }
}
