export enum Errors {
  error = 'Could not process your request!',
}
export enum CellStatus {
  running = 'running',
  pending = 'pending',
}

export enum CountryFiltersEnum {
  countryCode = 'countryCode',
  countryName = 'countryName',
}
