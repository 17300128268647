import { IMapper } from 'src/app/models/i-mapper';
import {
  ValidationModuleListCodesModel,
  ValidationModuleListCodesResponseModel,
} from '../models/validation-module-list-codes-models';

export class ValidationModuleListCodesMapper implements IMapper {
  response?: ValidationModuleListCodesResponseModel;
  fillFromJson(json: any): void {
    const responseList: ValidationModuleListCodesModel[] = [];
    json.data.forEach((code: any) => {
      responseList.push({
        codeId: code.codeId,
        code: code.code,
        codeDescription: code.description,
      });
    });
    this.response = {
      data: responseList,
      total: json.total,
    };
  }
  fillToJson(): string {
    return '';
  }
}
