<div class="conditional-formatting-container">
  @if (isConditionalFormattingFilterOn) {
    <i class="fa-solid fa fa-trash" style="cursor: pointer" (click)="clearConditionalFormattingFilter()" matTooltip="Clear filter"></i>
  }
  <button color="primary" mat-raised-button type="button" onclick="this.blur()"
          class="conditional-formatting-button" (click)="openConditionalFormattingDialog()">
    Conditional formatting
  </button>
  <button color="primary" mat-raised-button type="button" onclick="this.blur()"
          class="show-type-button" [matMenuTriggerFor]="showTypeMenu">
    {{ showAll ? 'ALL ROWS' : showDifferences ? 'DIFF ROWS' : 'INSERT ROWS' }}<mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #showTypeMenu="matMenu">
    <button class="mat-menu-show-type" mat-menu-item
            (click)="showType('all')" onclick="this.blur()">
      Show all
    </button>
    <button class="mat-menu-show-type" mat-menu-item
            (click)="showType('inserts')" onclick="this.blur()">
      Show inserts only
    </button>
    <button class="mat-menu-show-type" mat-menu-item
            (click)="showType('differences')" onclick="this.blur()">
      Show differences only
    </button>
  </mat-menu>
</div>

<div [formGroup]="filtersForm" class="upload-filters">
  <mat-form-field>
    <input matInput formControlName="codeFilter" (input)="applyFilter()" placeholder="Filter by Code">
  </mat-form-field>
  <mat-form-field>
    <input matInput formControlName="dataYearFilter" (input)="applyFilter()" placeholder="Filter by Year">
  </mat-form-field>
  <mat-form-field>
    <input matInput formControlName="isoCodeFilter" (input)="applyFilter()" placeholder="Filter by Country Code">
  </mat-form-field>
</div>
<div class="table-container mat-elevation-z8" tabindex="0">
  <table mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef onclick="this.blur()" style="width: 40px">
        <mat-checkbox
          class="no-focus-outline"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          onclick="this.blur()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element" onclick="this.blur()"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}"
          style="width: 40px">
        <mat-checkbox
          class="no-focus-outline"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(element) : null"
          [checked]="selection.isSelected(element)"
          onclick="this.blur()"
        >
        </mat-checkbox>
        {{element.name}}
      </td>
    </ng-container>
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>Code</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}">
        <span class="row-line">
          {{element.code}}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="dataYear">
      <th mat-header-cell *matHeaderCellDef style="width: 60px" appResizeColumn>
        <div mat-sort-header>Year</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}" style="width: 60px">
        <span class="row-line">
          {{element.dataYear}}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="isoCode">
      <th mat-header-cell *matHeaderCellDef style="width: 100px" appResizeColumn>
        <div mat-sort-header>Country</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}">
        <span class="row-line">
          {{element.isoCode}}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="oldNotes">
      <th mat-header-cell *matHeaderCellDef style="border-left: 2px solid #a9a9a9" appResizeColumn>
        <div mat-sort-header>Old Notes</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}"
          style="border-left: 2px solid #a9a9a9">
        <div class="scrollable-text">
          <span class="row-line">
            {{ getOldValue(element, 'notes') }}
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="newNotes">
      <th mat-header-cell *matHeaderCellDef style="border-right: 2px solid #a9a9a9" appResizeColumn>
        <div mat-sort-header>New Notes</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}"
          style="border-right: 2px solid #a9a9a9">
        <div class="scrollable-text">
          <span class="row-line">
            {{ getNewValue(element, 'notes') }}
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="oldNotesInternal">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>Old Internal Notes</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}">
        <div class="scrollable-text">
          <span class="row-line">
            {{ getOldValue(element, 'notesInternal') }}
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="newNotesInternal">
      <th mat-header-cell *matHeaderCellDef style="border-right: 2px solid #a9a9a9" appResizeColumn>
        <div mat-sort-header>New Internal Notes</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}"
          style="border-right: 2px solid #a9a9a9">
        <div class="scrollable-text">
          <span class="row-line">
            {{ getNewValue(element, 'notesInternal') }}
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="oldSource">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>Old Source</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}">
        <div class="scrollable-text">
          <span class="row-line">
            {{ getOldValue(element, 'source') }}
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="newSource">
      <th mat-header-cell *matHeaderCellDef style="border-right: 2px solid #a9a9a9" appResizeColumn>
        <div mat-sort-header>New Source</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}"
          style="border-right: 2px solid #a9a9a9">
        <div class="scrollable-text">
          <span class="row-line">
            {{ getNewValue(element, 'source') }}
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="oldValue">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>Old Value</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}"
          [ngStyle]="{'background-color': checkConditions(getOldValue(element, 'value')) ? color : ''}">
        <div class="scrollable-text">
          <span class="row-line">
            {{ getOldValue(element, 'value') }}
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="newValue">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>New Value</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}"
          [ngStyle]="{'background-color': checkConditions(getNewValue(element, 'value')) ? color : ''}">
        <div class="scrollable-text">
          <span class="row-line">
            {{ getNewValue(element, 'value') }}
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="valueDifference">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>Value Diff</div>
      </th>
      <td mat-cell *matCellDef="let element"
          [ngClass]="{'difference-row': element.differencesData, 'selected-row': selection.isSelected(element)}"
          [ngStyle]="{'background-color': checkConditions(getValueDiff(element)) ? color : ''}">
        <div class="scrollable-text">
          <span class="row-line">
            {{ getValueDiff(element) }}
          </span>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'table-row': true, 'selected-row': selection.isSelected(row)}"></tr>
  </table>
</div>

<div class="paginator">
  <mat-paginator class="mat-elevation-z8 hide-ripple"
                 [length]="dataSource.data.length"
                 [pageSizeOptions]="[10, 25, 50, 100, 150]">
  </mat-paginator>
</div>
