import { Injectable } from '@angular/core';
import axios from 'axios';
import { IMapper } from '../../models/i-mapper';
import { IAuthService } from '../auth-utils/i-auth-service';
import { IAuthenticator } from 'src/app/models/i-authenticator';
import { HttpVerb } from '../../models/http-verb';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private authenticator?: IAuthenticator;
  private authService?: IAuthService;
  private headers: { [key: string]: string } = {};

  constructor() {
    // this.headers['Content-Type'] = 'application/json';
  }

  setAuthenticator(authenticator: IAuthenticator): void {
    this.authenticator = authenticator;
  }
  setAuthService(authService: IAuthService): void {
    this.authService = authService;
  }

  async get(
    url: string,
    mapper: IMapper,
    params?: { [key: string]: any }
  ): Promise<IMapper | any> {
    try {
      const request = await this.authenticator?.getRequest(
        url,
        HttpVerb.get,
        {},
        undefined,
        params
      );
      delete request.headers.host;
      const response = await axios.get(url, {
        headers: request.headers,
        params,
      });
      return this.manageResponse(response, mapper);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.cause || error.message);
    }
  }

  async post(
    url: string,
    bodyMapper: IMapper,
    responseMapper: IMapper,
    headers?: { [key: string]: any }
  ): Promise<IMapper | any> {
    this.headers = headers ? headers : {};
    const body = bodyMapper.fillToJson();
    this.headers['Content-Type'] = 'application/json';
    const request = await this.authenticator?.getRequest(
      url,
      HttpVerb.post,
      this.headers,
      body
    );
    delete request.headers.host;
    try {
      const response = await axios.post(url, body, {
        headers: request.headers,
      });
      return this.manageResponse(response, responseMapper);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async put(
    url: string,
    bodyMapper: IMapper,
    responseMapper: IMapper,
    headers?: { [key: string]: any }
  ): Promise<IMapper | any> {
    this.headers = headers ? headers : {};
    const body = bodyMapper.fillToJson();
    this.headers['Content-Type'] = 'application/json';
    const request = await this.authenticator?.getRequest(
      url,
      HttpVerb.put,
      this.headers,
      body
    );
    delete request.headers.host;
    try {
      const response = await axios.put(url, body, {
        headers: request.headers,
      });
      return this.manageResponse(response, responseMapper);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async delete(
    url: string,
    mapper: IMapper,
    params?: { [key: string]: any },
    headers?: { [key: string]: string }
  ): Promise<IMapper | any> {
    this.headers = headers ? headers : {};
    const request = await this.authenticator?.getRequest(
      url,
      HttpVerb.delete,
      {},
      undefined,
      params
    );
    delete request.headers.host;
    try {
      const response = await axios.delete(url, {
        headers: request.headers,
        params,
      });
      return this.manageResponse(response, mapper);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private async manageResponse(response: any, mapper: IMapper): Promise<any> {
    if (
      this.authenticator &&
      this.authenticator?.isAuthenticationError(response.status)
    ) {
      return this.authenticator?.manageError(response);
    } else if (response.status.toString().startsWith('2')) {
      return Promise.resolve(this.convertToObject(response.data, mapper));
    } else {
      return Promise.reject(
        `Status code: ${response.status} - error: ${response.data.responseMessage}`
      );
    }
  }

  private convertToObject(responseBody: any, mapper: IMapper): IMapper {
    if (responseBody) {
      mapper.fillFromJson(responseBody);
    }
    return mapper;
  }
}
