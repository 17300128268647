import { IMapper } from 'src/app/models/i-mapper';
import { CodeCreationModel } from '../models/code-creation-model';

export class CreateCodesMapper implements IMapper {
  codes!: CodeCreationModel[];
  result?: string;
  fillFromJson(json: any): void {
    this.result = json;
  }
  fillToJson(): string {
    return JSON.stringify(
      this.codes.map((code) => ({
        disaggregationId: code.disaggregation.disaggregationId,
        unitId: code.unit.unitId,
        code: code.code,
        description: code.description,
        definition: code.definition,
        collectionId: code.collection?.collectionID,
        databaseId: code.database.databaseID,
        decimalNumber: code.decimalNumber,
      }))
    );
  }
}
