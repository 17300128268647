<div class="confirmation-dialog-container">
  <span class="dialog-title">
    {{ data.title | translate }}
  </span>
  <div class="dialog-text">
    <span>
      {{ data.message | translate }}
    </span>
  </div>
  <div class="buttons-container">
    <button mat-raised-button (click)="confirm()">
      <span>{{ 'CONFIRMATION_DIALOG.CONFIRM' | translate }}</span></button>
    <button mat-raised-button color="warn" (click)="cancel()"><span>{{ 'CONFIRMATION_DIALOG.CANCEL' | translate }}</span></button>
  </div>
</div>
