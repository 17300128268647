import { Component, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProviderService } from 'src/app/core/provider.service';
import { CodeCreationModel } from './models/code-creation-model';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { CollectionModel } from '../filters/collection-filter/models/collection-model';
import { DatabasesListModel } from '../codes-management/models/databases-model';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bulk-create-codes',
  templateUrl: './bulk-create-codes.component.html',
  styleUrls: ['./bulk-create-codes.component.scss'],
})
export class BulkCreateCodesComponent {
  baseCodeFormGroup = new FormGroup({
    codeName: new FormControl('', Validators.required),
    codeDescription: new FormControl('', Validators.required),
    collection: new FormControl({} as CollectionModel),
    database: new FormControl({} as DatabasesListModel, Validators.required),
    definition: new FormControl(''),
    decimalNumber: new FormControl(2, Validators.required),
  });
  disaggregationsFormGroup = new FormGroup({
    disaggregations: new FormControl([], Validators.required),
  });
  unitsFormGroup = new FormGroup({
    units: new FormControl([], Validators.required),
  });
  codeCreationModelDataSource: MatTableDataSource<CodeCreationModel> =
    new MatTableDataSource<CodeCreationModel>();
  selection = new SelectionModel<CodeCreationModel>(true, []);
  displayedColumns = [
    'select',
    'disaggregation',
    'unit',
    'code',
    'description',
    'definition',
    'decimalNumber',
  ];
  loading = false;
  creatingCodes = false;
  createCodesDialog!: any;

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  get isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.codeCreationModelDataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected ? this.selection.clear() : this.selectAll();
  }

  selectAll(): void {
    this.selection.select(...this.codeCreationModelDataSource.data);
  }

  openCreateCodesDialog(dialogRef: TemplateRef<any>): void {
    this.createCodesDialog = this.dialog.open(dialogRef, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
    });
  }

  goBackToHome(): void {
    this.providerService.utilService.navigateTo('/app/home');
  }

  enforceMinMax(el: any): void {
    const srcElement = el.srcElement;
    if (srcElement.value != '') {
      if (parseInt(srcElement.value) < parseInt(srcElement.min)) {
        srcElement.value = srcElement.min;
      }
      if (parseInt(srcElement.value) > parseInt(srcElement.max)) {
        srcElement.value = srcElement.max;
      }
    }
  }

  populateCodesTable(): void {
    this.codeCreationModelDataSource = new MatTableDataSource(
      this.providerService.bulkCreateCodesService.generateCodeCreationModels(
        {
          codeName: this.baseCodeFormGroup.value.codeName!,
          codeDescription: this.baseCodeFormGroup.value.codeDescription!,
          collection: this.baseCodeFormGroup.value.collection!,
          database: this.baseCodeFormGroup.controls.database.value!,
          definition: this.baseCodeFormGroup.value.definition!,
          decimalNumber: this.baseCodeFormGroup.value.decimalNumber!,
        },
        this.disaggregationsFormGroup.controls.disaggregations.value!,
        this.unitsFormGroup.controls.units.value!
      )
    );
    this.selection = new SelectionModel(
      true,
      this.codeCreationModelDataSource.data
    );
  }

  async stepperGoBack(stepper: MatStepper): Promise<void> {
    if (
      await this.providerService.utilService.openConfirmationDialog(
        'BULK_CODE_CREATE.GO_BACK_CONFIRMATION_TITLE',
        'BULK_CODE_CREATE.GO_BACK_CONFIRMATION_MESSAGE'
      )
    ) {
      stepper.previous();
    }
  }

  async createCodes(): Promise<void> {
    this.loading = true;
    this.creatingCodes = true;
    try {
      await this.providerService.bulkCreateCodesService.createCodes(
        this.selection.selected
      );
      this.snackBar.open('Codes created sucessfully!', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      await this.providerService.utilService.navigateTo('app/home');
    } catch (e) {
      this.snackBar.open('An error occured while creating the codes', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
      console.error(e);
    } finally {
      this.loading = false;
      this.creatingCodes = false;
      this.createCodesDialog.close();
    }
  }
}
