import { IMapper } from '../../../models/i-mapper';

export class UpdateCellMapper implements IMapper {
  body?: {
    answerId: string;
    value: number | null;
    notes: string | null;
    source: string | null;
  };
  fillFromJson(_json: any): void {
    return;
  }
  fillToJson(): string {
    return JSON.stringify([this.body]);
  }
}
