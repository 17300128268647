import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private totalCount = 0;
  constructor(private http: HttpClient) {}

  getTotalCount(): number {
    return this.totalCount;
  }

  find<T>(
    url: string,
    parentId: number,
    filter = '',
    sortItem = '',
    sortOrder = 'asc',
    pageNumber = 0,
    pageSize = 3
  ): Observable<T[]> {
    return this.http
      .get(url, {
        params: new HttpParams()
          .set('parentId', parentId.toString())
          .set('filter', filter)
          .set('sortItem', sortItem)
          .set('sortOrder', sortOrder)
          .set('pageNumber', pageNumber.toString())
          .set('pageSize', pageSize.toString()),
      })
      .pipe(
        map((res: any) => {
          this.totalCount = res['totalCount'];
          return res['datasource'] as T[];
        })
      );
  }
}
