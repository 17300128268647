import { IMapper } from '../../../models/i-mapper';
import { SurveySeriesModel } from '../models/survey-series-model';

export class SurveySeriesListMapper implements IMapper {
  surveySeries: SurveySeriesModel[] = [];

  fillFromJson(data: any): void {
    this.surveySeries = data;
  }

  fillToJson(): string {
    return '';
  }
}
