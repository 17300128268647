import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ProviderService } from '../../../core/provider.service';
import { UserModel, UsersRoles } from '../models/users-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import {
  RegionsCountriesListModel,
  RegionsListsModel,
} from '../../regions-management/models/region-filters-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
import { AntiMemLeak } from '../../../core/form-utils/anti-mem-leak/anti-mem-leak';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.scss'],
})
export class AdminViewComponent extends AntiMemLeak implements AfterViewInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;
  tableDataSource = new MatTableDataSource<UserModel>();
  tableDataCount = 0;
  creatingUser = false;
  deletingUser = false;
  updatingUser = false;
  hasUpdatedUser = false;
  users!: UserModel[];
  selectedUser!: any;
  eUsersRole = UsersRoles;
  regions!: RegionsListsModel[];
  countries!: RegionsCountriesListModel[];
  loading = false;
  detailOpen = false;
  showTableError = false;
  createUserDialog!: any;
  deleteUserDialog!: any;
  updateUserDialog!: any;
  createUserFormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.email, Validators.required]),
    userRole: new FormControl('', Validators.required),
    countryID: new FormControl(''),
  });
  updateUserFormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    userRole: new FormControl('', Validators.required),
    countryID: new FormControl(''),
  });
  filterFormGroup = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    role: new FormControl(''),
  });
  displayedColumns = ['name', 'surname', 'email', 'role', 'country'];

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData();
        }
      })
    );
    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.getTableData();
      })
    );
    this.subscriptions.add(
      this.filterFormGroup.controls.name.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterFormGroup.controls.email.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterFormGroup.controls.role.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.createUserFormGroup.controls.userRole.valueChanges.subscribe(
      (userRole: string | null) => {
        if (userRole) {
          this.updateCountryValidator(userRole);
        }
      }
    );
    this.updateUserFormGroup.controls.userRole.valueChanges.subscribe(
      (userRole: string | null) => {
        if (userRole) {
          this.updateCountryValidator(userRole);
        }
      }
    );
  }

  ngOnInit(): void {
    this.getCountries();
    this.getTableData();
  }

  async getCountries(): Promise<any> {
    this.regions =
      await this.providerService.regionsManagementService.getRegionsList(
        environment.ituClassId
      );
    this.countries = (
      await this.providerService.regionsManagementService.getCountriesByRegions(
        this.regions.map((value) => value.regionID)
      )
    ).sort((a, b) => {
      if (a.shortName < b.shortName) return -1;
      if (a.shortName > b.shortName) return 1;
      return 0;
    });
  }

  async getTableData(): Promise<void> {
    this.loading = true;
    this.showTableError = false;
    try {
      const name = this.filterFormGroup.controls.name.value || undefined;
      const role = this.filterFormGroup.controls.role.value || undefined;
      const email = this.filterFormGroup.controls.email.value || undefined;
      const usersBE = await this.providerService.adminService.getUsersAndCount(
        this.paginator?.pageSize || 25,
        this.paginator?.pageIndex || 0,
        this.sort?.active,
        this.sort?.active,
        this.sort?.direction !== '' ? this.sort?.direction : undefined,
        name || role || email
          ? JSON.stringify({ name, role, email })
          : undefined
      );
      this.tableDataSource.data = usersBE.users;
      this.tableDataCount = usersBE.count;
      this.users = usersBE.users;
    } catch (e) {
      this.showTableError = true;
      this.tableDataSource.data = [];
      console.error('error');
    } finally {
      this.loading = false;
    }
  }

  async createUser(): Promise<void> {
    try {
      this.creatingUser = true;
      const name = this.createUserFormGroup.controls.name.value || '';
      const surname = this.createUserFormGroup.controls.surname.value || '';
      const email = this.createUserFormGroup.controls.email.value || '';
      const role = this.createUserFormGroup.controls.userRole
        .value as UsersRoles;
      const countryID =
        this.createUserFormGroup.controls.countryID.value || undefined;
      await this.providerService.adminService.createUser(
        name,
        surname,
        email,
        role,
        countryID
      );
      this.dialog.closeAll();
      this.snackBar.open('User created successfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      await this.getTableData();
    } catch (e) {
      this.snackBar.open('An error occured while creating the user', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.creatingUser = false;
      this.reset();
    }
  }

  async updateUser(): Promise<void> {
    try {
      this.updatingUser = true;
      const name = this.updateUserFormGroup.controls.name.value || '';
      const surname = this.updateUserFormGroup.controls.surname.value || '';
      const role = this.updateUserFormGroup.controls.userRole
        .value as UsersRoles;
      const countryID =
        this.updateUserFormGroup.controls.countryID.value || undefined;
      const userId = this.selectedUser.id;
      await this.providerService.adminService.updateUser(
        userId,
        name,
        surname,
        role,
        countryID
      );
      this.dialog.closeAll();
      this.selectedUser.name =
        this.updateUserFormGroup.controls.name.value || '';
      this.selectedUser.surname =
        this.updateUserFormGroup.controls.surname.value || '';
      this.selectedUser.role = this.updateUserFormGroup.controls.userRole
        .value as UsersRoles;
      this.selectedUser.countryId =
        this.updateUserFormGroup.controls.countryID.value || undefined;
      this.snackBar.open('User updated successfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      this.hasUpdatedUser = true;
    } catch (e) {
      this.snackBar.open('An error occurred while updating the user', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.updatingUser = false;
      this.reset();
    }
  }

  async deleteUser(): Promise<void> {
    try {
      this.deletingUser = true;
      const userId = this.selectedUser.id;
      await this.providerService.adminService.deleteUser(userId);
      this.dialog.closeAll();
      this.goBack();
      this.snackBar.open('User deleted successfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
      await this.getTableData();
    } catch (e) {
      this.snackBar.open('An error occurred while deleting the user', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.deletingUser = false;
      this.reset();
    }
  }

  displayRole(role: UsersRoles): string {
    if (role === UsersRoles.ADMIN) return 'ADMIN';
    if (role === UsersRoles.EXTERNAL) return 'COUNTRY USER';
    if (role === UsersRoles.INTERNAL) return 'INTERNAL';
    else return '-';
  }

  displayCountry(countryId: string): string {
    if (countryId) {
      const country = this.countries?.find((c) => c.countryID === countryId);
      if (country) {
        return country.shortName;
      }
    }
    return '-';
  }

  updateCountryValidator(userRole: string): void {
    const createUserCountryControl =
      this.createUserFormGroup.controls.countryID;
    const updateUserCountryControl =
      this.updateUserFormGroup.controls.countryID;
    if (userRole === UsersRoles.EXTERNAL) {
      createUserCountryControl.setValidators([Validators.required]);
      updateUserCountryControl.setValidators([Validators.required]);
    } else {
      createUserCountryControl.clearValidators();
      createUserCountryControl.reset();
      updateUserCountryControl.clearValidators();
      updateUserCountryControl.reset();
    }
    createUserCountryControl.updateValueAndValidity();
    updateUserCountryControl.updateValueAndValidity();
  }

  openCreateUserDialog(dialogRef: any): any {
    this.createUserDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  openDeleteUserDialog(dialogRef: any): any {
    this.deleteUserDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  openUpdateUserDialog(dialogRef: any): any {
    this.updateUserFormGroup.patchValue({
      name: this.selectedUser.name,
      surname: this.selectedUser.surname,
      userRole: this.selectedUser.role,
      countryID: this.selectedUser.countryId,
    });
    this.updateUserDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  reset(): void {
    this.createUserFormGroup.reset();
  }

  openDetail(id: string): void {
    this.detailOpen = true;
    this.selectedUser = this.users.find((user) => user.id === id);
  }

  goBack(): void {
    this.detailOpen = false;
    if (this.hasUpdatedUser) {
      this.getTableData();
    }
    this.hasUpdatedUser = false;
  }

  clearRole(event: any): void {
    event.stopPropagation();
    this.filterFormGroup.controls.role.setValue('');
  }

  goBackToHome(): void {
    this.providerService.utilService.navigateTo('/app/home');
  }
}
