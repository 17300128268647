<div class="container">
  <div #rectangularSelection id="rectangular-div" hidden></div>
  @if (loading) {
  <app-loader></app-loader>
  }
  <div class="filters-class">
    <div class="filters-bar" [formGroup]="countryFiltersFormGroup">
      <div class="first-column">
        <div class="mat-drop-down large">
          <app-region-country-filter styleType="filters-bar"
            [resetFilterObservable]="clearFiltersEmitter.asObservable()"
            (countriesChanged)="countriesSelectionChanged($event)"></app-region-country-filter>
        </div>
        <div class="mat-drop-down code-filter-style">
          <app-code-filter [resetFilterObservable]="clearFiltersEmitter.asObservable()"
            (codeSelectionChanged)="codeSelectionChanged($event)"></app-code-filter>
        </div>
        <div class="mat-drop-down">
          <mat-label>Description: </mat-label>
          <input class="filter-input" matInput type="text" formControlName="description">
        </div>
        <div class="mat-drop-down">
          <app-unit-filter [resetFilterObservable]="clearFiltersEmitter.asObservable()"
            (unitsChanged)="unitsChanged($event)"></app-unit-filter>
        </div>
        <div class="mat-drop-down">
          <app-collection-filter [resetFilterObservable]="clearFiltersEmitter.asObservable()"
          (collectionsChanged)="collectionsChanged($event)"></app-collection-filter>
        </div>
        <div class="mat-drop-down">
          <mat-label>From Year: </mat-label>
          <input class="filter-input" matInput type="number" formControlName="fromYear"
            (input)="validateYearFormDigits($event)">
          @if (fromYearError) {
          <span class="inline-form-error">Invalid value</span>
          }
        </div>
        <div class="mat-drop-down">
          <mat-label>To Year: </mat-label>
          <input class="filter-input" matInput type="number" formControlName="toYear"
            (input)="validateYearFormDigits($event)">
          @if (toYearError) {
          <span class="inline-form-error">Invalid value</span>
          }
        </div>
        <div class="mat-drop-down">
          <app-disaggregation-filter [level]="1" [resetFilterObservable]="clearFiltersEmitter.asObservable()"
            (disaggregationsChanged)="disaggregationsChanged($event, 1)"></app-disaggregation-filter>
        </div>
        <div class="mat-drop-down">
          <app-disaggregation-filter [level]="2" [resetFilterObservable]="clearFiltersEmitter.asObservable()"
            (disaggregationsChanged)="disaggregationsChanged($event, 2)"></app-disaggregation-filter>
        </div>
        <div class="mat-drop-down checkbox-container">
          <mat-checkbox #estimateCheckbox [indeterminate]="true" (change)="estimateFilterChanged(estimateCheckbox)">
            <span>
              Estimate
            </span>
          </mat-checkbox>
          <mat-checkbox #publishedCheckbox [indeterminate]="true" (change)="publishedFilterChanged(publishedCheckbox)">
            <span>
              Published
            </span>
          </mat-checkbox>
        </div>
      </div>
      <div class="last-column">
        <div class="conditional-formatting-container">
          <i class="fa-solid fa-x" style="cursor: pointer" (click)="clearFilters()" matTooltip="Clear filters"></i>
          <div class="divider"></div>
          @if (isConditionalFormattingFilterOn) {
          <i class="fa-solid fa fa-trash" style="cursor: pointer" (click)="clearConditionalFormattingFilter()"
            matTooltip="Clear"></i>
          }
          <button color="primary" mat-raised-button type="button" onclick="this.blur()"
            class="conditional-formatting-button" (click)="openConditionalFormattingDialog()">
            Conditional formatting
          </button>
          <button id="clipboard-button" color="primary" mat-raised-button type="button" onclick="this.blur()"
            class="conditional-formatting-button" (click)="openCopyToClipboardDialog(copyToClipboardOptionsDialog)">
            Copy to clipboard
          </button>
          <div class="divider"></div>
          <div class="button-container">
            <button mat-raised-button color="primary" type="button" class="selected-download-button"
              onclick="this.blur()" [disabled]="fetchingTableDataCount" [matMenuTriggerFor]="downloadMenu">
              Download<mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #downloadMenu="matMenu">
              <button class="mat-menu-item-download" mat-menu-item
                (click)="downloadShowedTableData(downloadDialog, true)" onclick="this.blur()">
                Download Raw
              </button>
              <button class="mat-menu-item-download" mat-menu-item
                (click)="downloadShowedTableData(downloadDialog, false)" onclick="this.blur()">
                Download Years as Columns
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="table-holder">
    <form class="table-scroller" matSort autocomplete="off" #formElement>
      <ng-container>
        @if (countriesDataBE.length !== 0) {
        <table mat-table [dataSource]="tableDataSource" class="mat-elevation-z8" id="country-table"
          [trackBy]="tableTrackBy">
          <caption></caption>
          @for (column of columns; track column; let i = $index) {
          @if (column.field !== 'actions' && column.field !== 'disaggregations1' && column.field !== 'disaggregations2')
          {
          @if (stickyColumns.includes(column.field)) {
          <ng-container matColumnDef="{{column.field}}" sticky>
            @if(column.sortable) {
            <th mat-sort-header [columnModel]="column" [disabled]="resizing" appResizeColumn
              (resizing)="this.resizingChanged($event)" mat-header-cell *matHeaderCellDef>{{
              column.title }}
            </th>
            } @else {
            <th mat-header [columnModel]="column" appResizeColumn (resizing)="this.resizingChanged($event)"
              mat-header-cell *matHeaderCellDef>{{
              column.title }}
            </th>
            }
            <td mat-cell class="read-only" *matCellDef="let element; let i = index"
              [ngClass]="trendChartSelection.isSelected(tableDataSource.data[i]) ? 'trend-chart-selected-row' : ''">
              <input class="form-control" (keydown.enter)="$event.preventDefault()" readonly
                [ngClass]="trendChartSelection.isSelected(tableDataSource.data[i]) ? 'trend-chart-selected-row' : ''"
                [value]="element[column.field]" />
            </td>
          </ng-container>
          }
          }
          @if (column.field === 'actions') {
          <ng-container matColumnDef="{{column.field}}" sticky>
            <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
            <td mat-cell *matCellDef="let element; let i = index" class="sticky-border">
              <span class="actions-container">
                <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="$event ? trendChartSelection.toggle(tableDataSource.data[i]) : null"
                  [checked]="trendChartSelection.isSelected(tableDataSource.data[i])">
                </mat-checkbox>
                <button mat-icon-button class="material-icons app-toolbar-menu save-button"
                  (click)="openModificationDialog(element)">
                  <mat-icon class="edit-icon">edit</mat-icon>
                </button>
              </span>
            </td>
          </ng-container>
          }
          @if (column.field === 'disaggregations1' || column.field === 'disaggregations2') {
          <ng-container matColumnDef="{{column.field}}" sticky>
            <th mat-header-cell *matHeaderCellDef appResizeColumn (resizing)="this.resizingChanged($event)">{{
              column.title }}</th>
            <td mat-cell *matCellDef="let element; let i = index" class="read-only"
              [ngClass]="trendChartSelection.isSelected(tableDataSource.data[i]) ? 'trend-chart-selected-row' : ''">
              <span [matTooltip]="displayMultipleDisaggregationsFn(element[column.field])"
                [ngClass]="trendChartSelection.isSelected(tableDataSource.data[i]) ? 'trend-chart-selected-row' : ''">
                {{displayMultipleDisaggregationsFn(element[column.field])}}
              </span>
            </td>
          </ng-container>
          }
          }
          @for(year of yearsArray; track year) {
          <ng-container matColumnDef="{{year}}">
            <th appResizeColumn mat-sort-header [disabled]="resizing" (resizing)="this.resizingChanged($event)"
              mat-header-cell *matHeaderCellDef>
              {{ year }}
            </th>
            <td mat-cell *matCellDef="let cell; let i = index"
              class="{{selectedCell === cell && selectedColumn === year ? 'selectedCell': ''}} selectable-cell {{cell.estimated[year] === true ? 'estimate-cell' : ''}}"
              #currentTD [ngStyle]="{'background-color': checkConditions(cell.values[year], cell) ? color : 'inherit'}"
              (click)="setSelectedCell(cell, year, currentTD); getCellInfo(cell, year);"
              (mousedown)="downEventOnCell($event, i, year);" (mouseup)="upEventOnCell($event, i, year)" (contextmenu)="openContextualMenu($event,
                        areCellsSelectedWithRectangularSelection ? tableContextualMenuWithSelection : tableContextualMenu,
                        cell, year, currentTD, year, i)">
              <div class="editable-cell-input-group">
                <input matInput type="text" class="form-control custom-number-input" appFormatNumberInput
                  [numDigits]="cell.decimalNumber ?? 2" (keydown.enter)="$event.preventDefault()"
                  [ngStyle]="{'background-color': checkConditions(cell.values[year], cell) ? color : 'inherit'}"
                  (dataChanged)="updateCell(cell, year, $event.value, $event.fromEnter)" [value]="cell.values[year]" />
              </div>
            </td>
          </ng-container>
          }
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        }
      </ng-container>
    </form>
  </div>
  @if (countriesDataBE.length === 0 && !loading && !showTableError) {
  <div class="no-data-found">No data found for your search</div>
  }
  @if (showTableError && !loading) {
  <div class="no-data-found">Something went wrong</div>
  }
  <div class="paginator">
    <div class="hide-columns-container">
      @if(!columnsHidden) {
      <i class="fa-solid fa-eye" matTooltip="Hide extra columns" (click)="columnsHidden = true"></i>
      } @else {
      <i class="fa-sharp fa-solid fa-eye-slash" matTooltip="Show extra columns" (click)="columnsHidden = false"></i>
      }
    </div>
    @if (fetchingTableDataCount) {
    <div class="spinner-container">
      <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
    </div>
    }
    <mat-paginator [length]="tableDataCount" [pageSizeOptions]="[25, 50, 100, 150, 250]">
    </mat-paginator>
  </div>
  <div class="data-info-container">
    @if (!cellInfoPresent && selectedCell) {
    <div class="no-cell-warning">
      <span>No value for selected year, insert a value to modify details</span>
    </div>
    }
    <div class="data-info">
      @if (cellInfoLoading) {
      <mat-spinner></mat-spinner>
      }
      <div class="notes-group">
        <div class="notes-container">
          <div class="notes-row">
            <span class="first">Internal notes: </span>
            @if (cellInfoPresent && !cellInfo) {
            <div class="loader-container">
              <i class="fa-solid fa-ellipsis ellipses-loader"></i>
            </div>
            }
            <input #publishedNote matInput [disabled]="cellInfoLoading || !cellInfoPresent"
              (input)="internalNotesSelectionChanged($event)"
              (focusout)="publishedNote.value.length > 0 ? updateInternalNotes() : updateInternalNotes('')"
              (keydown.enter)="publishedNote.value.length > 0 ? updateInternalNotes() : updateInternalNotes('')"
              type="text" [value]="cellInfo === undefined ? '' : cellInfo.internalNotes">
          </div>
          <mat-divider></mat-divider>
          <div class="notes-row">
            <span>Published notes: </span>
            @if (cellInfoPresent && !cellInfo) {
            <div class="loader-container">
              <i class="fa-solid fa-ellipsis ellipses-loader"></i>
            </div>
            }
            <input #internalNote matInput [disabled]="cellInfoLoading || !cellInfoPresent"
              (input)="notesSelectionChanged($event)"
              (focusout)="internalNote.value.length > 0 ? updatePublishedNotes() : updatePublishedNotes('')"
              (keydown.enter)="internalNote.value.length > 0 ? updatePublishedNotes() : updatePublishedNotes('')"
              type="text" [value]="cellInfo === undefined ? '' : cellInfo.publishNotes">
          </div>
        </div>
        <div class="notes-states" [formGroup]="checkboxFormGroup">
          <div class="flex-row">
            <span>Published</span>
            <div>
              <mat-checkbox [disabled]="cellInfoLoading || !cellInfoPresent"
                [formControl]="checkboxFormGroup.controls.published" (change)="publishedChanged($event)"></mat-checkbox>
            </div>
          </div>
          <div class="flex-row">
            <span>Estimated</span>
            <div>
              <mat-checkbox [disabled]="cellInfoLoading || !cellInfoPresent"
                [formControl]="checkboxFormGroup.controls.estimated" (change)="estimatedChanged($event)"></mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="data-history">
        <span>Data history</span>
        <div>
          @for (comment of cellHistory; track comment) {
          @if (selectedCell) {
          @if (comment.value != null) {
          <p>user {{ comment.user }} changed value to {{ comment.value }} on {{ comment.lastModifiedOn }}</p>
          } @else {
          <p>user {{ comment.user }} deleted the value on {{ comment.lastModifiedOn }}</p>
          }
          }
          }
        </div>
        <app-upload-retrieve-file [cell]="selectedCell" [table]="'household-view'" [column]="selectedColumn"
          [cellInfo]="cellInfo" [disabled]="cellInfoPresent && !cellInfo">
        </app-upload-retrieve-file>
        <div class="import chart-buttons-container">
          <button mat-raised-button class="chart-button" [disabled]="trendChartSelection.selected.length === 0"
            onclick="this.blur()" (click)="opendTrendChart(trendChartDialog)">
            View Chart
          </button>
          @if (trendChartSelection.selected.length > 0) {
          <button mat-raised-button class="chart-button" [disabled]="trendChartSelection.selected.length === 0"
            onclick="this.blur()" (click)="trendChartSelection.clear()">
            Deselect All
          </button>
          }
        </div>
      </div>
      <div class="data-source">
        <span>Source</span>
        <input #sourceInput [disabled]="cellInfoLoading || !cellInfoPresent" (input)="sourceSelectionChanged($event)"
          (focusout)="updateSource();deleteSource( false,sourceInput.value)" type="text"
          (keydown.enter)="updateSource();deleteSource( false,sourceInput.value)"
          [value]="cellInfo === undefined ? '' : cellInfo.source">
      </div>
    </div>
  </div>
</div>

<ng-template #downloadDialog let-data>
  <div class="container-dialog-select-columns">
    @if (data.isRaw && downloadRawStep === 0) {
    <h2 mat-dialog-title class="mat-dialog-title">Select columns to download</h2>
    <mat-dialog-content [formGroup]="countryFiltersFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>CSV Columns</mat-label>
        <mat-select #columnFilter [formControl]="csvColumnsFormControl" class="alt-select"
          (selectionChange)="this.countryFiltersFormGroup.controls.autocompleteColumn.setValue('')"
          (openedChange)="onOpenedColumnChange($event)" multiple>
          <mat-select-trigger>
            <span class="label" [matTooltip]="displayMultipleColumnFn(csvColumnsFormControl.value)">
              {{csvColumnsFormControl.value?.[0] || ''}}
              @if ((csvColumnsFormControl.value?.length || 0) > 1) {
              <span class="example-additional-selection">
                (+{{(csvColumnsFormControl.value?.length || 0) - 1}} {{csvColumnsFormControl.value?.length === 2 ?
                'other' : 'others'}})
              </span>
              }
            </span>
            @if ((csvColumnsFormControl.value?.length || 0) > 0) {
            <i (click)="removeColumnFilter($event)" class="fa fa-x clear-filter" [matTooltip]="'Clear filter'"></i>
            }
          </mat-select-trigger>
          <input matInput type="text" formControlName="autocompleteColumn" class="autocomplete-input"
            (keydown)="$event.stopPropagation()" #columnInputElement>
          @if (countryFiltersFormGroup.controls.autocompleteColumn.value === '') {
          <span class="autocomplete-placeholder">Search...</span>
          }
          @for (column of rawCountryViewDataColumns; track column) {
          @if (column.value === 'All columns') {
          <mat-option [ngClass]=" visuallyFilterColumns(column.value) ? '' : 'hidden'" (click)="allColumnsSelected()"
            [value]="column.value">
            {{ column.name | translate }}
          </mat-option>
          }
          @else {
          <mat-option [ngClass]="visuallyFilterColumns(column.value) ? '' : 'hidden'" (click)="otherColumnSelected()"
            [value]="column.value">
            {{ column.name | translate }}
          </mat-option>
          }
          }
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>
    } @else if(!data.isRaw || downloadRawStep === 1) {
    <h2 mat-dialog-title class="mat-dialog-title">{{ 'DIALOG.DOWNLOAD_TITLE' | translate }}</h2>
    <mat-dialog-content>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <p>{{ 'DIALOG.DOWNLOAD_MESSAGE' | translate }}</p>
    </mat-dialog-content>
    }
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close (click)="cancelDownload()">{{ 'DIALOG.CANCEL' | translate }}</button>
      @if(data.isRaw && downloadRawStep === 0) {
      <button class="select-columns-button" mat-raised-button color="primary"
        [disabled]="!csvColumnsFormControl.value.length" (click)="downloadShowedRawTableData()">
        Next
      </button>
      }
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #copyToClipboardOptionsDialog>
  <div class="copy-to-clipboard-dialog">
    <h2 mat-dialog-title class="mat-dialog-title">Select copy options</h2>
    <mat-dialog-content>
      <div class="checkbox-container">
        <mat-checkbox [checked]="columnCopyOption" (change)="columnCopyOptionChanged($event.checked);">
          Include column names in the copy
        </mat-checkbox>
        <mat-checkbox [checked]="copyExtraColumnsOption" (change)="copyExtraColumnsOption = $event.checked" [disabled]="!columnCopyOption">
          Include disaggregations, collection and unit columns in the copy
        </mat-checkbox>
        <mat-checkbox [checked]="codeCopyOption" (change)="codeCopyOption = $event.checked">
          Include code and country columns in the copy
        </mat-checkbox>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions style="display:flex; justify-content: space-between">
      <button mat-raised-button mat-dialog-close>{{ 'DIALOG.CANCEL' | translate }}</button>
      <button class="select-columns-button" mat-raised-button color="primary"
        (click)="copyToClipboardDialog?.close(); copyToClipboard()">
        Copy
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #tableContextualMenu>
  <div class="table-contextual-menu">
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
      (click)="copyToClipboard(); closeContextualMenu()">
      Copy
    </button>
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
      (click)="openCopyToClipboardDialog(copyToClipboardOptionsDialog); closeContextualMenu()">
      Copy Options
    </button>
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
      (click)="openConditionalFormattingDialog(); closeContextualMenu()">
      Conditional Formatting
    </button>
  </div>
</ng-template>

<ng-template #tableContextualMenuWithSelection>
  <div class="table-contextual-menu">
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
      (click)="copyToClipboard(); closeContextualMenu()">
      Copy
    </button>
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
      (click)="openCopyToClipboardDialog(copyToClipboardOptionsDialog); closeContextualMenu()">
      Copy Options
    </button>
    <button class="table-contextual-menu-item" (contextmenu)="$event.preventDefault()"
      (click)="openConditionalFormattingDialog(); closeContextualMenu()">
      Conditional Formatting
    </button>
  </div>
</ng-template>

<ng-template #trendChartDialog>
  <div class="trend-chart-dialog">
    <i class="fa fa-x" (click)="chartDialog.close()"></i>
    <canvas baseChart [datasets]="trendChartData" [labels]="trendChartLabels" [options]="trendChartOptions"
      [legend]="trendChartLegend" [type]="trendChartType">
    </canvas>
  </div>
  <div class="trend-chart-actions-buttons">
    <div class="select-chart-type">
      <mat-form-field appearance="fill">
        <mat-label>Type</mat-label>
        <mat-select [formControl]="trendChartTypeForm">
          <mat-option [value]="'bar'">
            Bar Chart
          </mat-option>
          <mat-option [value]="'line'">
            Line Chart
          </mat-option>
          <mat-option [value]="'scatter'">
            Scatter Chart
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="select-chart-type">
      <mat-form-field appearance="fill">
        <mat-label>From Year</mat-label>
        <mat-select [formControl]="trendChartStartYearForm">
          @for (year of trendChartYears; track year) {
          <mat-option [value]="year">{{ year }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="select-chart-type">
      <mat-form-field appearance="fill">
        <mat-label>To Year</mat-label>
        <mat-select [formControl]="trendChartEndYearForm">
          @for (year of trendChartYears; track year) {
          <mat-option [value]="year">{{ year }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <button mat-raised-button color="primary" onclick="this.blur()" (click)="exportTrendChartToImage()">
      Export to JPEG
    </button>
  </div>
</ng-template>