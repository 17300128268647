import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/net-utils/network.service';
import { environment } from 'src/environments/environment';
import { ValidationModuleCountryMapper } from '../mappers/validation-module-country-mapper';
import { ValidationModuleGroupByCountryModel } from '../models/validation-module-country-model';
import { UpdateCellMapper } from '../mappers/update-cell-mapper';
import { getCellHistoryMapper } from '../mappers/get-cell-history-mapper';
import { ValidationCellHistoryModel } from '../models/validation-cell-history-model';
import { ValidationCellHistoryElaborated } from '../models/validation-cell-history-elaborated';
import { UtilsService } from 'src/app/core/app-utils/utils/utils.service';
import { InsertCellMapper } from '../mappers/insert-cell-mapper';
import { UpdateCheckedMapper } from '../mappers/update-checked-mapper';
import { getValidationStatusMapper } from '../mappers/get-validation-status-mapper';

@Injectable({
  providedIn: 'root',
})
export class ValidationModuleCountryTableService {
  private networkService!: NetworkService;
  baseUrl = `${environment.backend.endpoint}/validation-module`;
  private emptyMapper = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fillFromJson(_json: any): void {},
    fillToJson(): string {
      return '{}';
    },
    toString(): string {
      return '';
    },
  };

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  constructor(private utilService: UtilsService) {}

  async getValidationModuleCountryTableListAndCount(
    surveyId: string,
    codes: string[] | undefined,
    percentage: number | undefined,
    countryId: string,
    fromYear: number,
    toYear: number,
    pageNumber: number,
    pageSize: number
  ): Promise<ValidationModuleGroupByCountryModel> {
    const mapper = new ValidationModuleCountryMapper();
    await this.networkService.get(
      `${this.baseUrl}/survey/${surveyId}/from_year/${fromYear}/to_year/${toYear}`,
      mapper,
      {
        percentage,
        countryId,
        code: codes?.join(',') ?? undefined,
        offset: pageNumber,
        limit: pageSize,
      }
    );
    return mapper.response!;
  }

  async completeSurvey(surveyId: string, countryId: string): Promise<void> {
    await this.networkService.post(
      `${this.baseUrl}/survey/${surveyId}/country/${countryId}/complete`,
      this.emptyMapper,
      this.emptyMapper
    );
  }

  async updateValidationModuleCell(
    surveyId: string,
    cellId: string,
    value: number | null,
    note: string | null
  ): Promise<void> {
    const mapper = new UpdateCellMapper();
    mapper.body = {
      answerId: cellId,
      value: value ? parseFloat(value.toString()) : null,
      notes: note,
    };
    await this.networkService.put(
      `${this.baseUrl}/survey/${surveyId}`,
      mapper,
      this.emptyMapper
    );
  }

  async getCellHistory(cellId: string): Promise<ValidationCellHistoryModel[]> {
    const mapper = new getCellHistoryMapper();
    await this.networkService.get(`${this.baseUrl}/history/${cellId}`, mapper);
    return mapper.validationCellHistoryModelList;
  }

  async changedChecked(
    cellIds: string[],
    surveyId: string,
    checked: boolean
  ): Promise<void> {
    const mapper = new UpdateCheckedMapper();
    mapper.body = {
      answerIds: cellIds,
      checked: checked,
    };
    await this.networkService.put(
      `${this.baseUrl}/survey/${surveyId}/checked`,
      mapper,
      this.emptyMapper
    );
  }

  elaborateCellHistory(
    cellHistory: ValidationCellHistoryModel[]
  ): ValidationCellHistoryElaborated[] {
    return cellHistory
      .sort(
        (a, b) =>
          new Date(a.lastModifiedOn).getTime() -
          new Date(b.lastModifiedOn).getTime()
      )
      .map((entry, index, arr) => {
        let type: 'insert' | 'modification' | 'note' = 'modification';

        if (index === 0) {
          type = 'insert';
        } else {
          const prev = arr[index - 1];
          if (entry.valueNumber !== prev.valueNumber) {
            type = 'modification';
          }
          if (entry.notes && entry.notes !== prev.notes) {
            type = 'note';
          }
        }

        let modifiedBy = entry.name ?? '';

        if (entry.surname) {
          modifiedBy += ` ${entry.surname}`;
        }

        return {
          type,
          value: entry.valueNumber,
          note: entry.notes,
          modifiedOn: this.utilService.humanReadableDate(
            entry.lastModifiedOn,
            true
          ),
          modifiedBy: modifiedBy.length > 0 ? modifiedBy : 'NA',
          order: index + 1,
        };
      });
  }

  async insertCellValue(
    surveyId: string,
    countryId: string,
    dataYear: number,
    codeId: string,
    checked: boolean,
    value: number | null,
    notes: string | null
  ): Promise<string> {
    const mapper = new InsertCellMapper();
    mapper.body = {
      countryId: countryId,
      codeId: codeId,
      dataYear: dataYear,
      value: value,
      notes: notes,
      checked: checked,
    };
    await this.networkService.post(
      `${this.baseUrl}/survey/${surveyId}`,
      mapper,
      mapper
    );
    return mapper.returnedId!;
  }

  async getValidationStatus(
    surveyId: string,
    countryId: string
  ): Promise<string> {
    const mapper = new getValidationStatusMapper();
    await this.networkService.get(
      `${this.baseUrl}/survey/${surveyId}/country/${countryId}`,
      mapper
    );
    return mapper.status!;
  }
}
