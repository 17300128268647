<div class="sign-in-container">
  <div class="sign-in-logo-container">
    <img class="logo" src="assets/images/logo.svg" title="logo" alt="logo" />
  </div>
  <div class="sign-in-form-container">

    <h1>{{ 'SIGN_IN.SIGN_IN' | translate }}</h1>
    <h3>{{ 'SIGN_IN.INSERT_CREDENTIALS' | translate }}</h3>

    <div class="auth-form">
      <form spellcheck="false" [formGroup]="form">

        <mat-form-field appearance="fill">
          <mat-label class="label-sign-in">{{ 'SIGN_IN.EMAIL_LABEL' | translate }}</mat-label>
          <input matInput type="email" id="email" name="email" formControlName="email"
                 placeholder="{{'SIGN_IN.EMAIL_PLACEHOLDER' | translate}}">
          <i class="fa fa-regular fa-envelope" matSuffix></i>
          <mat-hint>{{ 'SIGN_IN.EMAIL_HINT' | translate }}</mat-hint>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'email'" [icon]="'fa-regular fa-circle-exclamation'"
                          message="{{'SIGN_IN.EMAIL_ERROR' | translate}}"></app-validator-ui>

        <mat-form-field appearance="fill">
          <mat-label class="label-sign-in">{{ 'SIGN_IN.PASSWORD_LABEL' | translate }}</mat-label>
          <input matInput [type]="showPassword ? 'text' : 'password'" id="password" name="password"
                 formControlName="password" (keydown.enter)="signIn()">
          <button class="transparent" matSuffix type="button" (click)="showPassword = !showPassword">
            <i class="fa-regular {{ showPassword ? 'fa-eye-slash' : 'fa-eye' }}"></i>
          </button>
          <mat-hint>{{ 'SIGN_IN.PASSWORD_HINT' | translate }}</mat-hint>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'password'" [icon]="'fa-regular fa-circle-exclamation'"
                          message="{{'SIGN_IN.PASSWORD_ERROR' | translate}}"></app-validator-ui>

        <button color="primary" mat-raised-button type="button" class="confirm-button" (click)="signIn()"
                [disabled]="loading">
          @if (loading) {
            <span class="fa fa-spin fa-spinner" role="status" aria-hidden="true"></span>
          }
          <span>{{ 'SIGN_IN.SIGN_IN' | translate }}</span>
        </button>
        @if (errorMessageVisible) {
          <span class="error-login" *ngIf="errorMessageVisible">{{ 'SIGN_IN.ERROR_MSG' | translate }}</span>
        }
      </form>
      <!--      <span class="sign-up-message">{{'SIGN_IN.NOT_REGISTERED_MESSAGE' | translate}} <a (click)="navigateToSignUp()">{{'SIGN_IN.HERE' | translate}}</a></span>-->
    </div>
  </div>
</div>
