<div class="download-page-container">

  @if(selectedDownloadViewType != undefined) {
    <button class="back-button" mat-raised-button (click)="selectView(undefined)">
      <i class="fa fa-arrow-left"></i>
      <span>Back</span>
    </button>
  }

  @if (!selectedDownloadViewType) {
    <div class="select-view-container">
      <mat-card-title class="action-message">
        <div class="back-button-container">
          <button mat-button mat-raised-button color="primary" (click)="goBackToHome()"><i
              class="fa-solid fa-arrow-left"></i> {{ 'DOWNLOAD_MODULE.BACK' | translate }}
          </button>
        </div>
        <span>
          {{ 'DOWNLOAD_MODULE.SELECT_VIEW' | translate }}
        </span>
      </mat-card-title>
      <div class="select-view-cards-container">
        <mat-card (click)="selectView(eDownloadViewType.DownloadList)">
          <i class="fa-solid fa-list"></i>
          <mat-card-title>{{ 'DOWNLOAD_MODULE.DOWNLOAD_LIST' | translate }}</mat-card-title>
        </mat-card>
        <mat-card (click)="selectView(eDownloadViewType.CountryView)">
          <i class="fa fa-earth-america"></i>
          <mat-card-title>{{ 'DOWNLOAD_MODULE.COUNTRY_VIEW' | translate }}</mat-card-title>
        </mat-card>
        <mat-card (click)="selectView(eDownloadViewType.HouseholdView)">
          <i class="fa fa-house-chimney-window"></i>
          <mat-card-title>{{ 'DOWNLOAD_MODULE.HOUSEHOLD_VIEW' | translate }}</mat-card-title>
        </mat-card>
      </div>
    </div>
  }

  @if (selectedDownloadViewType === eDownloadViewType.CountryView) {
    <app-country-view-download></app-country-view-download>
  }
  @else if(selectedDownloadViewType === eDownloadViewType.HouseholdView) {
    <app-household-view-download></app-household-view-download>
  }
  @else if(selectedDownloadViewType === eDownloadViewType.DownloadList) {
    <app-download-list></app-download-list>
  }

</div>
