import { IMapper } from '../../../models/i-mapper';
import {
  ValidationModuleCodeListModel,
  ValidationModuleGroupByCountryModel,
} from '../models/validation-module-country-model';

export class ValidationModuleCountryMapper implements IMapper {
  response?: ValidationModuleGroupByCountryModel;
  fillFromJson(json: any): void {
    const groupedData: { [key: string]: ValidationModuleCodeListModel } = {};

    json.data.forEach((item: any) => {
      if (!groupedData[item.codeId]) {
        groupedData[item.codeId] = {
          codeId: item.codeId,
          codeName: item.code,
          years: [],
        };
      }
      groupedData[item.codeId].years.push({
        dataId: item.answerId,
        year: item.dataYear,
        value: item.value,
        percentageChange: item.valueDiffPercentage,
        source: item.answer,
        note: item.notes,
      });
    });

    this.response = {
      data: Object.values(groupedData),
      total: json.total,
    };
  }
  fillToJson(): string {
    return '';
  }
}
