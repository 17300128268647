<div class="form-container filters-bar">
  <div class="mat-drop-down">
    <mat-label>{{'FILTERS.CODE' | translate}}</mat-label>
    <i class="fa fa-circle-info"
      matTooltip="{{'FILTERS.CODE_TOOLTIP' | translate}}"></i>
    <mat-form-field class="code-form-field">
      <mat-chip-grid #chipGrid>
        @for (filter of filters; track filter) {
        <mat-chip-row (removed)="removeChip(filter)" [removable]="true" [editable]="false" class="standard">
          {{ filter }}
          <button matChipRemove class="mat-chip-remove-code">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        }
        <input class="code-filter-input" matInput type="text" [matChipInputFor]="chipGrid"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addChipValue($event)" #chipsFilterInputElement/>
      </mat-chip-grid>
    </mat-form-field>
  </div>
</div>