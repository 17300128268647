import { Component, Input, OnInit } from '@angular/core';
import { CodesListModel } from '../models/codes-model';
import { DatabasesListModel } from '../models/databases-model';
import { CollectionsModel } from '../models/collections-model';
import { UnitModel } from '../../filters/unit-filter/models/unit-model';

@Component({
  selector: 'app-code-detail',
  templateUrl: './code-detail.component.html',
  styleUrl: './code-detail.component.scss',
})
export class CodeDetailComponent implements OnInit {
  @Input()
  code!: CodesListModel | undefined;
  @Input()
  units!: UnitModel[];
  @Input()
  databases!: DatabasesListModel[];
  @Input()
  collections!: CollectionsModel[];
  databaseName!: string | undefined;
  unitDescription!: string | undefined;
  collectionName!: string | undefined;

  ngOnInit(): void {
    this.updateDescriptions();
  }

  updateDescriptions(): void {
    this.databaseName =
      this.databases.find((db) => db.databaseID === this.code?.databaseID)
        ?.databaseName || undefined;
    this.unitDescription =
      this.units.find((db) => db.unitId === this.code?.unitID)
        ?.unitDescription || undefined;
    this.collectionName =
      this.collections.find((db) => db.collectionID === this.code?.collectionID)
        ?.collectionName || undefined;
  }
}
