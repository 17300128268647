<div class="container">
  <div mat-dialog-title class="header">
    <div class="title">{{ 'CONDITIONAL_FORMATTING.TITLE' | translate }}</div>
    <i (click)="closeDialog()" matTooltip="Close" class="fa fa-times" aria-hidden="true"></i>
  </div>

  <mat-dialog-content>
    <div class="filter-container" [formGroup]="formGroup">
      <div formArrayName="conditionsArray">
        @for (conditionGroup of conditionsArray.controls; let i = $index; track conditionGroup) {
          @if (i > 0) {
            <div class="logical-operator-label">{{ logicalOperatorsList[i - 1] }}</div>
          }
          <div [formGroupName]="i" class="form-array">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'CONDITIONAL_FORMATTING.SELECT_CONDITION_LABEL' | translate }}</mat-label>
              <mat-select formControlName="conditionOperation">
                @for (condition of eConditionalFormattingOperations | keyvalue; track condition) {
                  <mat-option [value]="condition.value">
                    {{ condition.value | translate }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            @if (i == 0) {
              <mat-form-field appearance="fill">
                <mat-label>{{ 'CONDITIONAL_FORMATTING.INSERT_VALUE_LABEL' | translate }}</mat-label>
                <input matInput type="number" formControlName="inputValue"/>
              </mat-form-field>
            }
            @else {
              <div class="form-array-with-bin">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'CONDITIONAL_FORMATTING.INSERT_VALUE_LABEL' | translate }}</mat-label>
                  <input matInput type="number" formControlName="inputValue"/>
                </mat-form-field>
                <i class="fa fa-solid fa-trash" (click)="removeFormFieldRow(i)"></i>
              </div>
            }
          </div>
        }
      </div>
    </div>
    <div class="add-filter">
      <div class="add-filter-button-group">
        <button mat-raised-button color="primary" (click)="addCondition(eLogicalOperators.AND)">
          <i class="fa fa-plus"></i>
          {{ 'CONDITIONAL_FORMATTING.ADD_AND_CONDITION' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="addCondition(eLogicalOperators.OR)">
          <i class="fa fa-plus"></i>
          {{ 'CONDITIONAL_FORMATTING.ADD_OR_CONDITION' | translate }}
        </button>
      </div>
      <div class="color-picker-container" onclick="this.blur()">
        <div class="color-picker-button" [(colorPicker)]="color">
          <div class="bucket-and-color-container">
            <i class="fa fa-fill-drip"></i>
            <div class="picked-color" [style.background]="color"></div>
          </div>
          <i class="fa fa-caret-down"></i>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions>
    <button mat-raised-button class="cancel-button" (click)="closeDialog()">
      {{ 'CONDITIONAL_FORMATTING.CANCEL' | translate }}
    </button>
    <button [disabled]="!formGroup.valid" mat-raised-button (click)="applyFilter()" [color]="formGroup.valid ? 'primary' : ''">
      <span [ngClass]="formGroup.valid ? 'active-button' : ''">{{ 'CONDITIONAL_FORMATTING.APPLY' | translate }}</span>
    </button>
  </mat-dialog-actions>
</div>

