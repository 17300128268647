import { createAggregatedClient } from "@smithy/smithy-client";
import { CognitoIdentityClient } from "./CognitoIdentityClient";
import { CreateIdentityPoolCommand } from "./commands/CreateIdentityPoolCommand";
import { DeleteIdentitiesCommand } from "./commands/DeleteIdentitiesCommand";
import { DeleteIdentityPoolCommand } from "./commands/DeleteIdentityPoolCommand";
import { DescribeIdentityCommand } from "./commands/DescribeIdentityCommand";
import { DescribeIdentityPoolCommand } from "./commands/DescribeIdentityPoolCommand";
import { GetCredentialsForIdentityCommand } from "./commands/GetCredentialsForIdentityCommand";
import { GetIdCommand } from "./commands/GetIdCommand";
import { GetIdentityPoolRolesCommand } from "./commands/GetIdentityPoolRolesCommand";
import { GetOpenIdTokenCommand } from "./commands/GetOpenIdTokenCommand";
import { GetOpenIdTokenForDeveloperIdentityCommand } from "./commands/GetOpenIdTokenForDeveloperIdentityCommand";
import { GetPrincipalTagAttributeMapCommand } from "./commands/GetPrincipalTagAttributeMapCommand";
import { ListIdentitiesCommand } from "./commands/ListIdentitiesCommand";
import { ListIdentityPoolsCommand } from "./commands/ListIdentityPoolsCommand";
import { ListTagsForResourceCommand } from "./commands/ListTagsForResourceCommand";
import { LookupDeveloperIdentityCommand } from "./commands/LookupDeveloperIdentityCommand";
import { MergeDeveloperIdentitiesCommand } from "./commands/MergeDeveloperIdentitiesCommand";
import { SetIdentityPoolRolesCommand } from "./commands/SetIdentityPoolRolesCommand";
import { SetPrincipalTagAttributeMapCommand } from "./commands/SetPrincipalTagAttributeMapCommand";
import { TagResourceCommand } from "./commands/TagResourceCommand";
import { UnlinkDeveloperIdentityCommand } from "./commands/UnlinkDeveloperIdentityCommand";
import { UnlinkIdentityCommand } from "./commands/UnlinkIdentityCommand";
import { UntagResourceCommand } from "./commands/UntagResourceCommand";
import { UpdateIdentityPoolCommand } from "./commands/UpdateIdentityPoolCommand";
const commands = {
  CreateIdentityPoolCommand,
  DeleteIdentitiesCommand,
  DeleteIdentityPoolCommand,
  DescribeIdentityCommand,
  DescribeIdentityPoolCommand,
  GetCredentialsForIdentityCommand,
  GetIdCommand,
  GetIdentityPoolRolesCommand,
  GetOpenIdTokenCommand,
  GetOpenIdTokenForDeveloperIdentityCommand,
  GetPrincipalTagAttributeMapCommand,
  ListIdentitiesCommand,
  ListIdentityPoolsCommand,
  ListTagsForResourceCommand,
  LookupDeveloperIdentityCommand,
  MergeDeveloperIdentitiesCommand,
  SetIdentityPoolRolesCommand,
  SetPrincipalTagAttributeMapCommand,
  TagResourceCommand,
  UnlinkDeveloperIdentityCommand,
  UnlinkIdentityCommand,
  UntagResourceCommand,
  UpdateIdentityPoolCommand
};
export class CognitoIdentity extends CognitoIdentityClient {}
createAggregatedClient(commands, CognitoIdentity);