import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ProviderService } from '../../../core/provider.service';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Observable } from 'rxjs';
import { AntiMemLeak } from '../../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-code-filter',
  templateUrl: './code-filter.component.html',
  styleUrls: ['./code-filter.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class CodeFilterComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  @Input()
  styleType: 'standard' | 'filters-bar' = 'filters-bar';
  @ViewChild('chipsFilterInputElement')
  chipsFilterInputElement!: ElementRef;
  @Output()
  codeSelectionChanged = new EventEmitter<string[]>();
  @Input()
  resetFilterObservable?: Observable<any>;
  @ViewChild(MatMenuTrigger)
  trigger!: MatMenuTrigger;
  isRemovingChip = false;
  private removeChipInterval?: any;
  private removeChipTimeout?: any;
  codeFilterEntered: any[] = [];
  filters: string[] = [];
  separatorKeysCodes: number[] = [13, 32, 188];
  loadingAutocomplete = false;
  codesAutocompleteCreateList: any = [];
  hasInsertedValueFromAutocomplete = false;
  formGroup = new FormGroup({
    autocompleteCode: new FormControl(''),
  });

  constructor(
    private provider: ProviderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.resetFilterObservable?.subscribe(() => {
        this.formGroup.controls.autocompleteCode.setValue('');
        this.filters = [];
        this.codeFilterEntered = [];
        this.emitChange();
      })
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.formGroup.controls.autocompleteCode.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          if (!this.hasInsertedValueFromAutocomplete) {
            this.getCodesAutocompleteCreate();
          } else {
            this.codesAutocompleteCreateList = [];
            this.hasInsertedValueFromAutocomplete = false;
          }
        })
    );
  }

  removeChip(item: any): void {
    this.isRemovingChip = true;
    this.chipsFilterInputElement.nativeElement.focus();
    if (this.removeChipTimeout) {
      clearTimeout(this.removeChipTimeout);
    }
    const index = this.filters.indexOf(item);
    const codeFilterIndex = this.codeFilterEntered.indexOf(item);
    if (index >= 0) {
      this.filters.splice(index, 1);
    }
    if (codeFilterIndex >= 0) {
      this.codeFilterEntered.splice(codeFilterIndex, 1);
    }
    if (this.removeChipInterval === undefined) {
      this.removeChipInterval = setInterval(() => {
        if (!this.isRemovingChip) {
          this.emitChange();
          clearInterval(this.removeChipInterval);
          this.removeChipInterval = undefined;
        }
      }, 550);
    }
    this.removeChipTimeout = setTimeout(() => {
      this.isRemovingChip = false;
    }, 500);
  }

  addChipValue(event: MatChipInputEvent): void {
    const value = event.value;
    if ((value || '').trim()) {
      this.addChip(event.value);
    }
    this.formGroup.controls.autocompleteCode.setValue('');
  }

  addChip(value: string): void {
    let filterChanged = false;
    if ((value || '').trim()) {
      if (value.includes(',')) {
        const codes = value.split(',');
        for (const code of codes) {
          if (!this.filters.includes(code)) {
            this.filters.push(code);
            this.codeFilterEntered.push(code);
            filterChanged = true;
          }
        }
      } else {
        if (!this.filters.includes(value)) {
          this.filters.push(value.trim());
          this.codeFilterEntered.push(value.trim());
          filterChanged = true;
        }
      }
    }
    this.chipsFilterInputElement.nativeElement.value = '';
    this.chipsFilterInputElement.nativeElement.focus();
    this.hasInsertedValueFromAutocomplete = true;
    if (filterChanged) {
      this.emitChange();
    }
  }

  emitChange(): void {
    this.codeSelectionChanged.emit(this.codeFilterEntered);
    this.codesAutocompleteCreateList = [];
    this.changeDetectorRef.detectChanges();
  }

  selectedFromAutocomplete(event: any): void {
    this.addChip(event.option.value);
  }

  async getCodesAutocompleteCreate(): Promise<void> {
    this.loadingAutocomplete = true;
    try {
      const codeName =
        this.formGroup.controls.autocompleteCode.value || undefined;
      const filters = {
        codeFilter: codeName + '*',
      };
      if (codeName) {
        this.codesAutocompleteCreateList =
          await this.provider.codesManagementService.getCodesList(
            'code',
            'ASC',
            this.provider.utilService.toBase64(JSON.stringify(filters)),
            0,
            10
          );
      } else {
        this.codesAutocompleteCreateList = [];
      }
    } catch (e) {
      this.loadingAutocomplete = false;
      this.codesAutocompleteCreateList = [];
      this.changeDetectorRef.detectChanges();
    } finally {
      this.changeDetectorRef.detectChanges();
      this.loadingAutocomplete = false;
    }
  }
}
