import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { SurveyModel } from '../survey-table/models/survey-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
import { ProviderService } from 'src/app/core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ValidationModuleTableCodeData } from './models/validation-module-country-table-model';
import { SelectionModel } from '@angular/cdk/collections';
import { ValidationModuleCodeListModel } from './models/validation-module-country-model';
import { CountrySurveyModel } from '../survey-country-table/models/country-survey-model';
import { ValidationCellHistoryElaborated } from './models/validation-cell-history-elaborated';

@Component({
  selector: 'app-validation-module-country-table',
  templateUrl: './validation-module-country-table.component.html',
  styleUrls: ['./validation-module-country-table.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class ValidationModuleCountryTableComponent extends AntiMemLeak implements AfterViewInit {
  @Input({ required: true }) selectedSurvey!: SurveyModel;
  @Input({ required: true }) selectedCountry!: CountrySurveyModel;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;

  loading = true;
  tableCount = 0;
  selectedCodes: string[] = [];
  dataSource = new MatTableDataSource<ValidationModuleTableCodeData>();
  originalData: ValidationModuleTableCodeData[] = [];
  displayedColumns?: string[];

  filterFormGroup = new FormGroup({
    percentageChange: new FormControl(null as unknown as number),
    yearFrom: new FormControl(moment().year() - 3),
    yearTo: new FormControl(moment().year() - 1),
  });
  yearsToDisplay: string[] = [];
  previousCell?: { cell: ValidationModuleTableCodeData; year: string };
  selectedCell?: { cell: ValidationModuleTableCodeData; year: string };
  historyLoading = false;
  cellHistory: ValidationCellHistoryElaborated[] = [];
  selection = new SelectionModel<ValidationModuleTableCodeData>(true, []);

  get selectedCellHasId(): boolean {
    return !!this.selectedCell?.cell?.dataIds[this.selectedCell?.year ?? ''];
  }

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.filterFormGroup.controls.yearFrom.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.setDisplayColumns();
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterFormGroup.controls.yearTo.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.setDisplayColumns();
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterFormGroup.controls.percentageChange.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData();
        }
      })
    );
    this.setDisplayColumns();
    this.getTableData();
  }

  groupHeaderClick(item: any): void {
    item.expanded = !item.expanded;
    this.dataSource.filter = Date.now().toString();
  }

  codeSelectionChanged($event: string[]): void {
    this.selectedCodes = $event;
    this.getTableData();
  }

  enforceMinMax(el: any): void {
    const srcElement = el.srcElement;
    if (srcElement.value != '') {
      if (parseInt(srcElement.value) < parseInt(srcElement.min)) {
        srcElement.value = srcElement.min;
      }
      if (parseInt(srcElement.value) > parseInt(srcElement.max)) {
        srcElement.value = srcElement.max;
      }
    }
  }

  async setCellValue(
    element: ValidationModuleTableCodeData,
    year: string,
    $event: number | null
  ): Promise<void> {
    if ($event) {
      if (element.dataIds[year]) {
        element.values[year] = $event;
        this.updateCell(
          element.dataIds[year] ?? '',
          $event,
          element.notes[year]
        );
      } else {
        if (this.selectedCell) {
          const cellId = await this.insertCell(
            this.selectedCell.cell,
            Number(this.selectedCell.year),
            $event
          );
          element.dataIds[this.selectedCell.year] = cellId;
          this.getCellHistory(cellId);
        }
      }
      const nextYear = Number(year) + 1;
      const previousYear = Number(year) - 1;
      if (element.values[previousYear] !== null) {
        element.percentages[year] = this.calcPercentage(
          $event,
          element.values[previousYear]!
        );
      }
      if (element.values[nextYear] !== null) {
        element.percentages[year + 1] = this.calcPercentage(
          element.values[nextYear]!,
          $event
        );
      }
    }
  }

  setSelectedCell(cell: ValidationModuleTableCodeData, year: string): void {
    this.selectedCell = {
      cell,
      year,
    };
    if (
      JSON.stringify(this.previousCell) !== JSON.stringify(this.selectedCell)
    ) {
      this.previousCell = this.selectedCell;
      const dataId = cell.dataIds[year];
      if (dataId) {
        this.getCellHistory(dataId);
      } else {
        this.cellHistory = [];
      }
    }
  }

  isCellSelected(cell: ValidationModuleTableCodeData, year: string): boolean {
    return this.selectedCell?.cell === cell && this.selectedCell?.year === year;
  }

  async getCellHistory(cellId: string): Promise<void> {
    this.historyLoading = true;
    try {
      const rawCellHistory =
        await this.providerService.validationModuleCountryService.getCellHistory(
          cellId
        );
      this.cellHistory = this.providerService.validationModuleCountryService
        .elaborateCellHistory(rawCellHistory)
        .sort((a, b) => b.order - a.order);
    } catch (e) {
      this.snackBar.open(
        'An error occured while retrieving the cell history',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.historyLoading = false;
    }
  }

  calcPercentage(x: number, y: number, fixed = 2): number | null {
    const percent = ((x - y) / y) * 100;

    if (!isNaN(percent)) {
      return Number(percent.toFixed(fixed));
    } else {
      return null;
    }
  }

  private async updateCell(
    cellId: string,
    value: number,
    note: string | null
  ): Promise<void> {
    try {
      await this.providerService.validationModuleCountryService.updateValidationModuleCell(
        this.selectedSurvey.surveyId,
        cellId,
        value,
        note,
        ''
      );
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while updating the cell value',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    }
  }

  private async insertCell(
    cell: ValidationModuleTableCodeData,
    year: number,
    value: number
  ): Promise<string> {
    try {
      return await this.providerService.validationModuleCountryService.insertCellValue(
        this.selectedSurvey.surveyId,
        this.selectedCountry.countryID,
        Number(year),
        cell.codeid,
        value,
        cell.notes[year]
      );
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while updating the cell value',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    }
    return '';
  }

  async completeSurvey(): Promise<void> {
    this.loading = true;
    try {
      await this.providerService.validationModuleCountryService.completeSurvey(
        this.selectedSurvey.surveyId,
        this.selectedCountry.countryID
      );
    } catch (e) {
      console.error(e);
      this.snackBar.open('An error occured while completing the survey', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.loading = false;
    }
  }

  async uploadData(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  private setDisplayColumns(): void {
    const yearFrom = this.filterFormGroup.controls.yearFrom.value ?? 0;
    const yearTo = this.filterFormGroup.controls.yearTo.value ?? 0;

    this.yearsToDisplay = Array.from(
      { length: yearTo - yearFrom + 1 },
      (_, i) => (yearFrom + i).toString()
    );

    this.displayedColumns = [
      'select',
      'codeName',
      ...this.yearsToDisplay,
      ...this.yearsToDisplay.map((value) => value + ' %'),
    ];
  }

  private async getTableData(): Promise<void> {
    this.loading = true;
    try {
      const backendData =
        await this.providerService.validationModuleCountryService.getValidationModuleCountryTableListAndCount(
          this.selectedSurvey.surveyId,
          this.selectedCodes.length > 0 ? this.selectedCodes : undefined,
          this.filterFormGroup.controls.percentageChange.value ?? undefined,
          this.selectedCountry.countryID,
          this.filterFormGroup.controls.yearFrom.value ?? moment().year() - 3,
          this.filterFormGroup.controls.yearTo.value ?? moment().year() - 1,
          this.paginator.pageIndex,
          this.paginator.pageSize
        );
      const elaboratedData = this.convertToValidationModuleTableData(
        backendData.data
      );
      this.dataSource.data = elaboratedData;
      this.tableCount = backendData.total;
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  convertToValidationModuleTableData(
    response: ValidationModuleCodeListModel[]
  ): ValidationModuleTableCodeData[] {
    const tableData: ValidationModuleTableCodeData[] = [];

    for (const data of response) {
      const dataIds: { [key: string]: string | null } = {};
      const values: { [key: string]: number | null } = {};
      const percentages: { [key: string]: number | null } = {};
      const notes: { [key: string]: string | null } = {};
      const sources: { [key: string]: string | null } = {};
      for (const yearToDisplay of this.yearsToDisplay) {
        const dataYear = data.years.find(
          (year) => year.year === Number(yearToDisplay)
        );
        dataIds[yearToDisplay] = dataYear?.dataId ?? null;
        values[yearToDisplay] = dataYear?.value
          ? parseFloat(dataYear?.value)
          : null;
        percentages[yearToDisplay] =
          dataYear?.percentageChange !== null &&
          dataYear?.percentageChange !== undefined
            ? parseFloat(dataYear?.percentageChange)
            : null;
        sources[yearToDisplay] = dataYear?.source ? dataYear?.source : null;
        notes[yearToDisplay] = dataYear?.note ? dataYear?.note : null;
      }
      tableData.push({
        codeid: data.codeId,
        codeName: data.codeName,
        dataIds,
        values,
        percentages,
        notes,
        sources,
      });
    }

    return tableData;
  }

  notesSelectionChanged(event: any): void {
    const value = event.target.value;
    if (value) {
      if (value !== this.selectedCell!.cell.notes[this.selectedCell!.year]) {
        this.selectedCell!.cell.notes[this.selectedCell!.year] = value;
      }
    }
  }

  async updateNotes(note?: string): Promise<void> {
    if (
      this.selectedCell &&
      this.selectedCell.cell.dataIds[this.selectedCell.year]
    ) {
      const originalDataPoint = this.originalData.find(
        (value) => value.codeid === this.selectedCell!.cell.codeid
      );
      const selectedNote =
        note === undefined
          ? this.selectedCell.cell.notes[this.selectedCell.year]
          : '';
      if (selectedNote !== originalDataPoint?.notes[this.selectedCell.year]) {
        await this.updateCell(
          this.selectedCell.cell.dataIds[this.selectedCell.year]!,
          this.selectedCell.cell.values[this.selectedCell.year]!,
          selectedNote
        );
        this.getCellHistory(
          this.selectedCell.cell.dataIds[this.selectedCell.year]!
        );
        if (originalDataPoint) {
          originalDataPoint.notes[this.selectedCell.year] = selectedNote;
        }
      }
    }
  }
}
