@if (styleType === 'standard') {
  <div class="mat-drop-down standard-form">
    <mat-label>{{'FILTERS.CODE' | translate}}</mat-label>
    <i class="fa fa-circle-info"
       matTooltip="{{'FILTERS.CODE_TOOLTIP' | translate}}"></i>
    @if (loadingAutocomplete && formGroup.controls.autocompleteCode.value !== '') {
      <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
    }
    <mat-form-field class="code-form-field">
      <mat-chip-grid #chipGrid>
        @for (filter of filters; track filter; let i = $index) {
          @if (i === 0) {
            <mat-chip-row (removed)="removeChip(filter)" [removable]="true" [editable]="false" class="standard"
              [matTooltip]="filter">
                {{ filter }}
              <button matChipRemove class="mat-chip-remove-code">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          } @else if (i === 1) {
            <mat-chip-row [removable]="false" [editable]="false" class="standard" [matMenuTriggerFor]="menu">
              +{{ filters.length - 1 }}
            </mat-chip-row>
          }
        }
        <input class="code-filter-input" matInput type="text" [ngClass]="filters.length === 0 ? '' : 'shorter-input-standard'"
               [matChipInputFor]="chipGrid" [formControl]="formGroup.controls.autocompleteCode"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" [matAutocomplete]="auto"
               (matChipInputTokenEnd)="addChipValue($event)" #chipsFilterInputElement/>
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedFromAutocomplete($event);">
        @for (code of codesAutocompleteCreateList; track code) {
          <mat-option [value]="code.code"
                      appStopBlur>
            {{code.code}}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </div>
} @else {
  <div class="form-container filters-bar">
    <div class="mat-drop-down">
      <mat-label>{{'FILTERS.CODE' | translate}}</mat-label>
      <i class="fa fa-circle-info"
         matTooltip="{{'FILTERS.CODE_TOOLTIP' | translate}}"></i>
      @if (loadingAutocomplete && formGroup.controls.autocompleteCode.value !== '') {
        <i class="fa-sharp-duotone fa-solid fa-spinner-third spinning-animation"></i>
      }
      <mat-form-field class="code-form-field">
        <mat-chip-grid #chipGrid>
          @for (filter of filters; track filter; let i = $index) {
            @if (i === 0) {
              <mat-chip-row (removed)="removeChip(filter)" [removable]="true" [editable]="false" class="standard"
              [matTooltip]="filter">
                {{ filter }}
                <button matChipRemove class="mat-chip-remove-code">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            } @else if (i === 1) {
              <mat-chip-row [removable]="false" [editable]="false" class="standard" [matMenuTriggerFor]="menu">
                +{{ filters.length - 1 }}
              </mat-chip-row>
            }
          }
          <input class="code-filter-input" matInput type="text" [ngClass]="filters.length === 0 ? '' : 'shorter-input'"
                 [matChipInputFor]="chipGrid" [formControl]="formGroup.controls.autocompleteCode"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" [matAutocomplete]="auto"
                 (matChipInputTokenEnd)="addChipValue($event)" #chipsFilterInputElement/>
        </mat-chip-grid>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedFromAutocomplete($event);">
          @for (code of codesAutocompleteCreateList; track code) {
            <mat-option [value]="code.code"
                        appStopBlur>
              {{code.code}}
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
}

<mat-menu #menu="matMenu">
  @for (filter of filters.slice(1); track filter) {
    <span class="extra-filters" mat-menu-item>
      <span>{{ filter }}</span>
      <mat-icon class="extra-filters-delete" (click)="removeChip(filter)">cancel</mat-icon>
    </span>
  }
</mat-menu>
