import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { environment } from '../../../../environments/environment';
import {
  CodeCreateMapper,
  CodeEditMapper,
  CodesCountMapper,
  CodesListMapper,
} from '../mappers/codes-mapper';
import { CodesListModel } from '../models/codes-model';
import { DatabasesListMapper } from '../mappers/databases-mapper';
import { DatabasesListModel } from '../models/databases-model';
import { CollectionsListMapper } from '../mappers/collections-mapper';
import { CollectionsModel } from '../models/collections-model';

@Injectable({
  providedIn: 'root',
})
export class CodesManagementService {
  networkService?: NetworkService;
  baseUrl = `${environment.backend.endpoint}/data-management-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getCodesList(
    sortCol: string,
    sortType: string,
    filters: string,
    pageNumber: number,
    pageSize: number
  ): Promise<CodesListModel[]> {
    const codesListMapper = new CodesListMapper();
    await this.networkService?.get(`${this.baseUrl}/code`, codesListMapper, {
      sortCol: sortCol ?? '',
      sortType: sortType,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      filters: filters || '',
    });
    return codesListMapper.codesList;
  }

  async getCodesCount(filters: any): Promise<number> {
    const codesCountMapper = new CodesCountMapper();
    await this.networkService?.get(
      `${this.baseUrl}/code/count`,
      codesCountMapper,
      {
        filters: filters,
      }
    );
    return codesCountMapper.tableDataCount;
  }

  async getDatabasesList(): Promise<DatabasesListModel[]> {
    const databasesListMapper = new DatabasesListMapper();
    await this.networkService?.get(
      `${this.baseUrl}/database`,
      databasesListMapper
    );
    return databasesListMapper.databasesList;
  }

  async getCollectionsList(): Promise<CollectionsModel[]> {
    const collectionsListMapper = new CollectionsListMapper();
    await this.networkService?.get(
      `${this.baseUrl}/collection`,
      collectionsListMapper
    );
    return collectionsListMapper.collectionsList;
  }

  async createCode(
    code: string,
    description: string,
    definition: string,
    databaseId: string,
    unitId: string,
    collectionId: string,
    decimalNumber: number
  ): Promise<void> {
    const mapper = new CodeCreateMapper();
    mapper.code = code;
    mapper.description = description;
    mapper.definition = definition;
    mapper.databaseId = databaseId;
    mapper.unitId = unitId;
    mapper.collectionId = collectionId;
    mapper.decimalNumber = decimalNumber;
    await this.networkService?.post(`${this.baseUrl}/code`, mapper, mapper);
  }

  async editCode(
    codeId: string,
    code: string,
    description: string,
    definition: string,
    databaseId: string,
    unitId: string,
    decimalNumber: number
  ): Promise<void> {
    const mapper = new CodeEditMapper();
    mapper.code = code;
    mapper.description = description;
    mapper.definition = definition;
    mapper.databaseId = databaseId;
    mapper.unitId = unitId;
    mapper.decimalNumber = decimalNumber;
    await this.networkService?.put(
      `${this.baseUrl}/code/${codeId}`,
      mapper,
      mapper
    );
  }

  async deleteCode(_codeID: string): Promise<void> {
    // const deleteCodeMapper = new CodesDeleteMapper():
    // deleteCodeMapper.codeID = codeID;
    // await this.networkService?.get(
    //   `${this.baseUrl}/delete-code`,
    //   deleteCodeMapper
    // );
  }
}
