import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { DisaggregationModel } from '../../filters/disaggregation-filter/models/disaggregation-model';
import { ProviderService } from 'src/app/core/provider.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';

@Component({
  selector: 'app-select-disaggregation-step',
  templateUrl: './select-disaggregation-step.component.html',
  styleUrls: ['./select-disaggregation-step.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class SelectDisaggregationStepComponent extends AntiMemLeak implements OnInit {
  @Input({ required: true })
  stepper!: MatStepper;
  @Input({ required: true })
  formGroup!: FormGroup;
  dataSource!: MatTableDataSource<DisaggregationModel>;
  displayedColumns = ['select', 'disaggregationDescription'];
  selection = new SelectionModel<DisaggregationModel>(true, []);
  loading = false;

  constructor(private providerService: ProviderService) {
    super();
  }

  ngOnInit(): void {
    this.getDisaggregationList();
    this.subscriptions.add(
      this.selection.changed.subscribe(() => {
        this.formGroup.controls['disaggregations'].setValue(
          this.selection.selected
        );
      })
    );
  }

  async getDisaggregationList(): Promise<void> {
    this.loading = true;
    try {
      this.dataSource = new MatTableDataSource<DisaggregationModel>(
        await this.providerService.disaggregationFilterService.getDisaggregationsList(
          1
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}
