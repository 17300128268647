import { IMapper } from '../../../../models/i-mapper';
import { CollectionModel } from '../models/collection-model';

export class ListCollectionsMapper implements IMapper {
  collectionsList: CollectionModel[] = [];
  fillFromJson(json: any): void {
    json.forEach((collectionList: any) => {
      this.collectionsList.push(collectionList);
    });
  }
  fillToJson(): string {
    return '';
  }
}
