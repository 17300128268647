<div mat-dialog-title class="header">
  <div class="title"><h1> Edit the record</h1> <i (click)="closeDialog()" matTooltip="Close" class="fa fa-times" aria-hidden="true"></i></div>
  <div class="subtitle">
    @for(headerData of headerData; track headerData) {
      <h4>{{headerData.title}}: {{headerData.value}}</h4>
    }
  </div>
</div>
@if (isLoading) {
  <app-loader></app-loader>
}
<mat-dialog-content>
  <form [formGroup]="form">
    @for(data of formData; track data; let index = $index) {
      <div class="year-source-group">
        @if (!headerColumns.includes(data.key)) {
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{data.key}}</mat-label>
            <input (input)="validateFormControls();setUserModificationsForYears(data.key, $event)" matInput type="text" formControlName="{{data.key}}" value="{{data.value}}">
            @if (form.controls[data.key].invalid && data.key !== 'countryCode') {
              <span class="error-message">
                Input should be only number!
              </span>
            }
          </mat-form-field>
        }
        @if (!headerColumns.includes(data.key)) {
          <mat-form-field matTooltip="{{data.dataId!.length === 0 ? 'Update the cell to insert source value': ''}}" appearance="outline" floatLabel="always">
            <mat-label>source</mat-label>
            @if (!(!data.value || data.dataId?.length === 0)) {
              <input (change)="getUserModificationsForSources(data.key)" matInput type="text" formControlName="{{data.key}}-source" value="{{data.dataSource}}">
            }
            @if (!data.value || data.dataId?.length === 0) {
              <input disabled="true" matInput type="text">
            }
            @if ( data.dataId?.length !== 0 && (form.controls[data.key + '-source'].value === '' && form.controls[data.key].value) && data.key !== 'countryCode') {
              <span class="source-warning">Source value is empty!</span>
            }
          </mat-form-field>
        }
      </div>

      @if (headerColumns.includes(data.key)) {
        <mat-form-field appearance="outline">
          <mat-label>{{data.key}}</mat-label>
          <input class="read-only" matInput type="text" formControlName="{{data.key}}" value="{{data.value}}" readonly>
          @if (form.controls[data.key].invalid) {
            <span class="error-message">
              Input should be only number!
            </span>
          }
        </mat-form-field>
      }
    }
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button class="cancel-button" (click)="closeDialog()">Cancel</button>
  <button [disabled]="showError" mat-raised-button class="{{showError || isLoading ? 'disabled' : 'confirm-button'}}" (click)="modifyData()">Confirm</button>
</mat-dialog-actions>

