import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SurveyModel } from '../survey-table/models/survey-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { CountrySurveyModel } from './models/country-survey-model';
import { ProviderService } from '../../core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SurveyStatusEnum } from './enums/survey-status-enum';
import { SurveyValidationStatusEnum } from './enums/survey-validation-status-enum';
import { MatDialog } from '@angular/material/dialog';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
import moment from 'moment';
import jszip from 'jszip';
import { CountriesListsModel } from '../update-country-table/models/country-filters-model';

@Component({
  selector: 'app-survey-country-table',
  templateUrl: './survey-country-table.component.html',
  styleUrl: './survey-country-table.component.scss',
})
// eslint-disable-next-line prettier/prettier
export class SurveyCountryTableComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  @Input({ required: true }) selectedSurvey!: SurveyModel;
  @Input() omitDownloadPdf = false;
  @Input() omitActions = false;
  @Input() contactValidationSelectable = true;
  @Output() countrySelected = new EventEmitter<{
    country: CountrySurveyModel;
    validateContact: boolean;
  }>();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;
  protected readonly SurveyStatusEnum = SurveyStatusEnum;
  protected readonly SurveyValidationStatusEnum = SurveyValidationStatusEnum;
  loading = true;
  filterCountrySurveyFormGroup = new FormGroup({
    surveyStatus: new FormControl(null),
    validationStatus: new FormControl(null),
  });
  dataSource!: MatTableDataSource<CountrySurveyModel>;
  displayedColumns: string[] = [];
  downloadingPDF = false;
  tableCount = 0;
  filterCountryFormGroup = new FormGroup({
    yearFrom: new FormControl(moment().year() - 3, [Validators.required]),
    yearTo: new FormControl(moment().year() - 1, [Validators.required]),
    stageFrom: new FormControl(4),
    stageTo: new FormControl(3),
  });
  progressBarValue = 0;
  selectedCountries: CountriesListsModel[] = [];
  private downloadDialog: any;

  get downloadPdfEnabled(): boolean {
    return this.filterCountryFormGroup.valid && !this.downloadingPDF;
  }

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    super();
    this.dataSource = new MatTableDataSource<CountrySurveyModel>();
  }

  countryFilterChanged($event: CountriesListsModel[]): void {
    this.selectedCountries = $event;
    this.paginator.pageIndex = 0;
    this.getTableData();
  }

  onRowClick(row: CountrySurveyModel): void {
    if (
      row.validationStatus ===
      SurveyValidationStatusEnum.CONTACT_VALIDATION_RUNNING
    ) {
      this.validateContact(row);
    } else if (
      row.validationStatus ===
        SurveyValidationStatusEnum.CONTACT_VALIDATION_COMPLETED ||
      row.validationStatus === SurveyValidationStatusEnum.COMPLETE_ERROR
    ) {
      this.selectCountry(row);
    }
  }

  ngOnInit(): void {
    if (this.omitActions) {
      this.displayedColumns = [
        'isoCode',
        'countryName',
        'surveyStatus',
        'validationStatus',
      ];
    } else {
      this.displayedColumns = [
        'isoCode',
        'countryName',
        'surveyStatus',
        'validationStatus',
        'actions',
      ];
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.filterCountrySurveyFormGroup.controls.surveyStatus.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterCountrySurveyFormGroup.controls.validationStatus.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData();
        }
      })
    );
    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.getTableData();
      })
    );
    this.getTableData();
  }

  isRowClickable(validationStatus: SurveyValidationStatusEnum): boolean {
    if (this.contactValidationSelectable) {
      if (
        validationStatus === SurveyValidationStatusEnum.COMPLETE_ERROR ||
        validationStatus ===
          SurveyValidationStatusEnum.CONTACT_VALIDATION_RUNNING ||
        validationStatus ===
          SurveyValidationStatusEnum.CONTACT_VALIDATION_COMPLETED
      ) {
        return true;
      }
    } else if (
      validationStatus ===
      SurveyValidationStatusEnum.CONTACT_VALIDATION_COMPLETED
    ) {
      return true;
    }
    return false;
  }

  private async getTableData(): Promise<void> {
    this.loading = true;
    try {
      let sortColumn = 'country';
      switch (this.sort.active) {
        case 'isoCode':
          sortColumn = 'iso_code';
          break;
        case 'countryName':
          sortColumn = 'country';
          break;
        case 'surveyStatus':
          sortColumn = 'survey_status';
          break;
        case 'validationStatus':
          sortColumn = 'validation_status';
          break;
      }
      const backendData =
        await this.providerService.surveyCountryTableService.getCountryList(
          this.paginator.pageIndex,
          this.paginator.pageSize,
          sortColumn,
          this.sort.direction,
          this.selectedSurvey.oldSurveyId,
          this.selectedCountries && this.selectedCountries.length > 0
            ? this.selectedCountries.map((value) => value.countryID)
            : undefined,
          this.filterCountrySurveyFormGroup.controls.surveyStatus.value ??
            undefined,
          this.filterCountrySurveyFormGroup.controls.validationStatus.value ??
            undefined
        );
      this.dataSource.data = backendData.data;
      this.tableCount = backendData.total;
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  selectCountry(country: CountrySurveyModel): void {
    this.countrySelected.emit({ country, validateContact: false });
  }

  validateContact(country: CountrySurveyModel): void {
    this.countrySelected.emit({ country, validateContact: true });
  }

  async startSurveyValidation(countryID: string): Promise<void> {
    this.loading = true;
    try {
      await this.providerService.surveyCountryTableService.startCountryValidation(
        this.selectedSurvey.surveyId,
        countryID
      );
      this.getTableData();
    } catch (e) {
      this.snackBar.open(
        'An error occured while starting the validation',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  showDownloadPDFDialog(dialogRef: any): void {
    this.downloadDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  async downloadPDF(): Promise<void> {
    this.downloadingPDF = true;
    try {
      this.progressBarValue = 0;
      const yearFrom = this.filterCountryFormGroup.controls.yearFrom.value ?? 0;
      const yearTo = this.filterCountryFormGroup.controls.yearTo.value ?? 0;

      const yearsToDisplay = Array.from(
        { length: yearTo - yearFrom + 1 },
        (_, i) => yearFrom + i
      );

      const countries: CountrySurveyModel[] = [];
      (
        await Promise.all([
          this.providerService.surveyCountryTableService.getCountryList(
            0,
            this.tableCount,
            'iso_code',
            'asc',
            this.selectedSurvey.oldSurveyId,
            undefined,
            undefined,
            SurveyValidationStatusEnum.COMPLETED
          ),
          this.providerService.surveyCountryTableService.getCountryList(
            0,
            this.tableCount,
            'iso_code',
            'asc',
            this.selectedSurvey.oldSurveyId,
            undefined,
            undefined,
            SurveyValidationStatusEnum.CONTACT_VALIDATION_COMPLETED
          ),
        ])
      ).map((value) => {
        countries.push(...value.data);
      });

      const generatedFiles: { fileName: string; blob: Blob }[] = [];

      const singleCountryValue = 100 / countries.length;

      if (countries.length === 0) {
        this.snackBar.open('No surveys found to download', 'X', {
          duration: 6000,
          panelClass: ['error-snackbar'],
        });
        return;
      }

      const generateFiles = async (country: CountrySurveyModel) => {
        const pdfData =
          await this.providerService.countryValidationTableService.getListForPdfGeneration(
            this.selectedSurvey.surveyId,
            country.countryID,
            this.filterCountryFormGroup.controls.yearFrom.value?.toString() ??
              (moment().year() - 3).toString(),
            this.filterCountryFormGroup.controls.yearTo.value?.toString() ??
              (moment().year() - 1).toString(),
            this.filterCountryFormGroup.controls.stageFrom.value!,
            this.filterCountryFormGroup.controls.stageTo.value!
          );
        const elaboratedData =
          this.providerService.countryValidationTableService.elaborateCountryValidationModel(
            pdfData.data,
            yearsToDisplay
          );
        generatedFiles.push(
          await this.providerService.countryValidationTableService.generatePDF(
            elaboratedData,
            this.selectedSurvey,
            country,
            yearsToDisplay
          )
        );
        this.progressBarValue += singleCountryValue;
      };

      for (const country of countries) {
        await generateFiles(country);
      }

      const zip = new jszip();

      generatedFiles.forEach((pdf) => {
        zip.file(pdf.fileName, pdf.blob);
      });

      zip.generateAsync({ type: 'blob' }).then((zipBlob) => {
        const url = URL.createObjectURL(zipBlob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.selectedSurvey.surveyName.trim()}.zip`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
      this.snackBar.open('An error occured while downloading the file', 'X', {
        duration: 6000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.progressBarValue = 0;
      this.downloadDialog.close();
      this.downloadingPDF = false;
    }
  }
}
