@if (isLoading) {
<app-loader></app-loader>
}
<div class="dialog-container">
  <div class="header">
    <div class="title">
      <h1>{{'MODIFY_ROW.TITLE'| translate}}</h1>
      <i (click)="closeDialog()" matTooltip="Close" class="fa fa-times" aria-hidden="true"></i>
    </div>
    <div class="subtitle">
      <span>{{'MODIFY_ROW.CODE'| translate}}</span>
      <span class="data">{{dialogData.data.code}}</span>
      <span>{{'MODIFY_ROW.DESCRIPTION'| translate}}</span>
      <span class="data">{{dialogData.data.description}}</span>
      <span>{{'MODIFY_ROW.COUNTRY'| translate}}</span>
      <span class="data">{{dialogData.data.countryName}}</span>
    </div>
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="form" class="form-container">
    @for(data of formData; track data; let index = $index) {
    <div class="year-source-group">
      <div class="input-container">
        <label>{{data.key}}</label>
        <input (input)="validateFormControls(); setUserModificationsForYears(data.key, $event)" matInput type="text"
          appFormatNumberInput [numDigits]="dialogData.data.decimalNumber ?? 2" formControlName="{{data.key}}"
          value="{{data.value}}">
        @if (form.controls[data.key].invalid) {
        <span class="error-message">
          {{'MODIFY_ROW.ERROR_MESSAGE'| translate}}
        </span>
        }
      </div>

      <div class="input-container"
        matTooltip="{{!data.dataId && !data.value ? 'Update the cell to insert source value': ''}}" appearance="fill"
        floatLabel="always">
        <label>{{'MODIFY_ROW.SOURCE_LABEL'| translate}}</label>
        @if (data.value || data.dataId) {
        <input matInput type="text" (dataChanged)="getUserModificationsForSources(data.key)"
          formControlName="{{data.key}}-source" value="{{data.dataSource}}" />
        }
        @if (!data.value && !data.dataId) {
        <input matInput disabled="true" type="text">
        }
      </div>
    </div>
    }
  </form>
  <div class="buttons-container">
    <button mat-raised-button class="cancel-button" (click)="closeDialog()">{{'MODIFY_ROW.CANCEL'| translate}}</button>
    <button [disabled]="showError" mat-raised-button color="{{showError || isLoading ? 'disabled' : 'primary'}}"
      (click)="modifyData()">{{'MODIFY_ROW.CONFIRM'| translate}}</button>
  </div>
</div>