import { Component } from '@angular/core';
import { IUser } from '../../../models/i-user';
import { Router } from '@angular/router';
import { ProviderService } from '../../../core/provider.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  loading: boolean;
  showPassword = false;
  errorMessageVisible = false;
  user: IUser;

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    private router: Router,
    private providerService: ProviderService
  ) {
    this.loading = false;
    this.user = {} as IUser;
  }

  signIn(): void {
    if (this.form.valid) {
      this.user.email = this.form.controls.email.value ?? '';
      this.user.password = this.form.controls.password.value ?? '';
      this.loading = true;
      this.providerService.authService
        .signIn(this.user)
        .then(() => {
          this.router.navigate(['/', 'app', 'home']);
        })
        .catch((_error: any) => {
          this.loading = false;
          this.errorMessageVisible = true;
        });
    }
  }

  navigateToSignUp(): void {
    this.providerService.utilService.navigateTo('/auth/sign-up');
  }
}
