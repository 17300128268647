<div class="upload-container">
  @if(uploadStep === 1) {
    <mat-card>
      <mat-card-header>
        <mat-card-title>Upload Data</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="upload-drop-zone" appDragDrop (fileDropped)="onFileDropped($event)">
          <p>Drag and drop your files here</p>
          <i class="fas fa-cloud-upload-alt"></i>
        </div>
        <input type="file" multiple (change)="onFileSelected($event)" hidden #fileInput>
      </mat-card-content>
      <mat-card-actions>
        <div class="upload-button">
          @if (files.length === 0) {
            <button mat-raised-button color="primary" onclick="this.blur()"
                    (click)="fileInput.click()">Select File</button>
          } @else {
            <button mat-raised-button color="primary"
                    onclick="this.blur()" (click)="checkFile(uploadDialog)">Upload File</button>
          }
        </div>
      </mat-card-actions>
    </mat-card>
  } @else if (uploadStep === 2) {
    <mat-card class="inserts-differences-table">
      <h2 class="table-title">Inserts List</h2>
      <table mat-table [dataSource]="insertsDataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>Code</th>
          <td mat-cell *matCellDef="let element"> {{element.code}} </td>
        </ng-container>
        <ng-container matColumnDef="dataYear">
          <th mat-header-cell *matHeaderCellDef>Year</th>
          <td mat-cell *matCellDef="let element"> {{element.dataYear}} </td>
        </ng-container>
        <ng-container matColumnDef="isoCode">
          <th mat-header-cell *matHeaderCellDef>Country Code</th>
          <td mat-cell *matCellDef="let element"> {{element.isoCode}} </td>
        </ng-container>
        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef>Notes</th>
          <td mat-cell *matCellDef="let element"> {{element.notes}} </td>
        </ng-container>
        <ng-container matColumnDef="notesInternal">
          <th mat-header-cell *matHeaderCellDef>Internal Notes</th>
          <td mat-cell *matCellDef="let element"> {{element.notesInternal}} </td>
        </ng-container>
        <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef>Source</th>
          <td mat-cell *matCellDef="let element"> {{element.source}} </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let element"> {{element.value}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card>
    <mat-card class="inserts-differences-table">
      <h2 class="table-title">Differences List</h2>
      <table mat-table [dataSource]="differencesDataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>Code</th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.code ? '' : 'modified-cell'">
            {{element.code ? element.code : getOldAndNewValues(element, 'code')}}
          </td>
        </ng-container>
        <ng-container matColumnDef="dataYear">
          <th mat-header-cell *matHeaderCellDef>Year</th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.dataYear ? '' : 'modified-cell'">
            {{element.dataYear ? element.dataYear : getOldAndNewValues(element, 'dataYear')}}
          </td>
        </ng-container>
        <ng-container matColumnDef="isoCode">
          <th mat-header-cell *matHeaderCellDef>Country Code</th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.isoCode ? '' : 'modified-cell'">
            {{element.isoCode ? element.isoCode : getOldAndNewValues(element, 'isoCode')}}
          </td>
        </ng-container>
        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef>Notes</th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.notes ? '' : 'modified-cell'">
            {{element.notes ? element.notes : getOldAndNewValues(element, 'notes')}}
          </td>
        </ng-container>
        <ng-container matColumnDef="notesInternal">
          <th mat-header-cell *matHeaderCellDef>Internal Notes</th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.notesInternal ? '' : 'modified-cell'">
            {{element.notesInternal ? element.notesInternal : getOldAndNewValues(element, 'notesInternal')}}
          </td>
        </ng-container>
        <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef>Source</th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.source ? '' : 'modified-cell'">
            {{element.source ? element.source : getOldAndNewValues(element, 'source')}}
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.value ? '' : 'modified-cell'">
            {{element.value ? element.value : getOldAndNewValues(element, 'value')}}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card>
    <div class="upload-button dual-button" style="width: 100%">
      <button mat-raised-button color="secondary" style="width: 200px"
              onclick="this.blur()" (click)="cancelUpload()">Cancel</button>
      <button mat-raised-button color="primary" style="width: 200px"
              onclick="this.blur()" (click)="uploadFile(uploadDialog)">Accept and Upload</button>
    </div>
  }

  @if (files.length > 0) {
    <mat-list>
      @for (file of files; track file;) {
        <mat-list-item>
          <div class="uploaded-file">
            <span> {{ file.name }} ({{ file.size | fileSizePipe }})</span>
            <button mat-icon-button (click)="removeFile(file)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </mat-list-item>
      }
    </mat-list>
  }
</div>

<ng-template #uploadDialog>
  <h2 mat-dialog-title class="mat-dialog-title">{{ 'DIALOG.UPLOAD_TITLE' | translate }}</h2>
  <mat-dialog-content>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    @if (uploadStep === 1) {
      <p>{{ 'DIALOG.UPLOAD_CHECK_MESSAGE' | translate }}</p>
    } @else if (uploadStep === 2) {
      <p>{{ 'DIALOG.UPLOADING_MESSAGE' | translate }}</p>
    }
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>{{ 'DIALOG.CANCEL' | translate }}</button>
  </mat-dialog-actions>
</ng-template>
