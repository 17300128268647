import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/net-utils/network.service';
import { environment } from 'src/environments/environment';
import { CodeCreationModel } from '../models/code-creation-model';
import { UnitModel } from '../../filters/unit-filter/models/unit-model';
import { DisaggregationModel } from '../../filters/disaggregation-filter/models/disaggregation-model';
import { CollectionModel } from '../../filters/collection-filter/models/collection-model';
import { DatabasesListModel } from '../../codes-management/models/databases-model';
import { CreateCodesMapper } from '../mappers/code-creation-mapper';

@Injectable({
  providedIn: 'root',
})
export class BulkCreateCodesService {
  private networkService!: NetworkService;
  private baseUrl = `${environment.backend.endpoint}/data-management-module`;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  generateCodeCreationModels(
    baseCode: {
      codeName: string;
      codeDescription: string;
      collection: CollectionModel | null;
      database: DatabasesListModel;
      definition: string | null;
      decimalNumber: number;
    },
    disaggregations: DisaggregationModel[],
    units: UnitModel[]
  ): CodeCreationModel[] {
    const result: CodeCreationModel[] = [];
    disaggregations.forEach((disaggregation) => {
      units.forEach((unit) => {
        result.push({
          disaggregation,
          unit,
          code: `${baseCode.codeName}-${disaggregation.disaggregationAbb}-${unit.unitAbb}`,
          description: `${baseCode.codeDescription}-${disaggregation.disaggregationAbb}-${unit.unitAbb}`,
          definition: baseCode.definition,
          collection: baseCode.collection,
          database: baseCode.database,
          decimalNumber: baseCode.decimalNumber,
        });
      });
    });
    return result;
  }

  async createCodes(codes: CodeCreationModel[]): Promise<void> {
    const mapper = new CreateCodesMapper();
    mapper.codes = codes;
    await this.networkService?.post(`${this.baseUrl}/code`, mapper, mapper);
  }
}
