import { IMapper } from '../../../models/i-mapper';
import {
  CountryYearsInfoModel,
  DataModel,
  ListCountryRequest,
} from '../models/update-country-table-models';
export class TableDataMapper implements IMapper {
  filterRequestBody?: ListCountryRequest;
  countriesData = [] as DataModel[];
  fillFromJson(json: any): void {
    let years: CountryYearsInfoModel[] = [];
    json.forEach((country: any) => {
      years = [];
      country.years.forEach((year: any) => {
        years.push({
          id: year.id,
          year: year.year,
          value: year.value,
        });
      });
      this.countriesData?.push({
        code: country.code,
        description: country.description,
        countryId: country.countryId,
        codeId: country.codeId,
        countryName: country.countryName,
        years: years,
      });
    });
  }
  fillToJson(): string {
    return JSON.stringify(this.filterRequestBody);
  }
}
