import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RegionDetailsModel, YearModel } from '../models/region-details-model';
import { ProviderService } from '../../../core/provider.service';
import { ClassModel } from '../models/class-model';
import { RegionsListsModel } from '../models/region-filters-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CountriesListsModel } from '../../update-country-table/models/country-filters-model';
import { BehaviorSubject } from 'rxjs';

export interface YearCountryTable {
  year: number;
  countryID: string;
  isoCode: string;
  shortName: string;
}

@Component({
  selector: 'app-region-detail',
  templateUrl: './region-detail.component.html',
  styleUrl: './region-detail.component.scss',
})
export class RegionDetailComponent implements OnInit {
  @Input()
  inputRegionDetails!: RegionDetailsModel;
  @Input()
  regionsList!: RegionsListsModel[];
  @Input()
  classesList!: ClassModel[];
  @Output()
  loadingDetails = new EventEmitter<boolean>();
  @Output()
  changeDetails = new EventEmitter<RegionDetailsModel>();
  createRegionDialog: any;
  hasNoYears!: boolean;
  hideYears!: boolean;
  isCreatingNewClass = false;
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
      this.dataSource.sortingDataAccessor = (item: any, property: any) => {
        const columnName = property;
        if (columnName === 'regionCountryCode') {
          return item['isoCode'].toString().toLowerCase();
        } else if (columnName === 'regionCountryName') {
          return item['shortName'].toString().toLowerCase();
        } else {
          return item[columnName].toString().toLowerCase();
        }
      };
    }
  }
  @ViewChild('countriesElement')
  countriesElement!: ElementRef;
  @ViewChild('countriesElement2')
  countriesElement2!: ElementRef;
  @ViewChild('yearElement')
  yearElement!: ElementRef;
  @ViewChild('classElement')
  classElement!: ElementRef;
  @ViewChild('parentRegionElement')
  parentRegionElement!: ElementRef;
  conditions: any[] = [];
  dataSource!: MatTableDataSource<YearCountryTable>;
  selectedYear!: number;
  selection = new SelectionModel<any>(true, []);
  displayedColumns = [
    'select',
    'year',
    'regionCountryCode',
    'regionCountryName',
  ];
  alreadyExistingYears: YearModel[] = [];
  forbiddenYears: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  addYearFormGroup = new FormGroup({
    year: new FormControl('', [
      Validators.required,
      this.alreadyUsedYears(this.forbiddenYears),
    ]),
    countries: new FormControl([], Validators.required),
    default: new FormControl(false, Validators.required),
    autocomplete: new FormControl(''),
    autocompleteCountries: new FormControl(''),
  });
  addCountriesFormGroup = new FormGroup({
    countries: new FormControl([], Validators.required),
    autocomplete: new FormControl(''),
  });
  filterRegionFormGroup = new FormGroup({
    region: new FormControl(''),
    regionCode: new FormControl(''),
  });
  classInfo!: ClassModel | null;
  parentRegionInfo!: RegionsListsModel | null;
  loading = false;
  editRegionFormGroup = new FormGroup({
    regionName: new FormControl('', Validators.required),
    regionCode: new FormControl('', Validators.required),
    parentRegion: new FormControl(''),
    class: new FormControl(''),
    newClassName: new FormControl(''),
    newClassCode: new FormControl(''),
    newClassDescription: new FormControl(''),
    autocomplete: new FormControl(''),
    autocompleteParent: new FormControl(''),
  });
  countries: CountriesListsModel[] = [];
  addableCountries: CountriesListsModel[] = [];
  regionsWithYear: YearCountryTable[] = [];
  selectedCopyfrom = true;
  manuallySelect = true;
  copyFromSelectedYear!: number;
  selectionIncomplete = false;

  constructor(
    private dialog: MatDialog,
    private provider: ProviderService,
    private snackBar: MatSnackBar
  ) {
    this.dataSource = new MatTableDataSource<YearCountryTable>([]);
    this.dataSource.filterPredicate = this.createFilter();
  }

  async ngOnInit(): Promise<void> {
    this.loadingDetails.emit(true);
    this.selectedYear = this.inputRegionDetails.years[0].year;
    this.alreadyExistingYears = this.inputRegionDetails.years;
    this.forbiddenYears.next(this.alreadyExistingYears.map((a) => a.year));
    this.hasNoYears =
      this.alreadyExistingYears.length === 1 &&
      this.alreadyExistingYears[0].year === null;
    const classId = this.inputRegionDetails.classID;
    if (classId) {
      this.classInfo =
        this.classesList.find((c: ClassModel) => c.classID === classId) || null;
    }
    const parentRegionId = this.inputRegionDetails.parentRegionID;
    if (parentRegionId) {
      this.parentRegionInfo =
        this.regionsList.find(
          (c: RegionsListsModel) => c.regionID === parentRegionId
        ) || null;
    }
    for (const yearList of this.inputRegionDetails.years) {
      this.regionsWithYear.push(
        ...yearList.countries.map((c: any) => {
          return { ...c, year: yearList.year };
        })
      );
    }
    this.dataSource.data = this.regionsWithYear;
    this.resetRegionInfo();
    this.loadingDetails.emit(false);
    this.selectAll();
    this.dataSource.filter = this.selectedYear?.toString() || '0';
  }

  selectAll(): void {
    this.selection.select(...this.dataSource.data);
  }

  checkIfPartialSelection(): void {
    const selectedIDs = this.selection.selected
      .filter((s) => s.year === this.selectedYear)
      .map((s) => s.countryID)
      .sort();

    const filteredDataIDs = this.dataSource.filteredData
      .map((s) => s.countryID)
      .sort();
    this.selectionIncomplete =
      JSON.stringify(selectedIDs) !== JSON.stringify(filteredDataIDs);
  }

  switchToYear(year: number): void {
    this.selectedYear = year;
    this.dataSource.filter = year.toString();
    this.checkIfPartialSelection();
  }

  onOpenedYearChange(isOpened: boolean): void {
    if (isOpened) {
      this.yearElement.nativeElement.focus();
    }
  }

  onOpenedClassChange(isOpened: boolean): void {
    if (isOpened) {
      this.classElement.nativeElement.focus();
    }
  }

  onOpenedParentChange(isOpened: boolean): void {
    if (isOpened) {
      this.parentRegionElement.nativeElement.focus();
    }
  }

  onOpenedCountryChange(isOpened: boolean): void {
    if (isOpened) {
      this.countriesElement.nativeElement.focus();
    }
  }

  onOpenedAddCountryChange(isOpened: boolean): void {
    if (isOpened) {
      this.countriesElement2.nativeElement.focus();
    }
  }

  createFilter(): (data: any, filter: string) => boolean {
    return (data: any, filter: string): boolean => {
      const year = Number(filter);
      return data.year === year;
    };
  }

  removeClass(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.isCreatingNewClass = false;
    this.editRegionFormGroup.controls.newClassName.clearValidators();
    this.editRegionFormGroup.controls.newClassCode.clearValidators();
    this.editRegionFormGroup.controls.newClassName.updateValueAndValidity();
    this.editRegionFormGroup.controls.class.setValue('');
    this.editRegionFormGroup.controls.autocomplete.setValue('');
    this.classElement.nativeElement.focus();
  }

  removeParentRegion(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.editRegionFormGroup.controls.parentRegion.setValue('');
    this.editRegionFormGroup.controls.autocompleteParent.setValue('');
    this.parentRegionElement.nativeElement.focus();
  }

  removeYear(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.addYearFormGroup.controls.countries.setValue([]);
    this.addYearFormGroup.controls.autocomplete.setValue('');
    this.manuallySelect = true;
    this.selectedCopyfrom = true;
    this.yearElement.nativeElement.focus();
  }

  visuallyFilterYear(year: any): boolean {
    const yearString = year.toString().toLowerCase();
    const input = (
      this.addYearFormGroup.controls.autocomplete.value || ''
    ).toLowerCase();
    return input === '' || yearString.includes(input);
  }

  visuallyFilterClass(cl: any): boolean {
    const classString = cl.toString().toLowerCase();
    const input = (
      this.editRegionFormGroup.controls.autocomplete.value || ''
    ).toLowerCase();
    return input === '' || classString.includes(input);
  }

  visuallyFilterRegion(region: any): boolean {
    const regionString = region.toString().toLowerCase();
    const input = (
      this.editRegionFormGroup.controls.autocompleteParent.value || ''
    ).toLowerCase();
    return input === '' || regionString.includes(input);
  }

  displayClassFn(classId: any): string {
    if (classId === 'new-class') return 'Create New Class';
    return classId
      ? this.classesList.find((cl) => cl.classID === classId)?.className ?? ''
      : '';
  }

  displayParentFn(value: any): string {
    return value
      ? this.regionsList.find((region) => region.regionID === value)
          ?.regionName ?? ''
      : '';
  }

  async openAddYearDialog(dialogRef: any): Promise<any> {
    this.loadingDetails.emit(true);
    if (this.countries.length === 0) {
      this.countries =
        await this.provider.regionsManagementService.getCountriesByRegions();
    }
    this.loadingDetails.emit(false);
    this.createRegionDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  async openAddCountryDialog(dialogRef: any): Promise<any> {
    this.loadingDetails.emit(true);
    this.countries =
      await this.provider.regionsManagementService.getCountriesByRegions();
    const nonAddableCountries =
      this.inputRegionDetails.years.find((y) => y.year === this.selectedYear)
        ?.countries || [];
    this.addableCountries = this.countries.filter(
      (addableCountry) =>
        !nonAddableCountries.some(
          (nonAddableCountry) =>
            nonAddableCountry.countryID === addableCountry.countryID
        )
    );
    this.loadingDetails.emit(false);
    this.createRegionDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  async openEditRegionDialog(dialogRef: any): Promise<any> {
    this.createRegionDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  resetRegionInfo(): void {
    this.editRegionFormGroup.controls.regionName.setValue(
      this.inputRegionDetails.regionName
    );
    this.editRegionFormGroup.controls.regionCode.setValue(
      this.inputRegionDetails.regionCode
    );
    this.editRegionFormGroup.controls.parentRegion.setValue(
      this.inputRegionDetails.parentRegionID || ''
    );
    this.editRegionFormGroup.controls.class.setValue(
      this.inputRegionDetails.classID || ''
    );
  }

  resetAddYear(): void {
    this.addYearFormGroup.controls.year.setValue('');
    this.addYearFormGroup.controls.countries.setValue([]);
    this.addYearFormGroup.controls.autocomplete.setValue('');
    this.addYearFormGroup.controls.year.markAsUntouched();
    this.addYearFormGroup.controls.countries.markAsUntouched();
    this.selectedCopyfrom = true;
    this.manuallySelect = true;
  }

  resetAddCountry(): void {
    this.addCountriesFormGroup.controls.countries.setValue([]);
    this.editRegionFormGroup.controls.autocomplete.setValue('');
  }

  displayFn(value: any): string {
    return value
      ? this.countries.find(
          (country: any) => country.countryID === value.countryID
        )?.shortName ?? ''
      : value;
  }

  displayMultipleFn(value: any): string {
    let result = '';
    let index = 0;
    for (const v of value) {
      index++ === 0
        ? (result = `${this.displayFn(v)}`)
        : (result = `${result}, ${this.displayFn(v)}`);
    }
    return result;
  }

  visuallyFilterCountries(country: CountriesListsModel): boolean {
    const countryName = country.shortName?.toLowerCase() || '';
    const countryisoCode = country.isoCode?.toLowerCase() || '';
    const input = (
      this.addYearFormGroup.controls.autocompleteCountries.value || ''
    ).toLowerCase();
    return (
      input === '' ||
      countryName.includes(input) ||
      countryisoCode.includes(input)
    );
  }

  visuallyFilterCountries2(country: CountriesListsModel): boolean {
    const countryName = country.shortName?.toLowerCase() || '';
    const countryisoCode = country.isoCode?.toLowerCase() || '';
    const input = (
      this.addCountriesFormGroup.controls.autocomplete.value || ''
    ).toLowerCase();
    return (
      input === '' ||
      countryName.includes(input) ||
      countryisoCode.includes(input)
    );
  }

  removeCountryOrRegionFilter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.manuallySelect = true;
    this.selectedCopyfrom = true;
    this.addYearFormGroup.controls.countries.setValue([]);
  }

  removeCountries(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.addCountriesFormGroup.controls.countries.setValue([]);
  }

  async addYear(): Promise<void> {
    try {
      this.loadingDetails.emit(true);
      this.dialog.closeAll();
      const region: RegionDetailsModel = {
        regionID: this.inputRegionDetails.regionID,
        regionCode: this.inputRegionDetails.regionCode,
        regionName: this.inputRegionDetails.regionName,
        classID: this.inputRegionDetails.classID,
        parentRegionID: this.inputRegionDetails.parentRegionID,
        years: this.inputRegionDetails.years.concat({
          year: parseInt(this.addYearFormGroup.controls.year.value || ''),
          countries: this.addYearFormGroup.controls.countries.value || [],
        }),
      };
      await this.provider.regionsManagementService.modifyRegion(region);
      if (this.hasNoYears) {
        this.hasNoYears = false;
      }
      for (const country of this.addYearFormGroup.controls.countries.value ||
        []) {
        this.regionsWithYear.push({
          year: parseInt(this.addYearFormGroup.controls.year.value || ''),
          countryID: country['countryID'],
          isoCode: country['isoCode'],
          shortName: country['shortName'],
        });
      }
      this.dataSource.data = this.regionsWithYear;
      this.alreadyExistingYears.push({
        year: parseInt(this.addYearFormGroup.controls.year.value || ''),
        countries: this.addYearFormGroup.controls.countries.value || [],
      });
      this.forbiddenYears.next(this.alreadyExistingYears.map((a) => a.year));
      this.selectAll();
      this.switchToYear(
        parseInt(this.addYearFormGroup.controls.year.value || '')
      );
      this.reloadRegionDetails(region);
      this.resetRegionInfo();
      this.resetAddYear();
      this.snackBar.open('Year added succesfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
    } catch (e) {
      this.snackBar.open('An error occured while adding the year', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.loadingDetails.emit(false);
    }
  }

  async removeUpdateCountries(): Promise<void> {
    const updatedYears = JSON.parse(
      JSON.stringify(this.inputRegionDetails.years)
    );
    const yearDetail = updatedYears.find(
      (y: any) => y.year === this.selectedYear
    );
    const updatedCountries = this.dataSource.filteredData.filter((item) =>
      this.selection.isSelected(item)
    );
    yearDetail.countries = updatedCountries;

    try {
      this.loadingDetails.emit(true);
      this.dialog.closeAll();
      const region: RegionDetailsModel = {
        regionID: this.inputRegionDetails.regionID,
        regionCode: this.inputRegionDetails.regionCode,
        regionName: this.inputRegionDetails.regionName,
        classID: this.inputRegionDetails.classID,
        parentRegionID: this.inputRegionDetails.parentRegionID,
        years: updatedYears,
      };
      await this.provider.regionsManagementService.modifyRegion(region);
      this.regionsWithYear = this.regionsWithYear.filter(
        (obj) =>
          obj.year !== this.selectedYear ||
          updatedCountries.some(
            (updatedObj) =>
              obj.year === updatedObj.year &&
              obj.countryID === updatedObj.countryID
          )
      );
      this.dataSource.data = this.regionsWithYear;
      this.dataSource.filter = this.selectedYear.toString();
      this.selectAll();
      this.reloadRegionDetails(region);
      this.selectionIncomplete = false;
      this.snackBar.open('Countries removed succesfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
    } catch (e) {
      this.snackBar.open('An error occured while adding the year', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.loadingDetails.emit(false);
    }
  }

  async addCountries(): Promise<void> {
    const updatedYears = JSON.parse(
      JSON.stringify(this.inputRegionDetails.years)
    );
    const yearDetail = updatedYears.find(
      (y: any) => y.year === this.selectedYear
    );
    yearDetail.countries.push(
      ...(this.addCountriesFormGroup.controls.countries.value || [])
    );
    try {
      this.loadingDetails.emit(true);
      this.dialog.closeAll();
      const region: RegionDetailsModel = {
        regionID: this.inputRegionDetails.regionID,
        regionCode: this.inputRegionDetails.regionCode,
        regionName: this.inputRegionDetails.regionName,
        classID: this.inputRegionDetails.classID,
        parentRegionID: this.inputRegionDetails.parentRegionID,
        years: updatedYears,
      };
      await this.provider.regionsManagementService.modifyRegion(region);
      for (const country of this.addCountriesFormGroup.controls.countries
        .value || []) {
        this.regionsWithYear.push({
          year: this.selectedYear,
          countryID: country['countryID'],
          isoCode: country['isoCode'],
          shortName: country['shortName'],
        });
      }
      this.dataSource.filter = this.selectedYear.toString();
      this.selectAll();
      this.reloadRegionDetails(region);
      this.selectionIncomplete = false;
      this.snackBar.open('Countries added succesfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
    } catch (e) {
      this.snackBar.open('An error occured while adding the year', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.loadingDetails.emit(false);
    }
  }

  async submitEditRegion(): Promise<void> {
    try {
      this.loadingDetails.emit(true);
      this.dialog.closeAll();
      if (this.isCreatingNewClass) {
        const newClassID =
          await this.provider.regionsManagementService.createClassification(
            this.editRegionFormGroup.controls.newClassName.value || '',
            this.editRegionFormGroup.controls.newClassCode.value || '',
            this.editRegionFormGroup.controls.newClassDescription.value || ''
          );
        this.classesList =
          await this.provider.regionsManagementService.getClassList();
        this.editRegionFormGroup.controls.class.setValue(newClassID || '');
      }
      const region: RegionDetailsModel = {
        regionID: this.inputRegionDetails.regionID,
        regionCode: this.editRegionFormGroup.controls.regionCode.value || '',
        regionName: this.editRegionFormGroup.controls.regionName.value || '',
        years: this.inputRegionDetails.years,
      };
      if (this.editRegionFormGroup.controls.parentRegion.value) {
        region.parentRegionID =
          this.editRegionFormGroup.controls.parentRegion.value || '';
      }
      if (this.editRegionFormGroup.controls.class.value) {
        region.classID = this.editRegionFormGroup.controls.class.value || '';
      }
      await this.provider.regionsManagementService.modifyRegion(region);
      this.reloadRegionDetails(region);
      this.resetRegionInfo();
      this.resetAddYear();
      this.isCreatingNewClass = false;
      this.snackBar.open('Region edited succesfully', 'X', {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
    } catch (e) {
      this.snackBar.open('An error occured while editing the region', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.loadingDetails.emit(false);
    }
  }

  reloadRegionDetails(region: RegionDetailsModel): void {
    this.changeDetails.emit(region);
    this.classInfo =
      this.classesList.find(
        (c) => c.classID === this.editRegionFormGroup.controls.class.value
      ) || null;
    this.parentRegionInfo =
      this.regionsList.find(
        (r) =>
          r.regionID === this.editRegionFormGroup.controls.parentRegion.value
      ) || null;
  }

  onDefaultCountriesChange(): void {
    if (this.addYearFormGroup.controls.default.value) {
      this.addYearFormGroup.controls.year.setValue('0');
      this.addYearFormGroup.controls.year.disable();
      this.hideYears = true;
    } else {
      this.addYearFormGroup.controls.year.setValue(null);
      this.addYearFormGroup.controls.year.enable();
      this.hideYears = false;
    }
  }

  alreadyUsedYears(forbiddenYears$: BehaviorSubject<number[]>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbiddenYears = forbiddenYears$.value;
      const forbidden = forbiddenYears.includes(control.value);
      return forbidden ? { forbiddenYear: { value: control.value } } : null;
    };
  }

  setupForNewClass(): void {
    this.isCreatingNewClass = true;
    this.editRegionFormGroup.controls.newClassName.setValidators([
      Validators.required,
      Validators.maxLength(255),
    ]);
    this.editRegionFormGroup.controls.newClassCode.setValidators([
      Validators.required,
      Validators.maxLength(20),
    ]);
    this.editRegionFormGroup.controls.newClassName.updateValueAndValidity();
  }
}
